import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../../constants/colors';

const Star = ({ color, ...rest }) => (
  <svg viewBox="0 0 16 16" {...rest}>
    <path fill="#fff" fillOpacity=".01" d="M0 0h16v16H0z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.065 1.338c.038.008.048.009.085.023a.41.41 0 0 1 .197.165c.02.033.023.043.038.079l1.537 4.354h4.337l.044.002.043.007a.41.41 0 0 1 .294.545.408.408 0 0 1-.096.146l-.033.029-3.584 2.816 1.542 4.626.012.043c.002.015.006.03.007.044a.409.409 0 0 1-.61.395c-.013-.007-.025-.016-.037-.024L8 11.772 4.16 14.587l-.038.024c-.013.007-.026.015-.04.02a.409.409 0 0 1-.563-.46l.012-.042 1.542-4.626-3.584-2.816-.033-.029-.03-.032a.41.41 0 0 1 .271-.666l.044-.002H6.08l1.536-4.354.017-.04c.019-.034.022-.044.046-.075a.41.41 0 0 1 .387-.152Z"
      fill={color}
    />
  </svg>
);

Star.defaultProps = {
  width: 16,
  height: 16,
  color: colors.highlightYellow,
};

Star.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Star);
