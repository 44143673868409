import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import DocumentIcon from '../../../../admin/components/FilePreview/DocumentIcon';
import HelpfulInfoCard from '../../../../components/HelpfulInfo';
import HelpfulInfoList from '../../../../components/HelpfulInfo/HelpfulInfoList';
import HelpfulInfoListItem from '../../../../components/HelpfulInfo/HelpfulInfoListItem';
import { SUB_DIVISION_NAMES_KEYS } from '../../../../constants/lease_agreement/sub-division-names';
import PropertyRolesEnum from '../../../../helpers/propertyRolesEnum';
import BookIcon from '../../../../icons/Informational';
import SectionTitle from '../../common/SectionTitle';

import AdditionsToLeaseAgreementAZ from './state_specific/AZ/AdditionsToLeaseAgreementAZ';
import AdditionsToLeaseAgreementDC from './state_specific/DC/AdditionsToLeaseAgreementDC';
import VoterRegistrationInformationDC from './state_specific/DC/VoterRegistrationInformationDC';
import DEResidentialLandlordTenantCode from './state_specific/DE/ResidentialLandlordTenantCode';
import AdditionsToLeaseAgreement from './state_specific/IL/AdditionsToLeaseAgreement';
import AdditionsToLeaseAgreementMD from './state_specific/MD/AdditionsToLeaseAgreementMD';
import AdditionalTermsHelper from './state_specific/VA/AdditionalTermsHelper/AdditionalTermsHelper';
import AdditionsToLeaseAgreementWA from './state_specific/WA/AdditionsToLeaseAgreementWA';

import styles from './SectionAdditionalProvisions.module.scss';

export const SectionAdditionalProvisionsTermsSection = ({
  state,
  leaseAgreementSelection,
  propertyRole,
  children,
}) => (
  <div className={cx(styles.column, styles.termsSection)}>
    <SectionTitle title="Additional Terms" icon={DocumentIcon} />
    <label className={cx(styles.headerLabel, styles.additionalTermsLabel)}>
      Are there any additional terms you’d like to add to this agreement?
    </label>
    <p className={styles.description}>
      This is the step where you can add any custom clauses, rules, or
      provisions specific to your property and/or local area. Language added
      here will supersede any conflicting provisions within the lease agreement.
    </p>
    <p className={styles.description}>
      If you'd prefer to avoid conflicting provisions or make further
      customizations use our Advanced Editor instead, after all sections of the
      Builder are complete.
    </p>
    {state === 'DE' && (
      <div>
        <DEResidentialLandlordTenantCode />
      </div>
    )}

    {(leaseAgreementSelection === SUB_DIVISION_NAMES_KEYS.cookCounty ||
      leaseAgreementSelection === SUB_DIVISION_NAMES_KEYS.chicago) && (
      <div>
        <AdditionsToLeaseAgreement subDivisionName={leaseAgreementSelection} />
      </div>
    )}
    {state === 'MD' && (
      <div>
        <AdditionsToLeaseAgreementMD
          subDivisionName={leaseAgreementSelection}
        />
      </div>
    )}
    {state === 'AZ' && (
      <div>
        <AdditionsToLeaseAgreementAZ />
      </div>
    )}
    {state === 'VA' && (
      <div>
        <AdditionalTermsHelper />
      </div>
    )}
    {state === 'DC' && (
      <div>
        <AdditionsToLeaseAgreementDC />
        <VoterRegistrationInformationDC />
      </div>
    )}
    {(leaseAgreementSelection === SUB_DIVISION_NAMES_KEYS.tacoma ||
      leaseAgreementSelection === SUB_DIVISION_NAMES_KEYS.seattle) && (
      <div>
        <AdditionsToLeaseAgreementWA
          leaseAgreementSelection={leaseAgreementSelection}
        />
      </div>
    )}
    <p className={styles.description}>
      If you add additional terms here or are using the advanced editor, you may
      consider speaking with a local lawyer to ensure compliance.
    </p>
    {propertyRole === PropertyRolesEnum.ROOM && (
      <HelpfulInfoCard
        Icon={BookIcon}
        className={styles.helpfulInfoCard}
        title="Room rentals typically include extra terms. Some examples include:"
      >
        <HelpfulInfoList>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            Definition of shared spaces (bathrooms, kitchen, outdoor space,
            etc.)
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            Which entrance to use
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            Furnishing details
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            Laundry rules, cleaning and maintenance
          </HelpfulInfoListItem>
          <HelpfulInfoListItem className={styles.helpfulItem}>
            Quiet hours
          </HelpfulInfoListItem>
        </HelpfulInfoList>
      </HelpfulInfoCard>
    )}
    {children}
  </div>
);

SectionAdditionalProvisionsTermsSection.propTypes = {
  state: PropTypes.string,
  leaseAgreementSelection: PropTypes.string,
  propertyRole: PropTypes.string,
  children: PropTypes.node,
};
