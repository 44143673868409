import { useQuery } from '@apollo/client';
import get from 'lodash/get';
import update from 'lodash/update';

const usePaginatedQuery = (query, dataPath, options = {}) => {
  const response = useQuery(query, options);
  const responseData = get(response, dataPath, {});
  const pageInfo = get(responseData, 'pageInfo', {});
  let requestSent = false;
  return {
    loading: response.loading && response.networkStatus !== 6,
    data: get(responseData, 'edges', []).map((edge) => edge.node),
    error: response.error,
    total: get(responseData, 'total', 0),
    pageInfo,
    fetchMore: () => {
      if (!pageInfo.hasNextPage || !pageInfo.endCursor) return;
      if (requestSent) return;
      requestSent = true;
      response.fetchMore({
        variables: {
          after: pageInfo.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          const prevData = get(prev, dataPath, {});
          const newData = get(fetchMoreResult, dataPath, {});

          // merge edges from newData to prevData
          const mergedEdges = [...prevData.edges, ...newData.edges];

          // update the cache with the new edges
          return update(prev, dataPath, () => ({
            ...newData,
            edges: mergedEdges,
          }));
        },
      });
    },
  };
};

export default usePaginatedQuery;
