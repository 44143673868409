import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { allowedColors, allowedSizes, iconNames } from './constants';

import fontStyles from './MCFont.module.scss';
import styles from './MultiColorIcon.module.scss';

const MultiColorIcon = ({
  name,
  className,
  size = 'md',
  color = 'default',
  ...rest
}) => (
  <span
    style={{
      fontSize: typeof size === 'number' && `${size}px`,
      ...(!allowedColors.includes(color) ? { color } : {}),
    }}
    className={cx(
      styles.mcIcon,
      fontStyles[`tt-icon-mc-${name}`],
      {
        [styles[`font-size-${size}`]]: allowedSizes.includes(size),
        [styles[`font-color-${color}`]]: allowedColors.includes(color),
      },
      className,
    )}
    {...rest}
  >
    {Array(14)
      .fill(0)
      .map((_, i) => (
        <span
          key={i}
          className={cx(
            fontStyles[`tt-icon-mc-${name}`],
            fontStyles[`path${i + 1}`],
          )}
        />
      ))}
  </span>
);

MultiColorIcon.propTypes = {
  name: PropTypes.oneOf(iconNames).isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(allowedSizes)]),
  color: PropTypes.oneOf(allowedColors),
};

export default MultiColorIcon;
