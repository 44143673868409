import { useReducer, useState } from 'react';
import debounce from 'lodash.debounce';

import {
  DOCUMENTS_SORTING_CRITERIA,
  DOCUMENTS_SORTING_OPTIONS,
} from '../../constants/documents/documents_sorting_options';

const sortInitialState = DOCUMENTS_SORTING_OPTIONS.NEWEST;

const filtersInitialState = {
  listingIds: [],
  leaseIds: [],
  categories: [],
  esignStatuses: undefined,
  search: '',
  isShared: undefined,
};

export const useDocumentsFilters = () => {
  const [filters, setFilters] = useReducer(
    (oldFilters, newFilters) => ({
      ...oldFilters,
      ...newFilters,
    }),
    filtersInitialState,
  );

  const [sortConfig, setSortConfig] = useState(sortInitialState);

  const areFiltersActive =
    Object.values(filters).some((filter) => filter && filter.length > 0) ||
    filters.isShared !== undefined;

  const handleSearch = (search) => {
    if (search.length > 2 || search === '') {
      setFilters({ search });
    }
  };

  const onFilterChange = (type, value) => {
    setFilters({ [type]: value });
  };

  const onSortingChange = (type) => {
    if (type === DOCUMENTS_SORTING_CRITERIA.CREATED_AT) {
      setSortConfig(
        sortConfig === DOCUMENTS_SORTING_OPTIONS.NEWEST
          ? DOCUMENTS_SORTING_OPTIONS.OLDEST
          : DOCUMENTS_SORTING_OPTIONS.NEWEST,
      );
    }

    if (type === DOCUMENTS_SORTING_CRITERIA.RENTAL) {
      setSortConfig(
        sortConfig === DOCUMENTS_SORTING_OPTIONS.RENTAL_ASC
          ? DOCUMENTS_SORTING_OPTIONS.RENTAL_DESC
          : DOCUMENTS_SORTING_OPTIONS.RENTAL_ASC,
      );
    }
  };

  const debouncedHandleSearch = debounce(handleSearch, 800);

  const resetFilters = () => {
    setFilters(filtersInitialState);
    setSortConfig(sortInitialState);
  };

  return {
    filters,
    resetFilters,
    sortConfig,
    handleSearch: debouncedHandleSearch,
    setSortConfig,
    onFilterChange,
    onSortingChange,
    areFiltersActive,
  };
};

export default useDocumentsFilters;
