import React from 'react';
import PropTypes from 'prop-types';

import openInNewTab from '../../../../helpers/openInNewTab';
import Star from '../../../../icons/streamline/filled/Star';
import Card from '../../../Card';

import styles from './GoogleReviewsCard.module.scss';

const GoogleReviewsCard = ({ link, image, name, units, review, rating }) => {
  return (
    <Card className={styles.reviewCard} onClick={() => openInNewTab(link)}>
      <div className={styles.header}>
        <img src={image} className={styles.reviewerImage} />
        <div>
          <span className={styles.name}>{name}</span>
          <span className={styles.units}>{units} Unit Landlord</span>
        </div>
      </div>
      <div className={styles.rating}>{Array(rating).fill(<Star />)}</div>
      <div className={styles.review}>{review}</div>
    </Card>
  );
};

GoogleReviewsCard.propTypes = {
  link: PropTypes.string,
  image: PropTypes.string,
  name: PropTypes.string,
  units: PropTypes.number,
  review: PropTypes.string,
  rating: PropTypes.number,
};

export default GoogleReviewsCard;
