import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ArrowFilledDown = ({ color, ...rest }) => (
  <svg {...rest} viewBox="0 0 24 24" fill="none">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7916 10H7.20825C7.13325 10 7.06408 10.0289 7.027 10.0756C6.98992 10.1223 6.99117 10.1794 7.02992 10.2255L11.8216 15.928C11.8587 15.9726 11.927 16 11.9999 16C12.0733 16 12.1408 15.9726 12.1783 15.928L16.9699 10.2255C17.0091 10.1794 17.0099 10.1223 16.9728 10.0756C16.9358 10.0289 16.8666 10 16.7916 10Z"
        fill={color}
      />
    </g>
  </svg>
);

ArrowFilledDown.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

ArrowFilledDown.defaultProps = {
  width: 24,
  height: 24,
  color: 'currentColor',
};

export default memo(ArrowFilledDown);
