import React from 'react';
import PropTypes from 'prop-types';

import parseScreeningReportDate from '../../../../../helpers/parseScreeningReportDate';

import styles from './AddressSection.module.scss';

const AddressSection = ({ report }) => {
  return (
    <div className={styles.flexContainer}>
      <div className={styles.firstColumn}>
        <span className={styles.iconNumber}>Address</span>
        {(report.addresses || [report.currentAddress]).map((address, idx) => (
          <div key={idx} className={styles.addressContent}>
            <div className={styles.addressHolder}>
              <span className={styles.sectionTitle}>
                {idx === 0 ? 'Current' : 'Past'} Address
              </span>
              <span>{address.street}</span>
              <span>{`${address.city}, ${address.state} ${address.zip}`}</span>
            </div>
            <div className={styles.dateHolder}>
              <span className={styles.sectionTitle}>Date Reported</span>
              <span>{parseScreeningReportDate(address.date)}</span>
            </div>
          </div>
        ))}
      </div>
      <div className={styles.secondColumn}>
        <span className={styles.iconNumber}>Employment</span>
        <div className={styles.marginTop12}>
          {report.employments && report.employments.length > 0
            ? report.employments.map((employment, idx) => (
                <div key={idx} className={styles.marginTop4}>
                  {employment.employerName || employment.EmployerName}
                </div>
              ))
            : 'Not Available'}
        </div>
      </div>
    </div>
  );
};

AddressSection.propTypes = {
  report: PropTypes.object,
};

export default AddressSection;
