import omit from 'lodash.omit';

import isSinglePropertyType from '../../../../../../helpers/isSinglePropertyType';
import isUuid from '../../../../../../services/utilities/isUuid';

export const stateToListing = (stateListing) => {
  const isSingleUnit = isSinglePropertyType(stateListing.property_type);
  let rooms = [];
  if (isSingleUnit) {
    rooms = stateListing.roomRentals
      ? [{ room_name: stateListing.room_name }]
      : [];
  }
  let units = [];
  if (!isSingleUnit) {
    units = [
      {
        unit: stateListing.unit_name,
        rooms: stateListing.roomRentals
          ? [{ room_name: stateListing.room_name }]
          : [],
      },
    ];
  }
  return {
    id: stateListing.id,
    property_type: stateListing.property_type,
    address: stateListing.address,
    unit: stateListing.unit,
    city: stateListing.city,
    state: stateListing.state,
    zip: stateListing.zip,
    has_rooms: stateListing.roomRentals,
    rooms,
    units,
  };
};

export const stateToLease = (stateLease) => ({
  id: stateLease.id,
});

export const monthlyChargesStateToCharges = (charges) => {
  return charges.map((charge) => ({
    id: isUuid(charge.id) ? null : charge.id,
    category: charge.category,
    description: charge.description,
    amount: Number(charge.amount) * 100,
    type: charge.type,
    start_date: charge.start_date,
    end_date: charge.no_end_date ? null : charge.end_date,
    due_day_of_month: charge.due_day_of_month,
    destination_id: charge.destination_id,
    late_fee_policy: {
      limit: {
        late_fee_limit_type: charge.late_fee_policy?.limit?.late_fee_limit_type,
        late_fee_limit_unit: charge.late_fee_policy?.limit?.late_fee_limit_unit,
        late_fee_limit_value:
          charge.late_fee_policy?.limit?.late_fee_limit_value,
      },
      policies: charge.late_fee_policy?.policies.map((policy) => ({
        ...omit(policy, '__typename'),
      })),
    },
  }));
};
export const oneTimeChargesStateToCharges = (charges) => {
  return charges.map((charge) => ({
    id: isUuid(charge.id) ? null : charge.id,
    category: charge.category,
    description: charge.description,
    amount: Number(charge.amount) * 100,
    type: charge.type,
    start_date: charge.start_date,
    end_date: charge.end_date,
    due_day_of_month: charge.due_day_of_month,
    destination_id: charge.destination_id,
  }));
};
