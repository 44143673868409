import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import ArrowRightIcon from '../../../../icons/ArrowRight';
import FormIcon from '../../../../icons/Form';
import { useConfig } from '../../../../providers/ConfigProvider';

import styles from './DraftEsigns.module.scss';

const DraftEsigns = ({ className, activeSignatureRequests }) => {
  if (!activeSignatureRequests?.length) {
    return null;
  }

  const showExpanded = activeSignatureRequests?.length > 2;
  const [expanded, setExpanded] = useState(false);
  const { PRIVATE_BASE_PATH } = useConfig();

  const activeSignaturesOptions = expanded
    ? activeSignatureRequests
    : activeSignatureRequests.slice(0, 2);

  return (
    <div
      className={cx(styles.container, className, {
        [styles.expanded]: showExpanded,
      })}
    >
      <h3 className={styles.title}>Draft E-signs</h3>
      <p className={styles.text}>
        Rather finish those?{' '}
        <span className={styles.hidden}>Click on the document to switch.</span>
      </p>
      <ul className={styles.list}>
        {activeSignaturesOptions.map((acr) => (
          <li className={styles.listItem} key={acr.id}>
            <FormIcon className={styles.icon} height={24} width={27} />
            <Link
              className={styles.link}
              to={`${PRIVATE_BASE_PATH}e-sign-lease/${acr.lease.id}/${acr.id}/add-documents`}
            >
              {acr.title ? acr.title : `${acr.lease.title} - E-sign Request`}
            </Link>
          </li>
        ))}
      </ul>
      {showExpanded && (
        <Button
          className={styles.btn}
          icon={ArrowRightIcon}
          iconProps={{
            className: cx(styles.btnIcon, {
              [styles.showLess]: expanded,
            }),
            width: 14,
            height: 9,
          }}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? 'See less' : 'See more'}
        </Button>
      )}
    </div>
  );
};

DraftEsigns.propTypes = {
  className: PropTypes.string,
  activeSignatureRequests: PropTypes.array,
};

export default DraftEsigns;
