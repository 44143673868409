const WizardSectionsEnum = {
  LEASE_SPECIFICS: 'lease-specifics',
  RENT_DEPOSIT_FEE: 'rent-deposit-fee',
  PEOPLE: 'people',
  PETS_SMOKING_INSURANCE: 'pets-smoking-insurance',
  UTILITIES: 'utilities',
  PROVISIONS: 'provisions',
};

export const wizardSections = Object.values(WizardSectionsEnum);

export default WizardSectionsEnum;
