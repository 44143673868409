/* eslint-disable eslint-local-rules/no-imports-from */
import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import ALL_DOCUMENTS_TYPES from '../../../constants/all_documents/AllDocumentsTypes';
import { ReportedTypeEnum } from '../../../constants/enums/ReportedTypeEnum';
import {
  COMPLETED,
  DRAFT,
  IN_PROGRESS,
} from '../../../constants/lease_document/esign-statuses';
import {
  CONDITION_REPORT_TEMPLATE,
  FORMS,
} from '../../../constants/lease_document/type';
import CopySquareIcon from '../../../icons/CopySquare';
import DotsDropdown from '../../../icons/DotsDropdown';
import Download from '../../../icons/Download';
import Edit from '../../../icons/Edit';
import Remove from '../../../icons/Remove';
import Esign from '../../../icons/streamline/Esign';
import Sparkles from '../../../icons/streamline/Sparkles';
import ESignTwoTone from '../../../icons/twoTone/ESign';
import { useConfig } from '../../../providers/ConfigProvider';
import DropdownMenu from '../../DropdownMenu/DropdownMenu';

import styles from './AllDocumentsOptions.module.scss';

const AllDocumentsOptions = ({ document, onCopy, onDelete }) => {
  const { PRIVATE_BASE_PATH } = useConfig();
  const history = useHistory();

  const handleEditDocument = () => {
    const { lease_id, wizard_document_id, id } = document;
    if (document.lease_agreement_id && !document.user_uploaded) {
      return history.push(
        `${PRIVATE_BASE_PATH}digital-leases/${lease_id}/overview`,
      );
    }
    if (document.wizard_document_id) {
      return history.push(
        `${PRIVATE_BASE_PATH}rental-addendum/${lease_id}/${wizard_document_id}?redirectTo=${encodeURIComponent(
          history.location.pathname,
        )}`,
      );
    }
    history.push(
      `${PRIVATE_BASE_PATH}leases/edit-document/${lease_id}/${id}?redirectTo=${encodeURIComponent(
        history.location.pathname,
      )}`,
    );
  };

  const handleViewEsignStatus = () => {
    const { lease_id, id } = document;

    if (document.document_type === ALL_DOCUMENTS_TYPES.SIGNATURE_REQUEST) {
      if (document.esign_status === DRAFT) {
        return history.push(
          `${PRIVATE_BASE_PATH}e-sign-lease/${lease_id}/${id}/add-documents?editMode=true`,
        );
      }
    }

    return history.push(
      `${PRIVATE_BASE_PATH}documents/e-sign?document=${document.title}`,
    );
  };

  const handleAddESign = () => {
    const { lease_id, id } = document;

    return history.push(
      `${PRIVATE_BASE_PATH}e-sign-lease/${lease_id}/new/how-it-works?documentToSign=${id}`,
    );
  };

  const isWizardOrAgreement =
    (document.lease_agreement_id || document.wizard_document_id) &&
    document.type !== CONDITION_REPORT_TEMPLATE;

  const isEditedForm = document.type === FORMS;

  const showDelete = isWizardOrAgreement || isEditedForm;

  const showEdit =
    document.document_type !== ALL_DOCUMENTS_TYPES.SIGNATURE_REQUEST &&
    !document.lease_agreement_id &&
    document.esign_status !== COMPLETED &&
    document.esign_status !== IN_PROGRESS;

  const showAddESign = document.category === ReportedTypeEnum.FORMS;

  return (
    <DropdownMenu className={styles.container} placement="bottom-end">
      <DropdownMenu.Trigger asChild>
        <span className={styles.trigger}>
          <DotsDropdown />
        </span>
      </DropdownMenu.Trigger>

      <DropdownMenu.Content>
        {showAddESign && (
          <DropdownMenu.Item onClick={handleAddESign} icon={ESignTwoTone}>
            Add E-Sign
          </DropdownMenu.Item>
        )}
        {(document.esign_status === IN_PROGRESS ||
          document.esign_status === DRAFT) && (
          <DropdownMenu.Item onClick={handleViewEsignStatus} icon={Esign}>
            View E-Sign Status
          </DropdownMenu.Item>
        )}
        {document.lease_agreement_id && !document.custom_html_request && (
          <DropdownMenu.Item as="a" icon={CopySquareIcon} onClick={onCopy}>
            Copy
          </DropdownMenu.Item>
        )}

        {document.category === ReportedTypeEnum.LEASE_AGREEMENT &&
          document.user_uploaded && (
            <DropdownMenu.Item
              as="a"
              target="_blank"
              href="/lease-agreement-audit-ai"
              icon={Sparkles}
            >
              Audit
            </DropdownMenu.Item>
          )}

        {showEdit && (
          <DropdownMenu.Item
            as="a"
            onClick={() => {
              handleEditDocument(document);
            }}
            icon={Edit}
          >
            Edit {document.user_uploaded && 'Details'}
          </DropdownMenu.Item>
        )}
        {document.url && (
          <DropdownMenu.Item
            as="a"
            href={document.url}
            target="_blank"
            icon={Download}
          >
            Download
          </DropdownMenu.Item>
        )}
        {showDelete && (
          <DropdownMenu.Item
            as="a"
            className={styles.delete}
            onClick={onDelete}
            icon={Remove}
            iconProps={{ color: 'red' }}
          >
            Delete
          </DropdownMenu.Item>
        )}
      </DropdownMenu.Content>
    </DropdownMenu>
  );
};

AllDocumentsOptions.propTypes = {
  document: PropTypes.object,
  onCopy: PropTypes.func,
  onDelete: PropTypes.func,
};

export default AllDocumentsOptions;
