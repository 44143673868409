import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Messages = (props) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.90039 3.32C1.90039 2.98863 2.16902 2.72 2.50039 2.72H18.196C18.5274 2.72 18.796 2.98863 18.796 3.32V8.68957C18.796 9.02094 18.5274 9.28957 18.196 9.28957C17.8647 9.28957 17.596 9.02094 17.596 8.68957V3.92H3.10039V12.633H4.97865C5.31002 12.633 5.57865 12.9017 5.57865 13.233V15.0889L7.85874 12.8088C7.97126 12.6963 8.12387 12.633 8.283 12.633H9.10909C9.44046 12.633 9.70909 12.9017 9.70909 13.233C9.70909 13.5644 9.44046 13.833 9.10909 13.833H8.53153L5.40292 16.9617C5.23132 17.1333 4.97325 17.1846 4.74904 17.0917C4.52484 16.9989 4.37865 16.7801 4.37865 16.5374V13.833H2.50039C2.16902 13.833 1.90039 13.5644 1.90039 13.233V3.32Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9873 10.7548C10.9873 10.4234 11.256 10.1548 11.5873 10.1548H21.5004C21.8318 10.1548 22.1004 10.4234 22.1004 10.7548V17.3635C22.1004 17.6949 21.8318 17.9635 21.5004 17.9635H20.4482V20.6678C20.4482 20.9041 20.3096 21.1184 20.094 21.2152C19.8785 21.312 19.6262 21.2732 19.4496 21.1163L15.9027 17.9635H11.5873C11.256 17.9635 10.9873 17.6949 10.9873 17.3635V10.7548ZM12.1873 11.3548V16.7635H16.1308C16.2778 16.7635 16.4196 16.8174 16.5294 16.915L19.2482 19.3317V17.3635C19.2482 17.0321 19.5168 16.7635 19.8482 16.7635H20.9004V11.3548H12.1873Z"
      fill={props.color}
    />
  </svg>
);

Messages.defaultProps = {
  width: 24,
  height: 24,
  color: colors.greyLight,
};

Messages.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Messages);
