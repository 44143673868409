import { getConfig } from '../../services/configService';

export const MODAL_LOCATIONS = {
  ADDENDUM: 'ADDENDUM',
  ADDENDUM_IN_PROGRESS: 'ADDENDUM_IN_PROGRESS',
  APPLICATION_TAB: 'APPLICATION_TAB',
  BANK_ACCOUNTS: 'BANK_ACCOUNTS',
  BILLING: 'BILLING',
  DASHBOARD: 'DASHBOARD',
  ENTITY_BANK_ACCOUNTS: 'ENTITY_BANK_ACCOUNTS',
  E_SIGN: 'E_SIGN',
  E_SIGN_DASHBOARD: 'E_SIGN_DASHBOARD',
  FORMS: 'FORMS',
  GET_FORMS_PACK: 'GET_FORMS_PACK',
  HELP_CENTER_CALL: 'HELP_CENTER_CALL',
  HELP_CENTER_FAQ: 'HELP_CENTER_FAQ',
  INCOME_INSIGHT_BANNER: 'INCOME_INSIGHT_BANNER',
  INVITE_TO_APPLY: 'INVITE_TO_APPLY',
  INVITE_TO_APPLY_ONBOARDING: 'INVITE_TO_APPLY_ONBOARDING',
  LEADS_BULK_INVITE_TO_APPLY: 'LEADS_BULK_INVITE_TO_APPLY',
  LEASE: 'LEASE',
  LEASE_REVIEW: 'LEASE_REVIEW',
  LEASE_IN_PROGRESS: 'LEASE_IN_PROGRESS',
  MARKETING: 'MARKETING',
  MARKETING_CALL_FORWARDING: 'MARKETING_CALL_FORWARDING',
  MOBILE_APP: 'MOBILE_APP',
  PAYMENTS_DASHBOARD: 'PAYMENTS_DASHBOARD',
  PAYMENTS_MBA: 'PAYMENTS_MBA',
  PAYMENTS_MBA_ONBOARDING: 'PAYMENTS_MBA_ONBOARDING',
  PREMIUM_UPSELL_PAGE: 'PREMIUM_UPSELL_PAGE',
  PAYMENTS_OVERVIEW: 'PAYMENTS_OVERVIEW',
  PRESCEENER_ONBOARDING_SETUP_LISTING: 'PRESCEENER_ONBOARDING_SETUP_LISTING',
  PRESCREENER_TAB: 'PRESCREENER_TAB',
  PROPERTIES: 'PROPERTIES',
  PUBLIC_SCREENING: 'PUBLIC_SCREENING',
  REI_HUB_ACCOUNTING_PAGE: 'REI_HUB_ACCOUNTING_PAGE',
  RESUBSCRIBE: 'RESUBSCRIBE',
  SIDEBAR_NAVIGATION_TAG: 'SIDEBAR_NAVIGATION_TAG',
  STANDALONE_BUNDLE_PROMO: 'STANDALONE_BUNDLE_PROMO',
  STANDALONE_SCREENING: 'STANDALONE_SCREENING',
  TOOLBOX: 'TOOLBOX',
  USE_YOUR_OWN_APP: 'USE_YOUR_OWN_APP',
  SIGNUP_AND_SUBSCRIBE_FLOW: 'SIGNUP_AND_SUBSCRIBE_FLOW',
  URL_PARAM: 'URL_PARAM',
};

export const MODAL_LOCATIONS_DATA = {
  [MODAL_LOCATIONS.INVITE_TO_APPLY_ONBOARDING]: {
    name: 'inviteToApplyOnboarding',
    segmentLocation: 'Invite to Apply Onboarding Subscription Modal',
    title: 'Invite to Apply Onboarding',
  },
  [MODAL_LOCATIONS.INVITE_TO_APPLY]: {
    name: 'inviteToApply',
    segmentLocation: 'Invite to Apply Subscription Modal',
    title: 'Invite to Apply',
  },
  [MODAL_LOCATIONS.INCOME_INSIGHT_BANNER]: {
    name: 'incomeInsightsBanner',
    segmentLocation: 'Get a clearer picture of your applicants',
    title: 'Income Insights Banner',
  },
  [MODAL_LOCATIONS.STANDALONE_SCREENING]: {
    name: 'standaloneScreening',
    segmentLocation: 'Standalone Screening Subscription Modal',
    title: 'Standalone Screening',
  },
  [MODAL_LOCATIONS.PUBLIC_SCREENING]: {
    name: 'publicScreening',
    segmentLocation: 'Screen a Tenant - Public Screening',
    title: 'Screen a Tenant - Public Screening',
  },
  [MODAL_LOCATIONS.HELP_CENTER_CALL]: {
    name: 'helpCenterCall',
    segmentLocation: 'Help Center Call Us Subscription Modal',
    title: 'Help Center - Call Us',
  },
  [MODAL_LOCATIONS.HELP_CENTER_FAQ]: {
    name: 'helpCenterFAQ',
    segmentLocation: 'Help Center FAQ Subscription Modal',
    title: 'Help Center - FAQ',
  },
  [MODAL_LOCATIONS.BILLING]: {
    name: 'billing',
    segmentLocation: 'Billing Subscription Modal',
    title: 'Account - Billing',
  },
  [MODAL_LOCATIONS.DASHBOARD]: {
    name: 'dashboard',
    segmentLocation: 'Dashboard Carousel Subscription Modal',
    title: 'Dashboard - Anchor Link',
  },
  [MODAL_LOCATIONS.LEASE]: {
    name: 'lease',
    segmentLocation: 'Lease Agreement Subscription Modal',
    title: 'Lease Agreement Completed',
  },
  [MODAL_LOCATIONS.LEASE_IN_PROGRESS]: {
    name: 'leaseInProgress',
    segmentLocation: 'Lease Agreement Subscription Modal',
    title: 'Lease Agreement In Progress',
  },
  [MODAL_LOCATIONS.ADDENDUM_IN_PROGRESS]: {
    name: 'addendumInProgress',
    segmentLocation: 'Lease Addendum Pricing Modal',
    title: 'Lease Addendum In Progress',
  },
  [MODAL_LOCATIONS.ADDENDUM]: {
    name: 'addendum',
    segmentLocation: 'Lease Addendum Subscription Modal',
    title: 'Lease Addendum Completed',
  },
  [MODAL_LOCATIONS.E_SIGN_DASHBOARD]: {
    name: 'esignDashboard',
    segmentLocation: 'E-Sign Lease Profile',
    title: 'E-sign Lease Profile',
  },
  [MODAL_LOCATIONS.GET_FORMS_PACK]: {
    name: 'getFormsPack',
    segmentLocation: 'Forms Pack',
    title: 'Forms Pack',
  },
  [MODAL_LOCATIONS.LEADS_BULK_INVITE_TO_APPLY]: {
    name: 'leadsBulkInviteToApply',
    segmentLocation: 'Leads Bulk Invite to Apply Subscription Modal',
    title: 'Leads Bulk Invite to Apply',
  },
  [MODAL_LOCATIONS.MARKETING]: {
    name: 'marketing',
    segmentLocation: 'Marketing Set Up Listing Completed Modal',
    title: 'Marketing - Set Up Listing',
  },
  [MODAL_LOCATIONS.PAYMENTS_OVERVIEW]: {
    name: 'paymentsOverview',
    segmentLocation: 'Payments Banner Subscription Modal',
    title: 'Payments - Overview',
  },
  [MODAL_LOCATIONS.PAYMENTS_MBA]: {
    name: 'paymentsMba',
    segmentLocation: 'Rent Payments MBA Setup Subscription Modal',
    title: 'Multiple Bank Accounts - Payment Setup',
  },
  [MODAL_LOCATIONS.PAYMENTS_MBA_ONBOARDING]: {
    name: 'paymentsMbaOnboarding',
    segmentLocation: 'Rent Payments MBA Onboarding Subscription Modal',
    title: 'Multiple Bank Accounts - Payments Onboarding',
  },
  [MODAL_LOCATIONS.PREMIUM_UPSELL_PAGE]: {
    name: 'premiumUpsellPage',
    segmentLocation: 'premium upsell page',
    title: 'Better Rent Payments. Better Everything',
  },
  [MODAL_LOCATIONS.PAYMENTS_DASHBOARD]: {
    name: 'paymentsDashboard',
    segmentLocation: 'Payments Dashboard',
    title: 'Payments Dashboard',
  },
  [MODAL_LOCATIONS.BANK_ACCOUNTS]: {
    name: 'bankAccounts',
    segmentLocation: 'Settings Bank Accounts Subscription Modal',
    title: 'Account - Bank Accounts - Add Bank Account',
  },
  [MODAL_LOCATIONS.ENTITY_BANK_ACCOUNTS]: {
    name: 'entityBankAccounts',
    segmentLocation: 'Settings Entity Management Subscription Modal',
    title: 'Account - Bank Accounts - Entity Management',
  },
  [MODAL_LOCATIONS.SIDEBAR_NAVIGATION_TAG]: {
    name: 'sidebarNavigationTag',
    segmentLocation: 'Navigation Bar Modal',
    title: 'Sidebar Navigation Tag',
  },
  [MODAL_LOCATIONS.PRESCREENER_TAB]: {
    name: 'prescreenerTab',
    segmentLocation: 'Custom Pre-Screener Questions Subscription Modal',
    title: 'Custom Pre-Screener Questions Subscription Modal',
  },
  [MODAL_LOCATIONS.PRESCEENER_ONBOARDING_SETUP_LISTING]: {
    name: 'prescreenerOnboardingSetupListing',
    segmentLocation: 'Prescreener Onboarding Setup Listing Subscription Modal',
    title: 'Prescreener Onboarding Setup Listing',
  },
  [MODAL_LOCATIONS.APPLICATION_TAB]: {
    name: 'applicationTab',
    segmentLocation: 'Custom Application Questions Subscription Modal',
    title: 'Custom Application Questions Subscription Modal',
  },
  [MODAL_LOCATIONS.RESUBSCRIBE]: {
    name: 'resubscribe',
    segmentLocation: 'Resubscribe Modal',
    title: 'Resubscribe',
  },
  [MODAL_LOCATIONS.USE_YOUR_OWN_APP]: {
    name: 'useYourOwnApp',
    segmentLocation: 'Use Your Own Application Subscription Modal',
    title: 'Use Your Own Application',
  },
  [MODAL_LOCATIONS.TOOLBOX]: {
    name: 'toolbox',
    segmentLocation: 'Toolbox Subscription Modal',
    title: 'Toolbox',
  },
  [MODAL_LOCATIONS.MOBILE_APP]: {
    name: 'mobileApp',
    segmentLocation: 'Premium Modal - LLs Coming From Mobile',
    title: 'Mobile App',
  },
  [MODAL_LOCATIONS.MARKETING_CALL_FORWARDING]: {
    name: 'marketingCallForwarding',
    segmentLocation: 'marketing_call_forwarding',
    title: 'Marketing Call Forwarding',
  },
  [MODAL_LOCATIONS.STANDALONE_BUNDLE_PROMO]: {
    name: 'standaloneBundlePromo',
    segmentLocation: 'Standalone Bundle Promo',
    title: 'Standalone Bundle Promo',
  },
  [MODAL_LOCATIONS.REI_HUB_ACCOUNTING_PAGE]: {
    name: 'reihubAccountingPage',
    segmentLocation: 'Reihub Accounting Page',
    title: 'Reihub Accounting Page',
  },
  [MODAL_LOCATIONS.PROPERTIES]: {
    name: 'properties',
    segmentLocation: 'Property Overview Carousel Modal',
    title: 'Property Overview - Anchor Link',
  },
  [MODAL_LOCATIONS.URL_PARAM]: {
    name: 'urlParam',
    segmentLocation: 'URL Param Modal',
    title: 'URL Param ?showPremiumModal=true',
  },
};

export const comparisonRows = () => {
  const { LEASE_AGREEMENT_FEE, FORMS_PACK_PRICE } = getConfig();
  return [
    ['Free Syndication', 1, 1, 'Marketing & Advertising'],
    ['Priority Listing Review', 0, 1, 'Marketing & Advertising'],
    ['Lead Management', 1, 1, 'Finding a Tenant'],
    ['Applications & Screening', 1, 1, 'Finding a Tenant'],
    ['Showing Scheduling', 1, 1, 'Finding a Tenant'],
    ['Lower Screening Fees', 0, 1, 'Finding a Tenant'],
    ['Income Insights', 0, 1, 'Finding a Tenant'],
    ['Custom Questions', 0, 1, 'Finding a Tenant'],
    ['Rent Payments', 1, 1, 'Rent Payments'],
    ['Automatic Late Fees, Receipts, & Reminders', 1, 1, 'Rent Payments'],
    ['Autopay Setup For Renters', 1, 1, 'Rent Payments'],
    ['Rent Reporting', 1, 1, 'Rent Payments'],
    ['Expense Tracking', 1, 1, 'Rent Payments'],
    ['Waived ACH Fees', 0, 1, 'Rent Payments'],
    ['Expedited Rent Payouts', 0, 1, 'Rent Payments'],
    ['Connected Bank Accounts', '1', 'Unlimited', 'Rent Payments'],
    ['Messaging ', 1, 1, 'Tenant Management'],
    ['Maintenance Requests ', 1, 1, 'Tenant Management'],
    ['Tenant Portals ', 1, 1, 'Tenant Management'],
    [
      'State-Specific Lease Agreements',
      `$${LEASE_AGREEMENT_FEE}/lease`,
      'Unlimited',
      'Leases & Forms',
    ],
    ['Lease Addendums', ``, 'Unlimited', 'Leases & Forms'],
    ['E-Signatures', ``, 'Unlimited', 'Leases & Forms'],
    ['Condition Reports', 1, 1, 'Leases & Forms'],
    ['Landlord Forms Pack', `$${FORMS_PACK_PRICE}`, 1, 'Leases & Forms'],
    ['Document Management', 1, 1, 'Leases & Forms'],
    ['In-App Help Center', 1, 1, 'Support'],
    ['7-Days-A-Week Chat and Email Support', 1, 1, 'Support'],
    ['Priority Chat Support', 0, 1, 'Support'],
    ['Phone Support', 0, 1, 'Support'],
  ];
};

export const TESTIMONIALS = {
  AMY: {
    paragraph: `"TurboTenant is the best! Easy to use, great customer service, and my tenants like it, too...Online application and screening reports with fast results."`,
    name: 'AMY',
    landlordOf: '5 Unit Landlord',
  },
  TERESA: {
    paragraph: `"TurboTenant's screening process is invaluable.  They have thorough screening reports that include income insights (so helpful when validating information on the application), credit scores, plus criminal and eviction history."`,
    name: 'TERESA',
    landlordOf: '1 Unit Landlord',
  },
  DUYEN: {
    paragraph: `"Very user friendly and convenient for landlords.  I really appreciate how quickly the response was when I had questions and how easy it was to navigate the portal."`,
    name: 'DUYEN',
    landlordOf: '1 Unit Landlord',
  },
  FRANK: {
    paragraph: `"This is a great product! It has made my life as a landlord much easier to manage the selection process, document signatures & storage, as well as the payment process. I continue to recommend your product to others."`,
    name: 'FRANK',
    landlordOf: '3 Unit Landlord',
  },
  SCOTT: {
    paragraph: `"I wanted to get away from all the paper management and trips to the bank. Since I moved my tenants over, TurboTenant runs it all basically on autopilot."`,
    name: 'SCOTT',
    landlordOf: '3 Unit Landlord',
  },
  MICHAEL: {
    paragraph: `"My last four renters all came through TurboTenant. The prescreening in invaluable"`,
    name: 'MICHAEL',
    landlordOf: '1 Unit Landlord',
  },
};

export const SUBSCRIPTION_FEATURES = {
  ACH_FEE: 'ACH Fee',
  SCREENING_FEE: 'Screening Fee',
  INCOME_INSIGHTS: 'Income Insights',
  RENTAL_ACCOUNTING_REI_HUB: 'Rental accounting (REI Hub)',
  UNLIMITED_ESIGNATURES: 'Unlimited eSignatures',
  UNLIMITED_BANK_ACCOUNTS: 'Unlimited bank accounts',
  FASTER_DESPOSIT_FOR_RENT_PAYMENTS: 'Faster desposit for rent payments',
  UNLIMITED_LEASE_AGREEMENTS: 'Unlimited lease agreements',
  PRIORITY_LISTING_REVIEW: 'Priority listing review',
  CUSTOM_QUESTIONS: 'Custom questions',
  PRIORITY_CHAT_SUPPORT: 'Priority chat support',
  PHONE_SUPPORT: 'Phone support',
  MARKETING_CALL_FORWARDING: 'Marketing call forwarding',
  LANDLORD_FORMS_PACK: 'Landlord forms pack',
  UNLIMITED_LEASE_ADDENDUMS: 'Unlimited lease addendums',
};
