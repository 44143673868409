export const DOCUMENTS_SORTING_OPTIONS = {
  NEWEST: 'NEWEST',
  OLDEST: 'OLDEST',
  RENTAL_ASC: 'RENTAL_ASC',
  RENTAL_DESC: 'RENTAL_DESC',
};

export const DOCUMENTS_SORTING_CRITERIA = {
  CREATED_AT: 'created_at',
  RENTAL: 'rental',
};
