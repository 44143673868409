import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import CloseIcon from '../../../icons/Close';
import Button from '../../Button';
import Card from '../../Card';
import FlatButton from '../../FlatButton';
import IconButton from '../../IconButton';

import styles from './RenterToDoCardBase.module.scss';

const renderSecondaryButton = (
  type,
  secondaryTo,
  secondaryOnClick,
  secondaryLabel,
) => {
  if (type === 'FlatButton')
    return (
      <FlatButton
        secondary
        className={cx(styles.action, styles.flatButton)}
        id="secondary-action"
        to={secondaryTo}
        onClick={secondaryOnClick}
      >
        {secondaryLabel}
      </FlatButton>
    );

  return (
    <Button
      secondary
      className={styles.action}
      id="secondary-action"
      to={secondaryTo}
      onClick={secondaryOnClick}
    >
      {secondaryLabel}
    </Button>
  );
};

const RenterToDoCard = ({
  className,
  title,
  subTitle,
  description,
  icon: Icon,
  iconProps,
  imgSrc,
  iconClassNameWrapper,
  primaryTo,
  primaryOnClick,
  primaryLabel,
  secondaryType,
  secondaryTo,
  secondaryOnClick,
  secondaryLabel,
  titleId,
  style,
  dataQA,
  dismissAction,
  disabled,
}) => (
  <Card className={cx(styles.container, className)} style={style}>
    {dismissAction && (
      <IconButton
        icon={CloseIcon}
        onClick={dismissAction}
        className={styles.dismissAction}
      />
    )}

    {Icon ? (
      <div className={iconClassNameWrapper}>
        <Icon
          width={152}
          {...iconProps}
          className={cx(styles.icon, iconProps.className)}
        />
      </div>
    ) : null}
    {imgSrc ? (
      <div className={styles.imageWrapper}>
        <img className={styles.image} src={imgSrc} alt="Card image" />
      </div>
    ) : null}
    <div className={styles.content}>
      {title ? (
        <h1 {...(titleId ? { id: titleId } : {})}>
          {title}
          {subTitle && ': '}
          {subTitle && <span className={styles.subTitle}>{subTitle}</span>}
        </h1>
      ) : null}
      {description ? <p>{description}</p> : null}
      <div className={styles.buttonsContainer}>
        {primaryLabel && (
          <Button
            className={styles.action}
            id="primary-action"
            data-qa={dataQA}
            to={primaryTo}
            onClick={primaryOnClick}
            disabled={disabled}
          >
            {primaryLabel}
          </Button>
        )}
        {secondaryLabel &&
          renderSecondaryButton(
            secondaryType,
            secondaryTo,
            secondaryOnClick,
            secondaryLabel,
          )}
      </div>
    </div>
  </Card>
);

RenterToDoCard.propTypes = {
  className: PropTypes.string,
  iconClassNameWrapper: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.elementType,
  iconProps: PropTypes.object,
  primaryLabel: PropTypes.string,
  primaryOnClick: PropTypes.func,
  secondaryLabel: PropTypes.string,
  secondaryOnClick: PropTypes.func,
  dismissAction: PropTypes.func,
};

RenterToDoCard.defaultProps = {
  iconProps: {},
  dataQA: 'main-action',
};

export default RenterToDoCard;
