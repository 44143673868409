import React from 'react';

import { smallScreenWidth } from '../../../../constants/media-breakpoints';
import { useIsScreenSize } from '../../../../hooks/useIsScreenSize';

import DesktopSkeleton from './components/DesktopSkeleton';
import MobileSkeleton from './components/MobileSkeleton';

import styles from './EsignSkeleton.module.scss';

const EsignSkeleton = () => {
  const isSmallScreen = useIsScreenSize(smallScreenWidth);

  if (isSmallScreen) {
    return (
      <MobileSkeleton
        className={styles.mobile}
        borderClassName={styles.svgBorder}
      />
    );
  }

  return (
    <DesktopSkeleton
      className={styles.desktop}
      borderClassName={styles.svgBorder}
    />
  );
};

export default EsignSkeleton;
