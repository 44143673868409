import React, { memo } from 'react';
import PropTypes from 'prop-types';

const YardSign = ({
  width = 17,
  height = 18,
  color = 'currentColor',
  ...props
}) => (
  <svg width={width} height={height} viewBox="0 0 17 18" {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M16.113 0c.306 0 .554.251.554.561v17.211c0 .31-.248.561-.554.561a.558.558 0 0 1-.554-.56V1.122l-4.064-.001v1.871h1.663c.275 0 .504.204.547.47l.007.091v8.98c0 .31-.248.561-.554.561H.6a.558.558 0 0 1-.554-.561v-8.98c0-.31.248-.56.554-.56l1.661-.001V1.122H.554a.556.556 0 0 1-.547-.47L0 .562C0 .251.248 0 .554 0Zm-3.51 4.116H1.154v7.857h11.45V4.116ZM3.37 2.993h7.018V1.122H3.369v1.871Z"
    />
  </svg>
);

YardSign.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(YardSign);
