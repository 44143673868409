import React, { memo, useEffect, useRef } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import deepEqual from 'deep-equal';
import PropTypes from 'prop-types';

import Condition from '../../../../../../../components/Condition';
import DropdownSubtextMenu from '../../../../../../../components/DropdownSubtextMenu';
import Input from '../../../../../../../components/Input';
import MultiCondition from '../../../../../../../components/MultiCondition';
import PropertyAddressFields from '../../../../../../../components/PropertyAddressFields';
import RadioGroup from '../../../../../../../components/RadioGroup';
import { Tooltip } from '../../../../../../../components/Tooltip';
import isSinglePropertyType from '../../../../../../../helpers/isSinglePropertyType';
import fieldRequired from '../../../../../../../validators/fieldRequired';
import validShareAbleAddress from '../../../../../../../validators/validShareAbleAddress';
import validShareAbleCity from '../../../../../../../validators/validShareAbleCity';
import validShareAbleUnit from '../../../../../../../validators/validShareAbleUnit';
import { propertyTypeDropdownOptions } from '../../../../../../digital-leases/standalone-wizard/helpers/propertyTypeDropdownOptions';

import styles from './ListingForm.module.scss';

const ListingForm = ({ listing, onSubmit }) => {
  const ref = useRef(null);

  // using the trick with ref to prevent the initial form values from being submitted
  useEffect(() => {
    if (ref.current) {
      return;
    }
    ref.current = true;
  }, []);

  return (
    <Form
      keepDirtyOnReinitialize
      validateOnBlur
      initialValues={listing}
      initialValuesEqual={(a, b) => deepEqual(a, b)}
      onSubmit={() => {}}
    >
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} id="listing_form">
          {listing.id ? null : (
            <Field
              label="Property Type"
              id="property_type"
              name="property_type"
              validate={fieldRequired}
            >
              {(props) => (
                <DropdownSubtextMenu
                  options={propertyTypeDropdownOptions}
                  {...props}
                >
                  <DropdownSubtextMenu.Trigger />
                  <DropdownSubtextMenu.Content>
                    {(options) =>
                      options.map((option) => (
                        <DropdownSubtextMenu.Item
                          key={option.value}
                          option={option}
                        />
                      ))
                    }
                  </DropdownSubtextMenu.Content>
                </DropdownSubtextMenu>
              )}
            </Field>
          )}

          <PropertyAddressFields
            excludeTerritories
            validators={{
              city: [validShareAbleCity],
              unit: [validShareAbleUnit],
              address: [validShareAbleAddress],
            }}
            showUnit={isSinglePropertyType(values.property_type)}
            className={styles.propertyAddressFields}
          />

          {!listing.id ? (
            <Field
              component={RadioGroup}
              id="roomRentals"
              name="roomRentals"
              label={
                <div className={styles.roomRentalsWrapper}>
                  Will you have room rentals?{' '}
                  <span
                    className={styles.learnMore}
                    data-tooltip-id="learn-more-key-tip"
                  >
                    Learn more
                  </span>
                  <Tooltip id="learn-more-key-tip">
                    Room rentals are when you're renting out rooms separately
                    within the property, each with their own lease.
                  </Tooltip>
                </div>
              }
              options={[
                {
                  label: 'Yes',
                  value: true,
                },
                {
                  label: 'No',
                  value: false,
                },
              ]}
              validate={fieldRequired}
            />
          ) : null}

          <MultiCondition
            condition={({ property_type, roomRentals }) =>
              isSinglePropertyType(property_type) && roomRentals
            }
          >
            <div className={styles.unitRoomContainer}>
              <div className={styles.sectionTitle}>Add Room</div>
              <p className={styles.sectionDescription}>
                Start with one room, even if you have more. You'll be able to
                set up the rest later.
              </p>
              <Field
                id="room_name"
                name="room_name"
                component={Input}
                label="Room Name"
                validate={fieldRequired}
                maxLength={50}
              />
            </div>
          </MultiCondition>
          <MultiCondition
            condition={({ property_type }) =>
              isSinglePropertyType(property_type) === false
            }
          >
            <div className={styles.unitRoomContainer}>
              <div className={styles.sectionTitle}>Add Unit</div>
              <p className={styles.sectionDescription}>
                Start with one unit, even if you have more. You'll be able to
                set up the rest later.
              </p>
              <Field
                id="unit_name"
                name="unit_name"
                className={styles.field}
                component={Input}
                label="Unit #"
                validate={fieldRequired}
                maxLength={50}
                placeholder="Address 2 (Apt, Suite, etc.)"
              />
              <Condition when="roomRentals" is={true}>
                <Field
                  id="room_name"
                  name="room_name"
                  component={Input}
                  label="Room Name"
                  validate={fieldRequired}
                  maxLength={50}
                />
              </Condition>
            </div>
          </MultiCondition>
          <FormSpy
            subscription={{
              values: true,
              errors: true,
              hasValidationErrors: true,
            }}
            onChange={(state) => {
              if (ref.current) {
                onSubmit(state.values, state.hasValidationErrors);
              }
            }}
          />
        </form>
      )}
    </Form>
  );
};

ListingForm.propTypes = {
  listing: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default memo(ListingForm);
