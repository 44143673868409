const CONDITION_REPORT_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  NEEDS_APPROVAL: 'NEEDS_APPROVAL',
  COMPLETE: 'COMPLETE',
  UPLOADED: 'UPLOADED',
};

export const { NOT_STARTED, IN_PROGRESS, NEEDS_APPROVAL, COMPLETE, UPLOADED } =
  CONDITION_REPORT_STATUS;

export default CONDITION_REPORT_STATUS;
