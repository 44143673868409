import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Close = ({
  color = colors.primary,
  width = 25,
  height = 24,
  strokeWidth = 0,
  ...rest
}) => (
  <svg
    viewBox="0 0 25 24"
    fill={color}
    width={width}
    height={height}
    stroke={color}
    strokeWidth={strokeWidth}
    {...rest}
  >
    <path d="M19.53 4.97a.75.75 0 0 1 0 1.06l-13 13a.75.75 0 0 1-1.06-1.06l13-13a.75.75 0 0 1 1.06 0Z" />
    <path d="M5.47 4.97a.75.75 0 0 1 1.06 0l13 13a.75.75 0 1 1-1.06 1.06l-13-13a.75.75 0 0 1 0-1.06Z" />
  </svg>
);

Close.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  strokeWidth: PropTypes.number,
};

export default memo(Close);
