import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import colors from '../../../constants/colors';
import CONDITION_REPORT_STATUS from '../../../constants/condition_reports/status';
import fromGlobalId from '../../../helpers/from-global-id';
import toGlobalId from '../../../helpers/to-global-id';
import useNavigator from '../../../hooks/useNavigator';
import CheckDouble from '../../../icons/CheckDouble';
import Download from '../../../icons/Download';
import Messages from '../../../icons/Messages';
import Notes from '../../../icons/Notes';
import { useConfig } from '../../../providers/ConfigProvider';
import { segmentTracking } from '../../../services/utilities';

import styles from './ConditionReportsList.module.scss';

const segmentLocation = { location: 'Condition Report Index Page' };

const ReportAction = ({ report }) => {
  if (!report) return null;

  const { status, assigned_to, lease_id, id } = report;
  const navigate = useNavigator();
  const location = useLocation();
  const { PRIVATE_BASE_PATH } = useConfig();

  const isSelfAssigned = assigned_to?.[0]?.user_role === 'OWNER';
  let Icon, text, actionHandler;

  switch (status) {
    case CONDITION_REPORT_STATUS.NEEDS_APPROVAL:
      Icon = CheckDouble;
      text = 'Review';
      break;
    case CONDITION_REPORT_STATUS.IN_PROGRESS:
    case CONDITION_REPORT_STATUS.NOT_STARTED:
      if (isSelfAssigned) {
        segmentTracking('fill_out clicked', segmentLocation);
        Icon = Notes;
        text = 'Fill Out';
        actionHandler = () =>
          navigate(
            `${PRIVATE_BASE_PATH}leases/${lease_id}/condition_reports/${id}/fill-out`,
          );
      } else {
        segmentTracking('message clicked', segmentLocation);
        Icon = Messages;
        text = 'Message';
        if (assigned_to.length > 1) {
          actionHandler = () =>
            navigate(`${PRIVATE_BASE_PATH}sendbulkmessage`, {
              state: {
                redirectTo: location.pathname,
                recipients: assigned_to,
                entity: 'tenants',
              },
            });
        } else {
          const renterId = fromGlobalId(assigned_to[0].id);
          const chatId = toGlobalId(`renter_${renterId}`, 'user');
          actionHandler = () =>
            navigate(`${PRIVATE_BASE_PATH}messages/${chatId}?tab=individual`);
        }
      }
      break;
    case CONDITION_REPORT_STATUS.COMPLETE:
      segmentTracking('download clicked', segmentLocation);
      Icon = Download;
      text = 'Download';
      actionHandler = () => window.open(report.download_url, '_blank');
      break;
    default: // Uploaded documents don't have status
      segmentTracking('download clicked', segmentLocation);
      Icon = Download;
      text = 'Download';
      actionHandler = () => window.open(report.download_url, '_blank');
      break;
  }

  if (!text || !Icon) return null;

  const onClick = (e) => {
    if (actionHandler) {
      e.stopPropagation();
      actionHandler();
    }
  };

  return (
    <div onClick={onClick} className={styles.actionLabel}>
      <Icon color={colors.primary} width={24} /> {text}
    </div>
  );
};

ReportAction.propTypes = {
  report: PropTypes.shape({
    status: PropTypes.string.isRequired,
    assigned_to: PropTypes.array.isRequired,
    lease_id: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    download_url: PropTypes.string,
  }).isRequired,
};

export default ReportAction;
