import React, { useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Experiments } from '../../../../constants/experiments';
import { useUserProfile } from '../../../../core/TTgraphql';
import getExperimentVariant from '../../../../helpers/experiments';

import BenefitItemsMobileExperimentH from './BenefitItemsMobile/BenefitItemsMobileExperimentH';
import BenefitSectionExperimentH from './BenefitSection/BenefitSectionExperimentH';
import PackageHeaderExperimentH from './PackageHeader/PackageHeaderExperimentH';
import PackageHeaderExperimentHMobile from './PackageHeader/PackageHeaderExperimentHMobile';

import styles from './BenefitsViewExperimentH.module.scss';

const BenefitsViewExperimentH = ({
  benefits,
  selectedBenefit,
  extraParams,
  displayMonthlyPrice,
  proPrice,
  onGetPro,
  premiumPrice,
  onGetPremium,
  isMobile,
  onClickSegment,

  preAppliedCoupon,
}) => {
  const PremiumPackageRef = useRef(null);
  const { user } = useUserProfile();

  const scrollToPremium = () => {
    if (PremiumPackageRef.current) {
      PremiumPackageRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const gbbExperimentVariant = getExperimentVariant(
    user,
    Experiments.GoodBetterBest.name,
  );

  const isVariantH =
    gbbExperimentVariant === Experiments.GoodBetterBest.variants.H;

  return isMobile ? (
    <>
      <div
        className={cx(styles.packageMobile, styles.blueBorder)}
        ref={PremiumPackageRef}
      >
        <PackageHeaderExperimentHMobile
          title="Premium"
          price={premiumPrice}
          displayMonthlyPrice={displayMonthlyPrice}
          onButtonClick={onGetPremium}
          preAppliedCoupon={preAppliedCoupon}
        />
        <BenefitItemsMobileExperimentH
          benefits={benefits}
          selectedBenefit={selectedBenefit}
          extraParams={extraParams}
          scrollToPremium={scrollToPremium}
          onClickSegment={onClickSegment}
          preAppliedCoupon={preAppliedCoupon}
        />
      </div>
      <div className={cx(styles.packageMobile, styles.pro)}>
        <PackageHeaderExperimentHMobile
          title="Pro"
          price={proPrice}
          displayMonthlyPrice={displayMonthlyPrice}
          onButtonClick={onGetPro}
          isPro
          preAppliedCoupon={preAppliedCoupon}
        />
        <BenefitItemsMobileExperimentH
          benefits={benefits}
          selectedBenefit={selectedBenefit}
          extraParams={extraParams}
          scrollToPremium={scrollToPremium}
          onClickSegment={onClickSegment}
          isProPlan
          preAppliedCoupon={preAppliedCoupon}
        />
      </div>
    </>
  ) : (
    <>
      <div
        className={cx(
          isVariantH ? styles.headerRow : styles.headerRowExperiment,
          styles[selectedBenefit],
        )}
      >
        <div className={styles.emptyHeader} />
        <PackageHeaderExperimentH
          title="Pro"
          price={proPrice}
          displayMonthlyPrice={displayMonthlyPrice}
          onButtonClick={onGetPro}
          isPro
          preAppliedCoupon={preAppliedCoupon}
        />
        <PackageHeaderExperimentH
          title="Premium"
          price={premiumPrice}
          displayMonthlyPrice={displayMonthlyPrice}
          onButtonClick={onGetPremium}
          preAppliedCoupon={preAppliedCoupon}
        />
      </div>
      <BenefitSectionExperimentH
        benefits={benefits}
        selectedBenefit={selectedBenefit}
        extraParams={extraParams}
      />
    </>
  );
};

BenefitsViewExperimentH.propTypes = {
  benefits: PropTypes.array.isRequired,
  selectedBenefit: PropTypes.string.isRequired,
  extraParams: PropTypes.object.isRequired,
  displayMonthlyPrice: PropTypes.bool.isRequired,
  proPrice: PropTypes.number.isRequired,
  onGetPro: PropTypes.func.isRequired,
  premiumPrice: PropTypes.number.isRequired,
  onGetPremium: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onClickSegment: PropTypes.func.isRequired,

  preAppliedCoupon: PropTypes.object,
};

export default BenefitsViewExperimentH;
