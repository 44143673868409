import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './VerticalProgressBar.module.scss';

const VerticalProgressBar = ({ className, active, children }) => {
  const childrenCount = React.Children.toArray(children).filter(Boolean).length;

  let activeIndex = 0;
  React.Children.forEach(children, (child, index) => {
    if (!child) return;
    const activeOption =
      child.props.label.toLowerCase() === active.toLowerCase();
    if (activeOption) {
      activeIndex = index;
    }
  });

  return (
    <div className={cx(styles.container, className)}>
      {React.Children.map(children, (child, index) => {
        if (!child) return null;
        return React.cloneElement(child, {
          active: activeIndex === index,
          completed: activeIndex > index,
          lastOption: childrenCount === index + 1,
        });
      })}
    </div>
  );
};

VerticalProgressBar.propTypes = {
  className: PropTypes.string,
  active: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default VerticalProgressBar;
