import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import ALL_DOCUMENTS_TYPES from '../../../constants/all_documents/AllDocumentsTypes';
import { NONE } from '../../../constants/lease_document/esign-statuses';
import getDocumentCategory from '../../../helpers/getDocumentCategory.js';
import getDocumentStatus from '../../../helpers/getDocumentStatus';
import Lock from '../../../icons/streamline/Lock';
import { Tooltip } from '../../Tooltip';
import AllDocumentsOptions from '../AllDocumentsOptions/AllDocumentsOptions';

import styles from './AllDocumentsTableRow.module.scss';

const AllDocumentsTableRow = ({ className, document, onCopy, onDelete }) => {
  const {
    id,
    created_at,
    url,
    title,
    category,
    listing,
    esign_status,
    is_shared,
    document_type,
  } = document;

  const titleTooLong = title.length > 45;
  const categoryTooLong = category.length > 50;
  const addressTooLong = listing?.address.length > 30;
  const isListingDocument = document_type === ALL_DOCUMENTS_TYPES.LISTING;
  const date = moment(created_at).format('MM/DD/YYYY');
  const allowDownload = url;
  const linkProps = {};
  const showPrivate = !is_shared && esign_status === NONE;

  if (allowDownload) {
    const timestamp = new Date().getTime();
    linkProps.href = `${url}?ts=${timestamp}`;
    linkProps.target = '_blank';
    linkProps.rel = 'noopener noreferrer';
  }

  return (
    <tr className={cx(className, styles.tableRow)}>
      <td className={styles.date}>{date}</td>
      <td
        className={cx(styles.title, {
          [styles.noDownload]: !allowDownload,
        })}
      >
        <a data-tooltip-id={`title-tooltip-${id}`} {...linkProps}>
          {title}
        </a>
        {titleTooLong && (
          <Tooltip
            id={`title-tooltip-${id}`}
            className={styles.tooltip}
            place="bottom"
          >
            {title}
          </Tooltip>
        )}
        {showPrivate && (
          <>
            <Lock
              data-tooltip-id="private-tooltip"
              className={cx(styles.private, {
                [styles.privateNoCategory]: !category,
              })}
            />
            <Tooltip
              id="private-tooltip"
              place="bottom"
              className={styles.tooltip}
            >
              This document is not shared with your tenants.
            </Tooltip>
          </>
        )}
        <p
          data-tooltip-id={`category-tooltip-${id}`}
          className={cx(styles.pointer, styles.category)}
        >
          {getDocumentCategory(category)}
        </p>
        {categoryTooLong && (
          <Tooltip id={`category-tooltip-${id}`} place="bottom">
            {getDocumentCategory(category)}
          </Tooltip>
        )}
      </td>
      <td className={styles.address}>
        <p
          className={cx({ [styles.pointer]: addressTooLong })}
          data-tooltip-id={`address-tooltip-${id}`}
        >
          {listing?.address}
        </p>
        {addressTooLong && (
          <Tooltip
            id={`address-tooltip-${id}`}
            place="bottom"
            className={styles.tooltip}
          >
            {listing?.address}
          </Tooltip>
        )}
      </td>
      <td className={cx(styles.mobileCategory)}>
        {getDocumentCategory(category)}
      </td>
      <td
        className={cx(styles.status, {
          [styles.mobileHide]: esign_status === NONE,
        })}
      >
        <span>E-Sign:</span> {getDocumentStatus(esign_status)}
      </td>
      <td className={styles.options}>
        {!isListingDocument && (
          <AllDocumentsOptions
            document={document}
            onCopy={onCopy}
            onDelete={onDelete}
          />
        )}
      </td>
    </tr>
  );
};

AllDocumentsTableRow.propTypes = {
  className: PropTypes.string,
  document: PropTypes.object,
  onCopy: PropTypes.func,
  onDelete: PropTypes.func,
};

export default AllDocumentsTableRow;
