import React from 'react';
import { useQuery } from '@apollo/client';

import LoadingScreen from '../../../../../components/LoadingScreen';
import onboardingDataGQL from '../../../../../graphql/queries/landlordOnboarding.graphql';

import ChargesFirst from './ChargesFirst';

const ChargesFirstWrapper = (props) => {
  const { data, loading } = useQuery(onboardingDataGQL, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <LoadingScreen loading />;
  }

  return <ChargesFirst data={data?.landlord_onboarding} {...props} />;
};
export default ChargesFirstWrapper;
