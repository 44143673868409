import moment from 'moment';

import {
  EnumPaymentMethodStatusTypes,
  EnumRentChargeType,
} from '../pages/enums';

const VALID_PAYMENT_METHOD_STATUS = [
  EnumPaymentMethodStatusTypes.VERIFIED,
  EnumPaymentMethodStatusTypes.VALIDATED,
];

export const INVALID_PAYMENT_METHOD_STATUSES = [
  EnumPaymentMethodStatusTypes.ERRORED,
  EnumPaymentMethodStatusTypes.VERIFICATION_FAILED,
];

/**
 * Checks whether a payment request is past due.
 * This is having its dueDate in the past and having a balanceDue greater than Zero
 * @param {object} request a paymentRequest object
 */
export const isPaymentRequestPastDue = (request) => {
  if (!request) return false;
  const dueDateMoment = moment(request.due_date, 'YYYY-MM-DD');
  return (
    request.balance_due > 0 &&
    dueDateMoment.startOf('day').isBefore(moment().startOf('day'))
  );
};

/**
 * Calculates the totalPastDue and the totalUnpaid for a given list of paymentRequests
 * @param {object} paymentRequests a paymentRequest object
 * @returns {object} { totalPastDue, totalPastDue }
 */
export const calculateTotalUnpaidAndPastDue = (paymentRequests) => {
  if (!paymentRequests) {
    return {
      totalPastDue: 0,
      totalUnpaid: 0,
      unpaidCount: 0,
    };
  }
  return paymentRequests.reduce(
    (acc, request) => {
      let totalPastDue = acc.totalPastDue;
      let unpaidCount = acc.unpaidCount;
      if (isPaymentRequestPastDue(request)) {
        totalPastDue += request.balance_due;
      }
      if (request.balance_due > 0) {
        unpaidCount += 1;
      }
      return {
        totalUnpaid: acc.totalUnpaid + request.balance_due,
        totalPastDue,
        unpaidCount,
      };
    },
    {
      totalPastDue: 0,
      totalUnpaid: 0,
      unpaidCount: 0,
    },
  );
};

/**
 * This function serves as a callback to sort an array of sent charges. It can be used as:
 * payment_requests.sort(sortSentChargesDueDateDesc)
 * It sorts the charges by theirs due_date and from olders to newers
 * @param {object} a sent charge object
 * @param {object} b sent charge object
 */
export const sortSentChargesDueDateDesc = (a, b) => {
  const dateA = moment(a.due_date);
  const dateB = moment(b.due_date);

  if (dateA.isBefore(dateB)) {
    return 1;
  } else if (dateA.isAfter(dateB)) {
    return -1;
  }
  return 0;
};

/**
 * Checks whether or not a renter has set payment methods
 * @param {object} user a renter profile
 */
export const renterHasPaymentMethod = (user) => {
  return user?.payment_methods?.length > 0;
};

/**
 * Returns the payment method used for rent payments.
 * It should be a card or a verified bank account. If nothing
 * matches this criteria, it will return undefined
 * @param {*} user
 * @returns the payment method or undefined
 */
export const getPaymentMethodUsedForRentRentPayment = (user) =>
  user?.payment_methods?.find(
    (pm) =>
      pm.used_for_rent_payments === true &&
      (pm.type !== 'BANK_ACCOUNT' ||
        (pm.type === 'BANK_ACCOUNT' &&
          VALID_PAYMENT_METHOD_STATUS.includes(pm.source_status))),
  );

/**
 * Returns the payment method that we want to show on rent payment setting page.
 * It should be a card or bank account that is unverified or valid. If nothing
 * matches this criteria, it will return undefined
 * @param {*} user
 * @returns the payment method or undefined
 */
export const getPaymentMethodForRentRentPaymentSettingsPage = (user) =>
  user?.payment_methods?.find(
    (pm) =>
      pm.used_for_rent_payments === true &&
      (pm.type !== 'BANK_ACCOUNT' ||
        (pm.type === 'BANK_ACCOUNT' &&
          [
            EnumPaymentMethodStatusTypes.NEW,
            ...VALID_PAYMENT_METHOD_STATUS,
          ].includes(pm.source_status))),
  );

/**
 * This function serves as a callback to sort an array of payment rules. It can be used as:
 * paymentRequestRules.sort(sortMonthlyPaymentRulesDueDateAsc)
 * It has to be used over an array containing only MONTLY rules
 * the rules are sorted comparing the due_day_of_month
 * Monthly rules goes to the top
 * @param {object} a payment rule object
 * @param {object} b payment rule object
 */
export const sortMonthlyPaymentRulesDueDateAsc = (a, b) => {
  if (a.type !== 'MONTHLY' || b.type !== 'MONTHLY') {
    throw 'ONLY MONTHLY RULES ALLOWED';
  }
  if (a.due_day_of_month < b.due_day_of_month) {
    return -1;
  } else if (a.due_day_of_month > b.due_day_of_month) {
    return 1;
  }
  return 0;
};

/**
 * This function serves as a callback to sort an array of payment rules. It can be used as:
 * paymentRequestRules.sort(sortOneTimePaymentRulesDueDateAsc)
 * It has to be used over an array containing only ONE TIME rules
 * the rules are sorted comparing the end_date
 * Monthly rules goes to the top
 * @param {object} a payment rule object
 * @param {object} b payment rule object
 */
export const sortOneTimePaymentRulesDueDateAsc = (a, b) => {
  if (a.type !== 'ONE_TIME' || b.type !== 'ONE_TIME') {
    throw 'ONLY ONE TIME RULES ALLOWED';
  }
  const dateA = moment(a.end_date);
  const dateB = moment(b.end_date);
  if (dateA.isBefore(dateB)) {
    return -1;
  } else if (dateA.isAfter(dateB)) {
    return 1;
  }
  return 0;
};

export const showTurboPayFeature = (user) =>
  user &&
  user.payments_status &&
  user.payments_status.fraud === 'APPROVED' &&
  user.has_payment_request_rules;

export const showRentPaymentsFeature = (user) => user && !user.payments_data;

export const hasUserPaymentRules = (leases) => {
  if (!leases || leases.length <= 0) return false;
  return leases.some((lease) => !!lease.payment_request_rules_count);
};

export const isOneTimeCharge = (charge) =>
  charge?.type === 'ONE_TIME' ||
  charge?.rule_type === 'ONE_TIME' ||
  charge?.__typename === 'PaymentRequest';

export const sortRequestsPaymentsByIdAsc = (a, b) => {
  if (a.id < b.id) {
    return -1;
  } else if (a.id > b.id) {
    return 1;
  }
  return 0;
};

export const paymentCategoryToString = (category) =>
  ({
    [EnumRentChargeType.RENT]: 'Rent',
    [EnumRentChargeType.LATE_FEE]: 'Late',
    [EnumRentChargeType.SECURITY_DEPOSIT]: 'Security Deposit',
    [EnumRentChargeType.UTILITY_CHARGE]: 'Utility Charge',
    [EnumRentChargeType.NSF_RETURNED_PAYMENT]: 'NSF Fee',
    [EnumRentChargeType.OTHER]: 'Other',
  })[category];

export const isPaymentMethodInvalid = (paymentMethod) => {
  return INVALID_PAYMENT_METHOD_STATUSES.includes(paymentMethod?.source_status);
};
