import React, { useEffect } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import Page from '../../components/page';
import componentLoader from '../../helpers/component-loader';

const PropertiesRoutes = componentLoader(
  () => import('../properties/routes.properties'),
);

const PrintProperty = componentLoader(
  () => import('../properties/PrintProperty'),
);

const ApplicantProfile = componentLoader(
  () => import('./renter-profile/ApplicantProfileContainer'),
);
const TenantProfile = componentLoader(
  () => import('./renter-profile/TenantProfileContainer'),
);

const LeadView = componentLoader(
  () => import('./renter-profile/LeadView/LeadViewContainer'),
);
const LeadQuestionnaireEdit = componentLoader(
  () => import('./renter-profile/lead-questionnaire/LeadQuestionnaireEdit'),
);

const RenterProfilePrint = componentLoader(
  () => import('./renter-profile/RenterProfilePrintContainer'),
);

const RentersListContainer = componentLoader(
  () => import('./renters/RentersListContainer'),
  {
    marginTop: '75px',
  },
);

const LeasesComponent = componentLoader(
  () => import('./leases/LeasesComponent'),
);

const ScreeningIntroduction = componentLoader(
  () => import('./external_application/Introduction.js'),
);

const ArchivedLeases = componentLoader(() => import('./leases/ArchivedLeases'));

const ArchivedContainer = componentLoader(
  () => import('./renters/ArchivedDataContainer'),
);

const AccountSettingsContainer = componentLoader(
  () => import('./profile/AccountSettingsContainer'),
);

const ExternalApplicationFormComponent = componentLoader(
  () => import('./external_application/ExternalApplication'),
);

const ExternalApplicationPaymentComponent = componentLoader(
  () => import('./external_application/ExternalApplicationPayment'),
);

const ExternalApplicationLeaseComponent = componentLoader(
  () => import('./external_application/LeaseScreen'),
);

const ExternalApplicationSuccessComponent = componentLoader(
  () => import('./external_application/ExternalApplicationSuccess'),
);

const InstantScreeningContainer = componentLoader(
  () => import('./profile/InstantScreeningContainer'),
);

const ApplicantActionsComponent = componentLoader(
  () => import('./renters/actions/ApplicantActions'),
);

const AddTenantInfoRoute = componentLoader(
  () => import('./renters/AddTenantInfoRoute'),
);

const InviteRenter = componentLoader(
  () => import('./renters/InviteRenterPage'),
);

const LeadsBulkActions = componentLoader(
  () => import('./renters/leads/LeadsBulkActions'),
);

const UploadPolicyComponent = componentLoader(
  () => import('../renters/insurance/UploadPolicyContainer'),
);

const LeasesRoutes = componentLoader(() => import('./leases/routes.leases'));

const DashboardComponent = componentLoader(
  () => import('./dashboard/DashboardComponent'),
);

const ElectronicSignRoute = componentLoader(
  () => import('../electronic-signature/ElectronicSignRoute'),
);

const TTDocumentsEsignRoutes = componentLoader(
  () =>
    import('../electronic-signature/TTDocumentsEsign/routes.ttDocumentsEsign'),
);

const ElectronicSignIntroRoute = componentLoader(
  () => import('../electronic-signature/ElectronicSignIntroRoute'),
);

const DigitalLeaseRoute = componentLoader(
  () => import('../digital-leases/DigitalLeaseRoute'),
);

const DigitalLeaseCreationRoute = componentLoader(
  () => import('../digital-leases/DigitalLeaseCreationRoute'),
);

const LeaseAgreementEsignRoute = componentLoader(
  () =>
    import(
      '../lease-agreement-esign/LeaseAgreementEsignRoute/LeaseAgreementEsignRoute'
    ),
);

const RenterESignDocument = componentLoader(
  () =>
    import(
      '../electronic-signature/SignatureProcessComponent/SignatureProcessComponent'
    ),
);

const EmailPreferencesRoute = componentLoader(
  () => import('../email-preferences/EmailPreferencesRoute'),
);

const SendBulkMessage = componentLoader(
  () => import('../common/messages/SendBulkMessage'),
);

const MessagesPage = componentLoader(() => import('./messages'));

const MessagesLoading = componentLoader(
  () => import('./messages/MessagesLoading'),
);

const HelpCenterPage = componentLoader(() => import('./help_center'));

const MarketingCallForwardingPage = componentLoader(
  () => import('./marketing_call_forwarding'),
);

const ReferralsPage = componentLoader(
  () => import('./referrals/ReferralsPage'),
);

const LeaseAgreementPromotional = componentLoader(
  () => import('./screening-report/LeaseAgreementPromotional'),
);

const StandalonePremiumModal = componentLoader(
  () => import('./components/StandalonePremiumModal'),
);

const ListingShowingsPage = componentLoader(
  () => import('./listing-showing/ListingShowingsPage'),
);

const StripeTokenPage = componentLoader(() => import('./mobile/StripeToken'));
const MobileAddStripeInfoPage = componentLoader(
  () => import('./mobile/AddStripeInfo'),
);

const ConditionReportsListPage = componentLoader(
  () => import('./condition-report'),
);

const PlaidAuthPage = componentLoader(() => import('../renters/plaid-auth'));

import PageNotFound from '../../components/PageNotFound';
import RedirectRoute from '../../components/RedirectRoute';
import { COOKIE_NAMES, setCookie } from '../../helpers/cookies';
import BlankPageLayout from '../../layout/BlankPageLayout/BlankPageLayout';
import DarkBluePageSimple from '../../layout/DarkBluePageSimple/DarkBluePageSimple';
import InAppLayouts from '../../layout/InAppLayouts/InAppLayouts';
import { RouteWithLayout } from '../../providers/LayoutProvider';
import RentButterReport from '../applications/RentButterReport';
import ConditionReportsLeaseSelector from '../document_wizards/condition_reports/ConditionReportsLeaseSelector';
import RentalAddendumLeaseSelector from '../document_wizards/rental-addendum/RentalAddendumLeaseSelector';
import RentalAddendumRoute from '../document_wizards/rental-addendum/RentalAddendumRoute';
import DocumentsPage from '../documents/DocumentsPage';
import FormsRoutes from '../forms/routes.forms';
import PaymentRoutes from '../payments/owner/routes.payments';

import AcademyPage from './academy/AcademyPage';
import LoadAcademyPage from './academy/LoadAcademyPage';
import GlobalNotification from './components/GlobalNotification';
import { DashboardProvider } from './dashboard/context.js';
import DashboardSuggestionHandler from './dashboard/SuggestionHandler';
import MaintenanceRoutes from './maintenance/routes.maintenance';
import ToolboxPage from './toolbox';

const OwnerRoutes = ({ location, match }) => {
  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    if (queryParams.academyReferral) {
      const sevenDays = 60000 * 60 * 24 * 7;
      setCookie(
        COOKIE_NAMES.ACADEMY_REFERRAL,
        queryParams.academyReferral,
        sevenDays,
      );
    }
  }, []);

  const me = match.url;
  return (
    <>
      <Switch>
        <Route
          path={`${me}/applicant-profile/:applicantId`}
          component={ApplicantProfile}
        />
        <Route
          path={`${me}/tenant-profile/:tenantId`}
          component={TenantProfile}
        />
        <Route path={`${me}/lead-profile/:leadId`} component={LeadView} />
        <Route
          exact
          path={`${me}/edit-lead-questionnaire/:leadId`}
          component={LeadQuestionnaireEdit}
        />
        <Route
          exact
          path={`${me}/profile-print/:applicationId`}
          component={RenterProfilePrint}
        />
        <Route
          exact
          path={`${me}/applicant-actions/:applicationId`}
          component={ApplicantActionsComponent}
        />
        <Route
          exact
          path={`${me}/screening-requested`}
          component={LeaseAgreementPromotional}
        />
        <Route path={`${me}/create-tenant`} component={AddTenantInfoRoute} />
        <Route path={`${me}/invite-renter`} component={InviteRenter} />
        <Route path={`${me}/leads-bulk-invite`} component={LeadsBulkActions} />
        <Route path={`${me}/renters/:type`} component={RentersListContainer} />
        <Route
          exact
          path={`${me}/archived/leads`}
          component={RentersListContainer}
        />
        <Route
          exact
          path={`${me}/leases`}
          render={(props) => (
            <Page title="My Leases - TurboTenant">
              <LeasesComponent {...props} />
            </Page>
          )}
        />
        <RedirectRoute
          from={`${me}/leases/archived`}
          to={`${me}/leases/past`}
        />
        <Route
          exact
          path={`${me}/leases/past`}
          render={(props) => (
            <Page title="My Past Leases - TurboTenant">
              <ArchivedLeases {...props} />
            </Page>
          )}
        />
        <Route
          exact
          path={`${me}/archived/:renterType`}
          component={ArchivedContainer}
        />
        <Route path={`${me}/properties`} component={PropertiesRoutes} />
        <Route path={`${me}/settings`} component={AccountSettingsContainer} />
        <Route
          exact
          path={`${me}/property-print/:id`}
          component={PrintProperty}
        />
        <RouteWithLayout
          exact
          path={`${me}/externalapplication/intro`}
          component={ScreeningIntroduction}
          layout={BlankPageLayout}
        />
        <RouteWithLayout
          exact
          path={`${me}/externalapplication/form`}
          component={ExternalApplicationFormComponent}
          layout={BlankPageLayout}
        />
        <RouteWithLayout
          exact
          path={`${me}/externalapplication/payment`}
          component={ExternalApplicationPaymentComponent}
          layout={BlankPageLayout}
        />
        <RouteWithLayout
          exact
          path={`${me}/externalapplication/lease`}
          component={ExternalApplicationLeaseComponent}
          layout={BlankPageLayout}
        />
        <Route
          exact
          path={`${me}/externalapplication/success`}
          component={ExternalApplicationSuccessComponent}
        />
        <Redirect
          from={`${me}/externalapplication`}
          to={`${me}/externalapplication/intro`}
        />
        <Route
          exact
          path={`${me}/instantscreening`}
          component={InstantScreeningContainer}
        />
        <Route
          exact
          path={`${me}/insurance/upload/:renterId/:listingId`}
          render={(props) => <UploadPolicyComponent {...props} refetchOwner />}
        />
        <Route path={`${me}/leases`} component={LeasesRoutes} />
        <Route
          path={`${me}/dashboard`}
          render={(props) => (
            <Page title="My Dashboard - TurboTenant">
              <DashboardProvider>
                <DashboardComponent {...props} />
                <DashboardSuggestionHandler />
              </DashboardProvider>
            </Page>
          )}
        />
        <Route
          path={`${me}/rental-addendum-select-lease/:listingId?`}
          component={RentalAddendumLeaseSelector}
        />
        <Route
          path={`${me}/condition-reports-select-lease/:listingId?`}
          component={ConditionReportsLeaseSelector}
        />
        <Route
          path={`${me}/rental-addendum/:leaseId`}
          component={RentalAddendumRoute}
        />
        <Route
          path={`${me}/add-digital-lease`}
          component={DigitalLeaseCreationRoute}
        />
        <Route
          path={`${me}/digital-leases/:leaseId`}
          component={DigitalLeaseRoute}
        />
        <Route
          path={`${me}/e-sign-lease-agreement/:leaseId/:signatureRequestId`}
          component={LeaseAgreementEsignRoute}
        />
        <Route
          path={`${me}/e-sign-lease/:leaseId/:signatureRequestId`}
          component={ElectronicSignRoute}
        />
        <Route
          path={`${me}/e-sign-document-ready/:leaseId/:documentId`}
          component={TTDocumentsEsignRoutes}
        />
        <Route
          path={`${me}/e-sign-lease-intro`}
          component={ElectronicSignIntroRoute}
        />
        <Route
          exact
          path={`${me}/esignature/sign/:signatureId/:leaseId`}
          component={RenterESignDocument}
        />
        {/**
         * NOTE: This route seams redundant.
         * The routes for preferences that are actually used are
         * the public (unauthorized) route,
         * and the `owners/settings/email-preferences` route
         */}
        <Route
          path={`${me}/email-preferences`}
          component={EmailPreferencesRoute}
        />
        <Route
          exact
          path={`${me}/sendbulkmessage`}
          component={SendBulkMessage}
        />
        <Route
          path={`${me}/payments`}
          render={(props) => <PaymentRoutes {...props} />}
        />
        <Route
          path={`${me}/maintenance`}
          render={(props) => <MaintenanceRoutes {...props} />}
        />
        <RouteWithLayout
          layout={InAppLayouts}
          exact
          path={`${me}/toolbox`}
          component={ToolboxPage}
        />
        <RouteWithLayout
          layout={InAppLayouts}
          exact
          path={`${me}/academy`}
          component={AcademyPage}
        />
        <RouteWithLayout
          layout={DarkBluePageSimple}
          exact
          path={`${me}/load-academy`}
          component={LoadAcademyPage}
        />
        <RouteWithLayout
          layout={InAppLayouts}
          path={`${me}/documents`}
          component={DocumentsPage}
        />
        <Redirect exact from={`${me}/forms`} to={`${me}/documents/forms`} />
        <Route path={`${me}/forms`} component={FormsRoutes} />
        <Route path={`${me}/messages/:receiverUID?`} component={MessagesPage} />
        <Route path={`${me}/messages-loading`} component={MessagesLoading} />
        {/* ROUTE USED BY MARKETING TO POINT TO A COPY OF THE PREMIUM MODAL  */}
        <Route
          path={`${me}/premium-modal/:version`}
          component={StandalonePremiumModal}
        />
        <Redirect exact from="/owners/" to={`${me}renters/applicants`} />
        <Redirect exact from="/owners" to={`${me}/renters/applicants`} />
        <Route
          path={`${me}/referrals`}
          render={(props) => (
            <Page title="Refer A Landlord - TurboTenant">
              <ReferralsPage {...props} />
            </Page>
          )}
        />
        <Redirect
          exact
          from={`${me}/renters`}
          to={`${me}/renters/applicants`}
        />
        <Route path={`${me}/help_center`} component={HelpCenterPage} />
        <RouteWithLayout
          layout={InAppLayouts}
          exact
          path={`${me}/marketing_call_forwarding/:listingId?`}
          component={MarketingCallForwardingPage}
        />
        <RouteWithLayout
          layout={BlankPageLayout}
          exact
          path={`${me}/onboarding_marketing_call_forwarding/:listingId?`}
          component={MarketingCallForwardingPage}
        />
        <Route path={`${me}/showings`} component={ListingShowingsPage} />
        <Route
          path={`${me}/condition-reports`}
          component={ConditionReportsListPage}
        />
        <Route path={`${me}/mobile/stripe-token`} component={StripeTokenPage} />
        <Route
          path={`${me}/mobile/add-stripe-info`}
          component={MobileAddStripeInfoPage}
        />
        <Route
          exact
          path={`${me}/plaid-authentication`}
          component={PlaidAuthPage}
        />
        <Route
          exact
          path={`${me}/rentbutter-report/:applicationId`}
          component={(props) => <RentButterReport {...props} />}
        />
        <Route component={PageNotFound} />
      </Switch>
      <GlobalNotification />
    </>
  );
};

OwnerRoutes.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
};

export default withRouter(OwnerRoutes);
