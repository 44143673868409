import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import getFormattedDateLocalTZ from '../../../../helpers/format-date-local';
import SheetColored from '../../../../icons/SheetColored';
import { useConfig } from '../../../../providers/ConfigProvider';
import RenterToDoCard from '../RenterToDoCardBase';

import styles from './ConditionReportTodoCard.module.scss';

export const docTypeLabels = {
  CONDITION_REPORT_MOVE_IN: 'Move-in',
  CONDITION_REPORT_MOVE_PERIODIC: 'Periodic',
  CONDITION_REPORT_MOVE_OUT: 'Move-out',
  OTHER: 'Other',
};

const ConditionReportTodoCard = ({
  className,
  inProgress,
  type,
  id,
  dueDate,
}) => {
  const { PRIVATE_BASE_PATH } = useConfig();
  const formattedDate = getFormattedDateLocalTZ(dueDate, 'MM/DD/YYYY');

  if (inProgress) {
    return (
      <RenterToDoCard
        dataQA="condition-report-renter-in-progress"
        title={`Finish your ${docTypeLabels[
          type
        ].toLowerCase()} condition report`}
        icon={SheetColored}
        description={`Make sure you complete the report that's in progress by ${formattedDate}. Remember, only one tenant needs to complete and submit the report.`}
        primaryLabel="Finish report"
        className={cx(styles.card, className)}
        primaryTo={`${PRIVATE_BASE_PATH}condition-report/fill/${id}`}
      />
    );
  }
  return (
    <RenterToDoCard
      dataQA="condition-report-renter-todo"
      title={`${docTypeLabels[type]} condition report requested`}
      icon={SheetColored}
      description={`Complete the report by ${formattedDate}. Upload pictures and mark the condition of rooms and items at the property.`}
      primaryLabel="Start report"
      className={cx(styles.card, className)}
      primaryTo={`${PRIVATE_BASE_PATH}condition-report/fill/${id}`}
    />
  );
};

ConditionReportTodoCard.propTypes = {
  className: PropTypes.string,
  inProgress: PropTypes.bool,
  type: PropTypes.string,
  id: PropTypes.string,
  dueDate: PropTypes.string,
};

export default ConditionReportTodoCard;
