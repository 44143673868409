import useWindowSize from '@rooks/use-window-size';

/**
 * This hook checks for the windows size and returns true if it
 * is <= than given breakpoint defined in media-breakpoints file
 * @returns
 */
export const useIsScreenSize = (breakpoint) => {
  const { innerWidth } = useWindowSize();
  return innerWidth <= breakpoint;
};
