import React from 'react';
import { createRoot } from 'react-dom/client';

// Filter React defaultProps warnings - when in development mode
if (process.env.NODE_ENV === 'development') {
  const originalError = console.error;

  console.error = (...args) => {
    if (args[0]?.includes?.('defaultProps')) {
      return;
    }
    originalError.apply(console, args);
  };
}

import './_entry.on-first-load';
import './assets/segment-script';
import './assets/favicon.png';

import AdminApplication from './admin/App';
import { ModalProvider } from './components/Modal/ModalProvider';
import { ShowHideProvider } from './components/ShowHideComponents';
import { ToastProvider } from './components/Toast';
import { StatusEndpointProvider } from './helpers/status-endpoint';
import MainApplication from './pages/MainApplication';
import ConfigProvider from './providers/ConfigProvider';

const container = document.getElementById('root');

const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <ToastProvider>
    <ShowHideProvider>
      <ConfigProvider>
        {(config) => (
          <StatusEndpointProvider>
            <ModalProvider>
              {config.IS_ADMIN ? <AdminApplication /> : <MainApplication />}
            </ModalProvider>
          </StatusEndpointProvider>
        )}
      </ConfigProvider>
    </ShowHideProvider>
  </ToastProvider>,
);

if (module.hot) module.hot.accept();
