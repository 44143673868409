import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../../../../components/Button';
import NumberedList from '../../../../../../../components/NumberedList';
import { segmentTracking } from '../../../../../../../services/utilities';

import crheaderImg from './crheader.png';

import styles from './ConditionReportingEmptyState.module.scss';

const ConditionReportingEmptyState = ({ className, onClick }) => {
  const location = useLocation();
  const history = useHistory();
  const { formsPage } = location.state || {};

  useEffect(() => {
    if (formsPage && onClick) {
      onClick();
      // clear formsPage param from the state
      history.replace({
        pathname: location.pathname,
        state: { ...location.state, formsPage: undefined },
      });
    }
  }, [formsPage, onClick]);

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.cardsWrapper}>
        <div className={styles.addCard}>
          <img src={crheaderImg} className={styles.img} />
          <h2 className={styles.subTitle}>
            Simplify your move-in/out process, digitally
          </h2>
          <NumberedList
            checked={(index) => index !== 100}
            className={styles.stepsList}
            list={[
              {
                title: 'Protect your property',
                subTitle: 'Be ready to track security deposit deductions',
              },
              {
                title: 'No paperwork headache',
                subTitle:
                  'Keep your condition reports online and out of your filing cabinet',
              },
              {
                title: 'Easy for you and your tenants',
                subTitle: 'They can upload photos right from their phone',
              },
            ]}
          />
          <Button
            onClick={() => {
              segmentTracking('create_report clicked', {
                location: 'Condition Reports Empty State',
              });
              if (onClick) onClick();
            }}
            className={styles.btn}
          >
            Create New Report
          </Button>
        </div>
      </div>
    </div>
  );
};

ConditionReportingEmptyState.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default ConditionReportingEmptyState;
