export const mapInitialListingToState = (listing) => {
  if (!listing) {
    return {
      roomRentals: false,
    };
  }

  return {
    id: listing.id,
    property_type: listing.details?.property_details?.property_type,
    roomRentals: listing.has_rooms,
    address: listing.address,
    unit: listing.unit,
    city: listing.city,
    state: listing.state,
    zip: listing.zip,
    is_multiunit: listing.is_multiunit,

    ...(!listing.is_multiunit && listing.has_rooms
      ? { room_name: listing.children_listings[0].room_name }
      : {}),
    ...(listing.is_multiunit
      ? {
          unit_name: listing.children_listings[0].unit,
          roomRentals: listing.children_listings[0].has_rooms,
          ...(listing.children_listings[0].has_rooms
            ? {
                room_name:
                  listing.children_listings[0].children_listings[0].room_name,
              }
            : {}),
        }
      : {}),
  };
};

export const mapInitialLeaseToState = (lease) => {
  if (!lease) return {};
  return {
    id: lease.id,
  };
};

export const mapInitialMonthlyCharges = (charges) => {
  if (!charges) return [];

  return charges.map((charge) => ({
    id: charge.id,
    amount: Number(charge.amount) / 100,
    category: charge.category,
    description: charge.description,
    type: charge.type,
    finalized: charge.finalized,
    due_day_of_month: charge.due_day_of_month,
    start_date: charge.start_date,
    end_date: charge.end_date,
    destination_id: charge.destination_id,
    bank_name: charge.bank_name,
    short_bank_name: charge.short_bank_name,
    entity_name: charge.entity_name,
    is_late_fee_percentage: charge.is_late_fee_percentage,
    late_fee_amount: charge.late_fee_amount,
    late_fee_grace_period: charge.late_fee_grace_period,
    late_fee_policy: charge.late_fee_policy,
  }));
};

export const mapInitialOneTimeCharges = (charges) => {
  if (!charges) return [];

  return charges.map((charge) => ({
    id: charge.id,
    amount: Number(charge.amount) / 100,
    category: charge.category,
    description: charge.description,
    type: charge.type,
    finalized: charge.finalized,
    due_day_of_month: charge.due_day_of_month,
    start_date: charge.start_date,
    end_date: charge.end_date,
    destination_id: charge.destination_id,
    bank_name: charge.bank_name,
    short_bank_name: charge.short_bank_name,
    entity_name: charge.entity_name,
  }));
};
