import React, { memo } from 'react';

const ThumbtackLogo = (props) => {
  return (
    <svg
      width="87"
      height="16"
      viewBox="0 0 87 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_12921_25407)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.08883 2.93903C5.16145 2.93903 4.25867 3.23878 3.65564 4.08419V8.33935C3.65564 9.26907 3.89919 10.1847 4.36054 10.9915L4.87106 11.8838L5.38159 10.9915C5.8441 10.1847 6.08649 9.27141 6.08649 8.33935V2.9402"
          fill="#2F3033"
        />
        <path
          d="M9.73979 0.00585938H-0.00701904V2.15217H9.73979V0.00585938Z"
          fill="#2F3033"
        />
        <path
          d="M20.1025 8.78782V3.40623H22.3893V8.10283C22.3893 9.14144 22.9689 9.80887 23.867 9.80887C24.7651 9.80887 25.5731 9.03489 25.5731 7.8745V3.40623H27.8599V11.7245H25.5731V10.7222C24.8693 11.5489 23.8857 11.8826 23.0228 11.8826C21.264 11.8826 20.1037 10.6344 20.1037 8.78782H20.1025Z"
          fill="#2F3033"
        />
        <path
          d="M38.869 5.25279C37.8655 5.25279 37.1266 6.04433 37.1266 7.18716V11.7245H34.8398V6.95883C34.8398 5.90382 34.2766 5.25279 33.3621 5.25279C32.3586 5.25279 31.6034 6.02677 31.6034 7.18716V11.7245H29.3165V3.3875H31.6034V4.4788C32.183 3.61583 33.0986 3.23059 34.0822 3.23059C35.3129 3.23059 36.2449 3.75868 36.7203 4.63805C37.3877 3.61817 38.4954 3.23059 39.5341 3.23059C41.5047 3.23059 42.6475 4.4788 42.6475 6.34409V11.7257H40.3607V6.96C40.3607 5.90499 39.7975 5.25396 38.8655 5.25396H38.8666L38.869 5.25279Z"
          fill="#2F3033"
        />
        <path
          d="M54.7866 8.82294V5.42842H53.3089V3.40623H54.7866V0.74939H57.0734V3.40506H59.2713V5.42725H57.0734V8.36511C57.0734 9.34986 57.3732 9.71988 58.146 9.71988C58.5336 9.71988 59.0418 9.59693 59.3942 9.42012L59.9387 11.4259C59.3228 11.7608 58.461 11.9013 57.6519 11.9013C56.1742 11.9013 54.7854 11.0934 54.7854 8.82411H54.7866V8.82294Z"
          fill="#2F3033"
        />
        <path
          d="M60.7829 9.36743C60.7829 7.59113 62.3133 7.08061 64.3179 6.81715C65.4256 6.67663 66.0416 6.46587 66.0416 6.0256C66.0416 5.5502 65.4619 5.21766 64.6177 5.21766C63.8437 5.21766 62.8754 5.55254 62.1201 6.36048L60.7302 4.90151C61.715 3.86289 63.1927 3.23059 64.688 3.23059C66.6938 3.23059 68.3108 4.3219 68.3108 6.34409V9.05362C68.3108 9.61684 68.504 9.84517 68.9092 9.84517C69.0145 9.84517 69.1199 9.82877 69.2089 9.81004V11.6917C68.7862 11.7971 68.3822 11.851 67.9947 11.851C67.0275 11.851 66.2886 11.447 66.1645 10.5149C65.654 11.3767 64.6868 11.8861 63.5088 11.8861C61.9257 11.8861 60.7829 10.9353 60.7829 9.37094V9.36743ZM66.0942 8.13678V7.82063C65.901 7.99627 65.4619 8.15552 64.6517 8.27729C63.613 8.43654 63.174 8.75269 63.174 9.24448C63.174 9.70114 63.5615 10.0009 64.1938 10.0009C65.3015 10.0009 66.0931 9.3323 66.0931 8.13678H66.0942Z"
          fill="#2F3033"
        />
        <path
          d="M69.6995 7.556C69.6995 5.00572 71.5637 3.23059 74.2556 3.23059C75.5226 3.23059 76.7708 3.75868 77.5799 4.761L76.0846 6.23871C75.628 5.72818 74.9418 5.37573 74.2908 5.37573C72.9887 5.37573 72.1093 6.29023 72.1093 7.55717C72.1093 8.91194 73.0414 9.71988 74.272 9.71988C74.9921 9.71988 75.5905 9.42012 76.0659 8.8932L77.5436 10.3522C76.8399 11.2667 75.5378 11.8826 74.2369 11.8826C71.5461 11.8826 69.6995 10.0887 69.6995 7.556Z"
          fill="#2F3033"
        />
        <path
          d="M15.8298 3.2306C14.8977 3.2306 13.9481 3.63457 13.3322 4.60176V0.00585938H11.0453V11.7245H13.3322V7.32768C13.3322 6.09703 14.1062 5.25279 15.0382 5.25279C15.9703 5.25279 16.5159 5.95652 16.5159 7.04665V11.7245H18.8028V6.43075C18.8028 4.5315 17.6424 3.22943 15.8298 3.22943V3.2306Z"
          fill="#2F3033"
        />
        <path
          d="M48.8757 3.2306C47.891 3.2306 46.994 3.59944 46.3606 4.25048V0.00585938H44.0737V11.7245H46.3606V10.8627C46.9929 11.495 47.891 11.8826 48.8757 11.8826C51.2152 11.8826 52.8159 10.0524 52.8159 7.55601C52.8159 5.05959 51.1977 3.2306 48.8757 3.2306ZM48.4003 9.75501C47.2212 9.75501 46.3606 8.89204 46.3606 7.53961C46.3606 6.25628 47.2224 5.34061 48.4003 5.34061C49.5783 5.34061 50.4225 6.25511 50.4225 7.55601C50.4225 8.85691 49.5958 9.75501 48.4003 9.75501Z"
          fill="#2F3033"
        />
        <path
          d="M83.9275 3.40624L81.0084 6.36049V0.00585938H78.7216V11.7245H81.0084V9.34987L82.0997 8.25856L84.1746 11.7233H86.7775L83.6828 6.67547L86.9707 3.40507H83.9287L83.9275 3.40624Z"
          fill="#2F3033"
        />
      </g>
      <defs>
        <clipPath id="clip0_12921_25407">
          <rect width="87" height="11.9435" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(ThumbtackLogo);
