import React, { useState } from 'react';
import useWindowSize from '@rooks/use-window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { smallScreenWidth } from '../../constants/media-breakpoints';
import FilterIcon from '../../icons/Filter';
import Button from '../Button';
import ButtonPopover from '../ButtonPopover';
import RadioButton from '../RadioButton';
import RadioGroup from '../RadioGroup';

import styles from './FilterRadio.module.scss';

const CUSTOM_VALUE = 'CUSTOM';

const FilterRadio = ({
  icon = FilterIcon,
  iconProps,
  filterName,
  options,
  value = null,
  onChange = () => null,
  allLabel = 'All',
  scrollable = true,
  custom,
  customLabel = 'Custom',
  className,
  staticFilterName,
  closeOnButtonsClick = false,
}) => {
  const { innerWidth } = useWindowSize();
  const [popoverOpen, setPopoverOpen] = useState(false);

  let scrollEffect = false;
  // On desktop we show scroll to users when they have more than 8 items
  // On mobile we show scroll to users when they have more than 5 items
  if (innerWidth > smallScreenWidth) {
    if (options.length > 8) {
      scrollEffect = true;
    }
  } else if (options.length > 5) {
    scrollEffect = true;
  }

  const handleOptionChange = (val) => {
    onChange(val);
    if (closeOnButtonsClick) {
      setPopoverOpen(false);
    }
  };

  return (
    <ButtonPopover
      className={cx(styles.wrapper, className)}
      customAction={(props) => (
        <Button
          className={cx(styles.button, {
            [styles.selected]: value?.length > 0,
          })}
          icon={icon}
          iconProps={{
            className: styles.icon,
            width: 15,
            height: 15,
            ...iconProps,
          }}
          {...props}
        >
          {value === null && !staticFilterName
            ? `All ${filterName}`
            : filterName}
        </Button>
      )}
      optionsClassName={styles.optionsContainer}
      opened={popoverOpen}
      setOpened={setPopoverOpen}
    >
      <div
        className={cx(styles.container, {
          [styles.scrollable]: scrollable && scrollEffect,
        })}
      >
        <RadioButton
          className={styles.radio}
          label={allLabel}
          checked={!value}
          onChange={() => {
            handleOptionChange(null);
          }}
        />
        <RadioGroup
          vertical
          options={options}
          input={{
            value: value,
            onChange: (val) => handleOptionChange(val),
          }}
          radiosContainerClassName={styles.radioContainer}
          radioRowClassName={styles.radioRowClassName}
        />
        {custom ? (
          <RadioButton
            className={styles.radioCustom}
            label={customLabel}
            onChange={() => handleOptionChange(CUSTOM_VALUE)}
            checked={value === CUSTOM_VALUE}
          />
        ) : null}
        {value === CUSTOM_VALUE ? custom : null}
      </div>
    </ButtonPopover>
  );
};

FilterRadio.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]),
  iconProps: PropTypes.object,
  filterName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ).isRequired,
  allLabel: PropTypes.string,
  value: PropTypes.string,
  scrollable: PropTypes.bool,
  customLabel: PropTypes.string,
  custom: PropTypes.node,
  className: PropTypes.string,
  onChange: PropTypes.func,
  staticFilterName: PropTypes.bool,
  closeOnButtonsClick: PropTypes.bool,
};

FilterRadio.CUSTOM = CUSTOM_VALUE;

export default FilterRadio;
