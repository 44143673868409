import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../components/Modal';

import styles from './ThumbtackIframeModal.module.scss';

const ThumbtackIframeModal = ({ open, onClose, iframeUrl }) => {
  return (
    <Modal open={open} onClose={onClose} showCloseIcon size="extra-large">
      <div className={styles.contentContainer}>
        <iframe
          frameBorder="0"
          className={styles.iframe}
          src={iframeUrl}
          id="thumbtack-pro-list"
          sandbox="allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        />
      </div>
    </Modal>
  );
};

ThumbtackIframeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  iframeUrl: PropTypes.string.isRequired,
};

export default ThumbtackIframeModal;
