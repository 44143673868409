import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { allowedColors, allowedSizes, iconNames } from './constants';

import fontStyles from './Font.module.scss';
import styles from './Icon.module.scss';

const Icon = ({ name, className, size = 'lg', color = 'default', ...rest }) => (
  <span
    style={{
      fontSize: typeof size === 'number' && `${size}px`,
      ...(!allowedColors.includes(color) ? { color } : {}),
    }}
    className={cx(
      styles.icon,
      fontStyles[`tt-icon-${name}`],
      {
        [styles[`font-size-${size}`]]: allowedSizes.includes(size),
        [styles[`font-color-${color}`]]: allowedColors.includes(color),
      },
      className,
    )}
    {...rest}
  />
);

Icon.propTypes = {
  name: PropTypes.oneOf(iconNames).isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(allowedSizes)]),
  color: PropTypes.oneOf(allowedColors),
};

export default Icon;
