import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { PREMIUM_SUBSCRIPTION_STRIPE_PRODUCT_ID } from '../../constants';
import { Experiments } from '../../constants/experiments';
import { useUserProfile } from '../../core/TTgraphql';
import basicProfileQuery from '../../graphql/basicUserProfile.graphql';
import createPremiumPlusSubscription from '../../graphql/mutations/payments/createPremiumPlusSubscription.graphql';
import { getCookies } from '../../helpers/cookies';
import { createAndSaveIdempotencyKey } from '../../helpers/createAndSaveIdempotencyKey';
import { getGBBPremiumPlanId } from '../../helpers/getPremiumPlanId';
import { useRenderOutside } from '../../helpers/render-outside';
import { resetIdempotencyKey } from '../../helpers/resetIdempotencyKey';
import { usePromoCodes } from '../../hooks/usePromoCodes';
import StripeElementsModal from '../../pages/common/stripe/StripeElementsModal';
import { useConfig } from '../../providers/ConfigProvider';
import { segmentTracking } from '../../services/utilities/segment';
import { MODAL_LOCATIONS } from '../PremiumSubscription/constants';
import getSegmentLocation from '../PremiumSubscription/get-premium-modal-segment-location';
import getPremiumPurchasedFrom from '../PremiumSubscription/getPremiumPurchasedFrom';
import PremiumIsActiveModal from '../PremiumSubscription/PremiumIsActivateModal';
import { useErrorToast } from '../Toast';

import GBBPremiumModal from './GBBPremiumModal';
import usePricesFromShortCodes from './usePricesFromShortCodes';

const GBBPremiumModalWrapper = ({
  onClose,
  singlePaymentType,
  singlePaymentAction,
  onSuccess,
  refetchQueries,
  postPaymentAction,
  hideButtons,
  benefitsType,
  openLocation,
  segmentProperties: segmentProps = {},
  onSuccessModalDoneClicked,
  purchase_location,
  extraParams = {},
  onSubscribeClicked,
  onPayClicked,
  wantsCosignerAgreement,
}) => {
  const [showStripe, setShowStripe] = useState(false);
  const [selectedShortCode, setSelectedShortCode] = useState();
  const errorToast = useErrorToast();
  const { user } = useUserProfile({}, false);
  const [totalCost, setTotalCost] = useState(0);
  const [isRentalAccountingActive, setIsRentalAccountingActive] =
    useState(false);
  const [rentalAccountingUnits, setRentalAccountingUnits] = useState(0);

  const validatedPromoCodes = usePromoCodes();

  const experimentValue = user.active_experiments?.find(
    (exp) => exp.name === Experiments.GoodBetterBest.name,
  )?.value;

  const renderOutside = useRenderOutside();

  const {
    GBB_SINGLE_LEASE_YEARLY_59_SHORT_CODE,
    GBB_FORMS_PACK_YEARLY_199_SHORT_CODE,
    GBB_PRO_YEARLY_119_SHORT_CODE,
    GBB_PREMIUM_PLUS_YEARLY_299_SHORT_CODE,
    GBB_PREMIUM_PLUS_YEARLY_249_SHORT_CODE,
    GBB_PREMIUM_YEARLY_149_SHORT_CODE,
    GBB_PREMIUM_YEARLY_179_SHORT_CODE,
    GBB_PREMIUM_YEARLY_199_SHORT_CODE,
    REI_HUB_ANNUAL_PRICE_FULL_PLAN_ID,
  } = useConfig();

  const userShortCode = getGBBPremiumPlanId(user);

  const proPrice = usePricesFromShortCodes(GBB_PRO_YEARLY_119_SHORT_CODE);

  const isVariantE = experimentValue === Experiments.GoodBetterBest.variants.E;

  const premiumPlusPrice = usePricesFromShortCodes(
    isVariantE
      ? GBB_PREMIUM_PLUS_YEARLY_249_SHORT_CODE
      : GBB_PREMIUM_PLUS_YEARLY_299_SHORT_CODE,
  );

  const premiumPrice = usePricesFromShortCodes(userShortCode);
  const queryParams = queryString.parse(location.search);
  const isMobileApp = queryParams?.mobile_app === 'true';
  const modalTypeDesktop = openLocation || singlePaymentType;
  const modalType = isMobileApp ? MODAL_LOCATIONS.MOBILE_APP : modalTypeDesktop;

  const { premium_referral } = getCookies();

  const segmentLocation = getSegmentLocation(modalType);

  const segmentProperties = {
    ...segmentProps,
    location: segmentLocation,
    experiment: `experiment_gbb_${experimentValue}`,
  };

  let stripeModalLabel;
  switch (selectedShortCode) {
    case GBB_PREMIUM_YEARLY_149_SHORT_CODE:
    case GBB_PREMIUM_YEARLY_179_SHORT_CODE:
    case GBB_PREMIUM_YEARLY_199_SHORT_CODE:
      stripeModalLabel = 'Premium Subscription';
      break;
    case GBB_PRO_YEARLY_119_SHORT_CODE:
      stripeModalLabel = 'Pro Subscription';
      break;
    case GBB_PREMIUM_PLUS_YEARLY_299_SHORT_CODE:
    case GBB_PREMIUM_PLUS_YEARLY_249_SHORT_CODE:
      stripeModalLabel = 'Premium Plus Subscription';
      break;
    case GBB_SINGLE_LEASE_YEARLY_59_SHORT_CODE:
      stripeModalLabel = 'Subscribe to Single Lease';
      break;
    case GBB_FORMS_PACK_YEARLY_199_SHORT_CODE:
      stripeModalLabel = 'Subscribe to Forms Pack';
      break;
  }

  const subscriptionPrice = usePricesFromShortCodes(selectedShortCode);

  const supportedStripeProductIds = [PREMIUM_SUBSCRIPTION_STRIPE_PRODUCT_ID];

  useEffect(() => {
    segmentTracking('gbb_premium_modal loaded', {
      ...segmentProperties,
    });
  }, []);

  const checkIsPremiumSelected = () =>
    selectedShortCode === GBB_PRO_YEARLY_119_SHORT_CODE ||
    selectedShortCode === GBB_PREMIUM_PLUS_YEARLY_299_SHORT_CODE ||
    selectedShortCode === GBB_PREMIUM_PLUS_YEARLY_249_SHORT_CODE;

  const checkIsPremiumPlusSelected = () =>
    selectedShortCode === GBB_PREMIUM_PLUS_YEARLY_299_SHORT_CODE ||
    selectedShortCode === GBB_PREMIUM_PLUS_YEARLY_249_SHORT_CODE;

  const [createSubscription] = useMutation(createPremiumPlusSubscription, {
    refetchQueries: [
      { query: basicProfileQuery },
      ...(refetchQueries?.map((query) => ({ query })) || []),
    ],
    awaitRefetchQueries: true,
  });

  const onToken = useCallback(
    async (token, { promoCode, tokenizationMethod }) => {
      let error;
      try {
        const idempotencyKey = createAndSaveIdempotencyKey();
        await createSubscription({
          variables: {
            ...(checkIsPremiumPlusSelected()
              ? { plusReiHubIncludedInPremium: true }
              : {}),
            token,
            promoCode,
            idempotencyKey,
            plan_id: selectedShortCode,
            // If not referred by any source, we track the location from where it is being bought
            referral: premium_referral || getPremiumPurchasedFrom(modalType),
            location: purchase_location,
            unitCount: isRentalAccountingActive
              ? rentalAccountingUnits
              : undefined,
            reiHubPlanId: isRentalAccountingActive
              ? REI_HUB_ANNUAL_PRICE_FULL_PLAN_ID
              : undefined,
          },
        });

        segmentTracking('purchase success', {
          paymentMethod: tokenizationMethod || 'card',
          ...segmentProperties,
        });

        let additionalProps = {};
        let segmentPlanName = '';

        if (!checkIsPremiumSelected()) {
          additionalProps = { showExpirationDate: true };
          segmentPlanName = 'premium';
        } else if (checkIsPremiumPlusSelected()) {
          additionalProps = { isPremiumPlusPlan: true };
          segmentPlanName = 'premium_plus';
        } else {
          additionalProps = { isProPlan: true };
          segmentPlanName = 'pro';
        }

        renderOutside((done) => (
          <PremiumIsActiveModal
            onClose={() => {
              done();
              onSuccessModalDoneClicked && onSuccessModalDoneClicked();
            }}
            open
            segmentProperties={segmentProperties}
            segmentLoadEvent={`${segmentPlanName}_success_modal loaded`}
            {...additionalProps}
          />
        ));

        if (postPaymentAction) {
          await postPaymentAction();
        }
        onSuccess();
      } catch (e) {
        const message = get(e, 'graphQLErrors[0].message', 'An error occurred');
        errorToast(message);
        error = e?.graphQLErrors;
      }

      resetIdempotencyKey(error);
    },
    [selectedShortCode],
  );

  const handleSubscribe = (plan, eventLabel) => {
    segmentTracking(`${eventLabel} clicked`, {
      ...segmentProperties,
    });

    let code;
    switch (plan) {
      case 'premium':
        code = userShortCode; // if premium we use the price set in the premium_price_signup
        break;
      case 'pro':
        code = GBB_PRO_YEARLY_119_SHORT_CODE;
        break;
      case 'premium_plus':
        code = isVariantE
          ? GBB_PREMIUM_PLUS_YEARLY_249_SHORT_CODE
          : GBB_PREMIUM_PLUS_YEARLY_299_SHORT_CODE;
        break;
      case 'single_lease':
        code = GBB_SINGLE_LEASE_YEARLY_59_SHORT_CODE;
        break;
      case 'forms_pack':
        code = GBB_FORMS_PACK_YEARLY_199_SHORT_CODE;
        break;
    }

    setSelectedShortCode(code);

    onSubscribeClicked ? onSubscribeClicked(plan) : setShowStripe(true);
  };

  if (!user?.id) {
    return null;
  }

  // GBB Experiment for rental accounting ( clean up to subscriptionPrice when the experiment is done)
  const GbbExperimentPrice = isRentalAccountingActive
    ? subscriptionPrice + totalCost
    : subscriptionPrice;

  const GbbExperimentTitle = isRentalAccountingActive
    ? `${stripeModalLabel} + Rental Accounting`
    : stripeModalLabel;

  if (showStripe) {
    return (
      <StripeElementsModal
        open
        onClose={() => {
          setShowStripe(false);
        }}
        onToken={onToken}
        title={GbbExperimentTitle}
        amount={GbbExperimentPrice}
        buttonLabel="Pay"
        disclaimer={(paymentBtnAvailable) =>
          `By clicking Pay${paymentBtnAvailable ? ' or by paying through Google or Apple,' : ''} you acknowledge that this auto-renews yearly.`
        }
        hideDisclaimerCheckbox
        supportedStripeProductIds={supportedStripeProductIds}
        amountLabel="year"
        showPaymentRequestButton
        platformIndependent
        segmentProperties={{
          ...segmentProperties,
          location: getSegmentLocation(modalType),
        }}
        onPayClicked={() => {
          segmentTracking('get_premium_purchased clicked', {
            ...segmentProperties,
          });

          if (onPayClicked) onPayClicked();
        }}
        enablePromo={!isRentalAccountingActive}
        preAppliedCoupon={validatedPromoCodes.premium}
      />
    );
  }
  return (
    <GBBPremiumModal
      {...{
        singlePaymentType,
        singlePaymentAction,
        openLocation,
        extraParams,
        segmentProperties,
        proPrice,
        premiumPlusPrice,
        user,
        premiumPrice,
        benefitsType,
        hideButtons,
        isMobileApp,
      }}
      onClose={() => {
        segmentTracking('close clicked', {
          ...segmentProperties,
        });
        onClose();
      }}
      onGetPremium={() => handleSubscribe('premium', 'get_premium')}
      onGetPremiumPlus={() =>
        handleSubscribe('premium_plus', 'get_premium_plus')
      }
      onGetPro={() => handleSubscribe('pro', 'get_pro')}
      price={subscriptionPrice}
      variantToRender={experimentValue}
      wantsCosignerAgreement={wantsCosignerAgreement}
      setTotalCost={setTotalCost}
      setRentalAccountingActive={setIsRentalAccountingActive}
      setRentalAccountingUnits={setRentalAccountingUnits}
      preAppliedCoupon={validatedPromoCodes.premium}
    />
  );
};

GBBPremiumModalWrapper.propTypes = {
  onClose: PropTypes.func,
  singlePaymentType: PropTypes.string,
  singlePaymentAction: PropTypes.func,
  onSuccess: PropTypes.func,
  refetchQueries: PropTypes.arrayOf(PropTypes.string),
  postPaymentAction: PropTypes.func,
  hideButtons: PropTypes.bool,
  benefitsType: PropTypes.string,
  openLocation: PropTypes.string,
  segmentProperties: PropTypes.object,
  onSuccessModalDoneClicked: PropTypes.func,
  purchase_location: PropTypes.string,
  extraParams: PropTypes.object,
  onSubscribeClicked: PropTypes.func,
  onPayClicked: PropTypes.func,
  wantsCosignerAgreement: PropTypes.bool,
};

export default GBBPremiumModalWrapper;
