import React, { useEffect, useRef, useState } from 'react';
import { Field, useField, useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import cx from 'classnames';
import PropTypes from 'prop-types';

import PROPERTY_ROLES from '../../helpers/propertyRolesEnum';
import { getUnitRoomOptions } from '../../helpers/propertyUnitRooms';
import buildListingsDropdownOptions from '../../pages/properties/maintenance/utils/buildListingsDropdownOptions';
import fieldRequired from '../../validators/fieldRequired';
import SelectField from '../SelectField';

import styles from './PropertyUnitRoomDropdowns.module.scss';

const PropertyUnitRoomDropdowns = ({
  properties,
  onListingChange,
  lockPreSelectedFields,
  label = 'Property',
  className,
  required,
  dataQaTags,
  specialOptions = [],
  empty = true,
  /**
   * As default we will require the user to select a leaf. But for
   * small use cases we allow the selection of a parent (a listing that
   * has children)
   */
  allowParentSelection = false,
  name = 'listing_id',
  selectedValue,
}) => {
  const form = useForm();
  const didMountRef = useRef(false);
  const [preSelectedFields, setPreSelectedFields] = useState([]);

  const {
    input: { value: property_id },
  } = useField('property_id');
  const {
    input: { value: unit_id },
  } = useField('unit_id');
  const {
    input: { value: room_id },
  } = useField('room_id');

  const selectedListingId = room_id || unit_id || property_id || selectedValue;

  useEffect(() => {
    if (didMountRef.current) {
      if (onListingChange && selectedListingId !== '') {
        onListingChange(selectedListingId);
      }
      form.change(name, selectedListingId);
    } else {
      didMountRef.current = true;
      setPreSelectedFields([property_id, unit_id, room_id].filter(Boolean));
    }
  }, [selectedListingId, property_id, unit_id, room_id]);

  const { unitOptions, roomOptions } = getUnitRoomOptions(
    properties,
    property_id,
    unit_id,
  );

  // Ensure that the initial values are set after options are loaded
  useEffect(() => {
    if (unitOptions.length > 0 && unit_id) {
      form.change('unit_id', unit_id);
    }
    if (roomOptions.length > 0 && room_id) {
      form.change('room_id', room_id);
    }
  }, [unitOptions, roomOptions, unit_id, room_id]);

  const shouldLockProperty =
    lockPreSelectedFields && preSelectedFields.includes(property_id);
  const shouldLockUnit =
    lockPreSelectedFields && preSelectedFields.includes(unit_id);
  const shouldLockRoom =
    lockPreSelectedFields && preSelectedFields.includes(room_id);

  useEffect(() => {
    if (properties?.length > 0 && selectedValue) {
      form.change(name, selectedValue);

      const property = properties.find(
        (property) =>
          property.id === selectedValue ||
          property.children_listings?.some(
            (child) =>
              child.id === selectedValue ||
              child.children_listings?.some(
                (grandchild) => grandchild.id === selectedValue,
              ),
          ),
      );

      const unit =
        property && property.id !== selectedValue
          ? property.children_listings?.find(
              (unit) =>
                unit.id === selectedValue ||
                unit.children_listings?.some(
                  (room) => room.id === selectedValue,
                ),
            )
          : null;

      const room =
        unit && unit.id !== selectedValue
          ? unit.children_listings?.find((room) => room.id === selectedValue)
          : null;

      const result = room || unit || property;

      if (!result) {
        return;
      }

      const { property_role, id, parent_listing_id, root_listing_id } = result;

      switch (property_role) {
        case PROPERTY_ROLES.SINGLE_UNIT:
        case PROPERTY_ROLES.MULTI_UNIT:
          form.change('property_id', id);
          setPreSelectedFields([id]);
          break;

        case PROPERTY_ROLES.UNIT:
          form.change('property_id', parent_listing_id);
          form.change('unit_id', id);
          setPreSelectedFields([parent_listing_id, id]);
          break;

        case PROPERTY_ROLES.ROOM:
          form.change('property_id', root_listing_id);
          form.change('unit_id', parent_listing_id);
          form.change('room_id', id);
          setPreSelectedFields([root_listing_id, parent_listing_id, id]);
          break;

        default:
          break;
      }
    }
  }, [properties?.length, selectedValue]);

  return (
    <div
      className={cx(styles.propertyUnitRoomDropdowns, {
        [className]: className,
      })}
    >
      <OnChange name="unit_id">
        {() => {
          /* TODO: investigate why we did this */
          // form.change('room_id', null);
        }}
      </OnChange>

      <OnChange name="property_id">
        {() => {
          form.change('room_id', null);
          form.change('unit_id', null);
        }}
      </OnChange>

      <Field
        label={label}
        component={SelectField}
        name="property_id"
        id="property"
        className={cx(styles.property, {
          [styles.fieldWithMargin]: selectedListingId,
          [styles.disabled]: shouldLockProperty,
        })}
        selectClassName={styles.select}
        options={[
          ...specialOptions,
          ...buildListingsDropdownOptions(properties),
        ]}
        empty={!shouldLockProperty && empty}
        disabled={shouldLockProperty}
        data-qa={dataQaTags?.property}
        validate={required ? fieldRequired : () => {}}
        value={property_id}
      />
      <div className={styles.unitAndRoomContainer}>
        {unitOptions.length > 0 && (
          <Field
            label="Unit"
            component={SelectField}
            name="unit_id"
            id="unit_id"
            className={cx(styles.property, {
              [styles.disabled]: shouldLockUnit,
            })}
            selectClassName={styles.select}
            labelProps={
              !shouldLockUnit && allowParentSelection
                ? { hint: '(Optional)' }
                : {}
            }
            options={unitOptions}
            empty={!shouldLockUnit}
            disabled={shouldLockUnit}
            validate={!allowParentSelection && fieldRequired}
            value={unit_id}
          />
        )}
        {roomOptions.length > 0 && (
          <Field
            label="Room"
            component={SelectField}
            name="room_id"
            id="room_id"
            className={cx(styles.property, {
              [styles.disabled]: shouldLockRoom,
            })}
            selectClassName={styles.select}
            labelProps={
              !shouldLockRoom && allowParentSelection
                ? { hint: '(Optional)' }
                : {}
            }
            options={roomOptions}
            empty={!shouldLockRoom}
            disabled={shouldLockRoom}
            validate={!allowParentSelection && fieldRequired}
          />
        )}
      </div>
      <Field name={name} type="hidden" component="input" />
    </div>
  );
};

PropertyUnitRoomDropdowns.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.object).isRequired,
  onListingChange: PropTypes.func,
  lockPreSelectedFields: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  specialOptions: PropTypes.arrayOf(PropTypes.object),
  empty: PropTypes.bool,
  allowParentSelection: PropTypes.bool,
  name: PropTypes.string,
  dataQaTags: PropTypes.object,
  selectedValue: PropTypes.string,
};

export default PropertyUnitRoomDropdowns;
