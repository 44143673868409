import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import confirmEmailGQL from './graphql/confirmEmail.graphql';
import ConfirmEmail from './ConfirmEmailComponent';

const ConfirmEmailContainer = (props) => {
  const [loading, setLoading] = useState(true);
  const [confirmEmailRequest] = useMutation(confirmEmailGQL, {
    onCompleted: () => {
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    },
  });

  const confirmEmail = useCallback(async (variables) => {
    const res = await confirmEmailRequest({
      variables: variables,
      awaitRefetchQueries: true,
      refetchQueries: ['basicProfileQuery'],
    });

    return res?.data?.verifyEmail;
  }, []);

  const newProps = {
    confirmEmail,
    token: props.match.params.token,
    loading: loading,
  };

  return <ConfirmEmail {...props} {...newProps} />;
};

ConfirmEmailContainer.propTypes = {
  match: PropTypes.object,
};

export default ConfirmEmailContainer;
