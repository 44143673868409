import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import RemoveDocumentModalTypes, {
  RemoveDocumentModalTypesValues,
} from '../../../../constants/lease/removeDocumentModalTypes';

import styles from './DeleteLeaseDocumentModal.module.scss';

const modalContent = {
  [RemoveDocumentModalTypes.STANDARD]: {
    heading: 'Permanently delete?',
    description: (
      <>
        This cannot be undone and you <b>will not be refunded</b> for your
        purchase.
      </>
    ),
  },
  [RemoveDocumentModalTypes.NON_REFUNDABLE]: {
    heading: 'Permanently delete?',
    description: `You can start over when you're ready.`,
  },
  [RemoveDocumentModalTypes.SAVE_COPY]: {
    heading: 'Permanently delete?',
    description:
      'Make sure you have a copy saved elsewhere if you need it. If it was shared with the tenants on this lease, they will no longer have access to it. ',
  },
  [RemoveDocumentModalTypes.STANDARD_NEW]: {
    heading: 'Are you sure you want to delete this document?',
    description: `This action cannot be undone.`,
    back: 'Cancel',
    delete: 'Yes, Delete',
  },
};

const DeleteLeaseDocumentModal = ({
  className,
  containerClassName,
  open,
  type,
  onGoBack,
  onDelete,
  loading = false,
}) => {
  const headingText = modalContent[type].heading;
  const descriptionText = modalContent[type].description;

  return (
    <Modal
      open={open}
      className={cx(styles.modal, className, {
        [styles.modalContainerStandardNew]:
          type === RemoveDocumentModalTypes.STANDARD_NEW,
      })}
      containerClassName={cx(styles.modalContainer, containerClassName)}
      onClose={onGoBack}
    >
      <h2 className={styles.heading}>{headingText}</h2>
      <p className={styles.text}>{descriptionText}</p>
      <div className={styles.btnWrapper}>
        <Button
          className={styles.btn}
          secondary
          onClick={onGoBack}
          disabled={loading}
        >
          {modalContent[type].back || 'Go Back'}
        </Button>
        <Button
          loading={loading}
          className={styles.btn}
          onClick={onDelete}
          {...(modalContent[type].delete ? { variant: 'danger' } : {})}
        >
          {modalContent[type].delete || 'Delete'}
        </Button>
      </div>
    </Modal>
  );
};

DeleteLeaseDocumentModal.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  open: PropTypes.bool,
  type: PropTypes.oneOf(RemoveDocumentModalTypesValues).isRequired,
  onDelete: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default DeleteLeaseDocumentModal;
