import get from 'lodash.get';

import { getActiveLease } from './getActiveLease';

/**
 * This function ONLY returns ONE lease that is NOT PAST.
 * Keep in mind that if the renter is only in a past leases
 * this function will return null
 *
 * Also if the renter is in multiple current leases it will just
 * return the first that was found in the list
 * @param {*} user
 * @returns
 */
export const getActiveLeaseForRenter = (user) => {
  return getActiveLease(get(user, 'leases', []) || []);
};
