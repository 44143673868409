import React, { useCallback, useEffect, useState } from 'react';
import { has } from 'lodash';

// import LaRpQuestionnaire from '../../../components/Questionnaires/LaRp';
import useSuggestion from '../../../hooks/useSuggestion';

const suggestionComponentMap = {
  // LA_RP_QUESTIONNAIRE: LaRpQuestionnaire,
};

const DashboardSuggestionHandler = () => {
  const [suggestionToShow, setSuggestionToShow] = useState(null);
  const { getSuggestion, suggestion } = useSuggestion();

  const resetSuggestion = useCallback(() => {
    setSuggestionToShow(null);
  }, []);

  useEffect(() => {
    getSuggestion('DASHBOARD');
  }, []);

  useEffect(() => {
    setSuggestionToShow(suggestion);
  }, [suggestion]);

  if (
    !suggestionToShow ||
    !has(suggestionComponentMap, suggestionToShow.suggestionType)
  ) {
    return null;
  }

  const SuggestionCompoonent =
    suggestionComponentMap[suggestionToShow.suggestionType];

  return (
    <SuggestionCompoonent
      suggestion={suggestionToShow}
      resetSuggestion={resetSuggestion}
      getSuggestion={getSuggestion}
    />
  );
};

export default DashboardSuggestionHandler;
