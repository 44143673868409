import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PageNotFound from '../components/PageNotFound';
import RedirectRoute from '../components/RedirectRoute';
import componentLoader from '../helpers/component-loader';
import ApplicationFlowLayout from '../layout/ApplicationFlowLayout/ApplicationFlowLayout';
import BlankPageLayout from '../layout/BlankPageLayout';
import BluePageSimple from '../layout/BluePageSimple/BluePageSimple';
import { useConfig } from '../providers/ConfigProvider';
import {
  RouteWithLayout,
  RouteWithLayoutV2,
} from '../providers/LayoutProvider';

import DocumentsUploaded from './applications/StandaloneUploadDocuments/DocumentsUploaded';
import StandaloneUploadDocuments from './applications/StandaloneUploadDocuments/StandaloneUploadDocuments';
import PrivateRoute from './auth/login/PrivateRoute';
import AuthRoutes from './auth/routes.auth';
import StandaloneLeaseAgreement from './digital-leases/standalone-wizard/StandaloneLeaseAgreement';
import LeaseAgreementAuditLoadingPage from './lease-agreement-audit-ai/LeaseAgreementAuditLoadingPage';
import LeaseAgreementAuditResults from './lease-agreement-audit-ai/LeaseAgreementAuditResults';
import LeaseAgreementAuditUpload from './lease-agreement-audit-ai/LeaseAgreementAuditUpload';
import PartnerScreeningReport from './owners/partner-screening-report';
import PublicInviteToApply from './public-invite-to-apply/PublicInviteToApply';
import PublicScreeningReport from './public-screening-report/PublicScreeningReport';
import UserRoutes from './_routes.users';

const ApplicationsRoutes = componentLoader(
  () => import('./applications/routes.applications'),
);

const ErrorPage = componentLoader(() => import('./error'));

const MaintenanceComponent = componentLoader(
  () => import('./auth/MaintenanceComponent'),
);

const RequestCanceledPage = componentLoader(() => import('./auth/ErrorPage'));

const PublicListingContainer = componentLoader(
  () => import('../pages/properties/public_listing/PublicListingContainer'),
);

const SampleListingContainer = componentLoader(
  () => import('../pages/properties/public_listing/SampleListingContainer'),
);

const OnBoardingPage = componentLoader(
  () => import('../pages/onboard/OnBoarding'),
);

const RenthopOnboardingPage = componentLoader(
  () => import('../pages/onboard/renthop/RenthopOnboarding'),
);

const LeadQuestionnaire = componentLoader(
  () => import('./renters/lead_questionnaire'),
);

const CraigsListContactLlRoute = componentLoader(
  () => import('./renters/lead_questionnaire/CraigsListContactLlRoute'),
);

const PublicEmailPreferencesRoute = componentLoader(
  () => import('./email-preferences/PublicEmailPreferencesRoute'),
);

const ReceiptComponentRoute = componentLoader(
  () => import('./owners/profile/payments/ReceiptComponent'),
);

const AdditionalSignerESignWelcome = componentLoader(
  () => import('./renters/esignature/WelcomeComponent'),
);

const AdditionalSignerESignDocument = componentLoader(
  () =>
    import(
      './electronic-signature/SignatureProcessComponent/SignatureProcessComponent'
    ),
);

const Rentometer = componentLoader(() => import('./renters/rentometer/Search'));

const TurboListThanks = componentLoader(
  () => import('./owners/turbolist/Thanks'),
);

const RentometerResult = componentLoader(
  () => import('./renters/rentometer/Result'),
);

const ListingSearchContainer = componentLoader(
  () => import('./properties/listing_search/components/ListingSearchContainer'),
);

const ListingSearchResults = componentLoader(
  () => import('./properties/listing_search_results'),
);

const StandAloneStripeUploadDocuments = componentLoader(
  () =>
    import(
      './payments/owner/stripe-verification-documents/StandAloneStripeUploadDocuments'
    ),
);

const StandAloneInternalReviewUploadDocuments = componentLoader(
  () =>
    import(
      './payments/owner/internal-review/StandAloneInternalReviewUploadDocuments'
    ),
);

const LeadIntake = componentLoader(() => import('./public-lead-intake'));

const LeadIntakeNoProperties = componentLoader(
  () => import('./public-lead-intake/LeadIntakeNoProperties'),
);

const LeadIntakeSuccessPage = componentLoader(
  () => import('./public-lead-intake/LeadIntakeSuccessPage'),
);

const EmailPreviewRoute = componentLoader(
  () => import('./email-preview/EmailPreviewRoute'),
);

const ScheduleShowing = componentLoader(
  () => import('./renters/showing/schedule_showing'),
);

const CancelShowing = componentLoader(
  () => import('./renters/showing/cancel_showing'),
);

const MainRoutes = () => {
  /* This change it is because we use both routes, /owners/ and /owners, and they should work and be redirected to
    the same page. With the new libraries, /owners doesn't work if we set our private routing with base in /owners/
    so we set it without the last slash, since that makes both routes to work.
    */
  const { PRIVATE_BASE_PATH, IS_RENTER, IS_OWNER, SAMPLE_LISTING_ID } =
    useConfig();
  const privateRouteWithOutEndingSlash = PRIVATE_BASE_PATH.substring(
    0,
    PRIVATE_BASE_PATH.length - 1,
  );

  return (
    <Switch>
      <RedirectRoute from="/login" to="/auth/login" />
      <RedirectRoute from="/signup" to="/auth/signup" />
      <RedirectRoute from="/appsumo" to="/auth/appsumo" />
      <RedirectRoute from="/logout" to="/auth/logout" />
      <RedirectRoute from="/setpassword" to="/auth/setpassword" />
      <RedirectRoute
        from="/applications/new/:listingId"
        to="/applications/:listingId/start"
      />
      <RedirectRoute
        from="/resetpassword/:type"
        to="/auth/resetpassword/:type"
      />
      <RedirectRoute
        from="/confirm_email/:token"
        to="/auth/confirm_email/:token"
      />
      <Route path="/auth" component={AuthRoutes} />
      <Route path="/error" component={ErrorPage} />
      <Route path="/applications" component={ApplicationsRoutes} />
      <Route
        path="/lead-questionnaire/success"
        component={LeadIntakeSuccessPage}
      />
      <Route path="/lead-questionnaire/:leadId" component={LeadQuestionnaire} />
      <Route
        path="/contact-landlord-craigslist/:idOrSlug"
        component={CraigsListContactLlRoute}
      />
      <Route
        path="/email-preferences"
        component={PublicEmailPreferencesRoute}
      />
      <Route path="/receipt/:id/:type" component={ReceiptComponentRoute} />
      <Route
        exact
        path="/esignature/welcome/:signatureId"
        component={AdditionalSignerESignWelcome}
      />
      <Route
        exact
        path="/esignature/sign/:signatureId/:additionalSigner"
        component={AdditionalSignerESignDocument}
      />
      <RouteWithLayout
        path="/lease-agreement/:step?"
        component={StandaloneLeaseAgreement}
        layout={BlankPageLayout}
      />
      <RouteWithLayout
        exact
        path="/lease-agreement-audit-ai"
        component={LeaseAgreementAuditUpload}
        layout={BlankPageLayout}
      />
      <RouteWithLayout
        exact
        path="/lease-agreement-audit-ai/:id"
        component={LeaseAgreementAuditResults}
        layout={BlankPageLayout}
      />
      <RouteWithLayout
        exact
        path="/lease-agreement-audit-ai-loading"
        component={LeaseAgreementAuditLoadingPage} // Create component for loading
        layout={BlankPageLayout}
      />
      <RouteWithLayout
        exact
        path="/invite-to-apply/:step?"
        component={PublicInviteToApply}
        layout={BlankPageLayout}
      />
      <RouteWithLayout
        exact
        path="/screening-report/:step?"
        component={PublicScreeningReport}
        layout={BlankPageLayout}
      />
      {IS_RENTER && (
        <Route
          exact
          path="/general-prescreener/success"
          component={LeadIntakeSuccessPage}
        />
      )}
      {IS_RENTER && (
        <Route
          exact
          path="/general-prescreener/noresults"
          component={LeadIntakeNoProperties}
        />
      )}
      {IS_RENTER && (
        <Route
          exact
          path="/general-prescreener/:ownerId"
          component={LeadIntake}
        />
      )}
      {IS_OWNER && SAMPLE_LISTING_ID && (
        <Route
          path={`/properties/${SAMPLE_LISTING_ID}`}
          component={SampleListingContainer}
        />
      )}
      {IS_OWNER && (
        <Route
          path="/properties/:idOrSlug"
          component={PublicListingContainer}
        />
      )}
      {IS_OWNER && (
        <Route path="/onboarding/:step" component={OnBoardingPage} />
      )}
      {IS_OWNER && (
        <Route
          path="/renthop/onboarding/:step"
          component={RenthopOnboardingPage}
        />
      )}
      {IS_RENTER && (
        <Route
          path="/screening-request-canceled"
          component={RequestCanceledPage}
        />
      )}
      {IS_RENTER && (
        <RouteWithLayout
          path="/application-documents-uploaded/:listingId/success"
          component={DocumentsUploaded}
          layout={BluePageSimple}
          layoutSettings={{
            dark: true,
            showLogo: false,
            showFooterContent: false,
          }}
        />
      )}
      {IS_RENTER && (
        <RouteWithLayoutV2
          path="/upload-application-documents"
          exact
          component={StandaloneUploadDocuments}
          layout={ApplicationFlowLayout}
          layoutSettings={{
            showFooterContent: false,
          }}
        />
      )}
      {IS_RENTER && (
        <Route path="/listingsearch" exact component={ListingSearchContainer} />
      )}
      {IS_OWNER && (
        <Route path="/rent-estimate-report" exact component={Rentometer} />
      )}
      {IS_OWNER && (
        <Route path="/turbolist/thankyou" exact component={TurboListThanks} />
      )}
      {IS_OWNER && (
        <Route
          exact
          path="/rent-estimate-report/:reportId"
          component={RentometerResult}
        />
      )}
      {IS_RENTER && (
        <Route
          path="/listingsearch/results"
          exact
          component={ListingSearchResults}
        />
      )}
      {IS_OWNER && (
        <Route
          path="/p/:address/:idOrSlug"
          component={PublicListingContainer}
        />
      )}
      {IS_OWNER && (
        <Route
          path="/upload-stripe-verification-documents"
          component={StandAloneStripeUploadDocuments}
        />
      )}
      {IS_OWNER && (
        <Route
          path="/upload-internalreview-documents"
          component={StandAloneInternalReviewUploadDocuments}
        />
      )}
      {IS_RENTER && (
        <RouteWithLayout
          path="/showing/cancel"
          component={CancelShowing}
          layout={BluePageSimple}
        />
      )}
      {IS_RENTER && (
        <RouteWithLayout
          path="/showing/:listingId"
          component={ScheduleShowing}
          layout={BluePageSimple}
        />
      )}
      <Route
        path="/reference-request-canceled"
        component={() => (
          <RequestCanceledPage
            title="Reference Request Canceled"
            description="The tenant no longer needs a landlord reference from you."
          />
        )}
      />
      <RouteWithLayout
        exact
        path="/maintenance"
        component={MaintenanceComponent}
        layout={BlankPageLayout}
      />
      <RouteWithLayout
        exact
        path="/partners/screening-report"
        component={PartnerScreeningReport}
        layout={BlankPageLayout}
      />
      <PrivateRoute path="/email-preview" component={EmailPreviewRoute} />
      <Redirect exact from="/" to={`${PRIVATE_BASE_PATH}dashboard`} />
      <PrivateRoute
        path={privateRouteWithOutEndingSlash}
        component={UserRoutes}
      />
      <Route component={() => <PageNotFound actionTitle="Back Home" />} />
    </Switch>
  );
};

MainRoutes.propTypes = {};

export default MainRoutes;
