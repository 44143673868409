import React from 'react';
import { useForm, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import {
  AgeField,
  NumericField,
  RadioField,
  TextField,
} from '../../../../components/FinalFormFields';
import { Column, Row } from '../../../../components/Grid';
import RemoveButton from '../../../../components/RemoveButton';

import { getPetAccountName } from './getPetAccountName';

import styles from './PetsEdit.module.scss';

const defaultPet = {
  kind: '',
  breed: '',
  weight: '',
  age: '',
  sex: null,
};

const PetsEdit = () => {
  const form = useForm();
  const { values } = useFormState({ values: true });

  return (
    <div>
      <p className={styles.sectionTitle}>Pets</p>
      <article>
        <div className={styles.radioButtonContainer}>
          <RadioField
            inline={false}
            label="Do they have any pets?"
            name="pet"
            className={styles.radioGroup}
            options={[
              { value: true, label: 'Yes' },
              { value: false, label: 'No' },
            ]}
          />
        </div>
        <div>
          {values.pet && values.pets?.length ? (
            <FieldArray name="pets">
              {({ fields }) =>
                fields?.map((name, index) => (
                  <div key={`pet-key-${index}`}>
                    <div className={styles.subTitle}>
                      {getPetAccountName(index)}
                    </div>
                    <div className={styles.petContainer}>
                      <Row className={styles.petForm}>
                        <Column lg={2} md={4} sm={4}>
                          <TextField
                            className={styles.field}
                            name={`${name}.kind`}
                            label="Type"
                          />
                        </Column>
                        <Column lg={2} md={4} sm={4}>
                          <TextField
                            className={styles.field}
                            name={`${name}.breed`}
                            label="Breed"
                          />
                        </Column>
                        <Column lg={2} md={4} sm={4}>
                          <NumericField
                            className={styles.field}
                            name={`${name}.weight`}
                            label="Weight (lbs)"
                          />
                        </Column>
                        <Column lg={2} md={4} sm={4}>
                          <AgeField
                            className={styles.field}
                            name={`${name}.age`}
                            label="Age"
                          />
                        </Column>
                        <Column lg={4} md={8} sm={8}>
                          <div className={cx(styles.item, styles.sex)}>
                            <RadioField
                              inline={false}
                              label="Sex"
                              name={`${name}.sex`}
                              className={cx(styles.radioGroup, styles.field)}
                              options={[
                                {
                                  value: 'male',
                                  label: 'Male',
                                },
                                {
                                  value: 'female',
                                  label: 'Female',
                                },
                              ]}
                            />
                          </div>
                        </Column>
                      </Row>
                      <RemoveButton
                        className={styles.remove}
                        onClick={() => form?.mutators?.remove('pets', index)}
                      />
                    </div>
                  </div>
                ))
              }
            </FieldArray>
          ) : null}

          {values?.pet && (
            <Button
              large
              secondary
              className={styles.addButton}
              onClick={() => form?.mutators?.push('pets', defaultPet)}
            >
              + add pet
            </Button>
          )}
        </div>
      </article>
    </div>
  );
};

PetsEdit.propTypes = {
  onSubmit: PropTypes.func,
};

export default PetsEdit;
