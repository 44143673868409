import React, { useEffect, useRef, useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { OnChange } from 'react-final-form-listeners';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import cx from 'classnames';
import arrayMutators from 'final-form-arrays';
import createFinalFormErrorDecorator from 'final-form-focus';
import get from 'lodash.get';
import moment from 'moment';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import Checkbox from '../../../../components/Checkbox';
import DateField from '../../../../components/DateField';
import FieldError from '../../../../components/FieldError';
import FlatButton from '../../../../components/FlatButton';
import HelpfulInfoCard from '../../../../components/HelpfulInfo';
import Input from '../../../../components/Input';
import trimEmail from '../../../../components/Input/trimEmail';
import InputWithIcon from '../../../../components/InputWithIcon';
import Label from '../../../../components/Label';
import LoadingScreen from '../../../../components/LoadingScreen';
import PhoneField from '../../../../components/PhoneField';
import PremiumSubscriptionModal from '../../../../components/PremiumSubscription';
import { MODAL_LOCATIONS } from '../../../../components/PremiumSubscription/constants';
import ProfilePicture from '../../../../components/ProfilePicture';
import RadioGroup from '../../../../components/RadioGroup';
import { useErrorToast, useInfoToast } from '../../../../components/Toast';
import WizardSaveTypesEnum from '../../../../constants/enums/WizardSaveTypesEnum';
import DIGITAL_LEASE_STATUS from '../../../../constants/lease_agreement/status';
import WIZARD_DOCUMENT_TYPE from '../../../../constants/wizard_document/type';
import basicUserProfile from '../../../../graphql/basicUserProfile.graphql';
import leaseAgreementQuery from '../../../../graphql/queries/leaseAgreement.graphql';
import leaseRequestQuery from '../../../../graphql/queries/leaseRequest.graphql';
import ownerSettingsQuery from '../../../../graphql/queries/ownerSettingsQuery.graphql';
import convertCentsToDollars from '../../../../helpers/convert-cents-to-dollars';
import convertServerDate from '../../../../helpers/convert-server-date';
import formatAddress from '../../../../helpers/format-address';
import handleFormKeyPress from '../../../../helpers/handleFormKeyPress';
import { useRenderOutside } from '../../../../helpers/render-outside';
import toCurrency from '../../../../helpers/toCurrency';
import CalendarIcon from '../../../../icons/Calendar';
import DocumentIcon from '../../../../icons/Document';
import DollarIcon from '../../../../icons/Dollar';
import EditIcon from '../../../../icons/Edit';
import LeaseIcon from '../../../../icons/LeasesThick';
import MinusCircle from '../../../../icons/MinusCircle';
import MoneyBagIcon from '../../../../icons/MoneyBagThick';
import PlusCircle from '../../../../icons/PlusCircle';
import RecurringIcon from '../../../../icons/Recurring';
import ResourcesIcon from '../../../../icons/resources';
import TurboTenantLogo from '../../../../icons/TurboTenantSmallLogo';
import { useConfig } from '../../../../providers/ConfigProvider';
import { segmentTracking } from '../../../../services/utilities';
import composeValidators from '../../../../validators/composeValidators';
import fieldRequired from '../../../../validators/fieldRequired';
import fieldRequiredShort from '../../../../validators/fieldRequiredShort';
import getFieldError from '../../../../validators/get-field-error';
import isDate from '../../../../validators/isDate';
import isLengthBetweenWithLimits from '../../../../validators/isLengthBetweenWithLimits';
import isNumber from '../../../../validators/isNumber';
import validateForHelloSign from '../../../../validators/validateForHelloSign';
import validEmail from '../../../../validators/validEmail';
import validName from '../../../../validators/validName';
import validPhone from '../../../../validators/validPhone';
import { emailInUseValidator } from '../../../digital-leases/sections/SectionPeopleOnTheLease/emailInUseValidator';
import {
  useCreateWizardDocument,
  useCreateWizardLeaseDocument,
  useUpdateWizardDocument,
} from '../../useDocumentWizards';
import SectionTitle from '../SectionTitle';
// import UnsavedChangesModal from '../UnsavedChangesModal';
import WizardHeader from '../WizardHeader';
import WizardIntroModal from '../WizardIntroModal';

import EditLandlordForm from './EditLandlordForm';
import PreviewModal from './PreviewModal';

import styles from './RentalAddendumWizard.module.scss';

const finalFormErrorDecorator = createFinalFormErrorDecorator();

const RentalAddendumWizard = ({
  location,
  match: {
    params: { addendumId },
  },
  history,
  lease,
  user,
}) => {
  const renderOutside = useRenderOutside();
  const showInfoToast = useInfoToast();
  const showErrorToast = useErrorToast();
  const { PRIVATE_BASE_PATH } = useConfig();

  const [pristine, setPristine] = useState(true);
  const [previewOpen, setPreviewOpen] = useState(null);
  const [fieldRefToFocus, setFieldRefToFocus] = useState(null);

  const [addNewLandlord, setAddNewLandlord] = useState(false);

  const [landlordEdit, setLandlordEdit] = useState({});

  const newRentField = useRef(null);
  const otherAmendmentsField = useRef(null);
  const editLandlordFormRef = useRef(null);

  const { redirectTo } = queryString.parse(location.search);
  const { data } = useQuery(leaseAgreementQuery, {
    variables: { id: lease?.lease_agreement?.id },
  });

  const currentLeaseAgreement = get(data, 'node', {});
  const hasLeaseAgreement = Object.keys(currentLeaseAgreement).length > 0;

  const [createWizardDocument, { loading: createWizardDocumentLoading }] =
    useCreateWizardDocument({
      refetchQueries: [
        {
          query: leaseRequestQuery,
          variables: {
            id: lease?.id,
          },
        },
      ],
    });

  const [updateWizardDocument, { loading: updateWizardDocumentLoading }] =
    useUpdateWizardDocument({
      refetchQueries: [
        {
          query: leaseRequestQuery,
          variables: {
            id: lease?.id,
          },
        },
      ],
    });

  const [
    createWizardLeaseDocument,
    { loading: createWizardLeaseDocumentLoading },
  ] = useCreateWizardLeaseDocument();

  const isSubscribedPremium = get(
    user,
    'premium_subscription_subscribed',
    false,
  );

  const wizardDocument = (lease?.wizard_documents || []).find(
    (wd) =>
      wd.id === addendumId && wd.type === WIZARD_DOCUMENT_TYPE.LEASE_ADDENDUM,
  );

  const isNew = !wizardDocument;
  const isPaid = wizardDocument?.paid || isSubscribedPremium;

  /**
   * NOTE: Redirect to rental addendum create route if `addendumId`
   * isn't tied to a wizardDocument in the lease
   */

  const doCreateWizardLeaseDocument = async (newWizDocId) => {
    try {
      if (!wizardDocument.completed_at) {
        await createWizardLeaseDocument({
          variables: {
            wizardDocumentId: newWizDocId,
          },
          update: (store, { data }) => {
            const newLeaseDoc = get(
              data,
              'createWizardLeaseDocument.leaseDocument',
              {},
            );
            const cachedData = store.readQuery({
              query: leaseRequestQuery,
              variables: {
                id: lease.id,
              },
            });

            const mutableCachedData = JSON.parse(JSON.stringify(cachedData));

            const lease_documents = get(cachedData, 'node.lease_documents', []);
            mutableCachedData.node.lease_documents = [
              ...lease_documents,
              newLeaseDoc,
            ];
            store.writeQuery({
              query: leaseRequestQuery,
              data: mutableCachedData,
              variables: {
                id: lease.id,
              },
            });
          },
        });
      }
      history.push(
        `${PRIVATE_BASE_PATH}e-sign-document-ready/${lease.id}/${newWizDocId}`,
      );
    } catch (e) {
      const message = get(e, 'graphQLErrors[0].message', 'An error occurred');
      showErrorToast(message);
    }
  };

  useEffect(() => {
    if (!wizardDocument) {
      history.replace({
        pathname: `${PRIVATE_BASE_PATH}rental-addendum/${lease.id}/create`,
        search: redirectTo ? `redirectTo=${redirectTo}` : '',
      });
    }
  }, [wizardDocument]);

  useEffect(() => {
    if (!wizardDocument) {
      renderOutside((done) => (
        <WizardIntroModal
          onClose={() => {
            done();
          }}
        />
      ));
    }
  }, []);

  useEffect(() => {
    if (fieldRefToFocus && fieldRefToFocus.current) {
      fieldRefToFocus.current.focus();
    }
  }, [fieldRefToFocus]);

  const existingEndDate =
    currentLeaseAgreement?.lease_end_date &&
    moment(currentLeaseAgreement.lease_end_date).format('MM/DD/YYYY');
  const existingBaseRent =
    currentLeaseAgreement?.base_rent_amount &&
    toCurrency(convertCentsToDollars(currentLeaseAgreement.base_rent_amount));

  const existingSecurityDeposit =
    currentLeaseAgreement?.security_deposit_amount !== null &&
    currentLeaseAgreement?.security_deposit_amount !== undefined &&
    toCurrency(
      convertCentsToDollars(currentLeaseAgreement.security_deposit_amount),
    );
  const existingPetDeposit =
    currentLeaseAgreement?.pet_deposit_amount !== null &&
    currentLeaseAgreement?.pet_deposit_amount !== undefined &&
    toCurrency(convertCentsToDollars(currentLeaseAgreement.pet_deposit_amount));
  const existingOtherDeposit =
    currentLeaseAgreement?.other_deposit_amount !== null &&
    currentLeaseAgreement?.other_deposit_amount !== undefined &&
    toCurrency(
      convertCentsToDollars(currentLeaseAgreement.other_deposit_amount),
    );
  const hasExistingDeposits =
    existingSecurityDeposit || existingPetDeposit || existingOtherDeposit;

  let property_address,
    property_unit,
    property_city,
    property_state,
    property_zip;

  if (currentLeaseAgreement?.property_address) {
    ({
      property_address,
      property_unit,
      property_city,
      property_state,
      property_zip,
    } = currentLeaseAgreement);
  } else {
    ({
      address: property_address,
      unit: property_unit,
      city: property_city,
      state: property_state,
      zip: property_zip,
    } = lease.listing);
  }

  const propertyAddress = `${property_address}${
    property_unit ? `, ${property_unit}` : ''
  }, ${property_city}, ${property_state} ${property_zip}
	`;

  const tenants = currentLeaseAgreement?.tenants?.length
    ? currentLeaseAgreement?.tenants
    : lease?.renters;

  const landlords = currentLeaseAgreement?.landlords ||
    wizardDocument?.form?.landlords || [
      {
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        company_name: user.company,
      },
    ];

  const checkIsFormFilledIn = ({
    original_agreement_date,
    addendum_takes_effect,
    change_agreement_end_date,
    change_agreement_rent_amount,
    change_agreement_deposits,
    change_other_amendments,
  }) => {
    const requiredFields = [
      original_agreement_date,
      addendum_takes_effect,
      change_agreement_end_date,
      change_agreement_rent_amount,
      change_agreement_deposits,
      change_other_amendments,
    ];

    /**
     * NOTE: If `requiredFields` doesn't have any
     * `null` or `undefined` elements we consider the form filled in
     */
    const filledIn =
      requiredFields.findIndex((field) => field === null) === -1 &&
      tenants.length > 0;

    return filledIn;
  };

  const renderPremiumModal = (isCompletedForm) =>
    renderOutside((done) => (
      <PremiumSubscriptionModal
        onClose={done}
        openLocation={
          isCompletedForm
            ? MODAL_LOCATIONS.ADDENDUM
            : MODAL_LOCATIONS.ADDENDUM_IN_PROGRESS
        }
        postPaymentAction={async () => {
          if (isCompletedForm) {
            await doCreateWizardLeaseDocument(wizardDocument.id);
            done();
          }
        }}
        onSuccess={done}
        refetchQueries={[basicUserProfile, ownerSettingsQuery]}
        copy="forms"
      />
    ));

  const handleSave = async (data) => {
    const { submitType, ...formData } = data;

    const payload = {
      ...formData,
      propertyAddress,
    };

    /**
     * NOTE: Escaped null character (\u0000) is the one Unicode code point
     * which is not valid in a string. If it makes it to the db it'll bring
     * issues when trying to parse it to json. We then replace null chars with an
     * empty string.
     */
    if (payload.new_other_amendments) {
      payload.new_other_amendments = payload.new_other_amendments.replace(
        /\0/g,
        '',
      );
    }

    // NOTE: Clening up the payload object so we don't retain old values
    if (!formData.change_agreement_end_date) {
      delete payload.new_end_date;
    }
    if (!formData.change_agreement_rent_amount) {
      delete payload.new_monthly_rent;
    }
    if (!formData.change_other_amendments) {
      delete payload.new_other_amendments;
    }
    if (!formData.change_agreement_deposits) {
      delete payload.security_deposit_changed;
      delete payload.new_security_deposit;
      delete payload.pet_deposit_changed;
      delete payload.new_pet_deposit;
      delete payload.other_deposit_changed;
      delete payload.new_other_deposit;
    } else {
      if (!formData.security_deposit_changed) {
        delete payload.new_security_deposit;
      }
      if (!formData.pet_deposit_changed) {
        delete payload.new_pet_deposit;
      }
      if (!formData.other_deposit_changed) {
        delete payload.new_other_deposit;
      }
    }

    let result;

    if (isNew) {
      result = await createWizardDocument({
        variables: {
          type: WIZARD_DOCUMENT_TYPE.LEASE_ADDENDUM,
          leaseId: lease?.id,
          form: payload,
          allFieldsCompleted: checkIsFormFilledIn(payload),
        },
      });
    } else {
      result = await updateWizardDocument({
        variables: {
          id: wizardDocument.id,
          form: payload,
          allFieldsCompleted: checkIsFormFilledIn(payload),
        },
      });
    }

    if (submitType === WizardSaveTypesEnum.SAVE_AND_PREVIEW) {
      if (isNew) {
        const newWizardDocumentId = get(
          result,
          'data.createWizardDocument.wizardDocument.id',
        );

        history.replace(
          `${PRIVATE_BASE_PATH}rental-addendum/${lease?.id}/${newWizardDocumentId}`,
        );
      }
      setPreviewOpen(true);
    }
    if (submitType === WizardSaveTypesEnum.SAVE) {
      history.push(`${PRIVATE_BASE_PATH}leases/view/${lease?.id}/summary`);
    }

    setPristine(true);
    showInfoToast('Changes Saved');
  };

  const getBackLink = () => {
    if (redirectTo) {
      return redirectTo;
    }
    return `${PRIVATE_BASE_PATH}leases/view/${lease?.id}`;
  };

  const renderContactItem = ({ item, type = 'tenant', key, name }) => (
    <div className={styles.contactItem} key={key}>
      <ProfilePicture
        firstName={item.company_name || item.first_name || ''}
        lastName={item.company_name ? '' : item.last_name || ''}
        className={styles.avatar}
        size="small"
      />
      <div className={styles.contactInfo}>
        <p className={styles.contactName}>
          {item.company_name ||
            `${item.first_name || ''} ${item.last_name || ''}`}
        </p>
        <p className={styles.contactAdditional}>
          {type === 'landlord' ? (
            item.email
          ) : (
            <>
              <span>TENANT AT: </span>{' '}
              {formatAddress({ ...(lease?.listing || {}), short: true })}
            </>
          )}
        </p>
      </div>
      {type === 'landlord' && (
        <FlatButton
          icon={EditIcon}
          onClick={() => {
            setLandlordEdit({ ...landlordEdit, [name]: true });
            window.scrollTo({
              top: editLandlordFormRef.current.offsetTop - 70,
              behavior: 'smooth',
            });
          }}
          className={styles.editButton}
        >
          <span className={styles.mobileHidden}>Edit</span>
        </FlatButton>
      )}
    </div>
  );

  const referenceLink = (
    <Link
      className={styles.link}
      to={`${PRIVATE_BASE_PATH}leases/view/${lease?.id}`}
      target="_blank"
    >
      Reference your original lease agreement
    </Link>
  );

  return (
    <LoadingScreen
      loading={createWizardDocumentLoading || updateWizardDocumentLoading}
    >
      <Form
        onSubmit={handleSave}
        decorators={[finalFormErrorDecorator]}
        mutators={{
          ...arrayMutators,
        }}
        subscription={{
          submitting: true,
          values: true,
        }}
        keepDirtyOnReinitialize
        initialValues={{
          original_agreement_date: convertServerDate(
            moment(lease?.lease_agreement?.updated_at).format('YYYY-MM-DD'),
          ),
          landlords,
          ...(isNew ? {} : wizardDocument.form),
          tenants, // Keep tenants in last position since we need to override the addendum with newest values
        }}
      >
        {({ handleSubmit, submitting, values, form }) => {
          const isFilledIn = checkIsFormFilledIn(values);

          return (
            <form onSubmit={handleSubmit} onKeyDown={handleFormKeyPress}>
              <FormSpy
                subscription={{ pristine: true }}
                onChange={(props) => {
                  setPristine(props.pristine);
                }}
              />
              <WizardHeader
                backLink={getBackLink()}
                onSave={() => {
                  return form.change('submitType', WizardSaveTypesEnum.SAVE);
                }}
                onPreview={() => {
                  if (!isFilledIn) {
                    return setPreviewOpen(true);
                  }
                  return form.change(
                    'submitType',
                    WizardSaveTypesEnum.SAVE_AND_PREVIEW,
                  );
                }}
                formFilledIn={isFilledIn}
                pristine={pristine}
              />
              <Card className={styles.container}>
                <h3 className={styles.title}>
                  Create Your Lease Agreement Addendum
                </h3>
                <HelpfulInfoCard
                  className={styles.helpfulInfoCard}
                  title="Here's what you need to know:"
                  noIcon
                >
                  <ol className={styles.helpfulInfoList}>
                    <li>
                      {referenceLink} as you fill out this form. This legal
                      document will amend your lease agreement.
                    </li>
                    {!isPaid && (
                      <li>
                        Upgrade to{' '}
                        <span
                          className={styles.link}
                          onClick={() => {
                            segmentTracking('learn_about_premium clicked', {
                              location: isFilledIn
                                ? 'Lease Addendum Review Page'
                                : 'Lease Addendum Wizard Summary Page',
                            });
                            renderPremiumModal(isFilledIn);
                          }}
                        >
                          Premium
                        </span>{' '}
                        for unlimited addendums, lease agreements, and much
                        more.
                      </li>
                    )}
                    <li>
                      Send the addendum to your tenants to e-sign or choose to
                      print it instead. You may edit the addendum even after
                      you've paid, until it's been signed.
                    </li>
                  </ol>
                </HelpfulInfoCard>
                <div className={styles.section}>
                  <SectionTitle title="Lease History" icon={LeaseIcon} />
                  <div className={styles.tenants}>
                    <Label className={styles.contactLabel}>Tenants</Label>
                    {tenants.map((tenant) =>
                      renderContactItem({ item: tenant, key: tenant?.id }),
                    )}
                    <Field id="tenants" name="tenants">
                      {({ input }) => {
                        return (
                          <div>
                            <div>
                              <input {...input} type="hidden" />
                            </div>
                          </div>
                        );
                      }}
                    </Field>
                    {tenants.length === 0 && (
                      <div className={styles.noTenantsContainer}>
                        <h3>You Don’t Have Any Tenants!</h3>
                        <p>
                          To finalize this addendum, you will need to have at
                          least one tenant added to your lease. You can add a
                          new tenant in this lease’s summary page.{' '}
                        </p>
                        <Button
                          secondary
                          to={`${PRIVATE_BASE_PATH}leases/view/${lease?.id}/summary`}
                        >
                          go to my lease
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className={styles.landlords}>
                    <Label className={styles.contactLabel}>{`Landlord${
                      landlords.length > 1 ? 's' : ''
                    }`}</Label>
                    <div>
                      <FieldArray name="landlords">
                        {({ fields }) => (
                          <>
                            {fields.map((landlord, index) => {
                              const l = fields.value[index];
                              return renderContactItem({
                                item: l,
                                name: landlord,
                                type: 'landlord',
                                key: landlord?.id || index,
                              });
                            })}
                          </>
                        )}
                      </FieldArray>
                    </div>
                    {addNewLandlord && (
                      <div className={styles.additionalLandlord}>
                        <div>
                          <div className={styles.fatDescription}>
                            2ND LANDLORD
                          </div>
                          <p className={styles.description}>
                            This landlord will be added as a signer on the lease
                            agreement.
                          </p>
                        </div>
                        <div className={styles.row}>
                          <Field
                            label="Legal First Name"
                            component={Input}
                            id="landlords[1].first_name"
                            name="landlords[1].first_name"
                            validate={composeValidators(
                              fieldRequiredShort,
                              validName,
                            )}
                            className={styles.inputContainer}
                            labelProps={{
                              className: styles.inputLabel,
                            }}
                            inputProps={{
                              className: styles.input,
                            }}
                          />
                          <Field
                            label="Last Name"
                            component={Input}
                            id="landlords[1].last_name"
                            name="landlords[1].last_name"
                            validate={composeValidators(
                              fieldRequiredShort,
                              validName,
                            )}
                            className={styles.inputContainer}
                            labelProps={{
                              className: styles.inputLabel,
                            }}
                            inputProps={{
                              className: styles.input,
                            }}
                          />
                        </div>
                        <Field
                          label="Email"
                          component={Input}
                          id="landlords[1].email"
                          name="landlords[1].email"
                          validate={composeValidators(
                            fieldRequiredShort,
                            validEmail,
                            emailInUseValidator(true),
                          )}
                          parse={trimEmail}
                          className={styles.inputEmailContainer}
                          labelProps={{
                            className: styles.inputLabel,
                          }}
                          inputProps={{
                            className: cx(styles.input, styles.landlordEmail),
                          }}
                        />
                        <Field
                          label="Phone"
                          component={PhoneField}
                          name="landlords[1].phone"
                          id="landlords[1].phone"
                          className={styles.phone}
                          validate={composeValidators(
                            fieldRequired,
                            validPhone,
                          )}
                        />
                      </div>
                    )}
                    {values.landlords.length < 2 &&
                      !addNewLandlord &&
                      !(
                        currentLeaseAgreement.status ===
                        DIGITAL_LEASE_STATUS.COMPLETED
                      ) && (
                        <FlatButton
                          onClick={() => {
                            setAddNewLandlord(true);
                          }}
                          icon={PlusCircle}
                          className={styles.addAnotherLandlord}
                        >
                          Add another landlord
                        </FlatButton>
                      )}
                    {!hasLeaseAgreement &&
                      (values.landlords.length > 1 || addNewLandlord) && (
                        <FlatButton
                          id="remove_landlord"
                          onClick={async () => {
                            setAddNewLandlord(false);
                            await form.change('landlords', [landlords[0]]);
                          }}
                          icon={MinusCircle}
                          className={styles.removeLandlord}
                        >
                          Remove 2nd landlord
                        </FlatButton>
                      )}
                    <div ref={editLandlordFormRef}>
                      <FieldArray name="landlords">
                        {({ fields }) => (
                          <>
                            {fields.map((landlord, index) => {
                              const l = fields.value[index];
                              return landlordEdit[landlord] ? (
                                <EditLandlordForm
                                  ref={editLandlordFormRef}
                                  landlord={l}
                                  onSave={(newValues) => {
                                    fields.update(index, newValues);
                                    setLandlordEdit({
                                      ...landlordEdit,
                                      [landlord]: false,
                                    });
                                  }}
                                  onCancelButtonClick={() => {
                                    setLandlordEdit({
                                      ...landlordEdit,
                                      [landlord]: false,
                                    });
                                  }}
                                  key={index}
                                />
                              ) : null;
                            })}
                          </>
                        )}
                      </FieldArray>
                    </div>
                    <HelpfulInfoCard
                      Icon={ResourcesIcon}
                      className={styles.landlordInfoCard}
                      noTitle
                    >
                      The landlord’s name or entity on the original lease
                      agreement must match what appears on the Addendum you are
                      creating. If you want to change that name or entity,
                      please contact a local attorney on how to legally make
                      that change.
                    </HelpfulInfoCard>
                  </div>
                  <Label
                    htmlFor="original_agreement_date"
                    className={styles.bigLabel}
                  >
                    When was the original lease agreement dated?
                  </Label>
                  <p className={styles.description}>
                    This is usually found on the top of your lease agreement and
                    probably differs from the lease term start date.
                  </p>
                  <Field
                    className={styles.inputContainer}
                    component={DateField}
                    name="original_agreement_date"
                    id="original_agreement_date"
                    validate={isDate}
                  />
                </div>
                <div className={styles.section}>
                  <SectionTitle
                    title="End Date"
                    icon={CalendarIcon}
                    iconProps={{ width: 24, height: 24 }}
                  />
                  <Field
                    component={RadioGroup}
                    vertical
                    id="change_agreement_end_date"
                    name="change_agreement_end_date"
                    label="Will there be a change to the lease agreement end date?"
                    labelProps={{ className: styles.bigLabel }}
                    className={styles.radioGroup}
                    radioRowClassName={styles.radioItem}
                    radiosContainerClassName={styles.radiosContainer}
                    options={[
                      {
                        id: 'change_agreement_end_date_yes',
                        value: true,
                        label: 'Yes',
                      },
                      {
                        id: 'change_agreement_end_date_no',
                        value: false,
                        label: 'No',
                      },
                    ]}
                  />
                  {values.change_agreement_end_date && (
                    <Field
                      className={styles.inputContainer}
                      label="New End Date"
                      component={DateField}
                      name="new_end_date"
                      id="new_end_date"
                      validate={composeValidators(fieldRequired, isDate)}
                    />
                  )}
                  {existingEndDate && (
                    <Card className={styles.currentDataCard}>
                      <span className={styles.currentDataRow}>
                        <strong>Current End Date:</strong> {existingEndDate}
                      </span>
                    </Card>
                  )}
                </div>
                <div className={styles.section}>
                  <HelpfulInfoCard
                    className={cx(styles.helpfulInfoCard, styles.tenantsInfo)}
                    noTitle
                    Icon={TurboTenantLogo}
                  >
                    Using TurboTenant to collect rent? Create a new charge to
                    reflect the changes in lease terms or rent amount to make
                    sure you get paid on time.{' '}
                    <a
                      className={styles.link}
                      href="https://support.turbotenant.com/en/articles/4004080-rent-payments-guide#h_6c1abf69f3"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Learn more.
                    </a>
                  </HelpfulInfoCard>
                  <SectionTitle title="Monthly Rent" icon={RecurringIcon} />
                  <OnChange name="change_agreement_rent_amount">
                    {(value) => {
                      setFieldRefToFocus(value ? newRentField : null);
                    }}
                  </OnChange>
                  <Field
                    component={RadioGroup}
                    vertical
                    id="change_agreement_rent_amount"
                    name="change_agreement_rent_amount"
                    label="Will there be a change to the monthly rent amount?"
                    labelProps={{ className: styles.bigLabel }}
                    className={styles.radioGroup}
                    radioRowClassName={styles.radioItem}
                    radiosContainerClassName={styles.radiosContainer}
                    options={[
                      {
                        id: 'change_agreement_rent_amount_yes',
                        value: true,
                        label: 'Yes',
                      },
                      {
                        id: 'change_agreement_rent_amount_no',
                        value: false,
                        label: 'No',
                      },
                    ]}
                  />
                  {values.change_agreement_rent_amount && (
                    <Field
                      label="New Monthly Rent"
                      component={InputWithIcon}
                      className={styles.inputContainer}
                      inputProps={{
                        ref: (ref) => (newRentField.current = ref),
                      }}
                      id="new_monthly_rent"
                      name="new_monthly_rent"
                      type="number"
                      inputMode="decimal"
                      step=".01"
                      pattern="[0-9]*"
                      validate={composeValidators(fieldRequiredShort, isNumber)}
                      icon={DollarIcon}
                    />
                  )}
                  {existingBaseRent && (
                    <Card className={styles.currentDataCard}>
                      <span className={styles.currentDataRow}>
                        <strong>Current Rent:</strong> {existingBaseRent}
                      </span>
                      <span className={styles.currentDataRow}>
                        Use our{' '}
                        <a
                          className={styles.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://rental.turbotenant.com/rent-estimate-report"
                        >
                          rent estimate
                        </a>{' '}
                        tool to see if your rental is priced comparably to the
                        surrounding market.
                      </span>
                    </Card>
                  )}
                </div>
                <div className={styles.section}>
                  <SectionTitle title="Deposits" icon={MoneyBagIcon} />
                  <Field
                    component={RadioGroup}
                    vertical
                    id="change_agreement_deposits"
                    name="change_agreement_deposits"
                    label="Will there be a change to any deposit amounts?"
                    labelProps={{ className: styles.bigLabel }}
                    className={styles.radioGroup}
                    radioRowClassName={styles.radioItem}
                    radiosContainerClassName={styles.radiosContainer}
                    options={[
                      {
                        id: 'change_agreement_deposits_yes',
                        value: true,
                        label: 'Yes',
                      },
                      {
                        id: 'change_agreement_deposits_no',
                        value: false,
                        label: 'No',
                      },
                    ]}
                  />
                  {values.change_agreement_deposits && (
                    <>
                      <Label className={styles.thinLabel}>
                        Select all that apply:
                      </Label>
                      <Field
                        component={Checkbox}
                        type="checkbox"
                        id="security_deposit_changed"
                        name="security_deposit_changed"
                        label="Security Deposit"
                        className={styles.checkbox}
                      />
                      {values.security_deposit_changed && (
                        <Field
                          component={InputWithIcon}
                          className={cx(
                            styles.inputContainer,
                            styles.depositsInput,
                          )}
                          inputProps={{
                            ref: (ref) => (newRentField.current = ref),
                          }}
                          id="new_security_deposit"
                          name="new_security_deposit"
                          type="number"
                          inputMode="decimal"
                          step=".01"
                          pattern="[0-9]*"
                          validate={composeValidators(
                            fieldRequiredShort,
                            isNumber,
                          )}
                          icon={DollarIcon}
                        />
                      )}
                      <Field
                        component={Checkbox}
                        type="checkbox"
                        id="pet_deposit_changed"
                        name="pet_deposit_changed"
                        label="Pet Deposit"
                        className={styles.checkbox}
                      />
                      {values.pet_deposit_changed && (
                        <Field
                          component={InputWithIcon}
                          className={cx(
                            styles.inputContainer,
                            styles.depositsInput,
                          )}
                          inputProps={{
                            ref: (ref) => (newRentField.current = ref),
                          }}
                          id="new_pet_deposit"
                          name="new_pet_deposit"
                          type="number"
                          inputMode="decimal"
                          step=".01"
                          pattern="[0-9]*"
                          validate={composeValidators(
                            fieldRequiredShort,
                            isNumber,
                          )}
                          icon={DollarIcon}
                        />
                      )}
                      <Field
                        component={Checkbox}
                        type="checkbox"
                        id="other_deposit_changed"
                        name="other_deposit_changed"
                        label="Other Deposit"
                        className={styles.checkbox}
                      />
                      {values.other_deposit_changed && (
                        <Field
                          component={InputWithIcon}
                          className={cx(
                            styles.inputContainer,
                            styles.depositsInput,
                          )}
                          inputProps={{
                            ref: (ref) => (newRentField.current = ref),
                          }}
                          id="new_other_deposit"
                          name="new_other_deposit"
                          type="number"
                          inputMode="decimal"
                          step=".01"
                          pattern="[0-9]*"
                          validate={composeValidators(
                            fieldRequiredShort,
                            isNumber,
                          )}
                          icon={DollarIcon}
                        />
                      )}
                      <Field
                        render={({ id, meta }) => {
                          const error = getFieldError(meta);
                          return (
                            <FieldError
                              focusOnErrorMessage
                              id={id}
                              error={error}
                            />
                          );
                        }}
                        name="deposits-error"
                        id="deposits-error"
                        className={styles.error}
                        validate={(
                          _,
                          {
                            security_deposit_changed,
                            pet_deposit_changed,
                            other_deposit_changed,
                          },
                        ) => {
                          const depositCheckboxes = [
                            security_deposit_changed,
                            pet_deposit_changed,
                            other_deposit_changed,
                          ];
                          if (
                            depositCheckboxes.every((cb) => {
                              return cb === null;
                            })
                          ) {
                            return 'At least one must be selected.';
                          }
                          return undefined;
                        }}
                      />
                    </>
                  )}
                  {hasExistingDeposits && (
                    <Card className={styles.currentDataCard}>
                      {existingSecurityDeposit && (
                        <span className={styles.currentDataRow}>
                          <strong>Current Security Deposit:</strong>{' '}
                          {existingSecurityDeposit}
                        </span>
                      )}
                      {existingPetDeposit && (
                        <span className={styles.currentDataRow}>
                          <strong>Current Pet Deposit:</strong>{' '}
                          {existingPetDeposit}
                        </span>
                      )}
                      {existingOtherDeposit && (
                        <span className={styles.currentDataRow}>
                          <strong>Current Other Deposit:</strong>{' '}
                          {existingOtherDeposit}
                        </span>
                      )}
                    </Card>
                  )}
                </div>
                <div className={styles.section}>
                  <SectionTitle title="Other Amendments" icon={DocumentIcon} />
                  <OnChange name="change_other_amendments">
                    {(value) => {
                      setFieldRefToFocus(value ? otherAmendmentsField : null);
                    }}
                  </OnChange>
                  <Label
                    htmlFor="change_other_amendments"
                    className={styles.bigLabel}
                  >
                    Will there be other amendments to the original lease
                    agreement terms?
                  </Label>
                  <p className={styles.description}>
                    E.g., changes to terms relating to parking, utilities,
                    smoking, etc.
                  </p>
                  <Field
                    component={RadioGroup}
                    vertical
                    id="change_other_amendments"
                    name="change_other_amendments"
                    labelProps={{ className: styles.bigLabel }}
                    className={styles.radioGroup}
                    radioRowClassName={cx(styles.radioItem, {
                      [styles.noMargin]: !values.change_other_amendments,
                    })}
                    radiosContainerClassName={styles.radiosContainer}
                    options={[
                      {
                        id: 'change_other_amendments_yes',
                        value: true,
                        label: 'Yes',
                      },
                      {
                        id: 'change_other_amendments_no',
                        value: false,
                        label: 'No',
                      },
                    ]}
                  />
                  {values.change_other_amendments && (
                    <>
                      <Field
                        as="textarea"
                        component={Input}
                        id="new_other_amendments"
                        name="new_other_amendments"
                        inputProps={{
                          rows: 8,
                          className: styles.otherAmendments,
                          ref: (ref) => (otherAmendmentsField.current = ref),
                        }}
                        maxLength={10000}
                        help={(val = '') =>
                          `${val.length} / 10,000 characters used`
                        }
                        validate={composeValidators(
                          fieldRequired,
                          isLengthBetweenWithLimits(20, 10000),
                          validateForHelloSign,
                        )}
                      />
                      <HelpfulInfoCard
                        className={cx(
                          styles.helpfulInfoCard,
                          styles.tenantsInfo,
                        )}
                        noTitle
                        Icon={TurboTenantLogo}
                      >
                        Language added above will supersede any conflicting
                        provisions within the original lease agreement.
                      </HelpfulInfoCard>
                    </>
                  )}
                </div>
                <div className={styles.section}>
                  <SectionTitle
                    title="Effective Date"
                    icon={CalendarIcon}
                    iconProps={{ width: 24, height: 24 }}
                  />
                  <Label
                    htmlFor="addendum_takes_effect"
                    className={styles.bigLabel}
                  >
                    When does this addendum take effect?
                  </Label>
                  <p className={styles.description}>
                    Typically lease amendments, like rent increases, will take
                    effect upon the end of the current term.
                  </p>
                  <Field
                    className={styles.inputContainer}
                    component={DateField}
                    name="addendum_takes_effect"
                    id="addendum_takes_effect"
                    validate={isDate}
                  />
                </div>
                <Button
                  className={styles.submitBtn}
                  type="submit"
                  loading={
                    submitting && values.submitType === WizardSaveTypesEnum.SAVE
                  }
                  onClick={() => {
                    return isFilledIn
                      ? form.change(
                          'submitType',
                          WizardSaveTypesEnum.SAVE_AND_PREVIEW,
                        )
                      : form.change('submitType', WizardSaveTypesEnum.SAVE);
                  }}
                  disabled={pristine && !isFilledIn}
                >
                  {isFilledIn ? 'Review and E-Sign' : 'Save & Finish Later'}
                </Button>
              </Card>
              {/* <UnsavedChangesModal when={!pristine && !submitting} /> */}
              {previewOpen && (
                <PreviewModal
                  isFilledIn={isFilledIn}
                  wizzardId={wizardDocument?.id}
                  renderPremiumModal={() => renderPremiumModal(isFilledIn)}
                  doCreateWizardLeaseDocument={async () =>
                    await doCreateWizardLeaseDocument(wizardDocument.id)
                  }
                  onClose={() => {
                    setPreviewOpen(false);
                  }}
                  createLeaseDocumentloading={createWizardLeaseDocumentLoading}
                  isPaid={isPaid}
                  completed={!!wizardDocument?.completed_at}
                />
              )}
            </form>
          );
        }}
      </Form>
    </LoadingScreen>
  );
};

RentalAddendumWizard.propTypes = {
  location: PropTypes.object,
  lease: PropTypes.object,
  user: PropTypes.object,
  onSave: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      addendumId: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
  }),
  pristine: PropTypes.bool,
};

RentalAddendumWizard.defaultProps = {
  onSave: () => ({}),
};

export default RentalAddendumWizard;
