import React, { useMemo } from 'react';
import useDimensions from 'react-cool-dimensions';
import cx from 'classnames';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';
import breakpoints from '../../constants/media-breakpoints';
import Close from '../../icons/Close';
import Button from '../Button';
import IconButton from '../IconButton';
import Loader from '../Loader';
import Modal from '../Modal';
import PDFDocumentViewer from '../PDFDocumentViewer';

import styles from './PDFDocumentViewerModal.module.scss';

const MOBILE_BREAKPOINT = breakpoints.mobileBreakpoint;

const PDFDocumentViewerModal = ({
  className,
  loading,
  loadingMessage,
  open,
  onClose,
  url,
  title,
  subtitle,
  closeLabel,
  onAction,
  actionLabel,
  actionLoading,
  contentDesc,
  dataQAAction,
  dataQAPDFViewer,
  showCloseIcon,
  ...rest
}) => {
  const { observe: modalRef, width: modalWidth } = useDimensions();
  const getPDFWidth = useMemo(() => {
    if (!modalWidth) {
      return;
    }

    if (modalWidth <= MOBILE_BREAKPOINT) {
      /**
       *  NOTE: On mobile Modal is full width of the screen
       * with 16px margin on both sides of the pdf document
       */
      return modalWidth - 32;
    }
    /**
     *  NOTE: On desktop Modal has 32px margin
     * on both sides of the pdf document
     */
    return modalWidth - 64;
  }, [modalWidth]);

  return (
    <Modal
      ref={modalRef}
      open={open}
      className={cx(styles.container, className)}
      onClose={onClose}
      mobileFullScreen
      id="previewModal"
      disableOutsideClick
      showCloseIcon={false}
    >
      <div className={styles.header}>
        <div>
          <h2 className={styles.title} data-qa="lease-preview-header">
            {title}
          </h2>
          {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
        </div>
        {closeLabel && (
          <Button
            secondary
            onClick={onClose}
            className={styles.closeBtn}
            small={!!onAction}
          >
            {closeLabel}
          </Button>
        )}
        {onAction && (
          <Button
            loading={actionLoading}
            onClick={onAction}
            className={styles.actionBtn}
            small
            data-qa={dataQAAction}
          >
            {actionLabel}
          </Button>
        )}
        {showCloseIcon && (
          <IconButton
            icon={Close}
            onClick={onClose}
            className={styles.closeIcon}
            iconProps={{ width: 14, height: 14, color: colors.darkGray }}
          />
        )}
      </div>
      {loading ? (
        <Loader className={styles.loading} loadingMessage={loadingMessage} />
      ) : (
        <div className={styles.contentContainer}>
          <PDFDocumentViewer
            className={styles.documentContainer}
            width={getPDFWidth}
            url={url}
            dataQaContainer={dataQAPDFViewer}
            customHeader={
              contentDesc ? (
                <span className={styles.descriptionContainer}>
                  <span className={styles.desc}>{contentDesc}</span>
                </span>
              ) : null
            }
            {...rest}
          />
        </div>
      )}
    </Modal>
  );
};

PDFDocumentViewerModal.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  loadingMessage: PropTypes.string,
  actionLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  actionLabel: PropTypes.string,
  className: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  closeLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  contentDesc: PropTypes.node,
  dataQAAction: PropTypes.string,
  dataQAPDFViewer: PropTypes.string,
  onDocumentLoaded: PropTypes.func,
  showCloseIcon: PropTypes.bool,
};

PDFDocumentViewerModal.defaultProps = {
  open: false,
  loading: false,
  loadingMessage: 'Generating PDF...',
  actionLoading: false,
  onClose: () => ({}),
  onDocumentLoaded: () => ({}),
  closeLabel: 'Close',
};

export default PDFDocumentViewerModal;
