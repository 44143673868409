import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';
import pdfWorker from 'pdfjs-dist/build/pdf.worker';
import PropTypes from 'prop-types';

import Loader from '../Loader';

import styles from './PDFDocumentViewer.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const PDFDocumentViewer = ({
  className,
  url,
  width,
  height,
  dataQaContainer,
  renderMode = 'canvas',
  customHeader,
  onDocumentLoaded = () => {},
  pageClassName,
  documentClassName,
}) => {
  const [loading, setLoading] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const pageRefs = useRef(null);
  const location = useLocation();

  const checkForSection = () => {
    const route = location.pathname.split('/').pop();

    // NOTE: this is a hack to get around the fact that the PDF is not a text file and we can't search for text in it easily
    // Here we return a regex that will match the text we want to find
    // We added numbered routes for public lease agreement flow - we have different sections order in PLA summary
    switch (route) {
      case 'lease-specifics':
        return /\d\.[0-9]+\sRENTAL\sPROPERTY/i;
      case 'rent-deposit-fee':
        return /\d\.[0-9]+\sRENT/i;
      case 'people':
        return /\d\.[0-9]+\sTENANT\(S\)/i;
      case 'pets-smoking-insurance':
        return /\d\.[0-9]+\sPETS/i;
      case 'utilities':
        return /\d\.[0-9]+\sUTILITIES\sAND\sOTHER\sSERVICES/i;
      case 'provisions':
        return /\d\.[0-9]+\sADDITIONAL\sTERMS/i;
      case '1':
        return /\d\.[0-9]+\sRENT/i;
      case '2':
        return /\d\.[0-9]+\sRENTAL\sPROPERTY/i;
      case '3':
        return /\d\.[0-9]+\sUTILITIES\sAND\sOTHER\sSERVICES/i;
      case '4':
        return /\d\.[0-9]+\sPETS/i;
      case '5':
        return /\d\.[0-9]+\sTENANT\(S\)/i;
      case '6':
        return /\d\.[0-9]+\sADDITIONAL\sTERMS/i;
      default:
        break;
    }
  };

  /**
   * NOTE: When state changes from `loading === true` to `loading === false`
   * we want to fire the `onDocumentLoaded` prop
   */

  useEffect(() => {
    const interval = setInterval(() => {
      if (pageRefs.current) {
        pageRefs.current?.scrollIntoView({ behavior: 'smooth' });
        clearInterval(interval);
      }
    }, 500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const delayedOnDocumentLoaded = async () => {
      await delay(1);
      onDocumentLoaded();
    };
    if (!loading) {
      delayedOnDocumentLoaded();
    }
  }, [loading]);

  const onDocumentLoadSuccess = async ({ numPages }) => {
    /**
     * NOTE: this is a hack to let the modal open
     * the second time around before trying to rerender
     * due to us setting new state. There is some issue with
     * the rendering order when there is no delay, and the modal
     * is opened (visible) only after the last rerender, which
     * causes it to open abruptly.
     */
    await delay(1);
    setNumPages(numPages);
    setLoading(false);
  };

  return (
    <div className={cx(className, styles.container)} data-qa={dataQaContainer}>
      {loading ? <Loader loadingMessage="Loading PDF..." /> : null}
      {customHeader && !loading ? customHeader : null}
      <Document
        renderMode={renderMode}
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        className={cx(
          {
            [styles.hidden]: loading,
          },
          documentClassName,
        )}
      >
        {Array.from(new Array(numPages), (_el, index) => {
          return (
            <div
              className={cx(pageClassName, styles.page)}
              id={`page_${index + 1}`}
              key={`page_${index + 1}`}
            >
              <Page
                width={width}
                height={height}
                customTextRenderer={(textItem) => {
                  const string = textItem.str;
                  const findText = checkForSection();
                  if (!findText) return;
                  if (findText.test(string)) {
                    return (
                      <span
                        className={styles.anchor}
                        key={`text_${index}`}
                        ref={(ref) => {
                          pageRefs.current = ref;
                        }}
                      >
                        {string}
                      </span>
                    );
                  }
                }}
                pageNumber={index + 1}
              />
            </div>
          );
        })}
      </Document>
    </div>
  );
};

PDFDocumentViewer.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
  dataQaContainer: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  renderMode: PropTypes.oneOf(['canvas', 'svg']),
  customHeader: PropTypes.node,
  onDocumentLoaded: PropTypes.func,
  pageClassName: PropTypes.string,
  documentClassName: PropTypes.string,
};

export default PDFDocumentViewer;
