import React, { useEffect, useState } from 'react';
import useWindowSize from '@rooks/use-window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { smallScreenWidth } from '../../constants/media-breakpoints';
import getPropertyName from '../../helpers/getPropertyName';
import loadChildren from '../../helpers/loadChildren';
import ArrowDown from '../../icons/ArrowDown';
import CheckboxFilled from '../../icons/CheckboxSquareFilled';
import KeyIcon from '../../icons/Key';
import SearchIcon from '../../icons/Search';
import Button from '../Button';
import Checkbox from '../Checkbox';
import Divider from '../Divider';
import FlatButton from '../FlatButton';
import InputWithIcon from '../InputWithIcon';

import styles from './PropertiesFilter.module.scss';

const PropertiesFilter = ({
  className,
  icon = KeyIcon,
  iconProps,
  filterName,
  properties = [],
  scrollable,
  hideMultiline,
  onApplyFilters,
  loading,
  selectedIds,
  radio,
  hideSearch,
}) => {
  const { innerWidth } = useWindowSize();
  const [showMenu, setShowMenu] = useState(false);
  let scrollEffect = false;
  const [referenceElement, setReferenceElement] = useState(null);
  const [search, setSearch] = useState('');

  const disabled = properties?.length === 0;

  // On desktop we show scroll to users when they have more than 8 items
  // On mobile we show scroll to users when they have more than 5 items
  if (innerWidth > smallScreenWidth) {
    if (properties?.length > 8) {
      scrollEffect = true;
    }
  } else {
    if (properties?.length > 5) {
      scrollEffect = true;
    }
  }

  const [selected, setSelected] = useState(
    Array.isArray(selectedIds) ? selectedIds : [selectedIds] || [],
  );
  const [expanded, setExpanded] = useState([]);
  const mupIds = properties
    ?.filter((property) => {
      return (
        (property.is_multiunit && !property.root_listing) ||
        (!property.root_listing &&
          property.property_role === 'SINGLE_UNIT' &&
          property.children_listings?.length > 0)
      );
    })
    ?.map((property) => {
      return property.id;
    });

  const filteredSelection = Array.isArray(selected)
    ? selected?.filter((option) => {
        return !mupIds.includes(option);
      })?.length
    : 0;

  useEffect(() => {
    if (selectedIds) {
      setSelected(selectedIds);
    }
  }, [selectedIds]);

  const onExpand = (selection) => {
    setExpanded(selection);
  };

  const check = (id) => {
    if (radio) {
      setSelected([id]);
    } else {
      setSelected((prevState) => [...prevState, id]);
    }
  };

  const uncheck = (id) => {
    setSelected((prevState) => [...prevState.filter((op) => op !== id)]);
  };

  const change = (property, children, isRoot) => {
    if (selected?.includes(property.id)) {
      if (!radio) {
        children?.map((child) => uncheck(child.id));
      }
      uncheck(property.id);
    } else {
      if (!radio) {
        children?.map((child) => check(child.id));
      }
      check(property.id);
      isRoot && onExpand([...expanded, property.id]);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        referenceElement &&
        !referenceElement.contains(event.target) &&
        !referenceElement.nextSibling?.contains(event.target)
      ) {
        setTimeout(() => setShowMenu(false), 0);
      }
    };
    document.addEventListener('click', handleOutsideClick, true);
    return () => {
      document.removeEventListener('click', handleOutsideClick, true);
    };
  }, [showMenu]);

  const CheckboxItem = ({ option, isRoot }) => {
    const isChecked = !!selected?.includes(option.id);
    const children =
      option.children_listings && loadChildren(option.children_listings);

    const childrenChecked = children?.filter((child) =>
      selected.includes(child.id),
    );

    if (childrenChecked?.length > 0 && !isChecked && !radio) {
      check(option.id);
    }

    const allChecked =
      children?.length > 0 && childrenChecked?.length === children?.length;

    return (
      <div className={styles.checkboxComponentContainer}>
        <Checkbox
          id={`${option.id}`}
          key={option.id}
          value={option.id}
          label={getPropertyName(option)}
          labelClassName={cx({
            [styles.checkboxLabel]: hideMultiline,
          })}
          checked={isChecked || allChecked}
          input={{
            onChange: () => {
              change(option, children, isRoot);
            },
          }}
          checkedIcon={allChecked && CheckboxFilled}
          className={styles.checkbox}
        />
        {isRoot && (
          <div className={styles.dropdownButtonContainer}>
            {childrenChecked?.length > 0 && !expanded?.includes(option.id) && (
              <span className={styles.selectedInfo}>
                {childrenChecked?.length}
              </span>
            )}
            <FlatButton
              icon={ArrowDown}
              iconProps={{
                className: styles.arrowIcon,
                width: 13,
                height: 13,
              }}
              className={cx(
                styles.dropdownButton,
                expanded?.includes(option.id)
                  ? styles.expanded
                  : styles.collapsed,
              )}
              onClick={() => {
                if (expanded?.includes(option.id)) {
                  onExpand(expanded.filter((op) => op !== option.id));
                } else {
                  onExpand([...expanded, option.id]);
                }
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const hideMenu = () => {
    setShowMenu(false);
  };

  const displayMenu = () => {
    setShowMenu(true);
  };

  const clearFilters = () => {
    onExpand([]);
    setSelected([]);
    onApplyFilters([]);
    hideMenu();
  };

  const applyFilters = () => {
    onApplyFilters(selected);
    hideMenu();
  };

  return (
    <div className={cx(styles.wrapper, className)} ref={setReferenceElement}>
      <Button
        loading={loading}
        className={cx(styles.toggleDropdownButton, styles.button, {
          [styles.selected]: selected?.length > 0,
          [styles.disabled]: disabled,
        })}
        icon={icon}
        iconProps={{
          className: styles.icon,
          width: 15,
          height: 15,
          ...iconProps,
        }}
        onClick={showMenu ? hideMenu : displayMenu}
      >
        {filterName}
        {selected?.length > 0 && (
          <span className={styles.selectedInfo}>{filteredSelection}</span>
        )}
      </Button>
      {!disabled && showMenu && (
        <div className={cx(styles.container)}>
          <div
            className={cx(styles.checkboxContainer, {
              [styles.scrollable]: scrollable && scrollEffect,
            })}
          >
            {!hideSearch && (
              <>
                <InputWithIcon
                  id="search"
                  placeholder="Search..."
                  className={cx(styles.search, {
                    [styles.hideSearch]: hideSearch,
                  })}
                  icon={SearchIcon}
                  value={search}
                  onChange={(e) => {
                    const val = e.target.value.toLowerCase();
                    setSearch(val);
                  }}
                />
                <Divider className={styles.divider} text="" />
              </>
            )}
            {properties
              .filter(
                (option) =>
                  (option?.nickname ? option.nickname : option.address)
                    ?.toLowerCase()
                    ?.indexOf(search) > -1,
              )
              .map((property) => (
                <div
                  key={property.id}
                  className={cx(styles.propertyContainer, {
                    [styles.expanded]: expanded?.includes(property.id),
                  })}
                >
                  <CheckboxItem
                    option={property}
                    isRoot={property?.children_listings?.length > 0}
                  />

                  {property?.children_listings?.map((unit) => (
                    <div className={styles.unitContainer} key={unit.id}>
                      <CheckboxItem option={unit} />

                      {unit?.children_listings?.map((room) => (
                        <div className={styles.roomContainer} key={room.id}>
                          <CheckboxItem option={room} />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
          </div>

          <Divider className={styles.divider} text={''} />

          <div className={styles.filterButtons}>
            <Button onClick={applyFilters} className={styles.btnApply}>
              APPLY FILTER
            </Button>

            <FlatButton onClick={clearFilters} className={styles.btnClear}>
              CLEAR FILTERS
            </FlatButton>
          </div>
        </div>
      )}
    </div>
  );
};

PropertiesFilter.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]),
  iconProps: PropTypes.object,
  filterName: PropTypes.string.isRequired,
  properties: PropTypes.array.isRequired,
  scrollable: PropTypes.bool,
  hideMultiline: PropTypes.bool,
  onApplyFilters: PropTypes.func,
  loading: PropTypes.bool,
  selectedIds: PropTypes.array,
  radio: PropTypes.bool,
  hideSearch: PropTypes.bool,
};

PropertiesFilter.defaultProps = {
  scrollable: true,
  hideMultiline: true,
  selectedIds: [],
  radio: false,
  hideSearch: false,
};

export default PropertiesFilter;
