import React, { useCallback } from 'react';

import PremiumSubscriptionModal from '../components/PremiumSubscription';
import basicUserProfile from '../graphql/basicUserProfile.graphql';
import ownerSettingsQuery from '../graphql/queries/ownerSettingsQuery.graphql';
import { useRenderOutside } from '../helpers/render-outside';

const usePremiumSubscriptionModal = ({
  openLocation,
  benefitsType,
  copy,
  refetchQueries = [],
  afterOnClose = () => {},
  afterSinglePaymentAction = () => {},
  afterOnSuccess = () => {},
  afterAll = () => {},
  onSuccessModalDoneClicked = () => {},
  segmentLocation,
  segmentProperties,
  ...otherProps
}) => {
  const renderOutside = useRenderOutside();

  return useCallback(
    ({ afterOnSuccessProps = {} }) => {
      renderOutside((done) => (
        <PremiumSubscriptionModal
          segmentProperties={{
            location: segmentLocation,
            properties: segmentProperties,
          }}
          openLocation={openLocation}
          benefitsType={benefitsType}
          copy={copy}
          refetchQueries={[
            basicUserProfile,
            ownerSettingsQuery,
            ...refetchQueries,
          ]}
          onClose={() => {
            done();
            afterOnClose();
            afterAll();
          }}
          singlePaymentAction={() => {
            done();
            afterSinglePaymentAction();
            afterAll();
          }}
          onSuccess={() => {
            done();
            afterOnSuccess(afterOnSuccessProps);
            afterAll();
          }}
          onSuccessModalDoneClicked={onSuccessModalDoneClicked}
          {...otherProps}
        />
      ));
    },
    [renderOutside],
  );
};

export default usePremiumSubscriptionModal;
