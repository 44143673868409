import { useEffect, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { useSuccessToast } from '../components/Toast';
import getPromoCodes from '../helpers/getPromoCodes';
import promoCodeQuery from '../pages/common/stripe/promoCode.graphql';

export function usePromoCodes({ showSuccessToast = true } = {}) {
  const storedPromoCodes = getPromoCodes();
  const [validatedPromoCodes, setValidatedPromoCodes] = useState({});
  const hasStartedValidationRef = useRef(false);
  const successToast = useSuccessToast();

  const [validatePromoCode] = useLazyQuery(promoCodeQuery, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (storedPromoCodes && !hasStartedValidationRef.current) {
      hasStartedValidationRef.current = true;
      Object.entries(storedPromoCodes)
        .filter(([key]) => key.startsWith('promo_'))
        .forEach(([key, code]) => {
          const promoType = key.replace('promo_', '');
          validatePromoCode({
            variables: { code },
            onCompleted: (result) => {
              const promo = result?.viewer?.promoCode;
              if (promo) {
                setValidatedPromoCodes((prev) => ({
                  ...prev,
                  [promoType]: promo,
                }));
                if (showSuccessToast) {
                  successToast('Promo code applied!');
                }
              }
            },
          });
        });
    }
  }, [storedPromoCodes, validatePromoCode, showSuccessToast, successToast]);

  return validatedPromoCodes;
}
