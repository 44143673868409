import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Esign = ({
  color = 'currentColor',
  width = 24,
  height = 25,
  ...rest
}) => (
  <svg {...rest} fill="none" width={width} height={height} viewBox="0 0 24 25">
    <g fill={color} fillRule="evenodd" clipRule="evenodd">
      <path d="M15.168 4.073a.6.6 0 0 1 .598.001l6.009 3.47a.6.6 0 0 1 .048 1.007l-2.368 1.69a.6.6 0 0 1-.648.03L15.149 8.16a.6.6 0 0 1-.3-.523l.017-3.047a.6.6 0 0 1 .302-.517Zm.893 1.556-.01 1.666 3.021 1.744 1.295-.923-4.306-2.487Z" />
      <path d="M15.142 7.125a.6.6 0 0 1 .607-.004l3.657 2.112a.6.6 0 0 1 .3.527l-.06 4.932a.6.6 0 0 1-.248.478l-9.027 6.56a.6.6 0 0 1-.95-.55l1.19-11.085a.6.6 0 0 1 .29-.452l4.241-2.518Zm.311 1.21-3.68 2.187-1.014 9.442 7.69-5.588.053-4.28-3.049-1.76Z" />
      <path d="M14.931 12.762a.6.6 0 0 1 .218.82l-4.329 7.472a.6.6 0 0 1-1.038-.601l4.33-7.472a.6.6 0 0 1 .819-.219Z" />
      <path d="M15.654 11.508c-.246-.142-.661-.069-.887.323-.227.393-.083.788.163.93.246.142.66.07.887-.323.227-.392.083-.788-.163-.93Zm-1.927-.277c.505-.873 1.618-1.287 2.527-.762.908.524 1.107 1.695.602 2.57-.504.873-1.618 1.286-2.526.762-.908-.525-1.107-1.696-.603-2.57ZM1.92 21.223a.6.6 0 0 1 .6-.6h7.31a.6.6 0 1 1 0 1.2H2.52a.6.6 0 0 1-.6-.6Z" />
    </g>
  </svg>
);

Esign.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Esign);
