import React from 'react';
import { has } from 'lodash';
import PropTypes from 'prop-types';

import CheckmarkFilled from '../../icons/streamline/CheckmarkFilled';
import Error from '../../icons/streamline/Error';
import Info from '../../icons/streamline/Info';
import Warning from '../../icons/streamline/Warning';

const mapper = {
  info: {
    icon: Info,
    width: 24,
    height: 24,
    color: '#2370A3',
  },
  warning: {
    icon: Warning,
    width: 24,
    height: 24,
    color: '#FF9F2A',
  },
  danger: {
    icon: Error,
    width: 24,
    height: 24,
    color: '#BF2C2C',
  },
  success: {
    icon: CheckmarkFilled,
    width: 24,
    height: 24,
    color: '#04BF8D',
  },
};

const IconForType = ({ type }) => {
  if (!type || !has(mapper, type)) return null;

  const { icon: Icon, width, height, color } = mapper[type];

  return <Icon width={width} height={height} color={color} />;
};

IconForType.propTypes = {
  type: PropTypes.oneOf(['info', 'warning', 'danger', 'success']),
};

export default IconForType;
