import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import FlatButton from '../../../../components/FlatButton';
import Logo from '../../../../components/Logo';
import BackArrow from '../../../../icons/BackArrow';
import NewWindowIcon from '../../../../icons/NewWindow';
import { segmentTracking } from '../../../../services/utilities';
import PreviewCardLA from '../../common/PreviewCardLA';

import styles from './StandaloneLAWizardLayout.module.scss';

const StandaloneLAWizardLayout = ({
  className,
  contentClassName,
  headerClassName,
  onLogoClick,
  onSignUp,
  onPreview,
  title,
  description,
  onBack,
  showLoginLink = false,
  children,
  currentSection,
}) => (
  <div className={cx(styles.container, className)}>
    <div className={cx(cx(styles.header, headerClassName))}>
      <Logo onClick={onLogoClick} className={styles.logo} />
      <div className={styles.headerButtons}>
        {onSignUp && (
          <Button
            onClick={onSignUp}
            className={cx(styles.btn, styles.save, styles.mobileHidden)}
            secondary
          >
            Save & Finish Later
          </Button>
        )}
        {onPreview && (
          <>
            <Button
              secondary
              onClick={onPreview}
              className={cx(styles.btn, styles.mobileHidden)}
            >
              Preview
            </Button>
            <FlatButton
              icon={NewWindowIcon}
              iconProps={{
                className: styles.icon,
              }}
              onClick={onPreview}
              className={cx(styles.flatBtn, styles.mobileOnly)}
            >
              Preview
            </FlatButton>
          </>
        )}
      </div>
    </div>
    <div className={cx(styles.content, contentClassName)}>
      {onBack && (
        <FlatButton
          icon={BackArrow}
          iconProps={{
            className: styles.backIcon,
          }}
          className={styles.backBtn}
          onClick={onBack}
        >
          Back to builder
        </FlatButton>
      )}
      {title && <h1 className={styles.title}>{title}</h1>}
      {description ? description : null}
      <div className={styles.contentWrapper}>
        <div className={styles.mainContent}>{children}</div>
        {onPreview && (
          <PreviewCardLA
            className={styles.previewCard}
            sectionName={currentSection || 'overview-page'}
            onPreviewClick={onPreview}
          />
        )}
      </div>
    </div>
    {showLoginLink && (
      <p className={styles.loginInfo}>
        Already have an account?{' '}
        <Link
          className={styles.link}
          to="/auth/login"
          onClick={() =>
            segmentTracking('log_in clicked', {
              location: 'Property Details - Public Lease Agreement',
            })
          }
        >
          Log in here.
        </Link>
      </p>
    )}
  </div>
);

StandaloneLAWizardLayout.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  onLogoClick: PropTypes.func,
  onSignUp: PropTypes.func,
  onPreview: PropTypes.func,
  title: PropTypes.node,
  description: PropTypes.node,
  onBack: PropTypes.func,
  showLoginLink: PropTypes.bool,
  children: PropTypes.node,
  currentSection: PropTypes.string,
};

export default StandaloneLAWizardLayout;
