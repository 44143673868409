import React, { useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import colors from '../../../constants/colors';
import { useIsSmallScreen } from '../../../hooks/useIsSmallScreen';
import { segmentTracking } from '../../../services/utilities';
import Button from '../../Button';
import ExpandableCard from '../../ExpandableCard';
import Tag from '../../Tag';
import { H2, Link, Paragraph } from '../../Typography';

import styles from './AccordionVariant.module.scss';

function Item({ item, disabled, premium }) {
  const { icon: ItemIcon, title, description } = item;

  return (
    <div
      key={title}
      className={cx(styles.benefitItem, {
        [styles.centered]: !description,
        [styles.disabled]: disabled,
      })}
    >
      {ItemIcon && (
        <div className={styles.itemIconWrapper}>
          <ItemIcon width={27} height={27} color={colors.orangeLight} />
        </div>
      )}
      <div>
        <Paragraph color={premium ? 'white' : undefined} weight="bold">
          {title}
        </Paragraph>
        {description && (
          <Paragraph
            className={styles.description}
            color={premium ? 'white' : ''}
          >
            {description}
          </Paragraph>
        )}
      </div>
    </div>
  );
}

Item.propTypes = {
  item: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  premium: PropTypes.bool,
};

function Benefits({
  benefits,
  isSmallScreen,
  isProPlan,
  onSegmentEvent,
  scrollToPremiumPlan,
}) {
  return benefits.map((benefit, idx) => {
    const { title, titleShort, items: allItems = [] } = benefit;

    const nonPremiumItems =
      allItems.filter((item) => item.plan !== 'premium') || [];

    const premiumItems =
      (isSmallScreen && allItems.filter((item) => item.plan === 'premium')) ||
      [];

    const sectionTitle = titleShort === 'Leases' ? title : titleShort || title;

    return (
      <ExpandableCard
        defaultExpanded={idx === 0}
        key={benefit.title}
        className={styles.expandableCard}
        headerClassName={styles.cardHeader}
        contentClassName={styles.cardContent}
        title={sectionTitle}
        onClick={() => {
          onSegmentEvent(`${(titleShort || title).toLowerCase()}_tab clicked`);
        }}
      >
        {isSmallScreen && isProPlan && (
          <div
            className={cx(styles.proPlan, {
              [styles.fullWidth]: isSmallScreen,
            })}
          >
            {nonPremiumItems.map((item) => (
              <Item key={`non-premium-${item.title}`} item={item} />
            ))}

            {!!premiumItems.length && (
              <>
                <Paragraph className={styles.premiumPlanLabel} weight="semi">
                  Only included with{' '}
                  <Link onClick={scrollToPremiumPlan}>Premium Plan</Link>:
                </Paragraph>
                {premiumItems.map((item) => (
                  <Item key={`premium-${item.title}`} item={item} disabled />
                ))}
              </>
            )}
          </div>
        )}

        {isSmallScreen && !isProPlan && (
          <div
            className={cx(styles.premiumPlan, {
              [styles.fullWidth]: isSmallScreen,
            })}
          >
            {allItems?.map((item) => (
              <Item key={item.title} item={item} premium />
            ))}
          </div>
        )}

        {!isSmallScreen && (
          <div className={styles.benefitsContainer}>
            <div
              className={cx(styles.proPlan, {
                [styles.fullWidth]: isSmallScreen,
              })}
            >
              {nonPremiumItems.map((item) => (
                <Item key={`non-premium-${item.title}`} item={item} />
              ))}
            </div>

            <div
              className={cx(styles.premiumPlan, {
                [styles.fullWidth]: isSmallScreen,
              })}
            >
              {allItems.map((item) => (
                <Item key={`premium-${item.title}`} item={item} premium />
              ))}
            </div>
          </div>
        )}
      </ExpandableCard>
    );
  });
}

function ProPlanCard({
  onGetPro,
  isSmallScreen = false,
  price,
  displayMonthlyPrice,
}) {
  return (
    <div className={cx(styles.proPlan, { [styles.fullWidth]: isSmallScreen })}>
      <H2>Pro</H2>
      <div
        className={cx(styles.price, { [styles.monthly]: displayMonthlyPrice })}
      >
        <H2 className={styles.priceValue}>
          ${displayMonthlyPrice ? (price / 12).toFixed(2) : price}
        </H2>
        <Paragraph weight="bold">
          {displayMonthlyPrice ? 'PER MONTH' : 'PER YEAR'}
        </Paragraph>
      </div>
      {displayMonthlyPrice && (
        <span className={styles.billedAnnuallyLabel}>Billed annually</span>
      )}
      <Button secondary onClick={onGetPro}>
        Get Pro
      </Button>
    </div>
  );
}

ProPlanCard.propTypes = {
  isSmallScreen: PropTypes.bool,
  onGetPro: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
  displayMonthlyPrice: PropTypes.bool,
};

function PremiumPlanCard({
  isSmallScreen = false,
  onGetPremium,
  premiumPlanRef,
  price,
  displayMonthlyPrice,
}) {
  return (
    <div
      className={cx(styles.premiumPlan, {
        [styles.fullWidth]: isSmallScreen,
        [styles.marginTop]: isSmallScreen,
      })}
      ref={premiumPlanRef}
    >
      <div className={styles.premiumHeader}>
        <H2 className={styles.label} color="white">
          Premium
        </H2>
        <Tag className={styles.tag} uppercase={false}>
          Best Value
        </Tag>
      </div>
      <div
        className={cx(styles.price, { [styles.monthly]: displayMonthlyPrice })}
      >
        <H2 className={styles.priceValue} color="white">
          ${displayMonthlyPrice ? (price / 12).toFixed(2) : price}
        </H2>
        <Paragraph color="white" weight="bold">
          {displayMonthlyPrice ? 'PER MONTH' : 'PER YEAR'}
        </Paragraph>
      </div>
      {displayMonthlyPrice && (
        <span className={cx(styles.billedAnnuallyLabel, styles.premium)}>
          Billed annually
        </span>
      )}

      <Button className={styles.premiumBtn} onClick={onGetPremium}>
        Get Premium
      </Button>
    </div>
  );
}

PremiumPlanCard.propTypes = {
  isSmallScreen: PropTypes.bool,
  onGetPremium: PropTypes.func.isRequired,
  premiumPlanRef: PropTypes.object,
  price: PropTypes.number.isRequired,
  displayMonthlyPrice: PropTypes.bool,
};

const AccordionVariant = ({
  allBenefits: benefits,
  className,
  onGetPremium,
  onGetPro,
  premiumPrice,
  proPrice,
  displayMonthlyPrice = false,
  segmentProperties = {},
}) => {
  const isSmallScreen = useIsSmallScreen();
  const premiumPlanRef = useRef(null);

  const onSegmentEvent = (event) => {
    segmentTracking(event, segmentProperties);
  };

  const scrollToPremiumPlan = () => {
    if (premiumPlanRef.current) {
      premiumPlanRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={className}>
      {isSmallScreen ? (
        <>
          <PremiumPlanCard
            onGetPremium={onGetPremium}
            isSmallScreen
            premiumPlanRef={premiumPlanRef}
            price={premiumPrice}
            displayMonthlyPrice={displayMonthlyPrice}
          />
          <Benefits
            benefits={benefits}
            isSmallScreen
            onSegmentEvent={onSegmentEvent}
          />
          <ProPlanCard
            onGetPro={onGetPro}
            isSmallScreen
            price={proPrice}
            displayMonthlyPrice={displayMonthlyPrice}
          />
          <Benefits
            benefits={benefits}
            isProPlan
            isSmallScreen
            scrollToPremiumPlan={scrollToPremiumPlan}
            onSegmentEvent={onSegmentEvent}
          />
        </>
      ) : (
        <>
          <div className={styles.plans}>
            <ProPlanCard
              onGetPro={onGetPro}
              price={proPrice}
              displayMonthlyPrice={displayMonthlyPrice}
            />
            <PremiumPlanCard
              onGetPremium={onGetPremium}
              price={premiumPrice}
              displayMonthlyPrice={displayMonthlyPrice}
            />
          </div>
          <Benefits
            benefits={benefits}
            isProPlan={true}
            onSegmentEvent={onSegmentEvent}
          />
        </>
      )}
    </div>
  );
};

AccordionVariant.propTypes = {
  allBenefits: PropTypes.array.isRequired,
  className: PropTypes.string,
  onGetPremium: PropTypes.func.isRequired,
  onGetPro: PropTypes.func.isRequired,
  proPrice: PropTypes.number.isRequired,
  premiumPrice: PropTypes.number.isRequired,
  displayMonthlyPrice: PropTypes.bool,
  segmentProperties: PropTypes.object,
};

export default AccordionVariant;
