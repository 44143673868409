import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import cx from 'classnames';
import _, { trim } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import FinalForm from '../../../../components/FinalForm';
import {
  AddressField,
  CityField,
  DateField,
  EmailField,
  InputField,
  MoneyField,
  PhoneField,
  ResidenceTypeField,
  StateField,
  TextAreaField,
  UnitField,
  ZipCodeField,
} from '../../../../components/FinalFormFields';
import { Column, Row } from '../../../../components/Grid';
import RemoveButton from '../../../../components/RemoveButton';
import { mapAddressTypeToInt } from '../../../../helpers/mapAddressTypeToInt';

import AddressHeader from './AddressHeader';

import styles from './AddressesEdit.module.scss';

const mapAddress = (address) => {
  const startDate = address.start_date
    ? moment(address.start_date, 'YYYY-MM-DD').format('MM/DD/YYYY')
    : undefined;

  address = {
    ...address,
    start_date: startDate,
  };

  return {
    ..._.pick(
      address,
      'id',
      'current',
      'tenancy_type',
      'address_1',
      'address_2',
      'city',
      'state',
      'zip',
      'monthly_payment',
      'reason_for_moving',
      'landlord_name',
      'landlord_email',
      'landlord_phone',
      'start_date',
    ),
  };
};

const AddressesEdit = ({ id, onSubmit, application, formRef }) => {
  const handleSubmit = async (data) => {
    const pastAddresses =
      data?.residences?.filter(({ current }) => !current) || [];

    await onSubmit({
      residences: data?.residences.map((address) => ({
        id: address.id,
        current: address.current,
        tenancy_type: mapAddressTypeToInt(address.tenancy_type),
        address_1: trim(address.address_1),
        unit: address.unit,
        city: trim(address.city),
        state: address.state,
        landlord_email: trim(address.landlord_email),
        landlord_name: trim(address.landlord_name),
        landlord_phone: trim(address.landlord_phone),
        monthly_payment: address.monthly_payment,
        reason_for_moving: trim(address.reason_for_moving),
        zip: trim(address.zip),
        start_date: address.start_date,
      })),
      past_address_not_applicable: pastAddresses.length <= 0,
    });
  };

  return (
    <FinalForm
      ref={formRef}
      id={id}
      initialValues={{
        residences: application?.residences.map(mapAddress),
      }}
      onSubmit={handleSubmit}
    >
      {({ form, values }) => (
        <article>
          <FieldArray name="residences">
            {({ fields }) => {
              return fields?.map((name, index) => {
                const address = fields?.value?.[index];

                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={`address-key-${index}`}>
                    <div style={{ marginTop: 32 }}>
                      <AddressHeader address={address} />
                      <div className={styles.addressFormContainer}>
                        <Row gap={16} className={styles.addressFieldsContainer}>
                          <Column xs={12} md={8}>
                            <ResidenceTypeField
                              name={`${name}.tenancy_type`}
                              label="Residence type"
                              className={cx(styles.field, styles.tenancyType)}
                            />
                          </Column>
                          <Column xs={12} md={4}>
                            {(application.applying_as_tenant ||
                              !application.application_group_identifier_token) && (
                              <DateField
                                name={`${name}.start_date`}
                                label="Date moved in"
                                required={address.current}
                                className={styles.field}
                              />
                            )}
                          </Column>
                          <Column span={12}>
                            <AddressField
                              label="Address"
                              className={styles.field}
                              name={`${name}.address_1`}
                            />
                          </Column>
                          <Column xs={6} md={4}>
                            <UnitField
                              required={false}
                              label="Unit"
                              name={`${name}.address_2`}
                              className={styles.field}
                            />
                          </Column>
                          <Column xs={12} md={8}>
                            <CityField
                              className={styles.field}
                              name={`${name}.city`}
                              label="City"
                            />
                          </Column>
                          <Column span={4}>
                            <StateField
                              className={styles.field}
                              name={`${name}.state`}
                              label="State"
                            />
                          </Column>
                          <Column span={8}>
                            <ZipCodeField
                              className={styles.field}
                              label="ZIP Code"
                              name={`${name}.zip`}
                            />
                          </Column>
                          {address?.tenancy_type !== 3 && (
                            <Column xs={9} md={5}>
                              <MoneyField
                                required={false}
                                name={`${name}.monthly_payment`}
                                label={`Monthly ${
                                  address?.tenancy_type === 0
                                    ? 'Mortgage'
                                    : 'Rent'
                                }`}
                                className={styles.field}
                              />
                            </Column>
                          )}
                          <Column span={12}>
                            {(application.applying_as_tenant ||
                              !application.application_group_identifier_token) && (
                              <TextAreaField
                                required={false}
                                label="Reason for moving"
                                name={`${name}.reason_for_moving`}
                                rows="4"
                                className={styles.field}
                              />
                            )}
                          </Column>
                          {(application.applying_as_tenant ||
                            !application.application_group_identifier_token) &&
                            address?.tenancy_type !== 0 && (
                              <>
                                {address.landlord_reference_submitted && (
                                  <Column
                                    span={12}
                                    className={cx(
                                      styles.field,
                                      styles.warningText,
                                    )}
                                  >
                                    Since the landlord has already provided a
                                    reference, you cannot edit their contact
                                    information
                                  </Column>
                                )}
                                <Column md={4} sm={12}>
                                  <InputField
                                    required={address.current}
                                    label="Landlord full name"
                                    name={`${name}.landlord_name`}
                                    className={styles.field}
                                  />
                                </Column>
                                <Column md={4} sm={12}>
                                  <EmailField
                                    required={false}
                                    label="Landlord email"
                                    name={`${name}.landlord_email`}
                                    disabled={
                                      address.landlord_reference_submitted
                                    }
                                    className={styles.field}
                                  />
                                </Column>
                                <Column md={4} sm={12}>
                                  <PhoneField
                                    required={address.current}
                                    label="Landlord phone"
                                    name={`${name}.landlord_phone`}
                                    className={styles.field}
                                  />
                                </Column>
                              </>
                            )}
                        </Row>
                        {!address.current && (
                          <RemoveButton
                            shrink={false}
                            onClick={() =>
                              form?.mutators?.remove('residences', index)
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              });
            }}
          </FieldArray>
          {(application.applying_as_tenant ||
            values?.residences?.length > 1) && (
            <Button
              small
              className={styles.addButton}
              secondary
              onClick={() =>
                form?.mutators?.push('residences', {
                  current: values?.residences.length < 1,
                  tenancy_type: null,
                  start_date: null,
                  address_1: null,
                  address_2: null,
                  city: null,
                  state: null,
                  zip: null,
                  reason_for_moving: null,
                  landlord_name: null,
                  landlord_email: null,
                  landlord_phone: null,
                  monthly_payment: null,
                })
              }
            >
              + add address
            </Button>
          )}
        </article>
      )}
    </FinalForm>
  );
};

AddressesEdit.propTypes = {
  onSubmit: PropTypes.func,
  application: PropTypes.object,
  id: PropTypes.string,
  formRef: PropTypes.object,
};

export default AddressesEdit;
