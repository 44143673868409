const getDeviceType = () => {
  try {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // iPadOS 13+ detection (iPads report as MacOS in newer versions)
    if (
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform)
    ) {
      return 'tablet';
    }

    // Tablet detection
    if (/tablet|ipad|playbook|silk/i.test(userAgent)) {
      return 'tablet';
    }

    // Mobile detection
    if (/iPhone/i.test(userAgent)) {
      return 'iphone';
    }

    if (/Android/i.test(userAgent)) {
      // Distinguish between Android phones and tablets
      if (!/Mobile/i.test(userAgent)) {
        return 'tablet';
      }
      return 'android';
    }

    // Check for other mobile devices
    if (
      /webOS|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i.test(userAgent) ||
      (window.innerWidth <= 800 && window.innerHeight <= 600)
    ) {
      return 'mobile';
    }

    if (typeof window !== 'undefined' && window.innerWidth > 800) {
      return 'desktop';
    }

    return 'not_set';
  } catch (error) {
    console.error('Error getting device type:', error);
    return 'not_set';
  }
};

export default getDeviceType;
