module.exports = {
  primary: '#033A6D',
  secondary: '#2370A3',
  text: '#042238',
  textLight: '#516A80',
  error: '#FF3B3B',
  errorLight: '#fbf3f2',
  greyLight: '#ACB9C8',
  blueLight: '#F5F6F8',
  orangeLight: '#FF9250',
  info: '#00C8FF',
  link: '#2370A3',
  facebookBlue: '#1877F2',
  googleBlue: '#4285F4',
  reiHubPurple: '#431C5D',
  redLight: '#FF3B3B',
  grayLight: '#E6E9F0',
  line: '#E6E9F0',
  checkInGood: '#00C8FF',
  checkInBad: '#FF3B3B',
  contentBackground: '#F5F6F8',
  grey1: '#516A80',
  grey2: '#D8D8D8',
  green: '#43A047',
  lightGreen: '#66BB6A',
  pink: '#E91E63',
  errorText: '#f44336',
  propertyLabelDisabled: '#E6E9F0',
  propertyLabelMarketing: '#00C8FF',
  propertyLabelAcceptingApplicants: '#FF9250',
  propertyLabelMarketingPending: '#f0dfb1',
  profilePictureColors: [
    '#00C8FF',
    '#FFCD00',
    '#FF9250',
    '#A9F3DF',
    '#04BF8D',
    '#7FE3FF',
    '#FF3B3B',
  ],
  profileColorTextPair: {
    '#00C8FF': '#115061',
    '#FFCD00': '#67550C',
    '#FF9250': '#6C320F',
    '#A9F3DF': '#2E7764',
    '#04BF8D': '#115A46',
    '#7FE3FF': '#184C5A',
    '#FF3B3B': '#591616',
  },
  black: '#21282A',
  white: '#FFFFFF',
  activityIconBackground: '#E6E9F0',
  labelYellow: '#FFCD00',
  highlightYellow: '#FFCD00',
  lightCardHeaderBackground: '#F5F8FF',
  greyBlue: '#C9EDFF',
  disabledInput: '#F0F2F5',
  darkBlueBackground: '#052144',
  lightBlueBackground: '#00C8FF',
  bulletPointYellow: '#FF9250',
  warning: '#FF9250',
  warningLight: '#AA6000',
  modalBlueBackground: '#e7f8fd',
  modalLightBlueBackground: '#f5f9ff',
  listCountBackground: '#627186',
  sureBlue: '#022c52',
  lightGreenAlt: '#04bf8d',
  helpCardBackground: '#A9F3DF',
  lightRedBackground: '#FEEFEF',
  grayModalBackground: '#FBF7F5',
  babyBlue: '#7FE3FF',
  darkGray: '#687A88',
  darkGreen: '#00805F',
  statusWarning: '#bf2c2c',
  successToastGreen: '#E6F9F4',
  warningToastOrange: '#ff9f2a',
  warningToastOrangeBackground: '#fff8e3',
  slimProgressBarFill: '#009e74',
  lightGray: '#CDD3D7',
};
