import React, { useEffect, useRef, useState } from 'react';
import { OnChange } from 'react-final-form-listeners';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { FORM_ERROR } from 'final-form';
import arrayMutators from 'final-form-arrays';
import createFinalFormErrorDecorator from 'final-form-focus';
import { parse } from 'query-string';

import UALAAnimation from '../../../../components/Animations/UALAAnimation';
import InlineAlert from '../../../../components/InlineAlert';
import WithFormValues from '../../../../components/WithFormValues';
import Wizard from '../../../../components/Wizard';
import WizardSectionsEnum from '../../../../constants/enums/WizardSectionsEnum';
import STATE_NAMES, {
  usTerritories,
} from '../../../../constants/lease_agreement/state-names';
import createStandaloneLeaseAgreementMutation from '../../../../graphql/mutations/lease_agreements/createStandaloneLeaseAgreement.graphql';
import getHasSubdivisionLA from '../../../../helpers/getHasSubdivisionLease';
import isSinglePropertyType from '../../../../helpers/isSinglePropertyType';
import { useRenderOutside } from '../../../../helpers/render-outside';
import TurboTenantSmallLogo from '../../../../icons/TurboTenantSmallLogo';
import { useConfig } from '../../../../providers/ConfigProvider';
import {
  getStateFullName,
  segmentTracking,
} from '../../../../services/utilities';
import useSignupMutation from '../../../auth/signup/useSignupMutation';
import SectionAdditionalProvisionsFields from '../../sections/SectionAdditionalProvisions/SectionAdditionalProvisionsFields';
import SectionLeaseSpecificsFields from '../../sections/SectionLeaseSpecifics/SectionLeaseSpecificsFields';
import SectionPeopleOnTheLeaseFields from '../../sections/SectionPeopleOnTheLease/SectionPeopleOnTheLeaseFields';
import SectionPetsSmokingOtherFields from '../../sections/SectionPetsSmokingOther/SectionPetsSmokingOtherFields';
import SectionRentDepositFeeFields from '../../sections/SectionRentDepositFee/SectionRentDepositFeeFields';
import SectionUtilitiesFields from '../../sections/SectionUtilities/SectionUtilitiesFields';
import AddRentalAddressModal from '../AddRentalAddressModal';
import OverviewPage from '../OverviewPage';
import PreviewPublicLease from '../PreviewPublicLease';
import SaveLeaseAgreementModal from '../SaveLeaseAgreementModal';
import StandaloneLAWizardLayout from '../StandaloneLAWizardLayout';
import { StandaloneWizardProvider } from '../StandaloneWizardProvider';
import StateCitySelectionModal from '../StateCitySelectionModal';
import UnsavedChangesPrompt from '../UnsavedChangesPrompt';
import UpdateStateCityModal from '../UpdateStateCityModal';

import { calculatePropertyRole } from './helpers/calculatePropertyRole';
import { getInitialStandaloneLAData } from './getInitialStandaloneLAData';
import { preparePayloadLeaseAgreementPublicLA } from './preparePayloadLeaseAgreementPublicLA';
import { preparePayloadPropertyDetailsPublicLA } from './preparePayloadPropertyDetailsPublicLA';
import { preparePreviewPublicLAData } from './preparePreviewPublicLAData';

import styles from './StandaloneLeaseAgreement.module.scss';

const finalFormErrorDecorator = createFinalFormErrorDecorator();

const stepToSegmentLocation = {
  0: 'Builder Summary Page - Public Lease Agreement',
  1: 'Lease Specifics - Public Lease Agreement',
  2: 'Rent Deposit Fees - Public Lease Agreement',
  3: 'Utilities Services Keys - Public Lease Agreement',
  4: 'Pets Smoking Insurance - Public Lease Agreement',
  5: 'People on Lease - Public Lease Agreement',
  6: 'Provisions Attachments - Public Lease Agreement',
};

const StandaloneLeaseAgreement = () => {
  const history = useHistory();
  const location = useLocation();

  const { state: queryParamState } = parse(location.search);
  const allowedStates = Object.keys(STATE_NAMES).filter(
    (key) => !Object.keys(usTerritories).includes(key),
  );

  const selectedState = allowedStates.includes(queryParamState)
    ? queryParamState
    : '';

  let [showAnimation, setShowAnimation] = useState(!!queryParamState);
  const [data, setData] = useState({
    currentState: queryParamState ? selectedState : '',
  });
  const [showUpdateStateModal, setShowUpdateStateModal] = useState(null);
  const [showPreview, setShowPreview] = useState(false);

  const pathParts = location.pathname.split('/');
  const wizardStepFromURL = pathParts[pathParts.length - 1];

  // We set initial wizard step based on the URL or default to 0
  const [wizardStep, setWizardStep] = useState(
    wizardStepFromURL === 'lease-agreement' || wizardStepFromURL === ''
      ? 0
      : parseInt(wizardStepFromURL, 10) || 0,
  );

  const startedSections = useRef([]);
  const renderOutside = useRenderOutside();
  const { PRIVATE_BASE_PATH } = useConfig();

  const signup = useSignupMutation(location);

  const [createStandaloneLA] = useMutation(
    createStandaloneLeaseAgreementMutation,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    segmentTracking('public_lease_agreement started', {
      location: 'Property Details - Public Lease Agreement',
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (wizardStep !== null && !startedSections.current.includes(wizardStep)) {
      startedSections.current.push(wizardStep);
    }
  }, [wizardStep]);

  // Update the step based on the URL change
  // This code will allow navigation between steps to the users that are using browser navigation
  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const wizardStepFromURL = pathParts[pathParts.length - 1];
    const searchParams = location.search;

    if (wizardStepFromURL === 'lease-agreement' || wizardStepFromURL === '') {
      setWizardStep(0);
    } else {
      const numStep = parseInt(wizardStepFromURL, 10);
      if (!isNaN(numStep) && numStep >= 0) {
        setWizardStep(numStep);
      } else {
        // Redirect to base if URL is incorrect, maintaining query parameters
        history.replace(`/lease-agreement${searchParams}`);
      }
    }
  }, [location, history]);

  const updateStepInURL = (step) => {
    const searchParams = location.search;

    if (step > 0) {
      history.push(`/lease-agreement/${step}${searchParams}`);
    } else {
      history.push(`/lease-agreement${searchParams}`);
    }
  };

  const handleWizardStepChange = (step) => {
    setWizardStep(step);
    updateStepInURL(step);
  };

  function handleSaveLeaseAgreement(values) {
    setData({
      ...data,
      leaseAgreement: {
        ...values,
      },
    });
  }

  function onNext(sectionData) {
    handleSaveLeaseAgreement(sectionData);
    segmentTracking('next clicked', {
      location: stepToSegmentLocation[wizardStep],
    });
    handleWizardStepChange(wizardStep + 1);
  }

  const currentState = data?.currentState;
  const leaseAgreementSelection = data?.lease_agreement_selection;

  const stateFullName = getStateFullName(
    currentState,
    leaseAgreementSelection,
    false,
  );

  const handleError = (error) => {
    const parsedError = error?.graphQLErrors?.[0];

    if (parsedError?.code === 471) {
      window.atatus?.notify({
        message: 'Standalone LA error: Email already in use.',
        error,
      });

      return {
        [FORM_ERROR]: parsedError.message,
      };
    }

    if (parsedError?.code === 500) {
      return {
        [FORM_ERROR]: 'Something went wrong, please try again',
      };
    }

    const message = parsedError?.message || 'Something went wrong';
    return {
      [FORM_ERROR]: message,
    };
  };

  const handleSubmit = async (userData, closeSignUpModal, data) => {
    const body = {
      ...data,
      userData,
    };

    setIsSubmitting(true);

    const segmentLocation = 'Save Sign Up Modal - Public Lease Agreement';

    segmentTracking('sign_up clicked', {
      location: segmentLocation,
    });

    try {
      await signup({
        ...body.userData,
        process: 'LEASE_AGREEMENT',
      });

      segmentTracking('account_creation success', {
        location: segmentLocation,
      });
    } catch (error) {
      segmentTracking('account_creation fail', {
        location: segmentLocation,
      });
      return handleError(error);
    }

    try {
      const { propertyDetails: propertyDetailsData, ...leaseAgreementData } =
        body.leaseAgreement;

      const { data: standaloneLARes } = await createStandaloneLA({
        variables: {
          property_details:
            preparePayloadPropertyDetailsPublicLA(propertyDetailsData),
          lease_agreement_details: preparePayloadLeaseAgreementPublicLA({
            lease_agreement_details: leaseAgreementData,
            property_details: propertyDetailsData,
            lease_agreement_selection: data?.lease_agreement_selection,
            currentState,
          }),
        },
      });

      segmentTracking('property_lease creation success', {
        location: segmentLocation,
      });

      closeSignUpModal();

      const leaseId = standaloneLARes.createStandaloneLeaseAgreement.lease.id;
      history.push({
        pathname: `${PRIVATE_BASE_PATH}digital-leases/${leaseId}/overview`,
        state: {
          fromUnauthFlow: true,
        },
      });
    } catch (error) {
      segmentTracking('property_lease creation fail', {
        location: segmentLocation,
      });
      window.atatus?.notify({
        message: `Standalone LA error: Error creating lease agreement: ${JSON.stringify(
          error?.graphQLErrors?.[0],
        )}`,
        error: error?.graphQLErrors?.[0],
      });
      return handleError(error);
    }
  };

  const handleLeaseAgreementModal = ({
    location = null,
    updatedData = null,
  } = {}) => {
    const { address, city, state, zip, roomRentals, room_name, unit_name } =
      updatedData
        ? updatedData?.leaseAgreement?.propertyDetails || {}
        : data?.leaseAgreement?.propertyDetails || {};

    // We show AddRentalAddressModal if any of the required fields are missing
    if (
      !address ||
      !city ||
      !state ||
      !zip ||
      roomRentals === undefined ||
      (roomRentals && !room_name) ||
      (!isSinglePropertyType(
        data?.leaseAgreement?.propertyDetails?.property_type,
      ) &&
        !unit_name)
    ) {
      renderOutside((done) => (
        <AddRentalAddressModal
          open
          onClose={done}
          onSubmit={(values) => {
            const updatedData = {
              ...data,
              leaseAgreement: {
                ...data.leaseAgreement,
                propertyDetails: {
                  ...data.leaseAgreement.propertyDetails,
                  ...values,
                },
              },
            };

            setData(updatedData);
            done();
            handleLeaseAgreementModal({ updatedData });
          }}
          segmentLocation={location}
          initialValues={data?.leaseAgreement?.propertyDetails}
          stateFullName={stateFullName}
        />
      ));
      return;
    }

    // We show SaveLeaseAgreementModal if all required fields are present
    renderOutside((done) => (
      <SaveLeaseAgreementModal
        open
        onClose={done}
        onSignUp={(userData) =>
          handleSubmit(
            userData,
            done,
            /** NOTE: We do this because this handleSubmit is being passed right before 'data' state variable
             * is updated in scenarios where AddRentalAddressModal is used. It is happening because setData
             * runs asynchronously. It is a hotfix we can look into tidying up later.
             */
            updatedData || data,
          )
        }
        location={location || stepToSegmentLocation[wizardStep]}
      />
    ));
  };

  function onSaveAndFinishLater(sectionData) {
    handleSaveLeaseAgreement(sectionData);
    handleLeaseAgreementModal();
    segmentTracking('save_and_finish_later clicked', {
      location: stepToSegmentLocation[wizardStep],
    });
  }

  if (showAnimation) {
    return (
      <StandaloneLAWizardLayout>
        <UnsavedChangesPrompt when={true} />
        <UALAAnimation
          style={{ width: '100%', height: '100%' }}
          onComplete={() => {
            setShowAnimation(false);
          }}
        />
      </StandaloneLAWizardLayout>
    );
  }

  const wizardOverviewTitle = (
    <>
      Customize your{' '}
      <span className={styles.titleBold}>{stateFullName} Lease Agreement</span>
    </>
  );

  const wizardOverviewDescription = (
    <InlineAlert
      containerClassName={styles.infoBlockContainer}
      title={`Here's what you need to know:`}
      icon={TurboTenantSmallLogo}
      iconProps={{ className: styles.infoBlockIcon, width: 24, height: 24 }}
    >
      <ul>
        <li>
          Our {stateFullName} lease agreement was drafted by legal professionals
          to keep you compliant and covered.
        </li>
        <li>Start/stop anywhere and preview the lease agreement anytime.</li>
        <li>Save to finish later or send out for e-sign.</li>
      </ul>
    </InlineAlert>
  );

  return (
    <StandaloneLAWizardLayout
      title={wizardStep === 0 ? wizardOverviewTitle : null}
      description={wizardStep === 0 ? wizardOverviewDescription : null}
      onBack={
        wizardStep === 0
          ? null
          : () => {
              handleWizardStepChange(0);
              updateStepInURL(0);
              segmentTracking('back_to_builder clicked', {
                location: stepToSegmentLocation[wizardStep],
              });
            }
      }
      onSignUp={() => {
        handleLeaseAgreementModal();
        segmentTracking('sign_up clicked', {
          location: stepToSegmentLocation[wizardStep],
        });
      }}
      onPreview={() => {
        setShowPreview(true);
        segmentTracking('preview_lease clicked', {
          location: stepToSegmentLocation[wizardStep],
        });
      }}
      onLogoClick={handleLeaseAgreementModal}
      contentClassName={wizardStep === 0 ? null : styles.wizardStepsContent}
      currentSection={
        wizardStep === 0
          ? 'overview-page'
          : Object.keys(WizardSectionsEnum)[wizardStep - 1]
      }
    >
      {showPreview && (
        <PreviewPublicLease
          leaseAgreement={preparePreviewPublicLAData({
            ...data.leaseAgreement,
            id: null,
          })}
          onClose={() => {
            setShowPreview(false);
            segmentTracking('go_back clicked', {
              location: 'Preview Lease Modal',
            });
          }}
          onAction={() => {
            handleLeaseAgreementModal({ location: 'Preview Lease Modal' });
            segmentTracking('sign_up clicked', {
              location: 'Preview Lease Modal',
            });
          }}
          openLocation={stepToSegmentLocation[wizardStep]}
        />
      )}
      <UnsavedChangesPrompt when={!isSubmitting} />
      <Wizard
        // We are using key to reset the form when the state changes
        // That way we avoid issues with initial values not being updated
        key={currentState}
        className={styles.wizardFieldsContent}
        page={wizardStep}
        onPrevClick={onSaveAndFinishLater}
        onNextClick={onNext}
        onSubmit={(sectionData) => {
          handleSaveLeaseAgreement(sectionData);
          handleLeaseAgreementModal();
          segmentTracking('get_lease_agreement', {
            location: stepToSegmentLocation[wizardStep],
          });
        }}
        previousBtnLabel="Save & Finish Later"
        previousBtnClassName={styles.previousBtn}
        buttonsClassName={styles.buttonsWrapper}
        submitLabel="Get My Lease Agreement"
        onValuesUpdate={handleSaveLeaseAgreement}
        mutators={{
          ...arrayMutators,
        }}
        decorators={[finalFormErrorDecorator]}
        showNavigation={wizardStep !== 0}
        initialValues={getInitialStandaloneLAData(currentState)}
      >
        <Wizard.Page>
          <OverviewPage
            onSectionSelect={(section) => {
              handleWizardStepChange(section);
            }}
            startedSections={startedSections.current}
            stateFullName={stateFullName}
          />
        </Wizard.Page>
        <Wizard.Page>
          <div>
            <h3 className={styles.sectionHeader}>Rent, Deposit, & Fees</h3>
            <SectionRentDepositFeeFields
              currentState={currentState}
              standAloneWizard={true}
              leaseAgreementSelection={leaseAgreementSelection}
            />
          </div>
        </Wizard.Page>
        <Wizard.Page>
          <h3 className={styles.sectionHeader}>Lease Specifics</h3>
          <SectionLeaseSpecificsFields
            currentState={currentState}
            standAloneWizard={true}
            leaseAgreementSelection={leaseAgreementSelection}
          />
          <OnChange name="propertyDetails.state">
            {(value) => {
              if (value !== currentState) {
                setShowUpdateStateModal(value);
              }
            }}
          </OnChange>
          {showUpdateStateModal && (
            <UpdateStateCityModal
              open
              currentState={currentState}
              stateCity={stateFullName}
              onUpdate={() => {
                setData({
                  ...getInitialStandaloneLAData(showUpdateStateModal),
                  currentState: showUpdateStateModal,
                });
                startedSections.current = [1]; // Update started sections to only show lease specifics
                setShowUpdateStateModal(false);
              }}
              onCancel={() => {
                setShowUpdateStateModal(false);
              }}
            />
          )}
        </Wizard.Page>
        <Wizard.Page>
          <h3 className={styles.sectionHeader}>Utilities, Servicies, & Keys</h3>
          <WithFormValues>
            {(values) => (
              <SectionUtilitiesFields
                currentState={currentState}
                propertyRole={calculatePropertyRole(values)}
                standAloneWizard={true}
                leaseAgreementSelection={leaseAgreementSelection}
              />
            )}
          </WithFormValues>
        </Wizard.Page>
        <Wizard.Page>
          <h3 className={styles.sectionHeader}>Pets, Smoking, & Other</h3>
          <SectionPetsSmokingOtherFields
            currentState={currentState}
            standAloneWizard={true}
          />
        </Wizard.Page>
        <Wizard.Page>
          <h3 className={styles.sectionHeader}>People on the Lease</h3>
          <WithFormValues>
            {(values) => (
              <SectionPeopleOnTheLeaseFields
                currentState={currentState}
                propertyRole={calculatePropertyRole(values)}
                standAloneWizard={true}
              />
            )}
          </WithFormValues>
        </Wizard.Page>
        <Wizard.Page>
          <h3 className={styles.sectionHeader}>Provisions & Attachments</h3>
          <WithFormValues>
            {(values) => (
              <SectionAdditionalProvisionsFields
                currentState={currentState}
                propertyRole={calculatePropertyRole(values)}
                standAloneWizard={true}
                leaseAgreementSelection={leaseAgreementSelection}
                onCreateAccount={() => {
                  segmentTracking('sign_up clicked', {
                    location: 'Condition Report - Public Lease Agreement',
                  });
                  handleLeaseAgreementModal();
                }}
              />
            )}
          </WithFormValues>
        </Wizard.Page>
      </Wizard>
      {!data?.currentState ||
      (!data.lease_agreement_selection &&
        getHasSubdivisionLA(data?.currentState)) ? (
        <StateCitySelectionModal
          open
          onSave={(values) => {
            segmentTracking('save_selection clicked', {
              location: 'Lease Agreement State Modal - Public Lease Agreement',
            });

            setData({
              currentState: values.state,
              lease_agreement_selection: values.lease_agreement_selection,
            });

            setShowAnimation(true);
          }}
          initialValues={{
            ...(data?.currentState ? { state: data.currentState } : {}),
          }}
        />
      ) : null}
    </StandaloneLAWizardLayout>
  );
};

const StandaloneLeaseAgreementWithContext = (...props) => (
  <StandaloneWizardProvider standAloneWizard>
    <StandaloneLeaseAgreement {...props} />
  </StandaloneWizardProvider>
);

export default StandaloneLeaseAgreementWithContext;
