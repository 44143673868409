import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Footer.module.scss';

const Footer = ({ align = 'center', layout = 'row', className, ...rest }) => (
  <div
    className={cx(
      styles.footer,
      styles[`align-${align}`],
      styles[`layout-${layout}`],
      className,
    )}
    {...rest}
  />
);

Footer.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  layout: PropTypes.oneOf(['row', 'col']),
  className: PropTypes.string,
};

export default Footer;
