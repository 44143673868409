import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import useWindowSize from '@rooks/use-window-size';
import { useHistory } from 'react-router-dom';

import Button from '../../../../../Button';
import { useConfig } from '../../../../../../providers/ConfigProvider';
import { smallScreenWidth } from '../../../../../../constants/media-breakpoints';
import { segmentTracking } from '../../../../../../services/utilities';

import styles from './RenterCheckInMessage.module.scss';

const formatComment = (comment) => {
  return comment.trim().split('\n').pop();
};

const RenterCheckInMessage = ({ message, isOwner }) => {
  const history = useHistory();
  const config = useConfig();
  const { innerWidth } = useWindowSize();
  const isMobile = innerWidth <= smallScreenWidth;

  const isGood = message?.metadata?.checkinStatus === 'GOOD';
  const listingId = message?.metadata?.listingId;
  const comment = formatComment(message?.data?.text);
  const hasComment = comment && comment !== '-';

  let sentAt = moment
    .utc(message?.sentAt * 1000)
    .format(isMobile ? 'MMM D' : 'MMMM D');
  sentAt = sentAt.charAt(0).toUpperCase() + sentAt.slice(1);

  const goToMaintenanceRequestsIndex = () => {
    segmentTracking('view_maintenance_request clicked', {
      location: 'Tenant Check-In In Messages',
    });
    const route = isOwner
      ? `properties/manage/${listingId}/maintenance`
      : `dashboard/maintenance`;
    history.push(`${config.PRIVATE_BASE_PATH}${route}`);
  };

  const emoji = isGood ? '😀' : '😔';
  return (
    <div
      className={cx(styles.container, {
        [styles.good]: isGood,
        [styles.bad]: !isGood,
      })}
    >
      <div className={styles.date}>{sentAt} check in</div>
      <div className={styles.answer}>
        <span className={styles.emoji}>{emoji}</span>
        {isGood ? 'Good' : 'Not Good'}
      </div>
      <div
        className={cx(styles.comment, {
          [styles.noComment]: !hasComment,
        })}
      >
        {hasComment ? comment : 'No comments.'}
      </div>
      <Button
        secondary
        className={styles.button}
        onClick={goToMaintenanceRequestsIndex}
      >
        View Maintenance Request
      </Button>
    </div>
  );
};

export default RenterCheckInMessage;
