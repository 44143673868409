/**
 * Retrieves all promo codes from cookies that start with 'promo_'
 * @returns {Object} Object with promo code names as keys and their values
 */
const getPromoCodes = () => {
  const cookies = document.cookie.split(';').map((cookie) => cookie.trim());
  const promoCodes = {};

  cookies.forEach((cookie) => {
    const [name, value] = cookie.split('=');
    if (name.startsWith('promo_')) {
      promoCodes[name] = value;
    }
  });

  return promoCodes;
};

export default getPromoCodes;
