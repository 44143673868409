import React from 'react';
const SkeletonSvgGradient = () => (
  <defs>
    <linearGradient id="skeletonSvgAnimation" x1="0" x2="100%">
      <stop offset="0%" stopColor="#e6e9f0" />
      <stop offset="50%" stopColor="#efefef" />
      <stop offset="100%" stopColor="#e6e9f0" />
      <animate
        attributeName="x2"
        dur="1400ms"
        from="0%"
        to="100%"
        repeatCount="indefinite"
      />
    </linearGradient>
  </defs>
);

export default SkeletonSvgGradient;
