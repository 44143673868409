import cloneDeepWith from 'lodash/cloneDeepWith';
import isPlainObject from 'lodash/isPlainObject';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';

const removeKeyDeep = (obj, keyToRemove) =>
  cloneDeepWith(obj, (value) => {
    if (isPlainObject(value)) {
      const withoutKey = omit(value, [keyToRemove]);
      return mapValues(withoutKey, (v) => removeKeyDeep(v, keyToRemove));
    }
  });

export default removeKeyDeep;
