import React, { memo } from 'react';
import PropTypes from 'prop-types';

const SuccessStatic = ({ width = 96, height = 97 }) => (
  <svg viewBox="0 0 96 97" width={width} height={height} fill="none">
    <circle opacity=".4" cx="48" cy="48.5" r="46" fill="#04BF8D" />
    <circle cx="47.5" cy="49" r="29.5" fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48 8.5c22.09 0 40 17.908 40 40s-17.91 40-40 40c-22.096 0-40-17.905-40-40s17.904-40 40-40Zm20.828 25.172a4 4 0 0 0-5.656 0L42 54.84l-9.172-9.168-.376-.333a4 4 0 0 0-5.28 5.99L38.465 62.62l.405.365a5 5 0 0 0 6.666-.365l23.292-23.293.333-.376a4 4 0 0 0-.333-5.28Z"
      fill="#009E74"
    />
  </svg>
);

SuccessStatic.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(SuccessStatic);
