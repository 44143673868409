import React, { useState } from 'react';
import { Icon } from '@tt/ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { useIsSmallScreen } from '../../../hooks/useIsSmallScreen';
import ThumbtackLogo from '../../../logos/ThumbtackLogo';
import { segmentTracking } from '../../../services/utilities/segment';
import Button from '../../Button';
import { H4 } from '../../Typography';

import ThumbtackIframeModal from './ThumbtackIframeModal';
import useThumbtackInfo from './useThumbtackInfo';

import styles from './ThumbtackCard.module.scss';

const ThumbtackCard = ({ className, category }) => {
  const { category: thumbtackCategory, iframeUrl } = useThumbtackInfo(category);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isSmallScreen = useIsSmallScreen();

  return (
    <div className={cx(styles.container, className)}>
      <ThumbtackIframeModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        iframeUrl={iframeUrl}
      />
      <div className={styles.content}>
        <div>
          <ThumbtackLogo className={styles.logo} />
          <H4 className={styles.title}>
            Find a {thumbtackCategory.name} specialist
          </H4>
          <p className={styles.subtitle}>
            Hire trusted, local pros with confidence.
          </p>
          <div className={styles.locationContainer}>
            <Icon name="location" size="sm" />
            <span className={styles.locationText}>Serves Denver</span>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            small
            className={styles.button}
            onClick={() => {
              segmentTracking('find_thumbtack_pro clicked', {
                location: 'maintenance request',
              });
              setIsModalOpen(true);
            }}
            dataQa="find-thumbtack-pro"
          >
            Find a Pro
          </Button>
        </div>
      </div>
      <div className={styles.socialProofContainer}>
        {[...Array(5)].map((_, index) => {
          const isLastStar = index === 4;
          return (
            <span key={index} className={styles.starWrapper}>
              <Icon
                name="star-filled"
                color="#069ED9"
                size="sm"
                className={styles.starIcon}
              />
              {isLastStar && <span className={styles.lastStarOverlay} />}
            </span>
          );
        })}
        <p className={styles.socialProof}>
          4.9 average rating
          {!isSmallScreen && ' | Trusted by over 4.5 million people'}
        </p>
      </div>
    </div>
  );
};

ThumbtackCard.propTypes = {
  className: PropTypes.string,
  category: PropTypes.string.isRequired,
};

export default ThumbtackCard;
