/**
 * Retrieves all experiment cookies and returns them in a structured format
 * @returns {Array<{name: string, value: string}>} Array of experiment names and their assigned variants
 */
function getCookieExperiments() {
  if (!document.cookie) {
    return [];
  }

  const cookies = document.cookie.split(';').map((cookie) => cookie.trim());

  const experimentCookies = cookies
    .filter((cookie) => cookie.startsWith('experiment_'))
    .map((cookie) => {
      const [fullName, value] = cookie.split('=');
      const name = fullName.replace('experiment_', '');

      return {
        name,
        value,
      };
    });

  // Get VWO cookies (format: _vis_opt_exp_EXPERIMENTID_combi)
  const vwoCookies = cookies
    .filter(
      (cookie) =>
        cookie.startsWith('_vis_opt_exp_') && cookie.includes('_combi'),
    )
    .map((cookie) => {
      const [fullName, value] = cookie.split('=');

      return {
        name: fullName,
        value,
      };
    });

  return [...experimentCookies, ...vwoCookies];
}

export default getCookieExperiments;
