import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import RedirectRoute from '../../components/RedirectRoute';
import componentLoader from '../../helpers/component-loader';
import TenantOnboarding from '../../layout/TenantOnboarding';
import RentersProfileProvider from '../../providers/RentersProfileProvider/RentersProfileProvider';

const RenterSettingsRoutes = componentLoader(
  () => import('./settings/routes.renters.settings'),
);

const RenterApplication = componentLoader(
  () => import('./application/RenterApplicationContainer'),
);

const RenterInsuranceComponent = componentLoader(
  () => import('./insurance/RenterInsurance'),
);

const RenterListingsComponent = componentLoader(
  () => import('./listings/RenterListingsContainer'),
);

const UploadPolicyComponent = componentLoader(
  () => import('./insurance/UploadPolicyContainer'),
);

const RentersDashboardPage = componentLoader(
  () => import('./dashboard/RentersDashboardPage'),
);

const RentersConditionReportPage = componentLoader(
  () => import('./condition_reports/RentersConditionReportPage'),
);

const IdentityVerificationComponent = componentLoader(
  () => import('./identity_verification/VerifyIdentityTU'),
);

const RenterProfilePrint = componentLoader(
  () => import('../owners/renter-profile/RenterProfilePrintContainer'),
);

const MessagesPage = componentLoader(
  () => import('../renters/messages/MessagesMiddleware'),
);

const MessagesPageLoading = componentLoader(
  () => import('../renters/messages/MessagesLoading'),
);

const RenterESignWelcome = componentLoader(
  () => import('./esignature/WelcomeComponent'),
);

const RenterESignDocument = componentLoader(
  () =>
    import(
      '../electronic-signature/SignatureProcessComponent/SignatureProcessComponent'
    ),
);

const RenterESignDocumentMobile = componentLoader(
  () =>
    import(
      '../electronic-signature/SignatureProcessComponentMobile/SignatureProcessComponentMobile'
    ),
);

const Setpassword = componentLoader(
  () => import('../auth/set_password/CommonSetPassword'),
);

const EmailPreferencesRoute = componentLoader(
  () => import('../email-preferences/EmailPreferencesRoute'),
);

const PaymentsRoute = componentLoader(
  () => import('./payments/routes.renter.payments'),
);

const CreateMaintenance = componentLoader(
  () => import('./maintenance/CreateMaintenancePage'),
);

const ViewMaintenance = componentLoader(
  () => import('./maintenance/ViewMaintenancePage'),
);

const MaintenanceFeedback = componentLoader(
  () => import('./maintenance/MaintenanceFeedbackPage'),
);

const CheckInPage = componentLoader(() => import('./check-in'));

const AddCreditCardPage = componentLoader(
  () => import('./payments/mobile/AddCreditCard'),
);

const VerifyIdPage = componentLoader(
  () => import('./payments/mobile/VerifyId'),
);

const PlaidAuthPage = componentLoader(() => import('./plaid-auth'));

const RenterHelpCenter = componentLoader(
  () => import('./help_center/HelpCenterPage'),
);

const SetupInsurance = componentLoader(
  () => import('./setup_insurance/SetupInsurance'),
);

const RenterRoutes = ({ match }) => {
  const { url: me } = match;
  return (
    <Switch>
      <Route path={`${me}/help_center`} component={RenterHelpCenter} />
      <Route path={`${me}/settings`} component={RenterSettingsRoutes} />
      <Route path={`${me}/payments`} component={PaymentsRoute} />
      <Route
        exact
        path={`${me}/insurance`}
        component={RenterInsuranceComponent}
      />
      <Route
        exact
        path={`${me}/insurance/setup/:step?`}
        component={SetupInsurance}
      />
      <Route
        exact
        path={`${me}/listings`}
        component={RenterListingsComponent}
      />
      <Route
        exact
        path={`${me}/insurance/upload/:renterId/:listingId`}
        render={(props) => <UploadPolicyComponent {...props} refetchRenter />}
      />
      <Route
        path={`${me}/dashboard`}
        render={(props) => (
          <RentersProfileProvider>
            <RentersDashboardPage {...props} />
          </RentersProfileProvider>
        )}
      />
      <Route
        path={`${me}/condition-report/fill/:id`}
        component={RentersConditionReportPage}
      />
      <Route
        exact
        path={`${me}/application/:applicationId`}
        component={RenterApplication}
      />

      <Route
        exact
        path={`${me}/identity_verification`}
        component={IdentityVerificationComponent}
      />

      <Route
        exact
        path={`${me}/profile-print/:applicationId`}
        component={RenterProfilePrint}
      />
      <Route path={`${me}/messages/:receiverUID?`} component={MessagesPage} />
      <Route path={`${me}/messages-loading`} component={MessagesPageLoading} />
      <Route
        exact
        path={`${me}/esignature/welcome/:signatureId`}
        component={RenterESignWelcome}
      />
      <Route
        exact
        path={`${me}/mobile/esignature/sign/:signatureId`}
        component={RenterESignDocumentMobile}
      />
      <Route
        exact
        path={`${me}/esignature/sign/:signatureId`}
        component={RenterESignDocument}
      />
      <Route exact path={`${me}/setpassword`} component={Setpassword} />
      <Route
        exact
        path={`${me}/email-preferences`}
        component={EmailPreferencesRoute}
      />
      <Route
        exact
        path={`${me}/maintenance/create`}
        component={CreateMaintenance}
      />
      <Route
        exact
        path={`${me}/maintenance/view/:maintenanceId`}
        component={ViewMaintenance}
      />
      <Route
        exact
        path={`${me}/maintenance/feedback/:maintenanceId`}
        component={MaintenanceFeedback}
      />
      <Route
        exact
        path={`${me}/mobile/add-card`}
        component={AddCreditCardPage}
      />
      <Route
        exact
        path={`${me}/mobile/verify-identity`}
        component={VerifyIdPage}
      />
      <Route
        exact
        path={`${me}/plaid-authentication`}
        component={PlaidAuthPage}
      />
      <Route
        path={`${me}/onboarding`}
        render={(props) => (
          <RentersProfileProvider>
            <TenantOnboarding {...props} />
          </RentersProfileProvider>
        )}
      />
      <Route exact path={`${me}/check-in/:leaseId`} component={CheckInPage} />
      <RedirectRoute to={`${me}/dashboard`} />
    </Switch>
  );
};

RenterRoutes.propTypes = {
  match: PropTypes.object,
};

export default withRouter(RenterRoutes);
