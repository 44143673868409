import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Sparkles = ({ color, ...props }) => (
  <svg width="24" height="24" fill="none" {...props}>
    <path
      d="M9.026 8.597c-.272 1.025-.64 1.772-1.209 2.346-.569.574-1.309.944-2.326 1.218 1.017.275 1.757.646 2.326 1.22.57.573.937 1.32 1.209 2.345.273-1.026.64-1.772 1.209-2.346.569-.574 1.309-.944 2.326-1.219-1.017-.274-1.757-.644-2.326-1.218-.57-.574-.937-1.32-1.209-2.346ZM18.5 4.444c-.29.97-.676 1.697-1.246 2.272-.57.574-1.291.962-2.254 1.255.963.292 1.685.68 2.254 1.254.57.574.956 1.302 1.246 2.271.29-.97.676-1.697 1.246-2.271.57-.574 1.291-.963 2.254-1.254-.963-.292-1.684-.681-2.254-1.255-.57-.575-.956-1.302-1.246-2.272Zm-2.568 11.014c-.488.492-1.107.825-1.932 1.076.825.25 1.444.583 1.932 1.075.489.492.82 1.116 1.068 1.946.248-.831.58-1.454 1.068-1.946.488-.493 1.107-.826 1.932-1.075-.825-.25-1.444-.584-1.932-1.076-.489-.492-.82-1.116-1.068-1.947-.248.832-.58 1.455-1.068 1.947Zm-8.844-5.25c.802-.809 1.119-2.206 1.425-4.667a.518.518 0 0 1 .45-.461.516.516 0 0 1 .574.453c.307 2.467.624 3.865 1.426 4.675.802.808 2.187 1.128 4.628 1.437a.518.518 0 0 1 .457.454.519.519 0 0 1-.448.579c-2.447.309-3.833.629-4.636 1.438-.802.809-1.119 2.205-1.425 4.666a.518.518 0 0 1-.45.462.517.517 0 0 1-.574-.453c-.307-2.467-.624-3.866-1.426-4.675-.802-.809-2.187-1.129-4.627-1.438a.518.518 0 0 1-.458-.453.519.519 0 0 1 .449-.58c2.446-.308 3.832-.628 4.635-1.437Z"
      fill={color}
    />
  </svg>
);

Sparkles.defaultProps = {
  width: 24,
  height: 24,
  color: 'currentColor',
};

Sparkles.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Sparkles);
