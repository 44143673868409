import { Experiments } from '../../../../constants/experiments';

export const getSidebarToShow = (
  gt3ExperimentVariant,
  onboardingAnswersExperiment,
) => {
  if (gt3ExperimentVariant === 'variant_1') {
    return 'gt3Variant1';
  } else if (gt3ExperimentVariant === 'variant_2') {
    return 'gt3Variant2';
  } else if (gt3ExperimentVariant === 'control') {
    return 'control';
  } else if (
    onboardingAnswersExperiment ===
    Experiments.GtOnePropertyNllSignupOnboardingAnswers.variants.variant_1
  ) {
    return Experiments.GtOnePropertyNllSignupOnboardingAnswers.variants
      .variant_1;
  }
};
