import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const DotsDropdown = ({ color, ...props }) => (
  <svg viewBox="0 0 4 17" {...props} fill={color}>
    <path
      id="Shape"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 4.52734C3.10457 4.52734 4 3.63191 4 2.52734C4 1.42277 3.10457 0.527344 2 0.527344C0.89543 0.527344 0 1.42277 0 2.52734C0 3.63191 0.89543 4.52734 2 4.52734ZM2 10.5273C3.10457 10.5273 4 9.63191 4 8.52734C4 7.42277 3.10457 6.52734 2 6.52734C0.89543 6.52734 0 7.42277 0 8.52734C0 9.63191 0.89543 10.5273 2 10.5273ZM2 16.5273C3.10457 16.5273 4 15.6319 4 14.5273C4 13.4228 3.10457 12.5273 2 12.5273C0.89543 12.5273 0 13.4228 0 14.5273C0 15.6319 0.89543 16.5273 2 16.5273Z"
      fill="#033A6D"
    />
  </svg>
);

DotsDropdown.defaultProps = {
  color: colors.text,
  width: 4,
  height: 17,
};

DotsDropdown.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(DotsDropdown);
