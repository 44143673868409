import { useConfig } from '../providers/ConfigProvider';

export const getPremiumPlanId = (user) => {
  const {
    PREMIUM_YEARLY_DEFAULT_SHORT_CODE,
    PREMIUM_YEARLY_TEST_109_SHORT_CODE,
    PREMIUM_YEARLY_TEST_119_SHORT_CODE,
    PREMIUM_YEARLY_TEST_139_SHORT_CODE,
    GBB_PREMIUM_YEARLY_149_SHORT_CODE,
    PREMIUM_YEARLY_TEST_199_SHORT_CODE,
    PREMIUM_PLAN_PRICE_DEFAULT,
    PREMIUM_PLAN_TEST_109_PRICE,
    PREMIUM_PLAN_TEST_119_PRICE,
    PREMIUM_PLAN_TEST_139_PRICE,
    PREMIUM_PLAN_TEST_149_PRICE,
    PREMIUM_PLAN_TEST_199_PRICE,
  } = useConfig(); // eslint-disable-line

  if (user?.premium_price_signup) {
    switch (user.premium_price_signup) {
      case PREMIUM_PLAN_PRICE_DEFAULT: {
        return PREMIUM_YEARLY_DEFAULT_SHORT_CODE;
      }

      case PREMIUM_PLAN_TEST_109_PRICE: {
        return PREMIUM_YEARLY_TEST_109_SHORT_CODE;
      }

      case PREMIUM_PLAN_TEST_119_PRICE: {
        return PREMIUM_YEARLY_TEST_119_SHORT_CODE;
      }

      case PREMIUM_PLAN_TEST_139_PRICE: {
        return PREMIUM_YEARLY_TEST_139_SHORT_CODE;
      }

      case PREMIUM_PLAN_TEST_149_PRICE: {
        return GBB_PREMIUM_YEARLY_149_SHORT_CODE;
      }

      case PREMIUM_PLAN_TEST_199_PRICE: {
        return PREMIUM_YEARLY_TEST_199_SHORT_CODE;
      }

      default: {
        return PREMIUM_YEARLY_DEFAULT_SHORT_CODE;
      }
    }
  }

  return PREMIUM_YEARLY_DEFAULT_SHORT_CODE;
};

export const getGBBPremiumPlanId = (user) => {
  const {
    PREMIUM_YEARLY_DEFAULT_SHORT_CODE,
    GBB_PRO_YEARLY_119_PRICE,
    GBB_PREMIUM_PLUS_YEARLY_299_PRICE,
    GBB_PREMIUM_PLUS_YEARLY_249_PRICE,
    GBB_PREMIUM_YEARLY_149_PRICE,
    GBB_PREMIUM_YEARLY_179_PRICE,
    GBB_PREMIUM_YEARLY_199_PRICE,
    GBB_PRO_YEARLY_119_SHORT_CODE,
    GBB_PREMIUM_PLUS_YEARLY_299_SHORT_CODE,
    GBB_PREMIUM_PLUS_YEARLY_249_SHORT_CODE,
    GBB_PREMIUM_YEARLY_149_SHORT_CODE,
    GBB_PREMIUM_YEARLY_179_SHORT_CODE,
    GBB_PREMIUM_YEARLY_199_SHORT_CODE,
  } = useConfig(); // eslint-disable-line

  if (user?.premium_price_signup) {
    switch (user.premium_price_signup) {
      case GBB_PRO_YEARLY_119_PRICE: {
        return GBB_PRO_YEARLY_119_SHORT_CODE;
      }

      case GBB_PREMIUM_PLUS_YEARLY_299_PRICE: {
        return GBB_PREMIUM_PLUS_YEARLY_299_SHORT_CODE;
      }

      case GBB_PREMIUM_PLUS_YEARLY_249_PRICE: {
        return GBB_PREMIUM_PLUS_YEARLY_249_SHORT_CODE;
      }

      case GBB_PREMIUM_YEARLY_149_PRICE: {
        return GBB_PREMIUM_YEARLY_149_SHORT_CODE;
      }

      case GBB_PREMIUM_YEARLY_179_PRICE: {
        return GBB_PREMIUM_YEARLY_179_SHORT_CODE;
      }

      case GBB_PREMIUM_YEARLY_199_PRICE: {
        return GBB_PREMIUM_YEARLY_199_SHORT_CODE;
      }

      default: {
        return PREMIUM_YEARLY_DEFAULT_SHORT_CODE;
      }
    }
  }

  return PREMIUM_YEARLY_DEFAULT_SHORT_CODE;
};

export const shortCodeToPlanId = (shortCode) => {
  const {
    GBB_PRO_YEARLY_119_SHORT_CODE,
    GBB_PREMIUM_PLUS_YEARLY_299_SHORT_CODE,
    GBB_PREMIUM_PLUS_YEARLY_249_SHORT_CODE,
    GBB_PREMIUM_YEARLY_149_SHORT_CODE,
    GBB_PREMIUM_YEARLY_179_SHORT_CODE,
    GBB_PREMIUM_YEARLY_199_SHORT_CODE,
    GBB_PRO_YEARLY_119_PLAN_ID,
    GBB_PREMIUM_PLUS_YEARLY_299_PLAN_ID,
    GBB_PREMIUM_PLUS_YEARLY_249_PLAN_ID,
    GBB_PREMIUM_YEARLY_149_PLAN_ID,
    GBB_PREMIUM_YEARLY_179_PLAN_ID,
    GBB_PREMIUM_YEARLY_199_PLAN_ID,
    PREMIUM_PLAN_ID_DEFAULT,
  } = useConfig(); // eslint-disable-line

  switch (shortCode) {
    case GBB_PRO_YEARLY_119_SHORT_CODE: {
      return GBB_PRO_YEARLY_119_PLAN_ID;
    }

    case GBB_PREMIUM_PLUS_YEARLY_299_SHORT_CODE: {
      return GBB_PREMIUM_PLUS_YEARLY_299_PLAN_ID;
    }

    case GBB_PREMIUM_PLUS_YEARLY_249_SHORT_CODE: {
      return GBB_PREMIUM_PLUS_YEARLY_249_PLAN_ID;
    }

    case GBB_PREMIUM_YEARLY_149_SHORT_CODE: {
      return GBB_PREMIUM_YEARLY_149_PLAN_ID;
    }

    case GBB_PREMIUM_YEARLY_179_SHORT_CODE: {
      return GBB_PREMIUM_YEARLY_179_PLAN_ID;
    }

    case GBB_PREMIUM_YEARLY_199_SHORT_CODE: {
      return GBB_PREMIUM_YEARLY_199_PLAN_ID;
    }

    default: {
      return PREMIUM_PLAN_ID_DEFAULT;
    }
  }
};

export default getPremiumPlanId;
