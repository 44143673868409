import React, { memo } from 'react';
import PropTypes from 'prop-types';

import SkeletonSvgGradient from '../../../../Skeleton/SkeletonSvgGradient';

const MobileSkeleton = (props) => (
  <svg width="100%" height="auto" viewBox="0 0 361 712" fill="none" {...props}>
    <SkeletonSvgGradient />
    <mask id="path-1-inside-1_13544_21054" fill="white">
      <path d="M0 0H361V142H0V0Z" />
    </mask>
    <path
      d="M0 1H361V-1H0V1Z"
      fill="#E6E9F0"
      mask="url(#path-1-inside-1_13544_21054)"
    />
    <path
      d="M16 20C16 17.7909 17.7909 16 20 16H76C78.2091 16 80 17.7909 80 20V28C80 30.2091 78.2091 32 76 32H20C17.7909 32 16 30.2091 16 28V20Z"
      fill="#E6E9F0"
    />
    <path
      d="M16 40C16 37.7909 17.7909 36 20 36H309C311.209 36 313 37.7909 313 40V52C313 54.2091 311.209 56 309 56H20C17.7909 56 16 54.2091 16 52V40Z"
      fill="#E6E9F0"
    />
    <path
      d="M16 64C16 61.7909 17.7909 60 20 60H204C206.209 60 208 61.7909 208 64V76C208 78.2091 206.209 80 204 80H20C17.7909 80 16 78.2091 16 76V64Z"
      fill="#E6E9F0"
    />
    <path
      d="M16 88C16 85.7909 17.7909 84 20 84H132C134.209 84 136 85.7909 136 88V100C136 102.209 134.209 104 132 104H20C17.7909 104 16 102.209 16 100V88Z"
      fill="#E6E9F0"
    />
    <path
      d="M16 112C16 109.791 17.7909 108 20 108H100C102.209 108 104 109.791 104 112V122C104 124.209 102.209 126 100 126H20C17.7909 126 16 124.209 16 122V112Z"
      fill="#E6E9F0"
    />
    <mask id="path-8-inside-2_13544_21054" fill="white">
      <path d="M0 142H361V284H0V142Z" />
    </mask>
    <path
      d="M0 143H361V141H0V143Z"
      fill="#E6E9F0"
      mask="url(#path-8-inside-2_13544_21054)"
    />
    <path
      d="M16 162C16 159.791 17.7909 158 20 158H76C78.2091 158 80 159.791 80 162V170C80 172.209 78.2091 174 76 174H20C17.7909 174 16 172.209 16 170V162Z"
      fill="#E6E9F0"
    />
    <path
      d="M16 182C16 179.791 17.7909 178 20 178H309C311.209 178 313 179.791 313 182V194C313 196.209 311.209 198 309 198H20C17.7909 198 16 196.209 16 194V182Z"
      fill="#E6E9F0"
    />
    <path
      d="M16 206C16 203.791 17.7909 202 20 202H204C206.209 202 208 203.791 208 206V218C208 220.209 206.209 222 204 222H20C17.7909 222 16 220.209 16 218V206Z"
      fill="#E6E9F0"
    />
    <path
      d="M16 230C16 227.791 17.7909 226 20 226H132C134.209 226 136 227.791 136 230V242C136 244.209 134.209 246 132 246H20C17.7909 246 16 244.209 16 242V230Z"
      fill="#E6E9F0"
    />
    <path
      d="M16 254C16 251.791 17.7909 250 20 250H100C102.209 250 104 251.791 104 254V264C104 266.209 102.209 268 100 268H20C17.7909 268 16 266.209 16 264V254Z"
      fill="#E6E9F0"
    />
    <mask id="path-15-inside-3_13544_21054" fill="white">
      <path d="M0 284H361V426H0V284Z" />
    </mask>
    <path
      d="M0 285H361V283H0V285Z"
      fill="#E6E9F0"
      mask="url(#path-15-inside-3_13544_21054)"
    />
    <path
      d="M16 304C16 301.791 17.7909 300 20 300H76C78.2091 300 80 301.791 80 304V312C80 314.209 78.2091 316 76 316H20C17.7909 316 16 314.209 16 312V304Z"
      fill="#E6E9F0"
    />
    <path
      d="M16 324C16 321.791 17.7909 320 20 320H309C311.209 320 313 321.791 313 324V336C313 338.209 311.209 340 309 340H20C17.7909 340 16 338.209 16 336V324Z"
      fill="#E6E9F0"
    />
    <path
      d="M16 348C16 345.791 17.7909 344 20 344H204C206.209 344 208 345.791 208 348V360C208 362.209 206.209 364 204 364H20C17.7909 364 16 362.209 16 360V348Z"
      fill="#E6E9F0"
    />
    <path
      d="M16 372C16 369.791 17.7909 368 20 368H132C134.209 368 136 369.791 136 372V384C136 386.209 134.209 388 132 388H20C17.7909 388 16 386.209 16 384V372Z"
      fill="#E6E9F0"
    />
    <path
      d="M16 396C16 393.791 17.7909 392 20 392H100C102.209 392 104 393.791 104 396V406C104 408.209 102.209 410 100 410H20C17.7909 410 16 408.209 16 406V396Z"
      fill="#E6E9F0"
    />
    <mask id="path-22-inside-4_13544_21054" fill="white">
      <path d="M0 426H361V568H0V426Z" />
    </mask>
    <path
      d="M0 427H361V425H0V427Z"
      fill="#E6E9F0"
      mask="url(#path-22-inside-4_13544_21054)"
    />
    <path
      d="M16 446C16 443.791 17.7909 442 20 442H76C78.2091 442 80 443.791 80 446V454C80 456.209 78.2091 458 76 458H20C17.7909 458 16 456.209 16 454V446Z"
      fill="#E6E9F0"
    />
    <path
      d="M16 466C16 463.791 17.7909 462 20 462H309C311.209 462 313 463.791 313 466V478C313 480.209 311.209 482 309 482H20C17.7909 482 16 480.209 16 478V466Z"
      fill="#E6E9F0"
    />
    <path
      d="M16 490C16 487.791 17.7909 486 20 486H204C206.209 486 208 487.791 208 490V502C208 504.209 206.209 506 204 506H20C17.7909 506 16 504.209 16 502V490Z"
      fill="#E6E9F0"
    />
    <path
      d="M16 514C16 511.791 17.7909 510 20 510H132C134.209 510 136 511.791 136 514V526C136 528.209 134.209 530 132 530H20C17.7909 530 16 528.209 16 526V514Z"
      fill="#E6E9F0"
    />
    <path
      d="M16 538C16 535.791 17.7909 534 20 534H100C102.209 534 104 535.791 104 538V548C104 550.209 102.209 552 100 552H20C17.7909 552 16 550.209 16 548V538Z"
      fill="#E6E9F0"
    />
  </svg>
);

MobileSkeleton.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(MobileSkeleton);
