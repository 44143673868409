import React, { useEffect, useState } from 'react';
import useWindowSize from '@rooks/use-window-size';
import cx from 'classnames';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';

import { smallScreenWidth } from '../../constants/media-breakpoints';
import FilterIcon from '../../icons/Filter';
import SearchIcon from '../../icons/Search';
import Button from '../Button';
import ButtonPopover from '../ButtonPopover';
import Checkbox from '../Checkbox';
import Divider from '../Divider';
import FlatButton from '../FlatButton';
import InputWithIcon from '../InputWithIcon';
import RadioButton from '../RadioButton';

import styles from './FilterGeneric.module.scss';

const FilterGeneric = ({
  className,
  icon = FilterIcon,
  iconProps,
  filterName,
  hideSearch,
  options,
  allLabel = 'All',
  selected = [],
  onChange = () => null,
  customOptions,
  scrollable = true,
  hideMultiline = true,
  loading,
  staticFilterName,
  showApplyFiltersButton,
  closeOnButtonsClick = false,
}) => {
  const [search, setSearch] = useState('');
  const [custom, setCustom] = useState(null);
  const [localSelected, setLocalSelected] = useState([]);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const handleSelectionChange = showApplyFiltersButton
    ? setLocalSelected
    : onChange;
  const currentValue = showApplyFiltersButton ? localSelected : selected;

  useEffect(() => {
    if (showApplyFiltersButton && !isEqual(selected, localSelected)) {
      setLocalSelected(selected);
    }
  }, [JSON.stringify(selected)]);

  const applyFilters = () => {
    onChange(localSelected);
    if (closeOnButtonsClick) {
      setPopoverOpen(false);
    }
  };

  const clearFilters = () => {
    onChange([]);
    setLocalSelected([]);
    setCustom(null);
    if (closeOnButtonsClick) {
      setPopoverOpen(false);
    }
  };

  const filterOptions = options.filter(
    (option) => option.label.toLowerCase().indexOf(search) > -1,
  );

  const { innerWidth } = useWindowSize();

  let scrollEffect = false;
  // On desktop we show scroll to users when they have more than 8 items
  // On mobile we show scroll to users when they have more than 5 items
  if (innerWidth > smallScreenWidth) {
    if (options.length > 8) {
      scrollEffect = true;
    }
  } else if (options.length > 5) {
    scrollEffect = true;
  }

  return (
    <ButtonPopover
      className={cx(styles.wrapper, className)}
      customAction={(props) => (
        <Button
          loading={loading}
          className={cx(styles.button, {
            [styles.selected]: currentValue?.length > 0,
          })}
          icon={icon}
          iconProps={{
            className: styles.icon,
            width: 15,
            height: 15,
            ...iconProps,
          }}
          {...props}
        >
          {currentValue?.length === 0 && !staticFilterName
            ? `All ${filterName}`
            : filterName}
          {currentValue?.length > 0 && custom === null && (
            <span className={styles.selectedInfo}>{currentValue.length}</span>
          )}
        </Button>
      )}
      optionsClassName={styles.optionsContainer}
      opened={popoverOpen}
      setOpened={setPopoverOpen}
    >
      <div
        className={cx(styles.container, {
          [styles.searchHiden]: hideSearch,
        })}
      >
        <InputWithIcon
          id="search"
          placeholder="Search..."
          className={cx(styles.search, {
            [styles.hideSearch]: hideSearch,
          })}
          icon={SearchIcon}
          value={search}
          onChange={(e) => {
            const val = e.target.value.toLowerCase();
            setSearch(val);
          }}
        />

        <div className={styles.radioContainer}>
          <RadioButton
            className={styles.radio}
            label={allLabel}
            checked={currentValue?.length === 0 && !custom}
            onChange={() => {
              handleSelectionChange([]);
              setCustom(null);
            }}
          />
          {customOptions?.map(({ label, onChange: radioOnChange }, index) => (
            <RadioButton
              key={label}
              className={styles.radio}
              label={label}
              checked={index === custom}
              onChange={() => {
                radioOnChange();
                setCustom(index);
              }}
            />
          ))}
        </div>

        {filterOptions.length > 0 && (
          <>
            <Divider className={styles.divider} />

            <div
              className={cx(styles.checkboxContainer, {
                [styles.scrollable]: scrollable && scrollEffect,
              })}
            >
              {filterOptions.map((option) => (
                <Checkbox
                  id={`${option.value}`}
                  key={option.value}
                  value={option.value}
                  label={option.label}
                  labelClassName={cx({
                    [styles.checkboxLabel]: hideMultiline,
                  })}
                  checked={
                    !!currentValue?.includes(option.value) && custom === null
                  }
                  input={{
                    onChange: () => {
                      if (custom !== null) {
                        handleSelectionChange([option.value]);
                      } else if (currentValue?.includes(option.value)) {
                        handleSelectionChange(
                          currentValue.filter((op) => op !== option.value),
                        );
                      } else {
                        handleSelectionChange([...currentValue, option.value]);
                      }
                      setCustom(null);
                    },
                  }}
                  className={styles.checkbox}
                />
              ))}
            </div>
          </>
        )}

        {showApplyFiltersButton && (
          <>
            <Divider className={styles.divider} text="" />

            <div className={styles.filterButtons}>
              <Button onClick={applyFilters} className={styles.btnApply}>
                APPLY FILTERS
              </Button>

              <FlatButton onClick={clearFilters} className={styles.btnClear}>
                CLEAR FILTERS
              </FlatButton>
            </div>
          </>
        )}
      </div>
    </ButtonPopover>
  );
};

FilterGeneric.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]),
  iconProps: PropTypes.object,
  filterName: PropTypes.string.isRequired,
  hideSearch: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ).isRequired,
  allLabel: PropTypes.string,
  selected: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  customOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
    }),
  ),
  scrollable: PropTypes.bool,
  hideMultiline: PropTypes.bool,
  loading: PropTypes.bool,
  staticFilterName: PropTypes.bool,
  showApplyFiltersButton: PropTypes.bool,
  closeOnButtonsClick: PropTypes.bool,
};

export default FilterGeneric;
