import React from 'react';
import PropTypes from 'prop-types';

import CreditCard from '../../../../icons/CreditCard';
import CreditCardAmex from '../../../../icons/CreditCardAmex';
import CreditCardDiscover from '../../../../icons/CreditCardDiscover';
import CreditCardMasterCard from '../../../../icons/CreditCardMasterCard';
import CreditCardVisa from '../../../../icons/CreditCardVisa';

const cards = {
  'american express': CreditCardAmex,
  'amex': CreditCardAmex,
  'visa': CreditCardVisa,
  'discover': CreditCardDiscover,
  'mastercard': CreditCardMasterCard,
};

const PaymentCardIcon = ({ brand, className }) => {
  const lowerCaseBrand = brand ? brand.toLowerCase() : null;
  // eslint-disable-next-line no-useless-assignment
  const Card = cards[lowerCaseBrand] || CreditCard;
  return <Card className={className} />;
};

PaymentCardIcon.propTypes = {
  brand: PropTypes.string,
  className: PropTypes.string,
};

export default PaymentCardIcon;
