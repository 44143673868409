import React from 'react';

import ConditionReportsList from '../../owners/condition-report/ConditionReportsList';

import styles from './ConditionReportsTab.module.scss';

const ConditionReportsTab = () => {
  return (
    <ConditionReportsList classNameMobile={styles.containerMobile} hideTitle />
  );
};

export default ConditionReportsTab;
