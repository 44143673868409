import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';

import styles from './EditLeaseAgreementModal.module.scss';

const EditLeaseAgreementModal = ({
  className,
  containerClassName,
  open,
  onClose,
  onGoBack,
  onEdit,
}) => {
  return (
    <Modal
      open={open}
      className={cx(styles.modal, className)}
      containerClassName={cx(styles.modalContainer, containerClassName)}
      onClose={onClose}
    >
      <h2 className={styles.title}>Do you want to make edits?</h2>
      <p className={styles.text}>
        After you make changes, you will need to resend the lease agreement for
        signatures.{' '}
        <b>Anyone who has already signed will need to do so again</b> with the
        updated version.
      </p>
      <div className={styles.btnWrapper}>
        <Button className={styles.btn} secondary onClick={onGoBack}>
          Go Back
        </Button>
        <Button className={styles.btn} onClick={onEdit}>
          Edit & Redo
        </Button>
      </div>
    </Modal>
  );
};

EditLeaseAgreementModal.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onGoBack: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default EditLeaseAgreementModal;
