import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import getFieldError from '../../validators/get-field-error';
import Label from '../Label';

import Icon from './Icon';

import styles from './SelectField.module.scss';

const SelectField = ({
  id,
  disabled = false,
  options = [],
  input,
  label,
  labelProps,
  iconProps = {},
  className,
  selectClassName,
  style,
  empty,
  emptyLabel = '',
  meta = {},
  dataQa,
  ...otherProps
}) => {
  const error = getFieldError(meta);
  return (
    <div
      className={cx(styles.container, className, {
        [styles.disabled]: disabled,
      })}
      style={style}
    >
      {label && (
        <Label htmlFor={id} {...labelProps}>
          {label}
        </Label>
      )}
      <div className={styles.wrap}>
        <select
          className={cx(styles.select, selectClassName, {
            [styles.invalid]: !!error,
            [styles.disabled]: disabled,
          })}
          id={id}
          disabled={disabled}
          data-qa={dataQa}
          {...input}
          {...otherProps}
        >
          {empty ? <option value="">{emptyLabel}</option> : null}
          {options &&
            options.map((opt) => (
              <option key={`${id}_${opt.value}`} value={opt.value}>
                {opt.label}
              </option>
            ))}
        </select>
        <Icon {...iconProps} className={cx(styles.icon, iconProps.className)} />
      </div>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

SelectField.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  input: PropTypes.object,
  style: PropTypes.object,
  labelProps: PropTypes.object,
  iconProps: PropTypes.object,
  className: PropTypes.string,
  selectClassName: PropTypes.string,
  meta: PropTypes.object,
  empty: PropTypes.bool,
  emptyLabel: PropTypes.string,
  dataQa: PropTypes.string,
};

export default SelectField;
