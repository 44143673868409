import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Label.module.scss';

const Label = ({
  children,
  className,
  htmlFor,
  hint,
  sublabel,
  hintClassName,
  ...otherProps
}) => (
  <>
    <label
      className={cx(styles.label, className)}
      htmlFor={htmlFor}
      {...otherProps}
    >
      {children}
      {hint && <span className={cx(styles.hint, hintClassName)}>{hint}</span>}
    </label>
    {sublabel && <span className={cx(styles.subLabel)}>{sublabel}</span>}
  </>
);

Label.propTypes = {
  htmlFor: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  sublabel: PropTypes.string,
  hintClassName: PropTypes.string,
};

export default Label;
