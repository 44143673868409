import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Avatar.module.scss';

const Avatar = ({
  size = 'normal',
  color = 'grey',
  initials,
  src,
  icon,
  className,
  children,
}) => {
  let content = children;
  if (src) {
    content = <img className={styles.image} src={src} />;
  } else if (initials) {
    content = <span className={styles.initials}>{initials}</span>;
  } else if (icon) {
    content = <div className={styles.icon}>{icon}</div>;
  }

  return (
    <div
      className={cx(
        styles.avatarParent,
        className,
        styles[`size-${size}`],
        styles[`color-type-${color}`],
      )}
    >
      {content}
    </div>
  );
};

Avatar.propTypes = {
  size: PropTypes.oneOf(['smaller', 'small', 'normal', 'large', 'larger']),
  color: PropTypes.oneOf([
    'blue',
    'light-blue',
    'green',
    'teal',
    'orange',
    'red',
    'yellow',
    'grey',
    'white',
  ]),
  initials: PropTypes.string,
  src: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.node,
};

export default Avatar;
