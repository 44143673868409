import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import img from '../../assets/reihub/mobile-acc-nav.png';
import { useUserFields } from '../../core/TTgraphql';
import SuccessStatic from '../../icons/SuccessStatic';
import { segmentTracking } from '../../services/utilities';
import BulletPoint from '../BulletPoint';
import Button from '../Button';
import Card from '../Card';
import FlatButton from '../FlatButton';
import ReiHubLoadingModal, {
  useOpenREIHub,
} from '../ReiHubSubscription/ReiHubLoadingModal';

import AccountingSuccessAnimation from './AccountingSuccessAnimation';

import styles from './REIHubSubscriptionSuccess.module.scss';

const REIHubSubscriptionSuccess = ({
  onClose,
  onShowFinalHintClose,
  className,
  disableFreeTrial = false,
}) => {
  const [showFinalHint, setShowFinalHint] = useState(false);

  const { user: { reihub_subscription_subscribed } = {} } = useUserFields([
    'reihub_subscription_subscribed',
  ]);

  const [isModalOpen, setOpenREIHub] = useOpenREIHub({
    isDemoMode: !reihub_subscription_subscribed,
  });

  const loginToReiHub = async () => {
    segmentTracking('go_to_reihub clicked', {
      location: 'reihub purchase success screen ',
    });

    await setOpenREIHub();

    if (
      window.location.pathname === '/owners/payments/accounting' ||
      window.location.pathname === '/owners/payments/reports'
    ) {
      setShowFinalHint(true);
    } else {
      onClose?.();
    }
  };

  const listItems = [
    {
      label: 'Seamlessly sync your data',
      description:
        'TurboTenant will automatically send your properties and rent payments to REI Hub.',
    },
    {
      label: 'Simplify your bookkeeping',
      description:
        'Review your info, set preferences, and link accounts to import transactions easily.',
    },
    {
      label: 'Track your growth',
      description:
        'Manage properties with confidence—subscription updates automatically reflect your portfolio.',
    },
  ];

  return showFinalHint ? (
    <Card className={cx(styles.finalHintCard, className)}>
      <img src={img} alt="REIHub mobile navigation" />
      <h3>You can access REI Hub in the future by clicking “Accounting”</h3>
      <Button
        className={styles.actionButton}
        primary
        onClick={() => {
          onShowFinalHintClose?.();
        }}
      >
        {disableFreeTrial ? 'Sounds Good' : 'Go To My Dashboard'}
      </Button>
    </Card>
  ) : (
    <>
      <AccountingSuccessAnimation />
      <Card className={cx(styles.card, className)}>
        <div className={styles.banner}>
          <SuccessStatic />
        </div>
        <div className={styles.content}>
          <h5>Success! Get started simplifying your bookkeeping.</h5>
          {listItems.map((item) => (
            <BulletPoint
              key={item.label}
              type="checkmark"
              label={item.label}
              description={item.description}
              color="primary"
              numericColor="white"
              size="medium"
            />
          ))}
          <Button
            className={styles.actionButton}
            primary
            onClick={loginToReiHub}
          >
            GO TO REI HUB
          </Button>
          <FlatButton
            className={styles.setupLaterBtn}
            onClick={() => {
              segmentTracking('setup_reihub_later clicked', {
                location: 'reihub purchase success screen ',
              });

              if (window.location.pathname === '/owners/dashboard') {
                onClose?.();
                return;
              }

              setShowFinalHint(true);
            }}
          >
            set up rei hub later
          </FlatButton>
        </div>

        <ReiHubLoadingModal
          open={isModalOpen}
          isDemoMode={!reihub_subscription_subscribed}
        />
      </Card>
    </>
  );
};

REIHubSubscriptionSuccess.propTypes = {
  onClose: PropTypes.func,
  onShowFinalHintClose: PropTypes.func,
  className: PropTypes.string,
  disableFreeTrial: PropTypes.bool,
};

export default REIHubSubscriptionSuccess;
