import React, { useEffect } from 'react';
import { bool, func, object, string } from 'prop-types';

import Button from '../../../components/Button';
import Card from '../../../components/Card';
import LoadingScreen from '../../../components/LoadingScreen';
import { useSuccessToast } from '../../../components/Toast';
import { H2, Paragraph } from '../../../components/Typography';
import * as authService from '../../../core/auth/authService';
import getDeviceSignature from '../../../helpers/get-device-signature';
import { useConfig } from '../../../providers/ConfigProvider';

import styles from './ConfirmEmailComponent.module.scss';

const ConfirmEmail = ({ history, token, confirmEmail, loading }) => {
  const config = useConfig();
  const showMessage = useSuccessToast();

  const login = (res) => {
    authService.login(res.token);
    if (config.IS_OWNER && res.onboarding_completed === false) {
      history.replace('/onboarding/process'); // go to onboarding flow first
    } else {
      history.replace('/');
    }
  };

  useEffect(() => {
    const handleConfirmEmail = async () => {
      const deviceSignature = getDeviceSignature();
      const confirmParams = { token, deviceSignature };
      try {
        const verifyEmail = await confirmEmail(confirmParams);
        if (verifyEmail.token) {
          showMessage('Email successfully confirmed!');
          login(verifyEmail);
        } else {
          const message = config.IS_OWNER
            ? 'You already confirmed your email. Login in to find your best tenant yet.'
            : 'You already confirmed your email. Login in to access your account.';
          showMessage(message);
          history.replace('/');
        }
      } catch {
        history.replace('/');
      }
    };

    if (!token) {
      history.replace('/auth/login');
      return;
    }
    handleConfirmEmail();
  }, [token, history, confirmEmail, config]);

  const renderVerificationContent = () => (
    <>
      <H2>Email not confirmed</H2>
      <Paragraph className={styles.text}>
        The provided token is invalid
      </Paragraph>
      <Button
        className={styles.button}
        onClick={() => history.replace('/auth/login')}
      >
        OK
      </Button>
    </>
  );

  return (
    <div className={styles.container}>
      {loading ? (
        <LoadingScreen loading={loading} />
      ) : (
        <Card className={styles.card}>{renderVerificationContent()}</Card>
      )}
    </div>
  );
};

ConfirmEmail.propTypes = {
  history: object,
  loading: bool,
  confirmEmail: func,
  token: string,
};

export default ConfirmEmail;
