// THIS COMPONENT IS JUST FOR TESTING PURPOSES DURING THE PILOT

import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import Button from '../../components/Button';
import LoadingScreen from '../../components/LoadingScreen';
import { useErrorToast } from '../../components/Toast';

import reportGQL from './graphql/getRentButterReport.graphql';

import styles from './RentButterReport.module.scss';

const RentButterReport = ({ match }) => {
  const { data, loading, error } = useQuery(reportGQL, {
    variables: { applicationId: match.params.applicationId },
    skip: !match.params.applicationId,
  });

  const showErrorToast = useErrorToast();

  useEffect(() => {
    if (error) {
      showErrorToast(error.message);
    }
  }, [error]);

  const reports = data?.viewer?.rentButterReport?.reports;
  const pdfLink = data?.viewer?.rentButterReport?.pdfUrl;
  const pdfTime = data?.viewer?.rentButterReport?.pdfFetchedIn;
  const reportTime = data?.viewer?.rentButterReport?.reportPulledIn;

  return (
    <LoadingScreen loading={loading} className={styles.container}>
      <div className={styles.actions}>
        <Button
          onClick={() => window.open(pdfLink, '_blank')}
          style={{ width: '200px' }}
        >
          DOWNLOAD PDF
        </Button>
      </div>
      <div className={styles.actions}>
        <h3>PDF Fetched In: {pdfTime}</h3>
        <h3>Report Pulled In: {reportTime}</h3>
      </div>
      <h1>Credit & Eviction Report</h1>
      {/* Credit Report Section */}
      <h2>Credit Report</h2>
      <p>
        <strong>Generated On:</strong> {reports?.credit?.dateGenerated || 'N/A'}
      </p>
      <p>
        <strong>Name:</strong>{' '}
        {reports?.credit?.subject?.names?.[0]?.first || 'N/A'}{' '}
        {reports?.credit?.subject?.names?.[0]?.last || 'N/A'}
      </p>
      <p>
        <strong>DOB:</strong> {reports?.credit?.subject?.dob || 'N/A'}
      </p>
      <p>
        <strong>SSN Match:</strong>{' '}
        {reports?.credit?.subject?.ssn_match_indicator || 'N/A'}
      </p>
      {/* Employment */}
      <h2>Employment</h2>
      <table border="1">
        <thead>
          <tr>
            <th>Source</th>
            <th>Employer</th>
            <th>Date on File Since</th>
            <th>Date Effective</th>
          </tr>
        </thead>
        <tbody>
          {reports?.credit?.subject?.employment?.map((employment, index) => (
            <tr key={index}>
              <td>{employment.source}</td>
              <td>{employment.employer?.unparsed}</td>
              <td>{employment.dateOnFileSince}</td>
              <td>{employment.dateEffective}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Addresses */}
      <h2>Addresses</h2>
      <table border="1">
        <thead>
          <tr>
            <th>Date</th>
            <th>Status</th>
            <th>Street</th>
            <th>City</th>
            <th>State</th>
            <th>Zip</th>
          </tr>
        </thead>
        <tbody>
          {reports?.credit?.subject?.addresses?.map((address, index) => (
            <tr key={index}>
              <td>{address.date}</td>
              <td>{address.status}</td>
              <td>{address.street}</td>
              <td>{address.city}</td>
              <td>{address.state}</td>
              <td>{address.zip}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Credit Status */}
      <h2>Credit Status</h2>
      {Object.entries(reports?.credit?.creditStatus || {}).map(
        ([key, value]) => (
          <p key={key}>
            <strong>{key}:</strong> {value ? 'Yes' : 'No'}
          </p>
        ),
      )}
      {/* Credit Scores */}
      <h2>Credit Scores</h2>
      {Object.entries(reports?.credit?.scores || {}).map(([key, value]) => (
        <p key={key}>
          <strong>{key} Score:</strong> {value || 'N/A'}
        </p>
      ))}
      {/* Credit Factors */}
      <h2>Credit Factors</h2>
      <table border="1">
        <thead>
          <tr>
            <th>Rank</th>
            <th>Code</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {reports?.credit?.factors?.resident2?.map((factor, index) => (
            <tr key={index}>
              <td>{factor.rank}</td>
              <td>{factor.code}</td>
              <td>{factor.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Trades */}
      <h2>Trades</h2>
      <table border="1">
        <thead>
          <tr>
            <th>Grantor</th>
            <th>Credit Limit</th>
            <th>Current Balance</th>
            <th>Past Due</th>
            <th>Utilization</th>
          </tr>
        </thead>
        <tbody>
          {reports?.credit?.trades?.map((trade, index) => (
            <tr key={index}>
              <td>{trade.grantor}</td>
              <td>{trade.creditLimit}</td>
              <td>{trade.currentBalance}</td>
              <td>{trade.pastDue}</td>
              <td>{trade.utilization}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Payment History */}
      <h2>Payment History</h2>
      <table className="min-w-full bg-white border border-gray-300" border="1">
        <thead>
          <tr className="bg-gray-200">
            <th className="px-4 py-2 border">Grantor</th>
            <th className="px-4 py-2 border">Date</th>
            <th className="px-4 py-2 border">Number of Payments</th>
          </tr>
        </thead>
        <tbody>
          {reports?.credit?.paymentHistory?.map((entry, index) =>
            entry.pattern.map((date, dateIndex) => (
              <tr
                key={`${index}-${dateIndex}`}
                className="odd:bg-white even:bg-gray-100"
              >
                {dateIndex === 0 && (
                  <td
                    rowSpan={entry.pattern.length}
                    className="border border-gray-300 px-4 py-2 font-semibold text-center"
                  >
                    {entry.grantor}
                  </td>
                )}
                <td className="border border-gray-300 px-4 py-2 text-center">
                  {new Date(date.x).toDateString()}
                </td>
                <td className="border border-gray-300 px-4 py-2 text-center">
                  {date.y}
                </td>
              </tr>
            )),
          )}
        </tbody>
      </table>
      {/* Inquiries */}
      <h2>Inquiries</h2>
      <table border="1">
        <thead>
          <tr>
            <th>Type</th>
            <th>Years</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(reports?.credit?.inquiries || {}).map(
            ([type, inquiries], index) => (
              <tr key={index}>
                <td>{type}</td>
                <td>{JSON.stringify(inquiries)}</td>
              </tr>
            ),
          )}
        </tbody>
      </table>
      {/* Collections */}
      <h2>Collections</h2>
      <table border="1">
        <thead>
          <tr>
            <th>Grantor</th>
            <th>Original Balance</th>
            <th>Current Balance</th>
            <th>Date Opened</th>
          </tr>
        </thead>
        <tbody>
          {reports?.credit?.collections?.map((collection, index) => (
            <tr key={index}>
              <td>{collection.grantor}</td>
              <td>{collection.original_balance}</td>
              <td>{collection.current_balance}</td>
              <td>{collection.date_opened}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Bankruptcies: */}
      <h2>Bankruptcies:</h2>
      <table border="1">
        <thead>
          <tr>
            <th>Attorney</th>
            <th>Filed</th>
            <th>Type</th>
            <th>Designation</th>
          </tr>
        </thead>
        <tbody>
          {reports?.credit?.bankruptcies?.map((br, index) => (
            <tr key={index}>
              <td>{br.attorney}</td>
              <td>{br.filed}</td>
              <td>{br.type}</td>
              <td>{br.designation}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Bankruptcies: */}
      <h2>Bankruptcies Full</h2>
      <table border="1">
        <thead>
          <tr>
            <th>Attorney</th>
            <th>Filed</th>
            <th>Type</th>
            <th>Designation</th>
            <th>Paid</th>
            <th>Reported</th>
            <th>Docket</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          {reports?.credit?.bankruptcies_full?.map((br, index) => (
            <tr key={index}>
              <td>{br.attorney}</td>
              <td>{br.filed}</td>
              <td>{br.type}</td>
              <td>{br.designation}</td>
              <td>{br.paid}</td>
              <td>{br.reported}</td>
              <td>{br.docket}</td>
              <td>{br.source}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Eviction Records Section */}
      <h2>Eviction Records</h2>
      {reports?.eviction?.records?.length > 0 ? (
        <table border="1">
          <thead>
            <tr>
              <th>Name</th>
              <th>Address</th>
              <th>City</th>
              <th>State</th>
              <th>Zip</th>
              <th>Court</th>
              <th>Docket</th>
              <th>Plaintiff</th>
              <th>Type</th>
              <th>Judge Date</th>
              <th>Judge Amount</th>
              <th>Filing Date</th>
            </tr>
          </thead>
          <tbody>
            {reports?.eviction?.records?.map((record, index) => (
              <tr key={index}>
                <td>{record.name}</td>
                <td>{record.address}</td>
                <td>{record.city}</td>
                <td>{record.state}</td>
                <td>{record.zip}</td>
                <td>{record.court}</td>
                <td>{record.docket}</td>
                <td>{record.plaintiff}</td>
                <td>{record.type}</td>
                <td>{record.judge_date}</td>
                <td>{record.judge_amount}</td>
                <td>{record.filing_date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No Eviction Records</p>
      )}
      <h2>Criminal Records</h2>
      <table border="1">
        <thead>
          <tr>
            <th>Provider</th>
            <th>Report ID</th>
            <th>Name</th>
            <th>Date of Birth</th>
            <th>Image</th>
            <th>Category</th>
            <th>Source</th>
            <th>Sex</th>
            <th>Race</th>
            <th>Hair</th>
            <th>Eyes</th>
            <th>Weight</th>
            <th>Height</th>
            <th>Scars</th>
            <th>Case Number</th>
            <th>State</th>
            <th>Jurisdiction</th>
            <th>Comments</th>
            <th>Offenses</th>
          </tr>
        </thead>
        <tbody>
          {reports?.criminal?.records?.map((record, index) => (
            <tr key={index}>
              <td>{record.provider}</td>
              <td>{record.report_id}</td>
              <td>{record.name}</td>
              <td>{record.dob || 'N/A'}</td>
              <td>
                {record.image ? (
                  <img
                    src={record.image}
                    alt="Criminal"
                    style={{ maxWidth: '100px', height: 'auto' }}
                  />
                ) : (
                  'N/A'
                )}
              </td>
              <td>{record.category}</td>
              <td>{record.source}</td>
              <td>{record.sex}</td>
              <td>{record.race}</td>
              <td>{record.hair || 'N/A'}</td>
              <td>{record.eyes || 'N/A'}</td>
              <td>{record.weight || 'N/A'}</td>
              <td>{record.height || 'N/A'}</td>
              <td>{record.scars || 'N/A'}</td>
              <td>{record.caseNumber || 'N/A'}</td>
              <td>{record.state || 'N/A'}</td>
              <td>{record.jurisdiction || 'N/A'}</td>
              <td>{record.comments || 'N/A'}</td>
              <td>
                {record.offenses.map((offense, i) => (
                  <div key={i}>
                    <strong>{offense.Description}</strong>
                    <br />
                    Case Type: {offense.CaseType || 'N/A'}
                    <br />
                    Filing Date: {offense.FilingDate || 'N/A'}
                    <br />
                    Disposition: {offense.Disposition || 'N/A'}
                    <br />
                    Disposition Date: {offense.DispositionDate || 'N/A'}
                    <br />
                    Court: {offense.Court || 'N/A'}
                    <br />
                    Conviction Location: {offense.ConvictionLocation || 'N/A'}
                    <br />
                    Statute: {offense.Statute || 'N/A'}
                    <br />
                    <hr />
                  </div>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </LoadingScreen>
  );
};

RentButterReport.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      applicationId: PropTypes.string,
    }),
  }).isRequired,
};

export default RentButterReport;
