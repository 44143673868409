import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import cx from 'classnames';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import FilterGeneric from '../../../components/FilterGeneric';
import allDocumentsFiltersQuery from '../../../graphql/queries/all_documents/allDocumentsFilters.graphql';
import getDocumentCategory from '../../../helpers/getDocumentCategory';
import parseListingsFilters from '../../../helpers/parseListingsFilters';
import Lease from '../../../icons/Lease';
import Documents from '../../../icons/streamline/Documents';
import ESign from '../../../icons/streamline/Esign';
import Padlock from '../../../icons/streamline/Lock';
import DropdownMenu from '../../DropdownMenu';
import PropertiesFilter from '../../PropertiesFilter';
import RadioButton from '../../RadioButton';

import styles from './AllDocumentsFilters.module.scss';

const AllDocumentsFilters = ({
  filters,
  onFilterChange,
  sortConfig,
  onSortingChange,
  onResetFilters,
  areFiltersActive,
}) => {
  const [getFilters, { loading: loadingFilters, error, data: filtersData }] =
    useLazyQuery(allDocumentsFiltersQuery);

  useEffect(() => {
    if (loadingFilters || error || filtersData) return;

    getFilters();
  }, []);

  const parseFilters = (data) => {
    return data.map((item) => {
      return {
        ...item,
        label: item.name,
        value: item.id,
        children_filters: item.children_filters
          ? parseFilters(item.children_filters)
          : null,
      };
    });
  };

  const esignOptions = [
    { label: 'In Progress', value: 'IN_PROGRESS' },
    { label: 'Completed', value: 'COMPLETED' },
    { label: 'Draft', value: 'DRAFT' },
    { label: 'None', value: 'NONE' },
  ];

  const isSharedOptions = [
    { label: 'Private', value: false },
    { label: 'Shared', value: true },
  ];

  const listings = get(filtersData, 'filters.documentsPage.listings', []);
  const leases = get(filtersData, 'filters.documentsPage.leases', []);
  const categories = get(
    filtersData,
    'filters.documentsPage.categories',
    [],
  ).map((item) => {
    return {
      ...item,
      id: item.name,
      name: getDocumentCategory(item.name),
    };
  });

  return (
    <div className={styles.container}>
      <div className={styles.filtersWrapper}>
        {/* TODO search in v2 */}
        {/* {showSearch && (
          <PlainInputWithIcon
            value={localSearchValue}
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
            placeholder="Search"
            icon={Search}
            className={styles.searchField}
            id="searchbox"
          />
        )} */}
        <FilterGeneric
          className={cx(styles.documentsFilter, styles.categoriesFilter)}
          icon={Documents}
          loading={loadingFilters}
          filterName="Categories"
          staticFilterName
          allLabel="All Categories"
          hideSearch={categories.length < 10}
          options={parseFilters(categories)}
          onChange={(value) => onFilterChange('categories', value)}
          selected={filters.categories}
          showApplyFiltersButton
          closeOnButtonsClick
        />
        <PropertiesFilter
          filterName="Rentals"
          loading={loadingFilters}
          properties={parseListingsFilters(listings)}
          className={styles.propertiesFilter}
          selectedIds={filters.listingIds}
          onChange={(value) => onFilterChange('listingIds', value)}
          onApplyFilters={(selectedProperties) => {
            onFilterChange('listingIds', selectedProperties);
          }}
        />
        <FilterGeneric
          loading={loadingFilters}
          className={styles.documentsFilter}
          icon={Lease}
          filterName="Leases"
          allLabel="All Leases"
          staticFilterName
          hideSearch={leases.length < 10}
          options={parseFilters(leases)}
          onChange={(value) => onFilterChange('leaseIds', value)}
          selected={filters.leaseIds}
          showApplyFiltersButton
          closeOnButtonsClick
        />
        <FilterGeneric
          loading={loadingFilters}
          className={cx(styles.documentsFilter, styles.esignFilter)}
          icon={ESign}
          iconProps={{ width: 20, height: 20 }}
          filterName={`E-Sign ${filters.esignStatuses?.length ? 'Status' : 'Statuses'}`}
          staticFilterName
          hideSearch
          options={esignOptions}
          allLabel="All E-Sign Statuses"
          onChange={(value) => onFilterChange('esignStatuses', value)}
          selected={filters.esignStatuses}
          showApplyFiltersButton
          closeOnButtonsClick
        />

        <DropdownMenu closeOnClick>
          <DropdownMenu.Trigger>
            <div
              className={cx(styles.privateTrigger, {
                [styles.active]:
                  filters.isShared === true || filters.isShared === false,
              })}
            >
              <Padlock height={18} width={18} />
              {filters.isShared === true || filters.isShared === false
                ? `Showing ${filters.isShared ? 'Shared' : 'Private'} documents`
                : 'Private'}
            </div>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content className={styles.privateDropdown}>
            <DropdownMenu.Item
              as="button"
              className={styles.filterOption}
              onClick={() => {
                onFilterChange('isShared', undefined);
              }}
            >
              <RadioButton
                checked={filters.isShared === undefined}
                label="All Statuses"
              />
            </DropdownMenu.Item>
            {isSharedOptions.map((option) => {
              return (
                <DropdownMenu.Item
                  key={`${option.value}${option.direction}`}
                  as="button"
                  className={styles.filterOption}
                  onClick={() => {
                    onFilterChange('isShared', option.value);
                  }}
                >
                  <RadioButton
                    checked={filters.isShared === option.value}
                    label={option.label}
                    className={styles.radioButton}
                  />
                </DropdownMenu.Item>
              );
            })}
          </DropdownMenu.Content>
        </DropdownMenu>

        {areFiltersActive && (
          <a className={styles.resetFiltersButton} onClick={onResetFilters}>
            RESET ALL
          </a>
        )}

        <DropdownMenu closeOnClick>
          <DropdownMenu.Trigger>
            <div className={styles.dropdownTrigger}>
              Sort by<b>{sortConfig?.replace('_', ' ')}</b>
              <DropdownMenu.Arrow />
            </div>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            <DropdownMenu.Item
              as="button"
              className={styles.sortOption}
              onClick={() => {
                onSortingChange('created_at');
              }}
            >
              Newest
            </DropdownMenu.Item>
            <DropdownMenu.Item
              as="button"
              className={styles.sortOption}
              onClick={() => {
                onSortingChange('created_at');
              }}
            >
              Oldest
            </DropdownMenu.Item>
            <DropdownMenu.Item
              as="button"
              className={styles.sortOption}
              onClick={() => {
                onSortingChange('rental');
              }}
            >
              Rental Ascending
            </DropdownMenu.Item>
            <DropdownMenu.Item
              as="button"
              className={styles.sortOption}
              onClick={() => {
                onSortingChange('rental');
              }}
            >
              Rental Descending
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu>
      </div>

      {/* TODO AFTER DESIGN IS DONE - UPLOAD DOCUMENT BUTTON */}
      {/* <Button
        className={styles.filterButton}
        onClick={() => {
          console.log('upload document connect');
        }}
      >
        upload document
      </Button> */}
    </div>
  );
};

AllDocumentsFilters.propTypes = {
  filters: PropTypes.object,
  onFilterChange: PropTypes.func,
  sortConfig: PropTypes.string,
  onSortingChange: PropTypes.func,
  onResetFilters: PropTypes.func,
  areFiltersActive: PropTypes.bool,
};

export default AllDocumentsFilters;
