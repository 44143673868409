import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import ExpandableCard from '../../../../ExpandableCard';
import BenefitItemExperimentHMobile from '../Benefit/BenefitItemExperimentHMobile';

import styles from './BenefItemsMobileExperimentH.module.scss';

const BenefitItemsMobileExperimentH = ({
  benefits,
  selectedBenefit,
  extraParams,
  isProPlan = false,
  onClickSegment,
}) => {
  return benefits.map((benefit) => {
    const benefitTitle = benefit.titleShort || benefit.title;

    const justPremiumBenefits = benefit.items.filter(
      (item) => item.plan === 'premium',
    );

    return (
      <div className={styles.expandableCardWrapper} key={benefit.key}>
        <ExpandableCard
          title={benefitTitle}
          className={cx(styles.expandableCard, styles.bolder)}
          contentClassName={styles.expandableCardContent}
          headerClassName={cx(styles.expandableCardHeader)}
          defaultExpanded={selectedBenefit === benefit.key}
          onClick={() => {
            onClickSegment(`${benefitTitle.toLowerCase()}_tab`);
          }}
        >
          {benefit.items.map((item) => {
            if (isProPlan && item.plan === 'premium') {
              return null;
            }
            return (
              <BenefitItemExperimentHMobile
                key={item.key}
                item={item}
                extraParams={extraParams}
                isProPlan={isProPlan}
                showCheckmark={true}
              />
            );
          })}
          {isProPlan &&
            justPremiumBenefits.map((item) => (
              <div key={item.key}>
                <BenefitItemExperimentHMobile
                  item={item}
                  extraParams={extraParams}
                  isProPlan={isProPlan}
                  showCheckmark={false}
                />
              </div>
            ))}
        </ExpandableCard>
      </div>
    );
  });
};

BenefitItemsMobileExperimentH.propTypes = {
  benefits: PropTypes.array.isRequired,
  selectedBenefit: PropTypes.string.isRequired,
  extraParams: PropTypes.object.isRequired,
  scrollToPremium: PropTypes.func.isRequired,
  isProPlan: PropTypes.bool,
};

export default BenefitItemsMobileExperimentH;
