import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Filter = (props) => (
  <svg {...props} viewBox="0 0 18 18" fill={props.color}>
    <path d="M7.2 9.683L.167 1.18A.72.72 0 01.72 0H17.28c.609 0 .943.71.554 1.179L10.8 9.683v5.436a.72.72 0 01-.211.509l-2.16 2.16a.72.72 0 01-1.229-.51V9.684zM2.25 1.44l6.225 7.525a.72.72 0 01.165.459v6.117l.72-.72V9.424a.72.72 0 01.165-.459L15.75 1.44H2.251z" />
  </svg>
);

Filter.defaultProps = {
  width: 18,
  height: 18,
  color: colors.primary,
};

Filter.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Filter);
