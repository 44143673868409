import React, { memo } from 'react';
import PropTypes from 'prop-types';

import SkeletonSvgGradient from '../../../../../components/Skeleton/SkeletonSvgGradient';

const MobileSkeleton = (props) => (
  <svg width="100%" height="auto" viewBox="0 0 361 712" fill="none" {...props}>
    <SkeletonSvgGradient />
    <mask id="path-3-inside-1_15000_39282" fill="white">
      <path d="M0 0H361V89H0V0Z" />
    </mask>
    <path
      d="M361 88H0V90H361V88Z"
      fill="#E6E9F0"
      mask="url(#path-3-inside-1_15000_39282)"
    />
    <path
      d="M24 19.5C24 17.2909 25.7909 15.5 28 15.5H92C94.2091 15.5 96 17.2909 96 19.5V27.5C96 29.7091 94.2091 31.5 92 31.5H28C25.7909 31.5 24 29.7091 24 27.5V19.5Z"
      fill="#E6E9F0"
    />
    <path
      d="M24 37.5C24 35.2909 25.7909 33.5 28 33.5H220C222.209 33.5 224 35.2909 224 37.5V49.5C224 51.7091 222.209 53.5 220 53.5H28C25.7909 53.5 24 51.7091 24 49.5V37.5Z"
      fill="#E6E9F0"
    />
    <path
      d="M24 59.5C24 57.2909 25.7909 55.5 28 55.5H148C150.209 55.5 152 57.2909 152 59.5V69.5C152 71.7091 150.209 73.5 148 73.5H28C25.7909 73.5 24 71.7091 24 69.5V59.5Z"
      fill="#E6E9F0"
    />
    <path
      d="M257 38.5C257 36.2909 258.791 34.5 261 34.5H333C335.209 34.5 337 36.2909 337 38.5V50.5C337 52.7091 335.209 54.5 333 54.5H261C258.791 54.5 257 52.7091 257 50.5V38.5Z"
      fill="#E6E9F0"
    />
    <mask id="path-11-inside-2_15000_39282" fill="white">
      <path d="M0 89H361V178H0V89Z" />
    </mask>
    <path
      d="M361 177H0V179H361V177Z"
      fill="#E6E9F0"
      mask="url(#path-11-inside-2_15000_39282)"
    />
    <path
      d="M24 108.5C24 106.291 25.7909 104.5 28 104.5H92C94.2091 104.5 96 106.291 96 108.5V116.5C96 118.709 94.2091 120.5 92 120.5H28C25.7909 120.5 24 118.709 24 116.5V108.5Z"
      fill="#E6E9F0"
    />
    <path
      d="M24 126.5C24 124.291 25.7909 122.5 28 122.5H220C222.209 122.5 224 124.291 224 126.5V138.5C224 140.709 222.209 142.5 220 142.5H28C25.7909 142.5 24 140.709 24 138.5V126.5Z"
      fill="#E6E9F0"
    />
    <path
      d="M24 148.5C24 146.291 25.7909 144.5 28 144.5H148C150.209 144.5 152 146.291 152 148.5V158.5C152 160.709 150.209 162.5 148 162.5H28C25.7909 162.5 24 160.709 24 158.5V148.5Z"
      fill="#E6E9F0"
    />
    <path
      d="M257 127.5C257 125.291 258.791 123.5 261 123.5H333C335.209 123.5 337 125.291 337 127.5V139.5C337 141.709 335.209 143.5 333 143.5H261C258.791 143.5 257 141.709 257 139.5V127.5Z"
      fill="#E6E9F0"
    />
    <mask id="path-19-inside-3_15000_39282" fill="white">
      <path d="M0 178H361V267H0V178Z" />
    </mask>
    <path
      d="M361 266H0V268H361V266Z"
      fill="#E6E9F0"
      mask="url(#path-19-inside-3_15000_39282)"
    />
    <path
      d="M24 197.5C24 195.291 25.7909 193.5 28 193.5H92C94.2091 193.5 96 195.291 96 197.5V205.5C96 207.709 94.2091 209.5 92 209.5H28C25.7909 209.5 24 207.709 24 205.5V197.5Z"
      fill="#E6E9F0"
    />
    <path
      d="M24 215.5C24 213.291 25.7909 211.5 28 211.5H220C222.209 211.5 224 213.291 224 215.5V227.5C224 229.709 222.209 231.5 220 231.5H28C25.7909 231.5 24 229.709 24 227.5V215.5Z"
      fill="#E6E9F0"
    />
    <path
      d="M24 237.5C24 235.291 25.7909 233.5 28 233.5H148C150.209 233.5 152 235.291 152 237.5V247.5C152 249.709 150.209 251.5 148 251.5H28C25.7909 251.5 24 249.709 24 247.5V237.5Z"
      fill="#E6E9F0"
    />
    <path
      d="M257 216.5C257 214.291 258.791 212.5 261 212.5H333C335.209 212.5 337 214.291 337 216.5V228.5C337 230.709 335.209 232.5 333 232.5H261C258.791 232.5 257 230.709 257 228.5V216.5Z"
      fill="#E6E9F0"
    />
    <mask id="path-27-inside-4_15000_39282" fill="white">
      <path d="M0 267H361V356H0V267Z" />
    </mask>
    <path
      d="M361 355H0V357H361V355Z"
      fill="#E6E9F0"
      mask="url(#path-27-inside-4_15000_39282)"
    />
    <path
      d="M24 286.5C24 284.291 25.7909 282.5 28 282.5H92C94.2091 282.5 96 284.291 96 286.5V294.5C96 296.709 94.2091 298.5 92 298.5H28C25.7909 298.5 24 296.709 24 294.5V286.5Z"
      fill="#E6E9F0"
    />
    <path
      d="M24 304.5C24 302.291 25.7909 300.5 28 300.5H220C222.209 300.5 224 302.291 224 304.5V316.5C224 318.709 222.209 320.5 220 320.5H28C25.7909 320.5 24 318.709 24 316.5V304.5Z"
      fill="#E6E9F0"
    />
    <path
      d="M24 326.5C24 324.291 25.7909 322.5 28 322.5H148C150.209 322.5 152 324.291 152 326.5V336.5C152 338.709 150.209 340.5 148 340.5H28C25.7909 340.5 24 338.709 24 336.5V326.5Z"
      fill="#E6E9F0"
    />
    <path
      d="M257 305.5C257 303.291 258.791 301.5 261 301.5H333C335.209 301.5 337 303.291 337 305.5V317.5C337 319.709 335.209 321.5 333 321.5H261C258.791 321.5 257 319.709 257 317.5V305.5Z"
      fill="#E6E9F0"
    />
    <mask id="path-35-inside-5_15000_39282" fill="white">
      <path d="M0 356H361V445H0V356Z" />
    </mask>
    <path
      d="M361 444H0V446H361V444Z"
      fill="#E6E9F0"
      mask="url(#path-35-inside-5_15000_39282)"
    />
    <path
      d="M24 375.5C24 373.291 25.7909 371.5 28 371.5H92C94.2091 371.5 96 373.291 96 375.5V383.5C96 385.709 94.2091 387.5 92 387.5H28C25.7909 387.5 24 385.709 24 383.5V375.5Z"
      fill="#E6E9F0"
    />
    <path
      d="M24 393.5C24 391.291 25.7909 389.5 28 389.5H220C222.209 389.5 224 391.291 224 393.5V405.5C224 407.709 222.209 409.5 220 409.5H28C25.7909 409.5 24 407.709 24 405.5V393.5Z"
      fill="#E6E9F0"
    />
    <path
      d="M24 415.5C24 413.291 25.7909 411.5 28 411.5H148C150.209 411.5 152 413.291 152 415.5V425.5C152 427.709 150.209 429.5 148 429.5H28C25.7909 429.5 24 427.709 24 425.5V415.5Z"
      fill="#E6E9F0"
    />
    <path
      d="M257 394.5C257 392.291 258.791 390.5 261 390.5H333C335.209 390.5 337 392.291 337 394.5V406.5C337 408.709 335.209 410.5 333 410.5H261C258.791 410.5 257 408.709 257 406.5V394.5Z"
      fill="#E6E9F0"
    />
    <mask id="path-43-inside-6_15000_39282" fill="white">
      <path d="M0 445H361V534H0V445Z" />
    </mask>
    <path
      d="M361 533H0V535H361V533Z"
      fill="#E6E9F0"
      mask="url(#path-43-inside-6_15000_39282)"
    />
    <path
      d="M24 464.5C24 462.291 25.7909 460.5 28 460.5H92C94.2091 460.5 96 462.291 96 464.5V472.5C96 474.709 94.2091 476.5 92 476.5H28C25.7909 476.5 24 474.709 24 472.5V464.5Z"
      fill="#E6E9F0"
    />
    <path
      d="M24 482.5C24 480.291 25.7909 478.5 28 478.5H220C222.209 478.5 224 480.291 224 482.5V494.5C224 496.709 222.209 498.5 220 498.5H28C25.7909 498.5 24 496.709 24 494.5V482.5Z"
      fill="#E6E9F0"
    />
    <path
      d="M24 504.5C24 502.291 25.7909 500.5 28 500.5H148C150.209 500.5 152 502.291 152 504.5V514.5C152 516.709 150.209 518.5 148 518.5H28C25.7909 518.5 24 516.709 24 514.5V504.5Z"
      fill="#E6E9F0"
    />
    <path
      d="M257 483.5C257 481.291 258.791 479.5 261 479.5H333C335.209 479.5 337 481.291 337 483.5V495.5C337 497.709 335.209 499.5 333 499.5H261C258.791 499.5 257 497.709 257 495.5V483.5Z"
      fill="#E6E9F0"
    />
    <mask id="path-51-inside-7_15000_39282" fill="white">
      <path d="M0 534H361V623H0V534Z" />
    </mask>
    <path
      d="M361 622H0V624H361V622Z"
      fill="#E6E9F0"
      mask="url(#path-51-inside-7_15000_39282)"
    />
    <path
      d="M24 553.5C24 551.291 25.7909 549.5 28 549.5H92C94.2091 549.5 96 551.291 96 553.5V561.5C96 563.709 94.2091 565.5 92 565.5H28C25.7909 565.5 24 563.709 24 561.5V553.5Z"
      fill="#E6E9F0"
    />
    <path
      d="M24 571.5C24 569.291 25.7909 567.5 28 567.5H220C222.209 567.5 224 569.291 224 571.5V583.5C224 585.709 222.209 587.5 220 587.5H28C25.7909 587.5 24 585.709 24 583.5V571.5Z"
      fill="#E6E9F0"
    />
    <path
      d="M24 593.5C24 591.291 25.7909 589.5 28 589.5H148C150.209 589.5 152 591.291 152 593.5V603.5C152 605.709 150.209 607.5 148 607.5H28C25.7909 607.5 24 605.709 24 603.5V593.5Z"
      fill="#E6E9F0"
    />
    <path
      d="M257 572.5C257 570.291 258.791 568.5 261 568.5H333C335.209 568.5 337 570.291 337 572.5V584.5C337 586.709 335.209 588.5 333 588.5H261C258.791 588.5 257 586.709 257 584.5V572.5Z"
      fill="#E6E9F0"
    />
    <mask id="path-59-inside-8_15000_39282" fill="white">
      <path d="M0 623H361V712H0V623Z" />
    </mask>
    <path
      d="M361 711H0V713H361V711Z"
      fill="#E6E9F0"
      mask="url(#path-59-inside-8_15000_39282)"
    />
    <path
      d="M24 642.5C24 640.291 25.7909 638.5 28 638.5H92C94.2091 638.5 96 640.291 96 642.5V650.5C96 652.709 94.2091 654.5 92 654.5H28C25.7909 654.5 24 652.709 24 650.5V642.5Z"
      fill="#E6E9F0"
    />
    <path
      d="M24 660.5C24 658.291 25.7909 656.5 28 656.5H220C222.209 656.5 224 658.291 224 660.5V672.5C224 674.709 222.209 676.5 220 676.5H28C25.7909 676.5 24 674.709 24 672.5V660.5Z"
      fill="#E6E9F0"
    />
    <path
      d="M24 682.5C24 680.291 25.7909 678.5 28 678.5H148C150.209 678.5 152 680.291 152 682.5V692.5C152 694.709 150.209 696.5 148 696.5H28C25.7909 696.5 24 694.709 24 692.5V682.5Z"
      fill="#E6E9F0"
    />
    <path
      d="M257 661.5C257 659.291 258.791 657.5 261 657.5H333C335.209 657.5 337 659.291 337 661.5V673.5C337 675.709 335.209 677.5 333 677.5H261C258.791 677.5 257 675.709 257 673.5V661.5Z"
      fill="#E6E9F0"
    />

    <defs>
      <clipPath id="clip0_15000_39282">
        <path
          d="M0 4C0 1.79086 1.79086 0 4 0H357C359.209 0 361 1.79086 361 4V708C361 710.209 359.209 712 357 712H4C1.79086 712 0 710.209 0 708V4Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

MobileSkeleton.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(MobileSkeleton);
