import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Error = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 24" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.076 11.576a.6.6 0 0 0 0 .848l9.5 9.5a.6.6 0 0 0 .848 0l9.5-9.5a.6.6 0 0 0 0-.848l-9.5-9.5a.6.6 0 0 0-.848 0l-9.5 9.5Zm9.923-4.926a.6.6 0 0 1 .6.6v6.907a.6.6 0 1 1-1.2 0V7.25a.6.6 0 0 1 .6-.6Zm0 9.498c-.573 0-1.031.458-1.031 1.031 0 .574.458 1.032 1.031 1.032.574 0 1.032-.458 1.032-1.032 0-.573-.458-1.031-1.032-1.031Z"
      fill={color}
    />
  </svg>
);

Error.defaultProps = {
  width: 24,
  height: 24,
  color: colors.statusWarning,
};

Error.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Error);
