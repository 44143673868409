import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const CheckboxSquareFilled = ({ color, checked, ...props }) => (
  <svg viewBox="0 0 18 18" {...props}>
    {checked ? (
      <g>
        <g
          id="MUP-B-Filter-3"
          transform="translate(-63.000000, -106.000000)"
          fill={color}
        >
          <g id="Properties" transform="translate(20.000000, 49.000000)">
            <g id="5445" transform="translate(16.000000, 16.000000)">
              <g
                id="Icons/Inputs/Checkbox/Off"
                transform="translate(24.000000, 38.000000)"
              >
                <path
                  d="M5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 L5,3 Z M7,11 L17,11 L17,13 L7,13 Z"
                  id="checkbox-on"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    ) : (
      <path d="M16 2v14H2V2h14zm0-2H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2z" />
    )}
  </svg>
);

CheckboxSquareFilled.defaultProps = {
  width: 18,
  height: 18,
  color: colors.primary,
};

CheckboxSquareFilled.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(CheckboxSquareFilled);
