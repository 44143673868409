import React from 'react';
import PropTypes from 'prop-types';

import FinalForm from '../../../../components/FinalForm';
import removeTypeNames from '../../../../helpers/remove-type-names';
import EmergencyContactsEdit from '../emergency-contacts/EmergencyContactsEdit';
import PetsEdit from '../pets/PetsEdit';
import VehiclesEdit from '../vehicles/VehiclesEdit';

const GeneralDetailsEdit = ({
  id,
  onSubmit,
  application,
  formRef,
  ...props
}) => (
  <FinalForm
    id={id}
    ref={formRef}
    initialValues={{
      contacts: application?.contacts?.map(removeTypeNames) || [],
      pets: application?.pets?.map(removeTypeNames) || [],
      vehicles: application?.vehicles?.map(removeTypeNames) || [],
      pet: application?.pet,
    }}
    onSubmit={onSubmit}
  >
    <EmergencyContactsEdit {...props} />
    <PetsEdit {...props} />
    <VehiclesEdit {...props} />
  </FinalForm>
);

GeneralDetailsEdit.propTypes = {
  application: PropTypes.object,
  onSubmit: PropTypes.func,
  id: PropTypes.string,
  formRef: PropTypes.object,
};

export default GeneralDetailsEdit;
