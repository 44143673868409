import { getConfig } from '../services/configService';

const calculateReiHubPrice = (listingsCount) => {
  const { REI_HUB_PRICING } = getConfig();

  const billableProperties =
    Number(listingsCount) > REI_HUB_PRICING.billableProperties
      ? REI_HUB_PRICING.billableProperties
      : Number(listingsCount);
  return (
    billableProperties * REI_HUB_PRICING.rest +
    REI_HUB_PRICING.first -
    REI_HUB_PRICING.rest
  );
};

export default calculateReiHubPrice;
