/* eslint-disable eslint-local-rules/no-imports-from */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import cx from 'classnames';
import get from 'lodash.get';

import EsignIllustration from '../../../assets/illustrations/Esign';
import SearchDocument from '../../../assets/illustrations/SearchDocument';
import Button from '../../../components/Button';
import CopyLeaseAgreementModal from '../../../components/CopyLeaseAgreementModal';
import AllDocumentsBannerCta from '../../../components/Documents/AllDocumentsBannerCta';
import AllDocumentsFilters from '../../../components/Documents/AllDocumentsFilters';
import AllDocumentsTable from '../../../components/Documents/AllDocumentsTable';
import { useSuccessToast } from '../../../components/Toast';
import { H2, Paragraph } from '../../../components/Typography';
import DOCUMENT_TYPE from '../../../constants/lease/document-type';
import RemoveDocumentModalTypes from '../../../constants/lease/removeDocumentModalTypes';
import { useLeases } from '../../../core/TTgraphql';
import deleteESignatureRequestMutation from '../../../graphql/mutations/deleteESignatureRequest.graphql';
import deleteLeaseDocumentMutation from '../../../graphql/mutations/deleteLeaseDocument.graphql';
import copyLeaseAgreementMutationGQL from '../../../graphql/mutations/lease_agreements/copyLeaseAgreement.graphql';
import removeLeaseAgreementMutation from '../../../graphql/mutations/lease_agreements/removeLeaseAgreement.graphql';
import removeWizardDocumentMutation from '../../../graphql/mutations/wizard_documents/removeWizardDocument.graphql';
import allDocumentsQuery from '../../../graphql/queries/all_documents/allDocuments.graphql';
import useDocumentsFilters from '../../../hooks/documents/useDocumentsFilters';
import Lock from '../../../icons/streamline/Lock';
import Esign from '../../../icons/twoTone/ESign';
import FormsTwoTone from '../../../icons/twoTone/FormsTwoTone';
import DeleteLeaseDocumentModal from '../../../pages/owners/components/DeleteLeaseDocumentModal';
import { useConfig } from '../../../providers/ConfigProvider';
import { segmentTracking } from '../../../services/utilities';

import styles from './AllDocumentsTab.module.scss';

const AllDocumentsTab = () => {
  const { PRIVATE_BASE_PATH, FORMS_PACK_NUMBER } = useConfig();
  const history = useHistory();
  const showSuccessToast = useSuccessToast();

  const {
    resetFilters,
    filters,
    sortConfig,
    onSortingChange,
    areFiltersActive,
    ...filterProps
  } = useDocumentsFilters();

  const refetchQueries = [
    {
      query: allDocumentsQuery,
      variables: {
        filters: filters,
        sort: sortConfig,
      },
    },
  ];

  const [copyLeaseAgreementMutation, { loading: copyingLeaseAgreement }] =
    useMutation(copyLeaseAgreementMutationGQL, {
      refetchQueries,
      awaitRefetchQueries: true,
    });
  const [
    deleteESignatureRequest,
    { loading: loadingESignatureRequestDeletion },
  ] = useMutation(deleteESignatureRequestMutation, {
    refetchQueries,
    awaitRefetchQueries: true,
  });

  const [removeWizardDocument, { loading: loadingWizardRemoval }] = useMutation(
    removeWizardDocumentMutation,
    {
      refetchQueries,
      awaitRefetchQueries: true,
    },
  );

  const [removeLeaseAgreement, { loading: loadingLeaseAgreementRemoval }] =
    useMutation(removeLeaseAgreementMutation, {
      refetchQueries,
      awaitRefetchQueries: true,
    });

  const [deleteLeaseDocument, { loading: loadingLeaseDocumentRemoval }] =
    useMutation(deleteLeaseDocumentMutation, {
      refetchQueries,
      awaitRefetchQueries: true,
    });

  const [showCopyLeaseAgreementModal, setShowCopyLeaseAgreementModal] =
    useState(false);

  const [deleteDocumentModal, setDeleteDocumentModal] = useState({
    show: false,
    type: RemoveDocumentModalTypes.STANDARD,
    id: null,
  });

  const { leases, loading: loadingLeases } = useLeases({}, 0);

  const copyLeaseAgreement = async (data) => {
    const result = await copyLeaseAgreementMutation({
      variables: {
        ...data,
      },
    });

    setShowCopyLeaseAgreementModal(false);
    const leaseId = get(result, 'data.copyLeaseAgreement.lease.id');
    if (leaseId) {
      history.push(`${PRIVATE_BASE_PATH}digital-leases/${leaseId}/overview`);
    } else {
      console.error('Failed to copy lease agreement');
    }
  };

  const { data, loading: loadingDocuments } = useQuery(allDocumentsQuery, {
    variables: {
      filters: filters,
      sort: sortConfig,
    },
  });

  const [selectedDocument, setSelectedDocument] = useState(null);

  const documents = get(data, 'allDocuments.edges', []).map(
    (item) => item.node,
  );

  const onCopy = (doc) => {
    setSelectedDocument(doc);
    setShowCopyLeaseAgreementModal(true);
  };

  const onDelete = (doc) => {
    setDeleteDocumentModal({
      show: true,
      type: RemoveDocumentModalTypes.SAVE_COPY,
      id: doc.wizard_document_id || doc.lease_agreement_id,
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <AllDocumentsBannerCta
          icon={Esign}
          title="E-sign a document"
          text={
            <>
              Send <b>any</b> document for easy and secure signing
            </>
          }
          to={`${PRIVATE_BASE_PATH}documents/e-sign`}
          onClick={() => {
            segmentTracking('esign clicked', {
              location: 'Documents tab',
            });
          }}
        />

        <AllDocumentsBannerCta
          icon={FormsTwoTone}
          title="Start with a form"
          text={`Explore ${FORMS_PACK_NUMBER} forms to get you started`}
          to={`${PRIVATE_BASE_PATH}documents/forms`}
          onClick={() => {
            segmentTracking('forms clicked', {
              location: 'Documents tab',
            });
          }}
        />
      </div>

      {((documents.length > 0 && !loadingDocuments) ||
        areFiltersActive ||
        loadingDocuments) && (
        <div className={styles.filters}>
          <AllDocumentsFilters
            areFiltersActive={areFiltersActive}
            filters={filters}
            loading={loadingDocuments}
            sortConfig={sortConfig}
            onSortingChange={onSortingChange}
            onResetFilters={resetFilters}
            {...filterProps}
          />
        </div>
      )}

      {(documents.length > 0 || loadingDocuments) && (
        <div className={styles.table}>
          <AllDocumentsTable
            documents={documents}
            loading={loadingDocuments}
            onCopy={onCopy}
            onDelete={onDelete}
            onSortingChange={onSortingChange}
            sortConfig={sortConfig}
          />
        </div>
      )}

      {documents?.length === 0 && !loadingDocuments && !areFiltersActive && (
        <div className={styles.emptyState}>
          <div className={styles.inner}>
            <EsignIllustration />
            <H2 className={styles.title}>
              Your property documents, simplified
            </H2>
            <Paragraph className={styles.description}>
              Store, organize, and e-sign all your important files in our secure
              document center. From lease agreements to tax forms, manage every
              document with confidence and ease.
            </Paragraph>
            {/* TODO DOCUMENT UPLOAD DESIGN NOT DONE */}
            {/* <Button
              className={styles.button}
              onClick={() => {
                console.log('Add document');
              }}
            >
              Add your first document
            </Button> */}
          </div>
        </div>
      )}

      {documents?.length === 0 && !loadingDocuments && areFiltersActive && (
        <div className={cx(styles.emptyState, styles.search)}>
          <div className={styles.inner}>
            <SearchDocument />
            <H2 className={styles.title}>
              No documents match that filter criteria
            </H2>

            <Button className={styles.button} secondary onClick={resetFilters}>
              RESET ALL FILTERS
            </Button>
          </div>
        </div>
      )}

      <div className={styles.footer}>
        <Lock width={24} height={24} /> We keep your documents secure.
      </div>

      <CopyLeaseAgreementModal
        leases={
          leases.map((lease) => ({
            label: lease.title,
            value: lease.id,
          })) || []
        }
        skipFiltering
        open={showCopyLeaseAgreementModal}
        loading={loadingDocuments || copyingLeaseAgreement || loadingLeases}
        onGoBack={() => setShowCopyLeaseAgreementModal(false)}
        onSubmit={(data) => {
          copyLeaseAgreement(data);
        }}
        leaseAgreementId={selectedDocument?.lease_agreement_id}
      />

      <DeleteLeaseDocumentModal
        loading={
          loadingESignatureRequestDeletion ||
          loadingWizardRemoval ||
          loadingLeaseAgreementRemoval ||
          loadingLeaseDocumentRemoval
        }
        type={deleteDocumentModal.type}
        onGoBack={() =>
          setDeleteDocumentModal({
            show: false,
            type: RemoveDocumentModalTypes.STANDARD,
            id: null,
          })
        }
        onDelete={async () => {
          if (deleteDocumentModal?.id) {
            const documentType = atob(deleteDocumentModal.id).split(':')[0];

            if (documentType === DOCUMENT_TYPE.WIZARD_DOCUMENT) {
              await removeWizardDocument({
                variables: {
                  id: deleteDocumentModal.id,
                },
              });
            }

            if (documentType === DOCUMENT_TYPE.LEASE_AGREEMENT) {
              await removeLeaseAgreement({
                variables: {
                  id: deleteDocumentModal.id,
                },
              });
            }

            if (documentType === DOCUMENT_TYPE.SIGNATURE_REQUEST) {
              await deleteESignatureRequest({
                variables: {
                  id: deleteDocumentModal.id,
                },
              });
            }

            if (deleteDocumentModal.isEditedForm) {
              await deleteLeaseDocument({
                variables: {
                  id: deleteDocumentModal.id,
                },
              });
            }
          }

          showSuccessToast('Document has been removed successfully');

          setDeleteDocumentModal({
            show: false,
            type: RemoveDocumentModalTypes.STANDARD,
            id: null,
          });
        }}
        open={deleteDocumentModal.show}
      />
    </div>
  );
};

export default AllDocumentsTab;
