const RemoveDocumentModalTypes = {
  STANDARD: 'STANDARD',
  NON_REFUNDABLE: 'NON_REFUNDABLE',
  SAVE_COPY: 'SAVE_COPY',
  STANDARD_NEW: 'STANDARD_NEW',
};

export const RemoveDocumentModalTypesValues = Object.values(
  RemoveDocumentModalTypes,
);

export default RemoveDocumentModalTypes;
