import moment from 'moment';

export const isLastDay = (dateString) => {
  if (!dateString) {
    return false;
  }

  const date = moment.utc(dateString);
  return date.date() === date.daysInMonth();
};
