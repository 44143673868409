import React from 'react';
import cx from 'classnames';
import { useSelect } from 'downshift';
import PropTypes from 'prop-types';

import DownArrow from '../../icons/streamline/filled/DownArrow';
import getFieldError from '../../validators/get-field-error';
import Checkbox from '../Checkbox';
import Label from '../Label';

import styles from './MultiSelectDropdown.module.scss';

const MultiSelectDropdown = ({
  className,
  buttonClassName,
  listClassName,
  listOptionClassName,
  options = [],
  placeholder,
  label,
  input,
  meta,
  disabled,
  id,
  hint,
  dataQa,
}) => {
  const error = getFieldError(meta);
  const selectedOption = input.value || [];

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: options,
    selectedItem: null,
    stateReducer: (_state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true,
          };
        default:
          return changes;
      }
    },
  });

  const toggleOption = (option) => {
    const newValue = selectedOption.includes(option.value)
      ? selectedOption.filter((value) => value !== option.value)
      : [...selectedOption, option.value];
    input.onChange(newValue);
  };

  const selectedCount = selectedOption.length;

  let selectedLabels;
  if (selectedCount === 0) {
    selectedLabels = '';
  } else if (selectedCount === 1) {
    selectedLabels = options.find(
      (option) => option.value === selectedOption[0],
    )?.label;
  } else {
    selectedLabels = 'Multiple';
  }

  return (
    <div className={cx(styles.container, className)}>
      {label && (
        <Label htmlFor={id} hint={hint}>
          {label}
        </Label>
      )}
      <div className={styles.selectWrapper}>
        <button
          type="button"
          className={cx(styles.button, buttonClassName, {
            [styles.invalid]: !!error,
            [styles.disabled]: disabled,
          })}
          {...getToggleButtonProps()}
          disabled={disabled}
          data-qa={dataQa}
        >
          <span
            className={cx(styles.buttonText, {
              [styles.placeholder]: !selectedLabels,
            })}
          >
            {selectedLabels || placeholder}
          </span>
          <DownArrow className={styles.buttonIcon} />
        </button>
        <ul
          className={cx(styles.list, listClassName, {
            [styles.opened]: isOpen,
          })}
          {...getMenuProps()}
        >
          {isOpen &&
            options.map((option, index) => (
              <li
                key={option.value}
                className={cx(styles.listItem, listOptionClassName, {
                  [styles.highlighted]: highlightedIndex === index,
                })}
                {...getItemProps({
                  option,
                  index,
                  onClick: (e) => {
                    e.preventDefault();
                    toggleOption(option);
                  },
                })}
              >
                <Checkbox
                  className={styles.checkbox}
                  controlled
                  checked={selectedOption.includes(option.value)}
                  onChange={() => toggleOption(option)}
                  disabled={disabled}
                  id={`${id}-${option.value}`}
                  label={option.label}
                  ignoreError
                />
              </li>
            ))}
        </ul>
      </div>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

MultiSelectDropdown.propTypes = {
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  listClassName: PropTypes.string,
  listOptionClassName: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  hint: PropTypes.string,
  dataQa: PropTypes.string,
};

export default MultiSelectDropdown;
