const LEASE_DOCUMENT_TYPE = {
  LEASE_DOCUMENT: 'LEASE_DOCUMENT',
  LEASE_SIGNATURE_DOCUMENT: 'LEASE_SIGNATURE_DOCUMENT',
  LEASE_AGREEMENT: 'LEASE_AGREEMENT',
  LEASE_AGREEMENT_ATTACHMENT: 'LEASE_AGREEMENT_ATTACHMENT',
  LEASE_ADDENDUM: 'LEASE_ADDENDUM',
  LEASE_ADDENDUM_SIGNATURE_DOCUMENT: 'LEASE_ADDENDUM_SIGNATURE_DOCUMENT',
  CONDITION_REPORT: 'CONDITION_REPORT',
  CONDITION_REPORT_MOVE_IN: 'CONDITION_REPORT_MOVE_IN',
  CONDITION_REPORT_MOVE_OUT: 'CONDITION_REPORT_MOVE_OUT',
  CONDITION_REPORT_MOVE_PERIODIC: 'CONDITION_REPORT_MOVE_PERIODIC',
  CONDITION_REPORT_TEMPLATE: 'CONDITION_REPORT_TEMPLATE',
  CONDITION_REPORT_TEMPLATE_SIGNATURE_DOCUMENT:
    'CONDITION_REPORT_TEMPLATE_SIGNATURE_DOCUMENT',
  FORMS: 'FORMS',
};

export const {
  LEASE_DOCUMENT,
  LEASE_SIGNATURE_DOCUMENT,
  LEASE_AGREEMENT,
  LEASE_AGREEMENT_ATTACHMENT,
  LEASE_ADDENDUM,
  LEASE_ADDENDUM_SIGNATURE_DOCUMENT,
  CONDITION_REPORT,
  CONDITION_REPORT_MOVE_IN,
  CONDITION_REPORT_MOVE_OUT,
  CONDITION_REPORT_MOVE_PERIODIC,
  CONDITION_REPORT_TEMPLATE,
  CONDITION_REPORT_TEMPLATE_SIGNATURE_DOCUMENT,
  FORMS,
} = LEASE_DOCUMENT_TYPE;

export default LEASE_DOCUMENT_TYPE;
