import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { useConfig } from '../../providers/ConfigProvider';
import Button from '../Button';
import InlineAlert from '../InlineAlert';
import Modal from '../Modal';
import SelectField from '../SelectField';

import styles from './CopyLeaseAgreementModal.module.scss';

const CopyLeaseAgreementModal = ({
  className,
  containerClassName,
  open,
  onGoBack,
  leases,
  loading,
  leaseAgreementId,
  onSubmit,
  skipFiltering = false,
}) => {
  const [selectedLeaseId, setSelectedLeaseId] = useState('');
  const [contentType, setContentType] = useState(null);
  const { PRIVATE_BASE_PATH } = useConfig();

  let leasesOptions = [
    {
      value: undefined,
      label: '',
    },
  ];

  if (skipFiltering) {
    leasesOptions = [...leasesOptions, ...leases];
  } else {
    const filteredLeases = leases.filter((status) => status !== 'PAST');

    filteredLeases.forEach(({ id: value, title: label }) =>
      leasesOptions.push({
        value,
        label,
      }),
    );
  }

  const handleLeaseSelect = (event) => {
    const value = event.target.value;
    setSelectedLeaseId(value);

    if (!value) {
      return setContentType(undefined);
    }

    const selectedLease = leases.find(({ id }) => id === value);

    const leaseAgreementStatus = selectedLease?.lease_agreement?.status;

    if (leaseAgreementStatus === 'COMPLETED') {
      return setContentType('LEASE_AGREEMENT_COMPLETED');
    }

    if (leaseAgreementStatus) {
      return setContentType('LEASE_AGREEMENT_IN_PROGRESS');
    }

    setContentType('LEASE_AGREEMENT_AVAILABLE');
  };

  const handleSubmit = () => {
    onSubmit &&
      onSubmit({
        leaseId: selectedLeaseId,
        leaseAgreementId,
      });
  };

  return (
    <Modal
      open={open}
      className={cx(styles.modal, className)}
      containerClassName={cx(styles.modalContainer, containerClassName)}
      onClose={onGoBack}
    >
      <h2 className={styles.title}>
        Which lease would you like to copy this Lease Agreement to?
      </h2>

      <p>
        This will duplicate your lease agreement to use as a template for
        another lease with the same state.{' '}
        <b>
          You’ll be able to review and customize the copied lease agreement.
        </b>
      </p>

      <SelectField
        id="lease"
        label="Lease"
        disabled={false}
        input={{
          value: selectedLeaseId,
          onChange: handleLeaseSelect,
        }}
        options={leasesOptions}
        className={cx(styles.select, {
          [styles.lease]: contentType,
        })}
      />

      {contentType === 'LEASE_AGREEMENT_IN_PROGRESS' && (
        <InlineAlert type="warning" containerClassName={styles.alertContainer}>
          <span className={styles.warningContent}>
            You already started an agreement for this lease.{' '}
            <a
              href={`${PRIVATE_BASE_PATH}digital-leases/${selectedLeaseId}/overview`}
            >
              Continue filling it out
            </a>
            , or select a different lease above.
          </span>
        </InlineAlert>
      )}

      {contentType === 'LEASE_AGREEMENT_COMPLETED' && (
        <InlineAlert type="danger" containerClassName={styles.alertContainer}>
          <span className={styles.warningContent}>
            You already have an agreement for this lease.{' '}
            <a
              href={`${PRIVATE_BASE_PATH}leases/view/${selectedLeaseId}/summary`}
            >
              View the lease
            </a>
            , or select a different lease above.
          </span>
        </InlineAlert>
      )}

      <Button
        className={styles.btn}
        disabled={
          !selectedLeaseId ||
          contentType !== 'LEASE_AGREEMENT_AVAILABLE' ||
          loading
        }
        loading={loading}
        onClick={handleSubmit}
      >
        COPY LEASE AGREEMENT
      </Button>
    </Modal>
  );
};

CopyLeaseAgreementModal.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  skipFiltering: PropTypes.bool,
  onGoBack: PropTypes.func.isRequired,
  leases: PropTypes.arrayOf(PropTypes.object),
  leaseAgreementId: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default CopyLeaseAgreementModal;
