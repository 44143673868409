import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Warning = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 24" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.442 2.176a.5.5 0 0 0-.895 0L1.963 21.37a.5.5 0 0 0 .448.724h19.167a.5.5 0 0 0 .447-.724L12.442 2.176Zm.152 6.911a.6.6 0 1 0-1.2 0v6.616a.6.6 0 1 0 1.2 0V9.087Zm-.6 8.34c-.573 0-1.032.458-1.032 1.032 0 .573.459 1.031 1.032 1.031s1.032-.458 1.032-1.031c0-.574-.459-1.032-1.032-1.032Z"
      fill={color}
    />
  </svg>
);

Warning.defaultProps = {
  width: 24,
  height: 24,
  color: colors.warningLight,
};

Warning.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Warning);
