import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import ArrowFilledDown from '../../../icons/streamline/ArrowFilledDown';

import { colors as tagColors, types } from './constants';

import styles from './Tag.module.scss';

const Tag = ({ color = 'navyBlue', children, type = 'default' }) => {
  return (
    <div
      className={cx(
        styles.container,
        styles[`color-${color}`],
        styles[`type-${type}`],
      )}
    >
      {children}
      {type === 'dropdown' && <ArrowFilledDown className={styles.caret} />}
    </div>
  );
};

Tag.propTypes = {
  color: PropTypes.oneOf(tagColors),
  type: PropTypes.oneOf(types),
  children: PropTypes.node,
};

export default Tag;
