import { useEffect, useState } from 'react';
import { load as loadRecaptcha } from 'recaptcha-v3';

import { FEATURE_FLAGS } from '../constants/feature_flags';
import { useConfig } from '../providers/ConfigProvider';
import { getItem } from '../services/localStorageService';

import { useStatusEndpoint } from './status-endpoint';

const useLoginRecaptcha = (ignoreLoginFailed = false) => {
  const { RECAPTCHA_LOGIN_SITE_KEY, RECAPTCHA_REQUIRED_AFTER_LOGIN_FAILED_MS } =
    useConfig();
  const { loading: loadingGlobalFeatures, globalFeatures } =
    useStatusEndpoint();
  const [recaptcha, setRecaptcha] = useState(null);

  useEffect(() => {
    const shouldLoadRecaptcha =
      !loadingGlobalFeatures &&
      globalFeatures.includes(FEATURE_FLAGS.LOGIN_RECAPTCHA);
    if (shouldLoadRecaptcha) {
      recaptcha ||
        loadRecaptcha(RECAPTCHA_LOGIN_SITE_KEY).then((r) => {
          setRecaptcha(r);
        });
    }
  }, [loadingGlobalFeatures]);
  return {
    getLoginCaptchaToken: async () => {
      const loginFailedTS = getItem('login_failed');
      if (
        !window.isCypress &&
        !loadingGlobalFeatures &&
        globalFeatures.includes(FEATURE_FLAGS.LOGIN_RECAPTCHA) &&
        (globalFeatures.includes(FEATURE_FLAGS.FORCE_LOGIN_RECAPTCHA) ||
          ignoreLoginFailed ||
          (loginFailedTS &&
            Number(loginFailedTS) >
              Date.now() - RECAPTCHA_REQUIRED_AFTER_LOGIN_FAILED_MS))
      ) {
        if (!recaptcha) {
          throw new Error('Failed to load recaptcha');
        }
        // We only send captcha if it's been less than 5 minutes from a failed login
        return await recaptcha.execute('submitLogin');
      }
    },
    getResetPasswordCaptchaToken: async () => {
      if (!globalFeatures.includes(FEATURE_FLAGS.LOGIN_RECAPTCHA)) {
        return null;
      }
      if (!recaptcha) {
        throw new Error('Failed to load recaptcha');
      }
      // We only send captcha if it's been less than 5 minutes from a failed login
      return recaptcha.execute('resetPassword');
    },
  };
};

export { useLoginRecaptcha };
