import React from 'react';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './TabsLayout.module.scss';

const TabsLayout = ({
  className,
  headerContent,
  headerControls,
  headerControlsClassName,
  headerWrapperClassName,
  headerClassName,
  headerContentClassName,
  headerLinksClassName,
  contentClassName,
  children,
  tabs,
  dataQa,
  stickyHeader,
  stickyFooter,
}) => (
  <div className={cx(styles.container, className)} data-qa={dataQa}>
    <div
      className={cx(styles.header, headerWrapperClassName, {
        [styles.sticky]: stickyHeader,
      })}
    >
      <div className={cx(styles.content, headerClassName)}>
        {headerControls && (
          <div className={cx(styles.controls, headerControlsClassName)}>
            {headerControls}
          </div>
        )}
        {headerContent && (
          <div className={cx(styles.headerContent, headerContentClassName)}>
            {headerContent}
          </div>
        )}
        {tabs && (
          <div className={cx(styles.links, headerLinksClassName)}>
            {tabs.map((tab, index) => (
              <>
                <NavLink
                  to={tab.url}
                  className={cx(styles.link, {
                    [styles.withLabelCount]: tab.labelCount,
                  })}
                  key={tab.id || index}
                  id={tab.id}
                  activeClassName={cx(styles.activeLink)}
                  data-qa={tab.dataQa}
                  onClick={tab.onClick}
                  exact={tab.url?.exact}
                >
                  {tab.label}
                </NavLink>
                {!!tab.labelCount && (
                  <div
                    className={styles.labelCount}
                    key={`${tab.id || index}-count`}
                  >
                    {tab.labelCount}
                  </div>
                )}
              </>
            ))}
            <div className={styles.spacer} />
          </div>
        )}
      </div>
    </div>
    <div className={cx(styles.tabs, { [styles.sticky]: stickyHeader })}>
      <div
        className={cx(styles.tabContent, contentClassName, {
          [styles.withStickyFooter]: stickyFooter,
        })}
      >
        {children}
      </div>
    </div>
  </div>
);

TabsLayout.propTypes = {
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  headerControlsClassName: PropTypes.string,
  headerContentClassName: PropTypes.string,
  headerWrapperClassName: PropTypes.string,
  headerLinksClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  tabs: PropTypes.array,
  children: PropTypes.node,
  headerContent: PropTypes.node,
  headerControls: PropTypes.node,
  dataQa: PropTypes.string,
  stickyHeader: PropTypes.bool,
  stickyFooter: PropTypes.bool,
};

export default React.memo(TabsLayout);
