import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';
import qs from 'query-string';

import Footer from '../../components/Footer';
import Logo from '../../components/Logo';
import { LAYOUT_IDS, RENTAL_REQUEST_SOURCES } from '../../constants';
import getExperimentVariant from '../../helpers/experiments';
import useApplicationFetcher from '../../hooks/useApplicationFetcher';
import useBodyAndRootStyles from '../../hooks/useBodyAndRootStyles';
import useListingFetcher from '../../hooks/useListingFetcher';
import RedfinLogo from '../../icons/RedfinLogo';
import useTokenStatus from '../../pages/applications/hooks/useTokenStatus';
import ApplicationContext from '../../pages/applications/providers/ApplicationProvider';
import { getItem, setItem } from '../../services/localStorageService';

import styles from './ApplicationFlowLayout.module.scss';

const Header = ({ shouldHideHeaderFooter, source, step }) => {
  if (shouldHideHeaderFooter) return null;

  const renderLogo = () => {
    if (source === RENTAL_REQUEST_SOURCES.REDFIN_API) {
      return (
        <div
          className={cx(styles.logoContainer, { [styles.hide]: step !== 0 })}
        >
          <RedfinLogo className={styles.partnerLogo} />
          <div className={styles.poweredByTT}>
            <span>Powered by</span>
            <Logo />
          </div>
        </div>
      );
    }

    return <Logo maxheight={40} maxwidth={139} className={styles.logo} />;
  };

  return renderLogo();
};

Header.propTypes = {
  shouldHideHeaderFooter: PropTypes.bool,
  source: PropTypes.string,
  step: PropTypes.number,
};

const ApplicationFlowLayout = ({
  showFooterContent = true,
  children,
  match = { params: {} },
  location,
  isDemo = false,
}) => {
  useBodyAndRootStyles('min-height: 100vh;');

  const applicationId = match.params.applicationId || null;
  const listingId = match.params.listingId || null;
  const step = match.params.step || 0;

  const tokenStatus = useTokenStatus(applicationId);

  const fetchApplication = useApplicationFetcher();
  const fetchListing = useListingFetcher();
  const history = useHistory();

  const {
    data: listing,
    loading: isListingLoading,
    error,
  } = fetchListing(listingId || null);

  const { data: application, loading } = fetchApplication({
    applicationId,
    skip:
      applicationId === null ||
      !!tokenStatus.isExpired ||
      tokenStatus.loading ||
      isDemo,
  });

  const handleAuthError = (error) => {
    const graphqlError = error?.graphQLErrors?.[0];
    if (
      graphqlError?.authenticationError === false &&
      graphqlError?.code === 464
    ) {
      history.push('/login');
    }
  };

  useEffect(() => {
    const ownerExperiments = listing?.owner?.active_experiments || [];

    if (ownerExperiments.length > 0 && !getItem('owner_active_experiments')) {
      setItem('owner_active_experiments', JSON.stringify(ownerExperiments));
    }
  }, [listing]);

  useEffect(() => {
    if (error) {
      handleAuthError(error);
    }
  }, [error]);

  const apartmentsExperimentVariant = listing?.owner
    ? getExperimentVariant(listing.owner, 'apartments_com_transition_test')
    : null;

  const isInApartmentsExperiment =
    apartmentsExperimentVariant && apartmentsExperimentVariant !== 'Control';

  const [isExperimentLoading, setIsExperimentLoading] = useState(false);

  const showApartmentsLoading = isInApartmentsExperiment && isExperimentLoading;

  const query = location?.search ? qs.parse(location.search) : {};

  const source = query?.source || application?.source;

  const isFromApartments = source === RENTAL_REQUEST_SOURCES.APARTMENTS;

  const shouldHideHeaderFooter =
    isFromApartments && (isListingLoading || showApartmentsLoading);

  useEffect(() => {
    if (isFromApartments && isInApartmentsExperiment) {
      setIsExperimentLoading(true);
      const timer = setTimeout(() => setIsExperimentLoading(false), 4000);

      return () => clearTimeout(timer);
    }
  }, [isFromApartments, isInApartmentsExperiment]);

  const contextValues = useMemo(
    () => ({
      application,
      loading,
      isApartmentsApplicationLoading: showApartmentsLoading,
      isListingLoading,
      listing,
    }),
    [application, loading, showApartmentsLoading, isListingLoading, listing],
  );

  return (
    <ApplicationContext.Provider value={contextValues}>
      <div className={cx(styles.container, styles[source])}>
        <Header
          shouldHideHeaderFooter={shouldHideHeaderFooter}
          source={source}
          step={step}
        />
        {children}
        {!shouldHideHeaderFooter && showFooterContent && (
          <Footer className={styles.footer} />
        )}
      </div>
    </ApplicationContext.Provider>
  );
};

ApplicationFlowLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  match: PropTypes.object,
  location: PropTypes.object,
  isDemo: PropTypes.bool,
  showFooterContent: PropTypes.bool,
};

ApplicationFlowLayout.layoutId = LAYOUT_IDS.APPLICATION_FLOW;

export default ApplicationFlowLayout;
