import React, { memo } from 'react';
import PropTypes from 'prop-types';

const FormsTwoTone = ({ color, fillColor, ...props }) => (
  <svg viewBox="0 0 56 56" fill="none" {...props}>
    <g fillRule="evenodd" clipRule="evenodd">
      <path
        d="M5.833 32.666h12.834v4.667h18.666v-4.666h12.834v15.166H5.833V32.667Z"
        fill={fillColor}
      />
      <path
        d="M44.528 7c.537 0 .972.427.972.955v23.862l4.861.001c.537 0 .972.427.972.955v15.272a.964.964 0 0 1-.972.955H5.64a.964.964 0 0 1-.972-.955V32.773c0-.528.435-.955.972-.955H10.5V11.772c0-.487.371-.889.85-.947l.122-.008h10.695V7.954c0-.487.37-.889.85-.948L23.139 7h21.389Zm-26.25 26.727H6.61v13.364H49.39V33.727H37.722v2.864a.964.964 0 0 1-.972.954h-17.5a.963.963 0 0 1-.972-.954v-2.864Zm3.889-21h-9.723v19.09l6.806.001c.537 0 .972.427.972.955v2.863h1.945V12.727ZM43.556 8.91H24.11v26.727h11.667v-2.863c0-.528.435-.955.972-.955h6.805V8.908ZM40.639 28c.537 0 .972.427.972.954a.964.964 0 0 1-.972.955H27.028a.964.964 0 0 1-.972-.954c0-.528.435-.955.972-.955h13.611Zm0-3.818c.537 0 .972.427.972.954a.964.964 0 0 1-.972.955H27.028a.964.964 0 0 1-.972-.955c0-.527.435-.954.972-.954h13.611Zm0-3.818c.537 0 .972.427.972.954a.963.963 0 0 1-.972.955H27.028a.963.963 0 0 1-.972-.955c0-.527.435-.954.972-.954h13.611Zm0-3.819c.537 0 .972.428.972.955a.963.963 0 0 1-.972.954H27.028a.963.963 0 0 1-.972-.954c0-.527.435-.954.972-.954h13.611Zm0-3.818c.537 0 .972.428.972.955a.964.964 0 0 1-.972.954h-9.722a.964.964 0 0 1-.973-.954c0-.527.436-.955.973-.955h9.722Z"
        fill={color}
      />
    </g>
  </svg>
);

FormsTwoTone.defaultProps = {
  width: 56,
  height: 56,
  color: '#042238',
  fillColor: '#7FE3FF',
};

FormsTwoTone.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  fillColor: PropTypes.string,
};

export default memo(FormsTwoTone);
