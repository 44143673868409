import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { get } from 'lodash';
import { parse } from 'query-string';
import { useDebounce } from 'use-debounce';

import EsignIllustration from '../../../assets/illustrations/Esign';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import InfiniteList from '../../../components/InfiniteList';
import { useErrorToast } from '../../../components/Toast';
import { H2 } from '../../../components/Typography';
import { useLeases } from '../../../core/TTgraphql';
import cancelSignatureRequest from '../../../graphql/mutations/cancelSignatureRequest.graphql';
import signatureRequestsQuery from '../../../graphql/queries/esign/signatureRequestIndexQuery.graphql';
import useSignatureRequests from '../../../hooks/useSignatureRequests';
import { useConfig } from '../../../providers/ConfigProvider';
import { segmentTracking } from '../../../services/utilities';
import HowESignWorks from '../../electronic-signature/HowESignWorks';
import SignatureRequestCanceledModal from '../../owners/components/SignatureRequestCanceledModal';
import SignatureRequest from '../../owners/leases/view/SignatureRequest';

import EsignSkeleton from './Skeleton/EsignSkeleton';
import EsignFilters, { useEsignFilters } from './EsignFilters';

import styles from './EsignTab.module.scss';

const EsignTab = () => {
  const history = useHistory();
  const showErrorMessage = useErrorToast();
  const { PRIVATE_BASE_PATH } = useConfig();
  const { resetFilters, handleSearch, filters, sortConfig, ...filterProps } =
    useEsignFilters();
  const [showCancelSuccessModal, setShowCancelSuccessModal] = useState(false);

  const [debouncedSearch] = useDebounce(filters.search, 800);

  const { loading, data, fetchMore, total } = useSignatureRequests({
    variables: {
      filters: {
        listings: filters.listing_ids,
        leases: filters.lease_ids,
        signers: filters.signers,
        search: debouncedSearch,
        status: filters.status || 'ALL',
      },
      sort: sortConfig?.value,
      sortDirection: sortConfig?.direction,
    },
  });

  const { leases, loading: loadingLeases } = useLeases({}, 0);
  const [doCancelRequest, { loading: loadingCancelRequest }] = useMutation(
    cancelSignatureRequest,
  );

  useEffect(() => {
    const searchedDocument = parse(location.search).document || '';
    if (searchedDocument && !filters.search) {
      handleSearch(searchedDocument);
      window.history.replaceState({}, '', location.pathname);
    }
  }, []);

  const loadingData = loading || loadingLeases || loadingCancelRequest;

  const cancelRequest = async ({
    signatureRequestId,
    wizardDocumentId,
    edit = false,
    isLeaseAgreement,
    isLeaseAddendum,
    leaseId,
  }) => {
    segmentTracking('yes_cancel_it', {
      location: 'Cancel Esign',
    });

    try {
      await doCancelRequest({
        variables: {
          signatureRequestId: signatureRequestId,
        },
        refetchQueries: [signatureRequestsQuery],
      });

      if (edit) {
        if (isLeaseAgreement) {
          return history.push(
            `${PRIVATE_BASE_PATH}digital-leases/${leaseId}/overview`,
          );
        }

        if (isLeaseAddendum && wizardDocumentId) {
          return history.push(
            `${PRIVATE_BASE_PATH}rental-addendum/${leaseId}/${wizardDocumentId}`,
          );
        }

        return history.push(
          `${PRIVATE_BASE_PATH}e-sign-lease/${leaseId}/${signatureRequestId}/add-documents?goBack=leases-view&editMode=true`,
        );
      }

      setShowCancelSuccessModal(true);
    } catch (e) {
      console.error(e);
      const message = get(e, 'graphQLErrors[0].message', 'An error occurred');

      showErrorMessage(message);
    }
  };

  if (
    total === 0 &&
    !filters.search &&
    !filters.listing_ids.length &&
    !filters.lease_ids.length &&
    !filters.signers.length &&
    !filters.status &&
    !loadingData
  ) {
    return <HowESignWorks leases={leases} />;
  }

  return (
    <div className={styles.container}>
      <EsignFilters
        filters={filters}
        sortConfig={sortConfig}
        {...filterProps}
        handleSearch={handleSearch}
        resetFilters={resetFilters}
      />

      {loadingData ? (
        <EsignSkeleton />
      ) : (
        <InfiniteList
          data={data}
          onNeedMoreRows={fetchMore}
          rowRenderer={({ item, onExpand }) => (
            <Card className={styles.summaryContainer}>
              <SignatureRequest
                signature_request={item}
                leaseId={item.lease_id}
                lease={leases.find((lease) => lease.id === item.lease_id)}
                listVariant
                onExpand={onExpand}
                cancelRequest={cancelRequest}
              />
            </Card>
          )}
          rowCount={total}
          defaultRowHeight={220}
          overscanRowCount={5}
        />
      )}

      {!loadingData && !data.length ? (
        <Card className={styles.noResults}>
          <EsignIllustration />
          <H2 className={styles.title}>
            No e-signatures match that filter criteria
          </H2>
          <Button className={styles.button} secondary onClick={resetFilters}>
            Reset all filters
          </Button>
        </Card>
      ) : null}
      <SignatureRequestCanceledModal
        open={showCancelSuccessModal}
        onClose={() => setShowCancelSuccessModal(false)}
        onDone={() => setShowCancelSuccessModal(false)}
      />
    </div>
  );
};

export default EsignTab;
