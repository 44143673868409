/**
 * Calculates the discounted price based on a coupon
 *
 * @param {number} price - The original price
 * @param {object} coupon - The coupon object containing discount information
 * @param {string} productId - The ID of the product to check if the coupon applies
 * @returns {number} - The discounted price or original price if no discount applies, rounded up to nearest integer
 */
const getDiscountedPrice = (price, coupon, productId) => {
  if (!coupon) return Math.ceil(price);

  const appliesForThisProduct = coupon?.applies_to?.products?.find(
    (product) => product?.id === productId,
  );

  if (!appliesForThisProduct) return Math.ceil(price);

  if (coupon.percent_off) {
    return Math.ceil(price * (1 - coupon.percent_off / 100));
  }

  if (coupon.amount_off) {
    return Math.ceil(Math.max(0, price - coupon.amount_off / 100)); // amount_off is in cents
  }

  return Math.ceil(price);
};

export default getDiscountedPrice;
