import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import basicUserProfile from '../../../graphql/basicUserProfile.graphql';
import ownerSettingsQuery from '../../../graphql/queries/ownerSettingsQuery.graphql';
import BusinessBankColor from '../../../icons/BusinessBankColor';
import DollarCircleBlue from '../../../icons/DollarCircleBlue';
import ESignColor from '../../../icons/ESignColor';
import Forms from '../../../icons/Forms';
import Badge from '../../../icons/PremiumBadge';
import RadialCircleNew from '../../../icons/RadialCircleNew';
import Reputation from '../../../icons/Reputation';
import YardSignColor from '../../../icons/YardSignColor';
import { useConfig } from '../../../providers/ConfigProvider';
import Button from '../../Button';
import Card from '../../Card';
import Logo from '../../Logo';
import PremiumSubscriptionModal from '../../PremiumSubscription';
import { MODAL_LOCATIONS } from '../constants';

import styles from './PremiumToolbox.module.scss';

const getTools = ({ FORMS_PACK_PRICE, ESIGNATURE_FEE }) => [
  {
    icon: YardSignColor,
    title: 'Prioritized Marketing',
    text: (
      <>
        Publish your listing to marketing sites faster with prioritized review —{' '}
        <i>normally takes up to 48 hours</i>.
      </>
    ),
  },
  {
    icon: Forms,
    title: 'Unlimited Lease Forms',
    text: (
      <>
        Get unlimited lease agreements, addendums, and our landlord forms pack —{' '}
        <i>a ${FORMS_PACK_PRICE} value</i>.
      </>
    ),
  },
  {
    icon: ESignColor,
    title: 'Unlimited E-Sign',
    text: (
      <>
        Get unlimited e-signatures on your documents —{' '}
        <i>normally valued at ${ESIGNATURE_FEE} per signature</i>.
      </>
    ),
  },
  {
    icon: BusinessBankColor,
    title: 'Enhanced Rent Payments',
    text: 'Get deposits twice as fast & unlock multiple bank accounts for deposits.',
  },
  {
    icon: DollarCircleBlue,
    title: 'Income Insights',
    text: (
      <>
        Included with screening reports, <i>Income Insights</i> checks the
        accuracy of applicants' self-reported income.
      </>
    ),
  },
  {
    icon: Reputation,
    title: '…And More to Come!',
    text: "We're continually striving to make landlording a more efficient process for our users.",
  },
];

const PremiumToolbox = ({ className, user }) => {
  const premiumActive = user?.premium_subscription_subscribed;
  const { FORMS_PACK_PRICE, ESIGNATURE_FEE } = useConfig();
  const [showPremiumModal, setShowPremiumModal] = useState(false);

  const togglePremiumModal = () => {
    setShowPremiumModal(!showPremiumModal);
  };

  return (
    <Card className={cx(styles.container, className)}>
      <RadialCircleNew className={styles.radialCircle} />
      <Badge className={styles.badge} />
      <div className={styles.header}>
        <Logo className={styles.logo} />
        <h3 className={styles.title}>
          {premiumActive
            ? 'Premium Subscription Active!'
            : 'Become a Premium Member'}
        </h3>
        <p className={styles.text}>
          {premiumActive
            ? 'You unlocked access to all the tools you need to manage your property professionally, efficiently, and confidently.'
            : 'Get all-inclusive access to all the tools you need to manage your property professionally, efficiently, and confidently.'}
        </p>
        {!premiumActive && (
          <Button className={styles.button} onClick={togglePremiumModal}>
            Get Premium
          </Button>
        )}
      </div>
      <div className={styles.toolsWrapper}>
        {getTools({ FORMS_PACK_PRICE, ESIGNATURE_FEE }).map((tool, index) => (
          <div className={styles.tool} key={index}>
            <tool.icon className={styles.toolIcon} />
            <div className={styles.toolTextWrapper}>
              <h4 className={styles.toolTitle}>{tool.title}</h4>
              <p className={styles.toolText}>{tool.text}</p>
            </div>
          </div>
        ))}
      </div>
      {!premiumActive && (
        <Button
          className={cx(styles.button, styles.showMobile)}
          onClick={togglePremiumModal}
        >
          Get Premium
        </Button>
      )}
      {showPremiumModal && (
        <PremiumSubscriptionModal
          openLocation={MODAL_LOCATIONS.TOOLBOX}
          onClose={togglePremiumModal}
          onSuccess={togglePremiumModal}
          refetchQueries={[basicUserProfile, ownerSettingsQuery]}
        />
      )}
    </Card>
  );
};

PremiumToolbox.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
};

export default PremiumToolbox;
