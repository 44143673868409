import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import { FORM_ERROR } from 'final-form';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import FlatButton from '../../../components/FlatButton';
import PageNotFound from '../../../components/PageNotFound';
import SideBarReviews from '../../../components/SideBarReviews';
import { TermsPrivacy } from '../../../components/SignupForm/TermsPrivacy';
import SignupFormExperiment from '../../../components/SignupFormProExperiment';
import SocialSignup from '../../../components/SocialSignup';
import { Paragraph } from '../../../components/Typography';
import authPage from '../../../hoc/auth-page';
import MarketingLayout from '../../../layout/MarketingLayout';
import { setItem } from '../../../services/localStorageService';
import {
  capitalizeFirstLetter,
  segmentTracking,
} from '../../../services/utilities';
import { SIGNUP_TEST_ONBOARDING } from '../signup/ABtestsHelper/FlowPicker';
import useSignupMutation from '../signup/useSignupMutation';

import { PlansEnum, quote, segmentLocation, signupTestValue } from './utils';

import styles from './SignupPageExperiment.module.scss';

const SignupPageExperiment = ({ location, plan }) => {
  const signup = useSignupMutation(location);
  const history = useHistory();

  useEffect(() => {
    /**
     * NOTE: Users that arrive at this flow, will be tagged with this experiment value
     */
    setItem(SIGNUP_TEST_ONBOARDING, signupTestValue);

    segmentTracking('onboarding started', {
      location: segmentLocation,
      experiment: `Purchase Onboarding Flow Experiment`,
    });
  }, []);

  const onSubmit = async (data) => {
    try {
      segmentTracking('sign_up clicked', {
        location: segmentLocation,
        experiment: `Purchase Onboarding Flow Experiment`,
      });

      await signup({
        ...data,
        experiment: `Purchase Onboarding Flow Experiment`,
        signup_test: signupTestValue,
        ...(location.state?.personalizeData || {}),
      });

      segmentTracking('account_created', {
        location: segmentLocation,
        experiment: `Purchase Onboarding Flow Experiment`,
      });

      history.push(`/onboarding/subscription/${plan}`);
    } catch (error) {
      const parsedError = get(error, `graphQLErrors[0]`) || {};
      if (parsedError && parsedError.code === 471) {
        // return email error
        return {
          email: parsedError.message,
        };
      }
      if (parsedError && parsedError.code === 500) {
        return {
          [FORM_ERROR]: 'Something went wrong, please try again',
        };
      }
      const message = parsedError.message || 'Something went wrong';
      return {
        [FORM_ERROR]: message,
      };
    }
  };

  if (plan !== PlansEnum.PRO && plan !== PlansEnum.PREMIUM) {
    return <PageNotFound actionTitle="Back Home" />;
  }

  return (
    <MarketingLayout
      action={
        <div className={styles.action}>
          <FlatButton
            to="/auth/login"
            className={styles.loginDesktop}
            onClick={() => {
              segmentTracking('log_in clicked', {
                location: segmentLocation,
              });
            }}
          >
            Log In
          </FlatButton>
          <Button
            style={{ width: 176 }}
            to="/auth/signup"
            small={true}
            secondary={true}
            onClick={() => {
              segmentTracking('sign_up_for_free clicked', {
                location: segmentLocation,
              });
            }}
          >
            Sign up for free
          </Button>
        </div>
      }
      side={
        <SideBarReviews
          alignTop
          titleClassName={styles.quoteText}
          quote={quote}
          reviewer="— Barbara H."
        />
      }
    >
      <div className={cx(styles.container)}>
        <div className={cx(styles.content)}>
          <h2 className={cx(styles.title)}>
            Sign up for your {capitalizeFirstLetter(plan)} plan
          </h2>
          <Paragraph className={styles.subtitle}>
            Create your account to access expedited rent payouts, unlimited
            lease agreements, priority listing review, and more!
          </Paragraph>
          <SocialSignup
            className={styles.social}
            segmentLocation={segmentLocation}
          />
          <SignupFormExperiment onSubmit={onSubmit} />
          <TermsPrivacy />
        </div>
      </div>
    </MarketingLayout>
  );
};

SignupPageExperiment.propTypes = {
  location: PropTypes.object.isRequired,
  plan: PropTypes.string.isRequired,
};

export default authPage(SignupPageExperiment);
