import React, { useCallback } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { OnChange } from 'react-final-form-listeners';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import FinalForm from '../../../../components/FinalForm';
import {
  DateField,
  EmailField,
  InputField,
  RadioField,
  SegmentedControllerField,
} from '../../../../components/FinalFormFields';
import FlatButton from '../../../../components/FlatButton';
import { isValidEmail } from '../../../../helpers/isValidEmail';
import removeTypeNames from '../../../../helpers/remove-type-names';
import PlusIcon from '../../../../icons/PlusCircle';
import TrashIcon from '../../../../icons/Remove';
import fieldRequired from '../../../../validators/fieldRequired';
import isDateLowerThan from '../../../../validators/isDateLowerThan';
import isLengthBetween from '../../../../validators/isLengthBetween';

import styles from './InfoEdit.module.scss';

const defaultCoApplicant = {
  name: '',
  email: '',
  type: 'TENANT',
};

const emailInUseValidator = (index, coApplicants, renterEmail) => (value) => {
  if (!value) {
    return undefined;
  }

  if (!isValidEmail(value)) {
    return 'Email is not valid';
  }

  const coApplicantsEmail = (coApplicants || []).map(
    (coApplicant) => coApplicant.email,
  );

  const prevEmail = coApplicantsEmail[index];

  // email did not change
  if (prevEmail === value) return undefined;

  if (index) {
    coApplicantsEmail.splice(index, 1);
  }

  const emailsToCheck = [renterEmail, ...coApplicantsEmail];
  const emailIsUsed = emailsToCheck.some(
    (email) => email.toLowerCase() === value.toLowerCase(),
  );

  if (emailIsUsed) {
    return 'Email is already in use.';
  }

  return undefined;
};

const CoApplicantSection = ({
  applying_as_tenant,
  co_applicant,
  renterEmail,
}) => (
  <FieldArray name="co_applicants">
    {({ fields }) => (
      <>
        {fields.map((name, index) => {
          return (
            <div
              key={`co_applicant_${index}`}
              style={{
                marginTop: 16,
                marginBottom: 16,
              }}
              className={styles.creds}
            >
              <div className={styles.input} style={{ marginRight: 16 }}>
                <InputField
                  name={`${name}.name`}
                  label={
                    applying_as_tenant
                      ? "Co-Applicant's Name"
                      : "Applicant's name"
                  }
                />
              </div>
              <div className={styles.input} style={{ marginRight: 16 }}>
                <EmailField
                  required={false}
                  name={`${name}.email`}
                  label={
                    applying_as_tenant
                      ? "Co-Applicant's Email"
                      : "Applicant's email"
                  }
                  validate={emailInUseValidator(
                    index,
                    fields.value,
                    renterEmail,
                  )}
                />
              </div>
              {applying_as_tenant && (
                <div className={styles.input}>
                  <SegmentedControllerField
                    name={`${name}.type`}
                    containerClassName={styles.segmentedController}
                    options={[
                      { label: 'Tenant', value: 'TENANT' },
                      { label: 'Co-signer', value: 'CO_SIGNER' },
                    ]}
                    label="They are a:"
                  />
                </div>
              )}
              {index !== 0 && (
                <FlatButton
                  small
                  id="remove_co_applicant"
                  icon={TrashIcon}
                  className={styles.removeButton}
                  onClick={() => fields?.remove(index)}
                />
              )}
            </div>
          );
        })}
        {co_applicant && applying_as_tenant && (
          <FlatButton
            id="add_co_applicant"
            small
            icon={PlusIcon}
            iconProps={{
              color: 'red',
            }}
            className={styles.addButton}
            onClick={() => {
              fields?.push(defaultCoApplicant);
            }}
          >
            add co-applicant
          </FlatButton>
        )}
      </>
    )}
  </FieldArray>
);
CoApplicantSection.propTypes = {
  applying_as_tenant: PropTypes.bool,
  co_applicant: PropTypes.bool,
  renterEmail: PropTypes.string,
};
const InfoEdit = ({ id, onSubmit, application, formRef }) => {
  const onSave = useCallback(
    (values) => {
      onSubmit({
        ...values,
        co_applicants: values?.co_applicants?.map(removeTypeNames),
      });
    },
    [application],
  );

  return (
    <FinalForm
      id={id}
      ref={formRef}
      initialValues={{
        first_name: application?.first_name,
        last_name: application?.last_name,
        applying_as_tenant: application?.applying_as_tenant,
        co_applicant: application?.co_applicant,
        co_applicants: application?.co_applicants,
        birthdate: application?.birthdate,
        smoking: application?.smoking,
      }}
      onSubmit={onSave}
    >
      {({ values, form }) => {
        return (
          <article>
            <div className={styles.infoEditContainer}>
              <div className={styles.creds}>
                <div className={cx(styles.leftSide, styles.flex)}>
                  <InputField
                    name="first_name"
                    label="First Name"
                    validate={[fieldRequired, isLengthBetween(1, 15)]}
                    data-qa="about-you-firstname-input"
                    className={styles.nameField}
                  />
                  <InputField
                    name="last_name"
                    label="Last Name"
                    validate={[fieldRequired, isLengthBetween(2, 25)]}
                    data-qa="about-you-lastname-input"
                    className={styles.nameField}
                  />
                </div>
                <div className={styles.applyingAs}>
                  <RadioField
                    name="applying_as_tenant"
                    className={styles.whatAreYouApplyingAs}
                    options={[
                      { value: true, label: 'Tenant' },
                      { value: false, label: 'Co-Signer' },
                    ]}
                    label="applying as a"
                  />
                </div>
              </div>
              {values?.applying_as_tenant && (
                <div
                  className={cx(styles.leftSide, styles.applyingWithSomeone)}
                >
                  <RadioField
                    name="co_applicant"
                    label="Applying with someone else?"
                    options={[
                      {
                        value: true,
                        label: 'Yes',
                        id: 'co_applicant_yes',
                      },
                      {
                        value: false,
                        label: 'No',
                        id: 'co_applicant_no',
                      },
                    ]}
                  />
                  <OnChange name="co_applicant">
                    {(value) => {
                      if (value && values?.co_applicants?.length === 0) {
                        form.mutators.push('co_applicants', defaultCoApplicant);
                      }
                    }}
                  </OnChange>
                  {values.co_applicant ? (
                    <CoApplicantSection
                      renterEmail={application?.renter.email}
                      {...values}
                    />
                  ) : null}
                </div>
              )}
              {!values?.applying_as_tenant && values.co_applicant && (
                <CoApplicantSection
                  renterEmail={application?.renter.email}
                  {...values}
                />
              )}
              <div className={styles.dateField}>
                <DateField
                  name="birthdate"
                  label="Date of Birth"
                  className={styles.dob}
                  validate={[
                    isDateLowerThan(
                      moment()
                        .add(1, 'day')
                        .subtract(18, 'years')
                        .format('MM/DD/YYYY'),
                      "You can't be less than 18 years old",
                    ),
                  ]}
                />
              </div>
              {!!values?.smoking && (
                <div style={{ marginTop: 16 }}>
                  <RadioField
                    name="smoking"
                    label="Do you smoke?"
                    options={[
                      {
                        label: 'Yes',
                        value: 0,
                        id: 'smoking_yes',
                      },
                      {
                        label: 'Outdoors Only',
                        value: 1,
                        id: 'smoking_outdoors_only',
                      },
                      {
                        label: 'Occasionally',
                        value: 2,
                        id: 'smoking_occasionally',
                      },
                      {
                        label: 'No',
                        value: 3,
                        id: 'smoking_no',
                      },
                    ]}
                  />
                </div>
              )}
            </div>
          </article>
        );
      }}
    </FinalForm>
  );
};

InfoEdit.propTypes = {
  application: PropTypes.object,
  onSubmit: PropTypes.func,
  id: PropTypes.string,
  formRef: PropTypes.object,
  applying_as_tenant: PropTypes.bool,
  co_applicant: PropTypes.bool,
  renterEmail: PropTypes.string,
};

export default InfoEdit;
