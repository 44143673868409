import React from 'react';
import cx from 'classnames';
import { bool, func, object, string } from 'prop-types';

import Button from '../../../components/Button';
import FlatButton from '../../../components/FlatButton';
import Edit from '../../../icons/Edit';
import Print from '../../../icons/Print';
import { useConfig } from '../../../providers/ConfigProvider';

import styles from './HeaderSectionComponent.module.scss';

const HeaderSectionComponent = ({
  children,
  location,
  applicationId,
  title,
  editMode,
  onEditClick,
  editId,
  onSaveClick,
  onCancelClick,
  saved = false,
  partnersApplication,
  hideEditButton = !!partnersApplication,
  showPrintButton,
  id,
}) => {
  const { PRIVATE_BASE_PATH } = useConfig();

  const handlePrintPress = () => {
    if (partnersApplication) {
      window.print();
    } else {
      const pathname = location.pathname;
      const renterType =
        pathname.indexOf('applicant-profile') !== -1 ? 'applicant' : 'tenant';

      window.open(
        `${PRIVATE_BASE_PATH}profile-print/${applicationId}?type=${renterType}`,
        '_blank',
      );
    }
  };

  const saveButtonText = saved ? 'SAVED!' : 'Save Changes';
  const saveButtonTextSmall = saved ? 'SAVED!' : 'Save';
  const cancelAction = saved ? () => {} : onCancelClick;
  const saveAction = saved ? () => {} : onSaveClick;

  return (
    <div className={styles.container}>
      <div className={styles.rowHeader}>
        <div className={cx(styles.rowCenter, { [styles.editMode]: editMode })}>
          <span
            className={cx(styles.title, {
              [styles.partnersApplicationTitle]: partnersApplication,
            })}
          >
            {partnersApplication && id === 'info'
              ? 'Application Overview'
              : title}
          </span>
          {!editMode && !hideEditButton && (
            <FlatButton
              onClick={onEditClick}
              icon={Edit}
              id={editId}
              className={styles.editButton}
            >
              EDIT
            </FlatButton>
          )}
          {editMode && (
            <div className={styles.editControls}>
              <Button
                onClick={cancelAction}
                secondary={true}
                id="cancel"
                className={styles.cancelButton}
                small
              >
                Cancel
              </Button>
              <Button
                onClick={saveAction}
                label={saveButtonText}
                className={styles.saveButton}
                id="save"
                small
              >
                {saveButtonText}
              </Button>
            </div>
          )}
        </div>
        {showPrintButton && !editMode && (
          <div>
            <FlatButton
              icon={Print}
              onClick={handlePrintPress}
              className={styles.printButton}
            >
              PRINT APPLICATION
            </FlatButton>
          </div>
        )}
      </div>
      {children}
      {editMode && (
        <div className={styles.editControlsMobile}>
          <Button
            onClick={cancelAction}
            className={styles.cancelEdit}
            secondary={true}
            small
          >
            Cancel
          </Button>
          <Button onClick={saveAction} className={styles.editSaved} small>
            {saveButtonTextSmall}
          </Button>
        </div>
      )}
    </div>
  );
};

HeaderSectionComponent.propTypes = {
  title: string,
  editMode: bool,
  hideEditButton: bool,
  saved: bool,
  onEditClick: func,
  onSaveClick: func,
  onCancelClick: func,
  children: object,
  editId: string,
  applicationId: string,
  location: object,
  showPrintButton: bool,
  partnersApplication: bool,
  id: string,
};

export default HeaderSectionComponent;
