const getCardBrand = (brand) => {
  if (brand === 'American Express') {
    return 'Amex';
  }
  if (brand === 'MasterCard') {
    return 'Mastercard';
  }
  if (!brand) {
    return 'Card';
  }
  return brand;
};

export default getCardBrand;
