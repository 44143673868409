import React from 'react';
import PropTypes from 'prop-types';

import { useUserProfile } from '../../core/TTgraphql';
import { SUBSCRIPTION_FEATURES } from '../PremiumSubscription/constants';

import IncomeInsightsCard from './IncomeInsightsCard';

const REPORT_PULLED_STATUSES = ['RECEIVED', 'NOT_RECEIVED'];

const IncomeInsightsCardContainer = ({ application, ...props }) => {
  const { user } = useUserProfile({ polling: false });
  const credit_check = application?.credit_check;
  const report = credit_check?.report;

  const reportStatus = application?.credit_check?.status;

  const isReportRequested = reportStatus === 'REQUESTED';

  const instantReport = ['PRE_ACCEPTED', 'RECEIVED'].includes(reportStatus);

  const isReportPulled = REPORT_PULLED_STATUSES.includes(reportStatus);
  const { incomeInsights } = report ? JSON.parse(report) : {};

  const isIncomeInsightsEnabled = user?.packageFeatures?.includes(
    SUBSCRIPTION_FEATURES.INCOME_INSIGHTS,
  );

  const shouldShowInsightsCard =
    isIncomeInsightsEnabled && isReportPulled && !!incomeInsights;

  return shouldShowInsightsCard ? (
    <IncomeInsightsCard
      application={application}
      instantReport={instantReport}
      isReportRequested={isReportRequested}
      incomeInsights={incomeInsights}
      {...props}
    />
  ) : null;
};

IncomeInsightsCardContainer.propTypes = {
  application: PropTypes.object,
};

IncomeInsightsCardContainer.defaultProps = {};

export default IncomeInsightsCardContainer;
