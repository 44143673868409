import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Experiments } from '../../../constants/experiments';
import { useUserProfile } from '../../../core/TTgraphql';
import getExperimentVariant from '../../../helpers/experiments';
import { useIsSmallScreen } from '../../../hooks/useIsSmallScreen';
import { segmentTracking } from '../../../services/utilities';
import { H4 } from '../../Typography';

import BenefitsViewExperimentH from './BenefitsView/BenefitsViewExperimentH';
import BenefitsView from './BenefitsView';

import styles from './TabVariantGBB.module.scss';

const TabVariantGBB = ({
  segmentProperties = {},
  extraParams,
  sections,
  onGetPro,
  onGetPremium,
  allBenefits: benefits,
  displayMonthlyPrice,
  premiumPrice,
  proPrice,
  subscriptionAddOnProps,
  preAppliedCoupon,
}) => {
  const isSmallScreen = useIsSmallScreen();
  const [selectedBenefit, setSelectedBenefit] = useState(benefits[0].key);
  const { user } = useUserProfile();

  const onClickSegment = (event) => {
    segmentTracking(`${event} clicked`, {
      ...segmentProperties,
    });
  };

  const tabs = [
    !isSmallScreen && { key: 'all_features', title: 'All Features' },
    ...sections,
  ];

  const handleTabClick = (tab) => {
    onClickSegment(`${tab.title.toLowerCase().split(' ').join('_')}_tab`);
    setSelectedBenefit(tab.key);
  };

  const gbbExperimentVariant = getExperimentVariant(
    user,
    Experiments.GoodBetterBest.name,
  );

  const isVariantH =
    gbbExperimentVariant === Experiments.GoodBetterBest.variants.H;

  return (
    <div
      className={cx(styles.tabsWrapper, {
        [styles.isVariantH]: isVariantH,
      })}
    >
      <div
        className={cx(styles.tabItems, {
          [styles.withMonthlyPrice]: displayMonthlyPrice,
        })}
      >
        {tabs.map((tab) => (
          <H4
            key={tab.key}
            className={cx(styles.item, {
              [styles.active]: selectedBenefit === tab.key,
            })}
            onClick={() => handleTabClick(tab)}
          >
            {tab.title}
          </H4>
        ))}
      </div>

      <div
        className={
          isVariantH ? styles.packageWrapperVariantH : styles.packageWrapper
        }
      >
        {!isVariantH ? (
          <BenefitsView
            benefits={benefits}
            selectedBenefit={selectedBenefit}
            extraParams={extraParams}
            displayMonthlyPrice={displayMonthlyPrice}
            proPrice={proPrice}
            onGetPro={onGetPro}
            premiumPrice={premiumPrice}
            onGetPremium={onGetPremium}
            isMobile={isSmallScreen}
            onClickSegment={onClickSegment}
            subscriptionAddOnProps={subscriptionAddOnProps}
            preAppliedCoupon={preAppliedCoupon}
          />
        ) : (
          <BenefitsViewExperimentH
            benefits={benefits}
            selectedBenefit={selectedBenefit}
            extraParams={extraParams}
            displayMonthlyPrice={displayMonthlyPrice}
            proPrice={proPrice}
            onGetPro={onGetPro}
            premiumPrice={premiumPrice}
            onGetPremium={onGetPremium}
            isMobile={isSmallScreen}
            onClickSegment={onClickSegment}
            preAppliedCoupon={preAppliedCoupon}
          />
        )}
      </div>
    </div>
  );
};

TabVariantGBB.propTypes = {
  segmentProperties: PropTypes.object,
  extraParams: PropTypes.object,
  sections: PropTypes.array,
  onGetPro: PropTypes.func,
  onGetPremium: PropTypes.func,
  allBenefits: PropTypes.array,
  displayMonthlyPrice: PropTypes.bool,
  premiumPrice: PropTypes.number,
  proPrice: PropTypes.number,
  subscriptionAddOnProps: PropTypes.object,
  preAppliedCoupon: PropTypes.object,
};

export default TabVariantGBB;
