import React, { memo } from 'react';
import PropTypes from 'prop-types';

import SkeletonSvgGradient from '../../../../../components/Skeleton/SkeletonSvgGradient';

const MobileSkeleton = ({ borderClassName, ...props }) => (
  <svg width="100%" height="auto" viewBox="0 0 361 1060" fill="none" {...props}>
    <SkeletonSvgGradient />
    <path
      d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H357C358.933 0.5 360.5 2.067 360.5 4V518C360.5 519.933 358.933 521.5 357 521.5H4C2.067 521.5 0.5 519.933 0.5 518V4Z"
      stroke="#E6E9F0"
      className={borderClassName}
    />
    <path
      d="M16 28C16 25.7909 17.7909 24 20 24H156C158.209 24 160 25.7909 160 28V36C160 38.2091 158.209 40 156 40H20C17.7909 40 16 38.2091 16 36V28Z"
      fill="#E6E9F0"
    />
    <path
      d="M16 48C16 45.7909 17.7909 44 20 44H341C343.209 44 345 45.7909 345 48V70C345 72.2091 343.209 74 341 74H20C17.7909 74 16 72.2091 16 70V48Z"
      fill="#E6E9F0"
    />
    <path
      d="M16 82C16 79.7909 17.7909 78 20 78H236C238.209 78 240 79.7909 240 82V98C240 100.209 238.209 102 236 102H20C17.7909 102 16 100.209 16 98V82Z"
      fill="#E6E9F0"
    />
    <circle cx="36" cy="138" r="20" fill="#E6E9F0" />
    <path
      d="M64 130C64 127.791 65.7909 126 68 126H341C343.209 126 345 127.791 345 130V146C345 148.209 343.209 150 341 150H68C65.7909 150 64 148.209 64 146V130Z"
      fill="#E6E9F0"
    />
    <circle cx="26" cy="184" r="10" fill="#E6E9F0" />
    <rect
      x="24"
      y="202"
      width="8"
      height="4"
      transform="rotate(-90 24 202)"
      fill="#E6E9F0"
    />
    <mask id="path-10-inside-1_13561_32782" fill="white">
      <path d="M40 174H345V202H40V174Z" />
    </mask>
    <path
      d="M345 201H40V203H345V201Z"
      fill="#E6E9F0"
      mask="url(#path-10-inside-1_13561_32782)"
    />
    <path
      d="M40 178C40 175.791 41.7909 174 44 174H124C126.209 174 128 175.791 128 178V186C128 188.209 126.209 190 124 190H44C41.7909 190 40 188.209 40 186V178Z"
      fill="#E6E9F0"
    />
    <rect
      x="24"
      y="214"
      width="12"
      height="4"
      transform="rotate(-90 24 214)"
      fill="#E6E9F0"
    />
    <circle cx="26" cy="224" r="10" fill="#E6E9F0" />
    <rect
      x="24"
      y="358"
      width="124"
      height="4"
      transform="rotate(-90 24 358)"
      fill="#E6E9F0"
    />
    <mask id="path-16-inside-2_13561_32782" fill="white">
      <path d="M40 214H345V358H40V214Z" />
    </mask>
    <path
      d="M345 357H40V359H345V357Z"
      fill="#E6E9F0"
      mask="url(#path-16-inside-2_13561_32782)"
    />
    <path
      d="M40 218C40 215.791 41.7909 214 44 214H124C126.209 214 128 215.791 128 218V226C128 228.209 126.209 230 124 230H44C41.7909 230 40 228.209 40 226V218Z"
      fill="#E6E9F0"
    />
    <path
      d="M40 246C40 243.791 41.7909 242 44 242H236C238.209 242 240 243.791 240 246V258C240 260.209 238.209 262 236 262H44C41.7909 262 40 260.209 40 258V246Z"
      fill="#E6E9F0"
    />
    <path
      d="M40 270C40 267.791 41.7909 266 44 266H196C198.209 266 200 267.791 200 270V282C200 284.209 198.209 286 196 286H44C41.7909 286 40 284.209 40 282V270Z"
      fill="#E6E9F0"
    />
    <path
      d="M265 246C265 243.791 266.791 242 269 242H341C343.209 242 345 243.791 345 246V262C345 264.209 343.209 266 341 266H269C266.791 266 265 264.209 265 262V246Z"
      fill="#E6E9F0"
    />
    <path
      d="M40 306C40 303.791 41.7909 302 44 302H236C238.209 302 240 303.791 240 306V318C240 320.209 238.209 322 236 322H44C41.7909 322 40 320.209 40 318V306Z"
      fill="#E6E9F0"
    />
    <path
      d="M40 330C40 327.791 41.7909 326 44 326H196C198.209 326 200 327.791 200 330V342C200 344.209 198.209 346 196 346H44C41.7909 346 40 344.209 40 342V330Z"
      fill="#E6E9F0"
    />
    <path
      d="M265 306C265 303.791 266.791 302 269 302H341C343.209 302 345 303.791 345 306V322C345 324.209 343.209 326 341 326H269C266.791 326 265 324.209 265 322V306Z"
      fill="#E6E9F0"
    />
    <rect
      x="24"
      y="370"
      width="12"
      height="4"
      transform="rotate(-90 24 370)"
      fill="#E6E9F0"
    />
    <circle cx="26" cy="380" r="10" fill="#E6E9F0" />
    <path
      d="M40 374C40 371.791 41.7909 370 44 370H124C126.209 370 128 371.791 128 374V382C128 384.209 126.209 386 124 386H44C41.7909 386 40 384.209 40 382V374Z"
      fill="#E6E9F0"
    />
    <path
      d="M40 402C40 399.791 41.7909 398 44 398H236C238.209 398 240 399.791 240 402V414C240 416.209 238.209 418 236 418H44C41.7909 418 40 416.209 40 414V402Z"
      fill="#E6E9F0"
    />
    <path
      d="M40 426C40 423.791 41.7909 422 44 422H196C198.209 422 200 423.791 200 426V438C200 440.209 198.209 442 196 442H44C41.7909 442 40 440.209 40 438V426Z"
      fill="#E6E9F0"
    />
    <rect x="16" y="458" width="329" height="40" rx="20" fill="#E6E9F0" />
    <path
      d="M0.5 542C0.5 540.067 2.067 538.5 4 538.5H357C358.933 538.5 360.5 540.067 360.5 542V1056C360.5 1057.93 358.933 1059.5 357 1059.5H4C2.067 1059.5 0.5 1057.93 0.5 1056V542Z"
      fill="white"
    />
    <path
      d="M0.5 542C0.5 540.067 2.067 538.5 4 538.5H357C358.933 538.5 360.5 540.067 360.5 542V1056C360.5 1057.93 358.933 1059.5 357 1059.5H4C2.067 1059.5 0.5 1057.93 0.5 1056V542Z"
      stroke="#E6E9F0"
      className={borderClassName}
    />
    <path
      d="M16 566C16 563.791 17.7909 562 20 562H156C158.209 562 160 563.791 160 566V574C160 576.209 158.209 578 156 578H20C17.7909 578 16 576.209 16 574V566Z"
      fill="#E6E9F0"
    />
    <path
      d="M16 586C16 583.791 17.7909 582 20 582H341C343.209 582 345 583.791 345 586V608C345 610.209 343.209 612 341 612H20C17.7909 612 16 610.209 16 608V586Z"
      fill="#E6E9F0"
    />
    <path
      d="M16 620C16 617.791 17.7909 616 20 616H236C238.209 616 240 617.791 240 620V636C240 638.209 238.209 640 236 640H20C17.7909 640 16 638.209 16 636V620Z"
      fill="#E6E9F0"
    />
    <circle cx="36" cy="676" r="20" fill="#E6E9F0" />
    <path
      d="M64 668C64 665.791 65.7909 664 68 664H341C343.209 664 345 665.791 345 668V684C345 686.209 343.209 688 341 688H68C65.7909 688 64 686.209 64 684V668Z"
      fill="#E6E9F0"
    />
    <circle cx="26" cy="722" r="10" fill="#E6E9F0" />
    <rect
      x="24"
      y="740"
      width="8"
      height="4"
      transform="rotate(-90 24 740)"
      fill="#E6E9F0"
    />
    <mask id="path-40-inside-3_13561_32782" fill="white">
      <path d="M40 712H345V740H40V712Z" />
    </mask>
    <path
      d="M345 739H40V741H345V739Z"
      fill="#E6E9F0"
      mask="url(#path-40-inside-3_13561_32782)"
    />
    <path
      d="M40 716C40 713.791 41.7909 712 44 712H124C126.209 712 128 713.791 128 716V724C128 726.209 126.209 728 124 728H44C41.7909 728 40 726.209 40 724V716Z"
      fill="#E6E9F0"
    />
    <rect
      x="24"
      y="752"
      width="12"
      height="4"
      transform="rotate(-90 24 752)"
      fill="#E6E9F0"
    />
    <circle cx="26" cy="762" r="10" fill="#E6E9F0" />
    <rect
      x="24"
      y="896"
      width="124"
      height="4"
      transform="rotate(-90 24 896)"
      fill="#E6E9F0"
    />
    <mask id="path-46-inside-4_13561_32782" fill="white">
      <path d="M40 752H345V896H40V752Z" />
    </mask>
    <path
      d="M345 895H40V897H345V895Z"
      fill="#E6E9F0"
      mask="url(#path-46-inside-4_13561_32782)"
    />
    <path
      d="M40 756C40 753.791 41.7909 752 44 752H124C126.209 752 128 753.791 128 756V764C128 766.209 126.209 768 124 768H44C41.7909 768 40 766.209 40 764V756Z"
      fill="#E6E9F0"
    />
    <path
      d="M40 784C40 781.791 41.7909 780 44 780H236C238.209 780 240 781.791 240 784V796C240 798.209 238.209 800 236 800H44C41.7909 800 40 798.209 40 796V784Z"
      fill="#E6E9F0"
    />
    <path
      d="M40 808C40 805.791 41.7909 804 44 804H196C198.209 804 200 805.791 200 808V820C200 822.209 198.209 824 196 824H44C41.7909 824 40 822.209 40 820V808Z"
      fill="#E6E9F0"
    />
    <path
      d="M265 784C265 781.791 266.791 780 269 780H341C343.209 780 345 781.791 345 784V800C345 802.209 343.209 804 341 804H269C266.791 804 265 802.209 265 800V784Z"
      fill="#E6E9F0"
    />
    <path
      d="M40 844C40 841.791 41.7909 840 44 840H236C238.209 840 240 841.791 240 844V856C240 858.209 238.209 860 236 860H44C41.7909 860 40 858.209 40 856V844Z"
      fill="#E6E9F0"
    />
    <path
      d="M40 868C40 865.791 41.7909 864 44 864H196C198.209 864 200 865.791 200 868V880C200 882.209 198.209 884 196 884H44C41.7909 884 40 882.209 40 880V868Z"
      fill="#E6E9F0"
    />
    <path
      d="M265 844C265 841.791 266.791 840 269 840H341C343.209 840 345 841.791 345 844V860C345 862.209 343.209 864 341 864H269C266.791 864 265 862.209 265 860V844Z"
      fill="#E6E9F0"
    />
    <rect
      x="24"
      y="908"
      width="12"
      height="4"
      transform="rotate(-90 24 908)"
      fill="#E6E9F0"
    />
    <circle cx="26" cy="918" r="10" fill="#E6E9F0" />
    <path
      d="M40 912C40 909.791 41.7909 908 44 908H124C126.209 908 128 909.791 128 912V920C128 922.209 126.209 924 124 924H44C41.7909 924 40 922.209 40 920V912Z"
      fill="#E6E9F0"
    />
    <path
      d="M40 940C40 937.791 41.7909 936 44 936H236C238.209 936 240 937.791 240 940V952C240 954.209 238.209 956 236 956H44C41.7909 956 40 954.209 40 952V940Z"
      fill="#E6E9F0"
    />
    <path
      d="M40 964C40 961.791 41.7909 960 44 960H196C198.209 960 200 961.791 200 964V976C200 978.209 198.209 980 196 980H44C41.7909 980 40 978.209 40 976V964Z"
      fill="#E6E9F0"
    />
    <rect x="16" y="996" width="329" height="40" rx="20" fill="#E6E9F0" />
  </svg>
);

MobileSkeleton.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  borderClassName: PropTypes.string,
};

export default memo(MobileSkeleton);
