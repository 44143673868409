import React from 'react';
import { Field } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Condition from '../../../../../../../components/Condition';
import HelpfulInfoCard from '../../../../../../../components/HelpfulInfo';
import Input from '../../../../../../../components/Input';
import RadioGroup from '../../../../../../../components/RadioGroup';
import RadiationIcon from '../../../../../../../icons/Radiation';
import ResourcesIcon from '../../../../../../../icons/resources';
import conditionalValidation from '../../../../../../../validators/conditionalValidation';
import fieldRequiredStandard from '../../../../../../../validators/fieldRequired';
import SectionTitle from '../../../../../common/SectionTitle';
import { useStandaloneWizardState } from '../../../../../standalone-wizard/StandaloneWizardProvider';

import styles from './RadeonDisclosure.module.scss';

const RadeonDisclosure = ({ className, leaseAgreementSelection }) => {
  const { standAloneWizard } = useStandaloneWizardState();
  const fieldRequired = conditionalValidation(
    fieldRequiredStandard,
    !standAloneWizard,
  );

  return (
    <>
      <div className={cx(styles.container, className)}>
        <SectionTitle title="Radon Disclosure" icon={RadiationIcon} />
        <label className={styles.label}>
          Is there any known radon hazard located in or around the property?
        </label>
        {!leaseAgreementSelection && (
          <p className={styles.description}>
            For rental units on the 2nd floor and below, you are required to
            disclose any known radon hazard in or around the property.
          </p>
        )}
        <Field
          component={RadioGroup}
          id="custom_state_data.radon_hazard"
          name="custom_state_data.radon_hazard"
          radiosContainerClassName={styles.radioButtonsContainer}
          options={[
            {
              id: 'radon_hazard_yes',
              value: true,
              label: 'Yes',
            },
            {
              id: 'radon_hazard_no',
              value: false,
              label: 'No',
            },
          ]}
          validate={fieldRequired}
        />
      </div>
      <HelpfulInfoCard
        className={styles.helpfulInfoCard}
        noTitle
        Icon={ResourcesIcon}
      >
        A copy of the{' '}
        <a
          target="_blank noopener noreferrer"
          href="https://iemaohs.illinois.gov/content/dam/soi/en/web/iemaohs/nrs/radon/documents/radonguidefortenants.pdf"
        >
          radon pamphlet
        </a>{' '}
        prepared by the State of Illinois is required to be attached to your
        lease agreement, which we have done for you.
      </HelpfulInfoCard>

      <Condition when="custom_state_data.radon_hazard" is={true}>
        <Field
          as="textarea"
          component={Input}
          id="custom_state_data.radon_hazard_explanation"
          name="custom_state_data.radon_hazard_explanation"
          label={
            <div className={styles.labelContainer}>
              <span className={styles.labelTitle}>
                The following radon tests, dates and results performed on the
                premises, are shown below:
              </span>
            </div>
          }
          labelProps={{
            className: styles.radonLabel,
          }}
          className={styles.termsContainer}
          inputProps={{
            rows: '5',
          }}
          maxLength={2000}
          validate={fieldRequired}
          help={(val = '') => `${val.length} / 2000 characters used`}
        />
      </Condition>
    </>
  );
};

RadeonDisclosure.propTypes = {
  className: PropTypes.string,
  leaseAgreementSelection: PropTypes.string,
};

export default RadeonDisclosure;
