import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { useModalContext } from '../../context';

import styles from './Header.module.scss';

const Header = ({ className, ...props }) => {
  const { hasBanner } = useModalContext();
  return (
    <div
      className={cx(
        styles.header,
        { [styles.withBanner]: hasBanner },
        className,
      )}
      {...props}
    />
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
