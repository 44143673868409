import React from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { useUserProfile } from '../../../core/TTgraphql';
import conditionReportsQuery from '../../../graphql/queries/conditionReportsQuery.graphql';

import ConditionReportsSkeleton from './Skeleton/ConditionReportsSkeleton';
import ConditionReportsTable from './ConditionReportsTable';

function ConditionReportsList({ className, classNameMobile, hideTitle }) {
  const { data, loading: loadingCR } = useQuery(conditionReportsQuery);
  const { user, loading: loadingUser } = useUserProfile();

  const conditionReports = data?.conditionReports;

  if (loadingCR || loadingUser) {
    return <ConditionReportsSkeleton />;
  }

  return (
    <ConditionReportsTable
      className={className}
      classNameMobile={classNameMobile}
      conditionReports={conditionReports}
      user={user}
      hideTitle={hideTitle}
    />
  );
}

ConditionReportsList.propTypes = {
  className: PropTypes.string,
  classNameMobile: PropTypes.string,
  hideTitle: PropTypes.bool,
};

export default ConditionReportsList;
