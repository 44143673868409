import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import { docTypeLabels as reportTypeLabels } from '../../../../components/RentersDashboard/RenterToDoCards/ConditionReportTodoCard/ConditionReportTodoCard';
import CONDITION_REPORT_STATUS from '../../../../constants/condition_reports/status';
import ConditionReportingEmptyState from '../../leases/view/Tabs/ConditionReporting/ConditionReportingEmptyState/ConditionReportingEmptyState';
import ConditionReportStatusTag from '../ConditionReportStatusTag';
import ReportAction from '../ReportAction';

import styles from './ConditionReportsTableDesktop.module.scss';

const ConditionReportsTableDesktop = ({
  className,
  conditionReports,
  goToLeasePage,
  onRowClick,
  createNewReportHandler,
  hideTitle,
}) => (
  <div className={cx(styles.container, className)}>
    {!hideTitle || conditionReports?.length ? (
      <div className={styles.header}>
        {!hideTitle && <div className={styles.title}>Condition Reports</div>}
        {conditionReports?.length ? (
          <Button
            className={styles.createNewReport}
            onClick={() =>
              createNewReportHandler(conditionReports[0]?.lease_id)
            }
          >
            Create New Report
          </Button>
        ) : null}
      </div>
    ) : null}
    {!conditionReports?.length ? (
      <Card className={styles.getStartedSection}>
        <ConditionReportingEmptyState
          onClick={() => createNewReportHandler(conditionReports[0]?.lease_id)}
        />
      </Card>
    ) : (
      <div className={styles.table}>
        <div className={styles.tableHeader}>
          <div className={styles.columnDueDate}>DUE DATE</div>
          <div className={styles.columnTitle}>TITLE</div>
          <div className={styles.columnType}>TYPE</div>
          <div className={styles.columnLease}>LEASE</div>
          <div className={styles.columnStatus}>STATUS</div>
          <div className={styles.columnAction}>ACTION</div>
        </div>
        {conditionReports?.map((report) => {
          const assignedTo = report.assigned_to
            ?.map((assignee) =>
              assignee.user_role === 'OWNER'
                ? 'You'
                : `${assignee.first_name} ${assignee.last_name}`,
            )
            ?.join(', ');

          const onClickLeaseTitle = (e) => {
            e.stopPropagation();
            goToLeasePage(report.lease_id);
          };

          const isSelfAssigned = report.assigned_to?.[0]?.user_role === 'OWNER';

          return (
            <div
              key={report.id}
              onClick={() =>
                onRowClick(
                  report.lease_id,
                  report.id,
                  report.status,
                  isSelfAssigned,
                )
              }
              className={styles.tableRow}
            >
              <div className={styles.columnDueDate}>
                {report.due_date
                  ? moment(report.due_date).format('MMM D, YYYY')
                  : 'N/A'}
              </div>
              <div className={styles.columnTitle}>
                <div className={styles.title}>{report.name}</div>
                {assignedTo && (
                  <div className={styles.assignedTo}>
                    Assigned to {assignedTo}
                  </div>
                )}
              </div>
              <div className={styles.columnType}>
                {reportTypeLabels[report.type]}
              </div>
              <div
                onClick={onClickLeaseTitle}
                className={cx(styles.columnLease, styles.leaseTitle)}
              >
                {report.lease?.title}
              </div>
              <div className={styles.columnStatus}>
                <ConditionReportStatusTag
                  status={report.status ?? CONDITION_REPORT_STATUS.UPLOADED}
                />
              </div>
              <div className={styles.columnAction}>
                <ReportAction report={report} />
              </div>
            </div>
          );
        })}
      </div>
    )}
  </div>
);

ConditionReportsTableDesktop.propTypes = {
  className: PropTypes.string,
  conditionReports: PropTypes.arrayOf(PropTypes.object),
  goToLeasePage: PropTypes.func,
  onRowClick: PropTypes.func,
  createNewReportHandler: PropTypes.func,
  hideTitle: PropTypes.bool,
};

export default ConditionReportsTableDesktop;
