import React, { memo } from 'react';
import PropTypes from 'prop-types';

import SkeletonSvgGradient from '../../../../../components/Skeleton/SkeletonSvgGradient';

const DesktopSkeleton = (props) => (
  <svg width="100%" height="auto" viewBox="0 0 960 867" fill="none" {...props}>
    <SkeletonSvgGradient />
    <rect
      x="1"
      y="1"
      width="958"
      height="864"
      rx="3"
      stroke="#E6E9F0"
      strokeWidth="2"
    />
    <mask
      id="path-2-outside-1_15000_39235"
      maskUnits="userSpaceOnUse"
      x="0"
      y="8"
      width="960"
      height="50"
      fill="black"
    >
      <path d="M0 8H960V56H0V8Z" />
    </mask>
    <path
      d="M960 54H0V58H960V54Z"
      fill="#E6E9F0"
      mask="url(#path-2-outside-1_15000_39235)"
    />
    <path d="M32 28C32 25.7909 33.7909 24 36 24H87C89.2091 24 91 25.7909 91 28V36C91 38.2091 89.2091 40 87 40H36C33.7909 40 32 38.2091 32 36V28Z" />
    <path d="M128 28C128 25.7909 129.791 24 132 24H183C185.209 24 187 25.7909 187 28V36C187 38.2091 185.209 40 183 40H132C129.791 40 128 38.2091 128 36V28Z" />
    <path d="M384 28C384 25.7909 385.791 24 388 24H439C441.209 24 443 25.7909 443 28V36C443 38.2091 441.209 40 439 40H388C385.791 40 384 38.2091 384 36V28Z" />
    <path d="M474 28C474 25.7909 475.791 24 478 24H529C531.209 24 533 25.7909 533 28V36C533 38.2091 531.209 40 529 40H478C475.791 40 474 38.2091 474 36V28Z" />
    <path d="M642 28C642 25.7909 643.791 24 646 24H697C699.209 24 701 25.7909 701 28V36C701 38.2091 699.209 40 697 40H646C643.791 40 642 38.2091 642 36V28Z" />
    <path d="M869 28C869 25.7909 870.791 24 873 24H924C926.209 24 928 25.7909 928 28V36C928 38.2091 926.209 40 924 40H873C870.791 40 869 38.2091 869 36V28Z" />
    <mask
      id="path-10-outside-2_15000_39235"
      maskUnits="userSpaceOnUse"
      x="32"
      y="57"
      width="896"
      height="81"
      fill="black"
    >
      <path d="M32 57H928V137H32V57Z" />
    </mask>
    <path
      d="M928 136H32V138H928V136Z"
      mask="url(#path-10-outside-2_15000_39235)"
    />
    <path d="M32 91C32 88.7909 33.7909 87 36 87H100C102.209 87 104 88.7909 104 91V103C104 105.209 102.209 107 100 107H36C33.7909 107 32 105.209 32 103V91Z" />
    <path d="M128 79C128 76.7909 129.791 75 132 75H340C342.209 75 344 76.7909 344 79V91C344 93.2091 342.209 95 340 95H132C129.791 95 128 93.2091 128 91V79Z" />
    <path d="M128 103C128 100.791 129.791 99 132 99H252C254.209 99 256 100.791 256 103V115C256 117.209 254.209 119 252 119H132C129.791 119 128 117.209 128 115V103Z" />
    <path d="M384 91C384 88.7909 385.791 87 388 87H452C454.209 87 456 88.7909 456 91V103C456 105.209 454.209 107 452 107H388C385.791 107 384 105.209 384 103V91Z" />
    <path d="M474 91C474 88.7909 475.791 87 478 87H614C616.209 87 618 88.7909 618 91V103C618 105.209 616.209 107 614 107H478C475.791 107 474 105.209 474 103V91Z" />
    <path d="M642 89C642 86.7909 643.791 85 646 85H718C720.209 85 722 86.7909 722 89V105C722 107.209 720.209 109 718 109H646C643.791 109 642 107.209 642 105V89Z" />
    <path d="M816 89C816 86.7909 817.791 85 820 85H924C926.209 85 928 86.7909 928 89V105C928 107.209 926.209 109 924 109H820C817.791 109 816 107.209 816 105V89Z" />
    <mask
      id="path-25-outside-3_15000_39235"
      maskUnits="userSpaceOnUse"
      x="32"
      y="138"
      width="896"
      height="81"
      fill="black"
    >
      <path d="M32 138H928V218H32V138Z" />
    </mask>
    <path
      d="M928 217H32V219H928V217Z"
      fill="#E6E9F0"
      mask="url(#path-25-outside-3_15000_39235)"
    />
    <path
      d="M32 172C32 169.791 33.7909 168 36 168H100C102.209 168 104 169.791 104 172V184C104 186.209 102.209 188 100 188H36C33.7909 188 32 186.209 32 184V172Z"
      fill="#E6E9F0"
    />
    <path
      d="M128 160C128 157.791 129.791 156 132 156H340C342.209 156 344 157.791 344 160V172C344 174.209 342.209 176 340 176H132C129.791 176 128 174.209 128 172V160Z"
      fill="#E6E9F0"
    />
    <path
      d="M128 184C128 181.791 129.791 180 132 180H252C254.209 180 256 181.791 256 184V196C256 198.209 254.209 200 252 200H132C129.791 200 128 198.209 128 196V184Z"
      fill="#E6E9F0"
    />
    <path
      d="M384 172C384 169.791 385.791 168 388 168H452C454.209 168 456 169.791 456 172V184C456 186.209 454.209 188 452 188H388C385.791 188 384 186.209 384 184V172Z"
      fill="#E6E9F0"
    />
    <path
      d="M474 172C474 169.791 475.791 168 478 168H614C616.209 168 618 169.791 618 172V184C618 186.209 616.209 188 614 188H478C475.791 188 474 186.209 474 184V172Z"
      fill="#E6E9F0"
    />
    <path
      d="M642 170C642 167.791 643.791 166 646 166H718C720.209 166 722 167.791 722 170V186C722 188.209 720.209 190 718 190H646C643.791 190 642 188.209 642 186V170Z"
      fill="#E6E9F0"
    />
    <path
      d="M816 170C816 167.791 817.791 166 820 166H924C926.209 166 928 167.791 928 170V186C928 188.209 926.209 190 924 190H820C817.791 190 816 188.209 816 186V170Z"
      fill="#E6E9F0"
    />
    <mask
      id="path-40-outside-4_15000_39235"
      maskUnits="userSpaceOnUse"
      x="32"
      y="219"
      width="896"
      height="81"
      fill="black"
    >
      <path d="M32 219H928V299H32V219Z" />
    </mask>
    <path
      d="M928 298H32V300H928V298Z"
      fill="#E6E9F0"
      mask="url(#path-40-outside-4_15000_39235)"
    />
    <path
      d="M32 253C32 250.791 33.7909 249 36 249H100C102.209 249 104 250.791 104 253V265C104 267.209 102.209 269 100 269H36C33.7909 269 32 267.209 32 265V253Z"
      fill="#E6E9F0"
    />
    <path
      d="M128 241C128 238.791 129.791 237 132 237H340C342.209 237 344 238.791 344 241V253C344 255.209 342.209 257 340 257H132C129.791 257 128 255.209 128 253V241Z"
      fill="#E6E9F0"
    />
    <path
      d="M128 265C128 262.791 129.791 261 132 261H252C254.209 261 256 262.791 256 265V277C256 279.209 254.209 281 252 281H132C129.791 281 128 279.209 128 277V265Z"
      fill="#E6E9F0"
    />
    <path
      d="M384 253C384 250.791 385.791 249 388 249H452C454.209 249 456 250.791 456 253V265C456 267.209 454.209 269 452 269H388C385.791 269 384 267.209 384 265V253Z"
      fill="#E6E9F0"
    />
    <path
      d="M474 253C474 250.791 475.791 249 478 249H614C616.209 249 618 250.791 618 253V265C618 267.209 616.209 269 614 269H478C475.791 269 474 267.209 474 265V253Z"
      fill="#E6E9F0"
    />
    <path
      d="M642 251C642 248.791 643.791 247 646 247H718C720.209 247 722 248.791 722 251V267C722 269.209 720.209 271 718 271H646C643.791 271 642 269.209 642 267V251Z"
      fill="#E6E9F0"
    />
    <path
      d="M816 251C816 248.791 817.791 247 820 247H924C926.209 247 928 248.791 928 251V267C928 269.209 926.209 271 924 271H820C817.791 271 816 269.209 816 267V251Z"
      fill="#E6E9F0"
    />
    <mask
      id="path-55-outside-5_15000_39235"
      maskUnits="userSpaceOnUse"
      x="32"
      y="300"
      width="896"
      height="81"
      fill="black"
    >
      <path d="M32 300H928V380H32V300Z" />
    </mask>
    <path
      d="M928 379H32V381H928V379Z"
      fill="#E6E9F0"
      mask="url(#path-55-outside-5_15000_39235)"
    />
    <path
      d="M32 334C32 331.791 33.7909 330 36 330H100C102.209 330 104 331.791 104 334V346C104 348.209 102.209 350 100 350H36C33.7909 350 32 348.209 32 346V334Z"
      fill="#E6E9F0"
    />
    <path
      d="M128 322C128 319.791 129.791 318 132 318H340C342.209 318 344 319.791 344 322V334C344 336.209 342.209 338 340 338H132C129.791 338 128 336.209 128 334V322Z"
      fill="#E6E9F0"
    />
    <path
      d="M128 346C128 343.791 129.791 342 132 342H252C254.209 342 256 343.791 256 346V358C256 360.209 254.209 362 252 362H132C129.791 362 128 360.209 128 358V346Z"
      fill="#E6E9F0"
    />
    <path
      d="M384 334C384 331.791 385.791 330 388 330H452C454.209 330 456 331.791 456 334V346C456 348.209 454.209 350 452 350H388C385.791 350 384 348.209 384 346V334Z"
      fill="#E6E9F0"
    />
    <path
      d="M474 334C474 331.791 475.791 330 478 330H614C616.209 330 618 331.791 618 334V346C618 348.209 616.209 350 614 350H478C475.791 350 474 348.209 474 346V334Z"
      fill="#E6E9F0"
    />
    <path
      d="M642 332C642 329.791 643.791 328 646 328H718C720.209 328 722 329.791 722 332V348C722 350.209 720.209 352 718 352H646C643.791 352 642 350.209 642 348V332Z"
      fill="#E6E9F0"
    />
    <path
      d="M816 332C816 329.791 817.791 328 820 328H924C926.209 328 928 329.791 928 332V348C928 350.209 926.209 352 924 352H820C817.791 352 816 350.209 816 348V332Z"
      fill="#E6E9F0"
    />
    <mask
      id="path-70-outside-6_15000_39235"
      maskUnits="userSpaceOnUse"
      x="32"
      y="381"
      width="896"
      height="81"
      fill="black"
    >
      <path d="M32 381H928V461H32V381Z" />
    </mask>
    <path
      d="M928 460H32V462H928V460Z"
      fill="#E6E9F0"
      mask="url(#path-70-outside-6_15000_39235)"
    />
    <path
      d="M32 415C32 412.791 33.7909 411 36 411H100C102.209 411 104 412.791 104 415V427C104 429.209 102.209 431 100 431H36C33.7909 431 32 429.209 32 427V415Z"
      fill="#E6E9F0"
    />
    <path
      d="M128 403C128 400.791 129.791 399 132 399H340C342.209 399 344 400.791 344 403V415C344 417.209 342.209 419 340 419H132C129.791 419 128 417.209 128 415V403Z"
      fill="#E6E9F0"
    />
    <path
      d="M128 427C128 424.791 129.791 423 132 423H252C254.209 423 256 424.791 256 427V439C256 441.209 254.209 443 252 443H132C129.791 443 128 441.209 128 439V427Z"
      fill="#E6E9F0"
    />
    <path
      d="M384 415C384 412.791 385.791 411 388 411H452C454.209 411 456 412.791 456 415V427C456 429.209 454.209 431 452 431H388C385.791 431 384 429.209 384 427V415Z"
      fill="#E6E9F0"
    />
    <path
      d="M474 415C474 412.791 475.791 411 478 411H614C616.209 411 618 412.791 618 415V427C618 429.209 616.209 431 614 431H478C475.791 431 474 429.209 474 427V415Z"
      fill="#E6E9F0"
    />
    <path
      d="M642 413C642 410.791 643.791 409 646 409H718C720.209 409 722 410.791 722 413V429C722 431.209 720.209 433 718 433H646C643.791 433 642 431.209 642 429V413Z"
      fill="#E6E9F0"
    />
    <path
      d="M816 413C816 410.791 817.791 409 820 409H924C926.209 409 928 410.791 928 413V429C928 431.209 926.209 433 924 433H820C817.791 433 816 431.209 816 429V413Z"
      fill="#E6E9F0"
    />
    <mask
      id="path-85-outside-7_15000_39235"
      maskUnits="userSpaceOnUse"
      x="32"
      y="462"
      width="896"
      height="81"
      fill="black"
    >
      <path d="M32 462H928V542H32V462Z" />
    </mask>
    <path
      d="M928 541H32V543H928V541Z"
      fill="#E6E9F0"
      mask="url(#path-85-outside-7_15000_39235)"
    />
    <path
      d="M32 496C32 493.791 33.7909 492 36 492H100C102.209 492 104 493.791 104 496V508C104 510.209 102.209 512 100 512H36C33.7909 512 32 510.209 32 508V496Z"
      fill="#E6E9F0"
    />
    <path
      d="M128 484C128 481.791 129.791 480 132 480H340C342.209 480 344 481.791 344 484V496C344 498.209 342.209 500 340 500H132C129.791 500 128 498.209 128 496V484Z"
      fill="#E6E9F0"
    />
    <path
      d="M128 508C128 505.791 129.791 504 132 504H252C254.209 504 256 505.791 256 508V520C256 522.209 254.209 524 252 524H132C129.791 524 128 522.209 128 520V508Z"
      fill="#E6E9F0"
    />
    <path
      d="M384 496C384 493.791 385.791 492 388 492H452C454.209 492 456 493.791 456 496V508C456 510.209 454.209 512 452 512H388C385.791 512 384 510.209 384 508V496Z"
      fill="#E6E9F0"
    />
    <path
      d="M474 496C474 493.791 475.791 492 478 492H614C616.209 492 618 493.791 618 496V508C618 510.209 616.209 512 614 512H478C475.791 512 474 510.209 474 508V496Z"
      fill="#E6E9F0"
    />
    <path
      d="M642 494C642 491.791 643.791 490 646 490H718C720.209 490 722 491.791 722 494V510C722 512.209 720.209 514 718 514H646C643.791 514 642 512.209 642 510V494Z"
      fill="#E6E9F0"
    />
    <path
      d="M816 494C816 491.791 817.791 490 820 490H924C926.209 490 928 491.791 928 494V510C928 512.209 926.209 514 924 514H820C817.791 514 816 512.209 816 510V494Z"
      fill="#E6E9F0"
    />
    <mask
      id="path-100-outside-8_15000_39235"
      maskUnits="userSpaceOnUse"
      x="32"
      y="543"
      width="896"
      height="81"
      fill="black"
    >
      <path d="M32 543H928V623H32V543Z" />
    </mask>
    <path
      d="M928 622H32V624H928V622Z"
      fill="#E6E9F0"
      mask="url(#path-100-outside-8_15000_39235)"
    />
    <path
      d="M32 577C32 574.791 33.7909 573 36 573H100C102.209 573 104 574.791 104 577V589C104 591.209 102.209 593 100 593H36C33.7909 593 32 591.209 32 589V577Z"
      fill="#E6E9F0"
    />
    <path
      d="M128 565C128 562.791 129.791 561 132 561H340C342.209 561 344 562.791 344 565V577C344 579.209 342.209 581 340 581H132C129.791 581 128 579.209 128 577V565Z"
      fill="#E6E9F0"
    />
    <path
      d="M128 589C128 586.791 129.791 585 132 585H252C254.209 585 256 586.791 256 589V601C256 603.209 254.209 605 252 605H132C129.791 605 128 603.209 128 601V589Z"
      fill="#E6E9F0"
    />
    <path
      d="M384 577C384 574.791 385.791 573 388 573H452C454.209 573 456 574.791 456 577V589C456 591.209 454.209 593 452 593H388C385.791 593 384 591.209 384 589V577Z"
      fill="#E6E9F0"
    />
    <path
      d="M474 577C474 574.791 475.791 573 478 573H614C616.209 573 618 574.791 618 577V589C618 591.209 616.209 593 614 593H478C475.791 593 474 591.209 474 589V577Z"
      fill="#E6E9F0"
    />
    <path
      d="M642 575C642 572.791 643.791 571 646 571H718C720.209 571 722 572.791 722 575V591C722 593.209 720.209 595 718 595H646C643.791 595 642 593.209 642 591V575Z"
      fill="#E6E9F0"
    />
    <path
      d="M816 575C816 572.791 817.791 571 820 571H924C926.209 571 928 572.791 928 575V591C928 593.209 926.209 595 924 595H820C817.791 595 816 593.209 816 591V575Z"
      fill="#E6E9F0"
    />
    <mask
      id="path-115-outside-9_15000_39235"
      maskUnits="userSpaceOnUse"
      x="32"
      y="624"
      width="896"
      height="81"
      fill="black"
    >
      <path d="M32 624H928V704H32V624Z" />
    </mask>
    <path
      d="M928 703H32V705H928V703Z"
      fill="#E6E9F0"
      mask="url(#path-115-outside-9_15000_39235)"
    />
    <path
      d="M32 658C32 655.791 33.7909 654 36 654H100C102.209 654 104 655.791 104 658V670C104 672.209 102.209 674 100 674H36C33.7909 674 32 672.209 32 670V658Z"
      fill="#E6E9F0"
    />
    <path
      d="M128 646C128 643.791 129.791 642 132 642H340C342.209 642 344 643.791 344 646V658C344 660.209 342.209 662 340 662H132C129.791 662 128 660.209 128 658V646Z"
      fill="#E6E9F0"
    />
    <path
      d="M128 670C128 667.791 129.791 666 132 666H252C254.209 666 256 667.791 256 670V682C256 684.209 254.209 686 252 686H132C129.791 686 128 684.209 128 682V670Z"
      fill="#E6E9F0"
    />
    <path
      d="M384 658C384 655.791 385.791 654 388 654H452C454.209 654 456 655.791 456 658V670C456 672.209 454.209 674 452 674H388C385.791 674 384 672.209 384 670V658Z"
      fill="#E6E9F0"
    />
    <path
      d="M474 658C474 655.791 475.791 654 478 654H614C616.209 654 618 655.791 618 658V670C618 672.209 616.209 674 614 674H478C475.791 674 474 672.209 474 670V658Z"
      fill="#E6E9F0"
    />
    <path
      d="M642 656C642 653.791 643.791 652 646 652H718C720.209 652 722 653.791 722 656V672C722 674.209 720.209 676 718 676H646C643.791 676 642 674.209 642 672V656Z"
      fill="#E6E9F0"
    />
    <path
      d="M816 656C816 653.791 817.791 652 820 652H924C926.209 652 928 653.791 928 656V672C928 674.209 926.209 676 924 676H820C817.791 676 816 674.209 816 672V656Z"
      fill="#E6E9F0"
    />
    <mask
      id="path-130-outside-10_15000_39235"
      maskUnits="userSpaceOnUse"
      x="32"
      y="705"
      width="896"
      height="81"
      fill="black"
    >
      <path d="M32 705H928V785H32V705Z" />
    </mask>
    <path
      d="M928 784H32V786H928V784Z"
      fill="#E6E9F0"
      mask="url(#path-130-outside-10_15000_39235)"
    />
    <path
      d="M32 739C32 736.791 33.7909 735 36 735H100C102.209 735 104 736.791 104 739V751C104 753.209 102.209 755 100 755H36C33.7909 755 32 753.209 32 751V739Z"
      fill="#E6E9F0"
    />
    <path
      d="M128 727C128 724.791 129.791 723 132 723H340C342.209 723 344 724.791 344 727V739C344 741.209 342.209 743 340 743H132C129.791 743 128 741.209 128 739V727Z"
      fill="#E6E9F0"
    />
    <path
      d="M128 751C128 748.791 129.791 747 132 747H252C254.209 747 256 748.791 256 751V763C256 765.209 254.209 767 252 767H132C129.791 767 128 765.209 128 763V751Z"
      fill="#E6E9F0"
    />
    <path
      d="M384 739C384 736.791 385.791 735 388 735H452C454.209 735 456 736.791 456 739V751C456 753.209 454.209 755 452 755H388C385.791 755 384 753.209 384 751V739Z"
      fill="#E6E9F0"
    />
    <path
      d="M474 739C474 736.791 475.791 735 478 735H614C616.209 735 618 736.791 618 739V751C618 753.209 616.209 755 614 755H478C475.791 755 474 753.209 474 751V739Z"
      fill="#E6E9F0"
    />
    <path
      d="M642 737C642 734.791 643.791 733 646 733H718C720.209 733 722 734.791 722 737V753C722 755.209 720.209 757 718 757H646C643.791 757 642 755.209 642 753V737Z"
      fill="#E6E9F0"
    />
    <path
      d="M816 737C816 734.791 817.791 733 820 733H924C926.209 733 928 734.791 928 737V753C928 755.209 926.209 757 924 757H820C817.791 757 816 755.209 816 753V737Z"
      fill="#E6E9F0"
    />
    <mask
      id="path-145-outside-11_15000_39235"
      maskUnits="userSpaceOnUse"
      x="32"
      y="786"
      width="896"
      height="81"
      fill="black"
    >
      <path d="M32 786H928V866H32V786Z" />
    </mask>
    <path
      d="M928 865H32V867H928V865Z"
      fill="#E6E9F0"
      mask="url(#path-145-outside-11_15000_39235)"
    />
    <path
      d="M32 820C32 817.791 33.7909 816 36 816H100C102.209 816 104 817.791 104 820V832C104 834.209 102.209 836 100 836H36C33.7909 836 32 834.209 32 832V820Z"
      fill="#E6E9F0"
    />
    <path
      d="M128 808C128 805.791 129.791 804 132 804H340C342.209 804 344 805.791 344 808V820C344 822.209 342.209 824 340 824H132C129.791 824 128 822.209 128 820V808Z"
      fill="#E6E9F0"
    />
    <path
      d="M128 832C128 829.791 129.791 828 132 828H252C254.209 828 256 829.791 256 832V844C256 846.209 254.209 848 252 848H132C129.791 848 128 846.209 128 844V832Z"
      fill="#E6E9F0"
    />
    <path
      d="M384 820C384 817.791 385.791 816 388 816H452C454.209 816 456 817.791 456 820V832C456 834.209 454.209 836 452 836H388C385.791 836 384 834.209 384 832V820Z"
      fill="#E6E9F0"
    />
    <path
      d="M474 820C474 817.791 475.791 816 478 816H614C616.209 816 618 817.791 618 820V832C618 834.209 616.209 836 614 836H478C475.791 836 474 834.209 474 832V820Z"
      fill="#E6E9F0"
    />
    <path
      d="M642 818C642 815.791 643.791 814 646 814H718C720.209 814 722 815.791 722 818V834C722 836.209 720.209 838 718 838H646C643.791 838 642 836.209 642 834V818Z"
      fill="#E6E9F0"
    />
    <path
      d="M816 818C816 815.791 817.791 814 820 814H924C926.209 814 928 815.791 928 818V834C928 836.209 926.209 838 924 838H820C817.791 838 816 836.209 816 834V818Z"
      fill="#E6E9F0"
    />
  </svg>
);

DesktopSkeleton.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(DesktopSkeleton);
