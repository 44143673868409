import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import cx from 'classnames';
import get from 'lodash.get';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '../../../../../components/Button';
import DropdownMenu from '../../../../../components/DropdownMenu';
import FlatButton from '../../../../../components/FlatButton';
import { useErrorToast, useInfoToast } from '../../../../../components/Toast';
import { Link, Paragraph } from '../../../../../components/Typography';
import VerticalProgressBar from '../../../../../components/VerticalProgressBar';
import VerticalProgressBarOption from '../../../../../components/VerticalProgressBar/VerticalProgressBarOption';
import colors from '../../../../../constants/colors';
import LEASE_DOCUMENT_TYPE from '../../../../../constants/lease_document/type';
import sendSignatureReminderEmail from '../../../../../graphql/mutations/sendSignatureReminderEmail.graphql';
import getListingBreadcrumbName from '../../../../../helpers/getListingBreadcrumbName';
import ArrowRight from '../../../../../icons/ArrowRight';
import CloseCircleIcon from '../../../../../icons/CloseCircle';
import DotsDropdown from '../../../../../icons/DotsDropdown';
import EditIcon from '../../../../../icons/Edit';
import FormIcon from '../../../../../icons/Form';
import ResendIcon from '../../../../../icons/Resend';
import { useConfig } from '../../../../../providers/ConfigProvider';
import CancelSignatureRequestModal from '../../../components/CancelSignatureRequestModal';
import EditLeaseAgreementModal from '../../../components/EditLeaseAgreementModal';
import EditStandaloneEsignModal from '../../../components/EditStandaloneEsignModal';

import styles from './SignatureRequest.module.scss';

const renderLabel = (backgroundColor, text) => {
  return (
    <div className={cx(styles.badgeContainer)}>
      <div className={styles.banner} style={{ backgroundColor }}>
        {text}
      </div>
    </div>
  );
};

const renderStatus = (signer) => {
  if (signer.signed_at) {
    return renderLabel('#04BF8D', 'Signed');
  } else if (signer.last_viewed_at) {
    return renderLabel(
      '#E6E9F0',
      `Viewed at ${moment(signer.last_viewed_at).format(
        'h:mma [on] MM/DD/YY',
      )}`,
    );
  }
  return renderLabel('#FFCD00', 'Not Viewed');
};

const SignatureRequest = ({
  signature_request,
  leaseId,
  lease,
  history,
  cancelRequest,
  listVariant,
  onExpand,
}) => {
  const [showCancelRequestModal, setShowCancelRequestModal] = useState(false);
  const [showEditLeaseAgreementModal, setShowEditLeaseAgreementModal] =
    useState(false);
  const [showEditStandaloneEsignModal, setShowEditStandaloneEsignModal] =
    useState(false);
  const [expandSigners, setExpandSigners] = useState(false);
  const showInfoMessage = useInfoToast();
  const showErrorMessage = useErrorToast();
  const { PRIVATE_BASE_PATH } = useConfig();

  const [sendReminderEmail] = useMutation(sendSignatureReminderEmail);

  const leaseDocument = get(signature_request, 'lease_documents[0]', {});
  const isLeaseAgreement = !!signature_request.lease_agreement;
  const isLeaseAddendum =
    signature_request?.wizard_document?.type ===
    LEASE_DOCUMENT_TYPE.LEASE_ADDENDUM;

  useEffect(() => {
    onExpand?.();
  }, [expandSigners]);

  const owner = signature_request.signatures.find(
    (s) => s.signer_type === 'OWNER',
  );

  const existsPendingSigners = signature_request.signatures.some(
    (s) => !s.signed_at && s.signer_type !== 'OWNER',
  );
  const canCollapseSigners =
    !existsPendingSigners && signature_request.signatures.length > 2;

  const handleSendReminderEmail = () => {
    sendReminderEmail({
      variables: { signatureRequestId: signature_request.id },
    })
      .then((ok) => {
        if (ok) showInfoMessage('A signature request reminder has been sent');
      })
      .catch(() => showErrorMessage('There was an error'));
  };

  const reminderSentAt = moment(
    signature_request.reminder_sent_at ||
      signature_request.sent_at ||
      signature_request.created_at,
  ).format('h:mma [on] MM/DD/YY');

  const status = existsPendingSigners
    ? 'waiting on signatures'
    : 'your turn to sign';
  const title =
    listVariant && lease
      ? getListingBreadcrumbName({
          listing: lease.listing,
          showRootLevel: true,
        })
      : 'Waiting on Signatures';

  const subTitle = lease?.title || 'No lease specified';
  const iconSize = listVariant ? 40 : 48;

  return (
    <>
      <div className={styles.titleContainer}>
        <div>
          {listVariant && (
            <Paragraph
              uppercase
              weight="bold"
              size="small"
              {...(existsPendingSigners
                ? { muted: true }
                : { className: styles.greenFont })}
            >
              {status}
            </Paragraph>
          )}
          <span className={styles.sectionTitle}>{title}</span>
          {listVariant && (
            <Paragraph size="large" muted weight="semi">
              {subTitle}
            </Paragraph>
          )}
        </div>
        <DropdownMenu className={cx(styles.mobileOnly)} placement="bottom-end">
          <DropdownMenu.Trigger asChild>
            <span className={styles.trigger}>
              <DotsDropdown />
            </span>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            <DropdownMenu.Item
              as="a"
              onClick={() => {
                if (isLeaseAgreement || isLeaseAddendum) {
                  setShowEditLeaseAgreementModal(true);
                } else {
                  setShowEditStandaloneEsignModal(true);
                }
              }}
              icon={EditIcon}
            >
              Edit
            </DropdownMenu.Item>

            <DropdownMenu.Item
              as="a"
              className={styles.delete}
              onClick={() => {
                setShowCancelRequestModal(true);
              }}
              icon={CloseCircleIcon}
              iconProps={{ color: 'red' }}
            >
              Cancel
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu>
      </div>
      <div className={styles.headerContainer}>
        <div
          id={`signature_request-document-${leaseDocument.id}`}
          style={{ width: '100%' }}
        >
          <div className={styles.documentTitle}>
            <FormIcon
              className={styles.icon}
              color={colors.text}
              style={{ width: iconSize, height: iconSize }}
            />
            <Link
              className={styles.docTitle}
              as="span"
              size="large"
              weight="semi"
              onClick={() => {
                signature_request.lease_documents.forEach((doc) => {
                  window.open(doc.url, '_blank');
                });
              }}
            >
              {signature_request.title}
            </Link>
          </div>
        </div>

        <div className={cx(styles.btnContainer, styles.inProgressBtns)}>
          <div className={cx(styles.actionButtonsWrapper, styles.mobileHidden)}>
            <FlatButton
              onClick={() => {
                if (isLeaseAgreement || isLeaseAddendum) {
                  setShowEditLeaseAgreementModal(true);
                } else {
                  setShowEditStandaloneEsignModal(true);
                }
              }}
              icon={EditIcon}
              className={cx(styles.btn, styles.actionButton, {
                [styles.topMarginOnSmall]: !existsPendingSigners,
              })}
              iconProps={{
                style: { marginRight: 4, top: 0, width: 20, height: 20 },
              }}
            >
              EDIT
            </FlatButton>

            <FlatButton
              onClick={() => {
                setShowCancelRequestModal(true);
              }}
              icon={CloseCircleIcon}
              className={cx(styles.btn, styles.actionButton, {
                [styles.topMarginOnSmall]: !existsPendingSigners,
              })}
              iconProps={{
                style: { marginRight: 4, top: 0, width: 20, height: 20 },
              }}
            >
              CANCEL
            </FlatButton>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: 'white' }}>
        <VerticalProgressBar
          active={existsPendingSigners ? 'Others Sign' : 'You Sign Last'}
        >
          <VerticalProgressBarOption
            label="Request Sent"
            className={styles.mobileRow}
            labelClassName={styles.progressBarLabel}
          >
            <div
              className={cx(styles.badgeContainer, styles.sentBadgeContainer)}
            >
              {existsPendingSigners && (
                <FlatButton
                  onClick={(e) => {
                    e.preventDefault();
                    handleSendReminderEmail();
                  }}
                  icon={ResendIcon}
                  className={cx(
                    styles.btn,
                    styles.btnMarginRight,
                    styles.mobileHidden,
                  )}
                  iconProps={{ style: { marginRight: 4, top: 0 } }}
                >
                  SEND REMINDER
                </FlatButton>
              )}
              <div className={cx(styles.banner, styles.mobileHidden)}>
                Sent at {reminderSentAt}
              </div>
              <div className={cx(styles.bannerMobile, styles.mobileOnly)}>
                {reminderSentAt}
              </div>
            </div>
          </VerticalProgressBarOption>
          <VerticalProgressBarOption
            label="Others Sign"
            labelContent={
              canCollapseSigners ? (
                <span
                  onClick={() => {
                    setExpandSigners(!expandSigners);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Others Sign{' '}
                  <ArrowRight
                    color="#033A6D"
                    width={9}
                    height={14}
                    style={{
                      position: 'relative',
                      top: 3,
                    }}
                    className={cx(styles.arrowDown, {
                      [styles.expanded]: expandSigners,
                    })}
                  />
                </span>
              ) : null
            }
            labelClassName={styles.progressBarLabel}
          >
            <div
              className={cx(styles.rentersList, {
                [styles.collapsable]: canCollapseSigners,
                [styles.expanded]: canCollapseSigners && expandSigners,
              })}
            >
              {signature_request.signatures
                .filter((s) => s.signer_type !== 'OWNER')
                .map((renter, index) => (
                  <div
                    className={styles.infoWrapper}
                    key={`signature-request-${index}`}
                  >
                    <div className={styles.info}>
                      <span className={styles.name}>{renter.signer_name}</span>
                      <span className={styles.email}>
                        {renter.signer_email_address}
                      </span>
                    </div>
                    {renderStatus(renter)}
                  </div>
                ))}
            </div>
            {canCollapseSigners && !expandSigners && (
              <span className={styles.everyoneElseSigned}>
                Everyone else has signed
              </span>
            )}
          </VerticalProgressBarOption>
          {owner ? (
            <VerticalProgressBarOption
              label="You Sign Last"
              labelClassName={styles.progressBarLabel}
            >
              <div className={cx(styles.infoWrapper, styles.ownerInfo)}>
                <div className={styles.info}>
                  <span className={styles.name}>{owner.signer_name}</span>
                  <span className={styles.email}>
                    {owner.signer_email_address}
                  </span>
                </div>
                {existsPendingSigners ? (
                  <span className={styles.ownerTurnLabel}>
                    We'll send you an email when it is your turn.
                  </span>
                ) : (
                  <Button
                    className={cx(styles.mobileHidden, styles.primaryButton)}
                    onClick={() =>
                      history.replace(
                        `${PRIVATE_BASE_PATH}esignature/sign/${signature_request.id}/${leaseId}`,
                      )
                    }
                  >
                    Sign Document
                  </Button>
                )}
              </div>
            </VerticalProgressBarOption>
          ) : null}
        </VerticalProgressBar>

        {existsPendingSigners ? (
          <Button
            secondary
            onClick={(e) => {
              e.preventDefault();
              handleSendReminderEmail();
            }}
            className={cx(styles.btn, styles.mobileOnly)}
            style={{ marginTop: 16 }}
          >
            SEND REMINDER
          </Button>
        ) : (
          <Button
            className={cx(styles.btn, styles.mobileOnly)}
            onClick={() =>
              history.replace(
                `${PRIVATE_BASE_PATH}esignature/sign/${signature_request.id}/${leaseId}`,
              )
            }
            style={{ marginTop: 16 }}
          >
            Sign Document
          </Button>
        )}
      </div>
      <CancelSignatureRequestModal
        open={showCancelRequestModal}
        onClose={() => setShowCancelRequestModal(false)}
        onGoBack={() => setShowCancelRequestModal(false)}
        onCancelRequest={() => {
          cancelRequest({
            signatureRequestId: signature_request.id,
            leaseId,
          });
        }}
      />
      <EditLeaseAgreementModal
        open={showEditLeaseAgreementModal}
        onClose={() => setShowEditLeaseAgreementModal(false)}
        onGoBack={() => setShowEditLeaseAgreementModal(false)}
        onEdit={() => {
          cancelRequest({
            signatureRequestId: signature_request.id,
            leaseId,
            edit: true,
            isLeaseAgreement: isLeaseAgreement,
            isLeaseAddendum: isLeaseAddendum,
            wizardDocumentId: isLeaseAddendum
              ? signature_request?.wizard_document?.id
              : null,
          });
        }}
      />
      <EditStandaloneEsignModal
        open={showEditStandaloneEsignModal}
        onClose={() => setShowEditStandaloneEsignModal(false)}
        onGoBack={() => setShowEditStandaloneEsignModal(false)}
        onEdit={() => {
          cancelRequest({
            signatureRequestId: signature_request.id,
            leaseId,
            edit: true,
          });
        }}
      />
    </>
  );
};

SignatureRequest.propTypes = {
  signature_request: PropTypes.object.isRequired,
  lease: PropTypes.object.isRequired,
  leaseId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  cancelRequest: PropTypes.func.isRequired,
  listVariant: PropTypes.bool,
  onExpand: PropTypes.func,
};

export default withRouter(SignatureRequest);
