import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import PageWrapper from '../../../../components/PageWrapper';
import componentLoader from '../../../../helpers/component-loader';
import { useConfig } from '../../../../providers/ConfigProvider';

const AddBankInfoManually = componentLoader(
  () => import('./AddBankInfoManually'),
);
const ManageBankAccount = componentLoader(() => import('./ManageBankAccount'));
const AddBankAccount = componentLoader(
  () => import('../../../../pages/owners/add-new-bank-account/AddBankAccount'),
);

const BankAccountRoutes = ({ match }) => {
  const me = match.url;
  const { PRIVATE_BASE_PATH } = useConfig();
  return (
    <Switch>
      <Route
        path={`${me}/add-bank-info-manually`}
        render={(props) => (
          <PageWrapper>
            <AddBankInfoManually {...props} />
          </PageWrapper>
        )}
      />
      <Route
        path={`${me}/replace-bank-info-manually/:bankAccountIDToReplace`}
        render={(props) => (
          <PageWrapper>
            <AddBankInfoManually {...props} />
          </PageWrapper>
        )}
      />
      <Route
        path={`${me}/add-bank-account`}
        render={(props) => (
          <PageWrapper>
            <AddBankAccount {...props} />
          </PageWrapper>
        )}
      />
      <Route
        path={`${me}/replace-bank-account/:bankAccountIDToReplace`}
        render={(props) => (
          <PageWrapper>
            <ManageBankAccount
              {...props}
              goBackToLink={`${PRIVATE_BASE_PATH}settings/rent-payments`}
            />
          </PageWrapper>
        )}
      />
    </Switch>
  );
};

BankAccountRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default BankAccountRoutes;
