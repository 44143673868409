import get from 'lodash.get';
import kebabCase from 'lodash.kebabcase';
import omit from 'lodash.omit';
import moment from 'moment';
import { parse, stringify } from 'query-string';

import browserHistory from '../browserHistory';
import STATE_NAMES from '../constants/lease_agreement/state-names';
import SUB_DIVISION_NAMES from '../constants/lease_agreement/sub-division-names';
import getAppliedTo from '../helpers/getAppliedTo';
import isTenant from '../helpers/isTenant';
import validateMailingAddress from '../helpers/validateMailingAddress';
import { EnumRentChargeType } from '../pages/enums';

import { getConfig } from './configService';
import * as localStorageService from './localStorageService';

export { segmentTracking } from './utilities/segment';

export function setUrlToRedirectAfterLogin(url) {
  localStorageService.setItem('redirectToAfterLogin', url);
}

export function setCurrentUrlToRedirectAfterLogin() {
  const url = browserHistory.location.pathname + location.search;
  setUrlToRedirectAfterLogin(url);
}

export function getUrlToRedirectAfterLogin() {
  return localStorageService.getItem('redirectToAfterLogin');
}

export function mapLeadPetsToLabel(pets) {
  if (!Array.isArray(pets) || pets.length === 0) return null;

  const exceptions = {
    NO_PETS: 'None',
  };
  return pets
    .map(
      (pet) =>
        exceptions[pet] ||
        pet
          .split('_')
          .map((s) => `${s[0].toUpperCase()}${s.toLowerCase().slice(1)}`)
          .join(' '),
    )
    .join(', ');
}

export function getPhones(application) {
  if (!application || !application.telephone) {
    return [];
  }
  const {
    telephone: { telephone_1, telephone_2 },
  } = application;
  const phones = [];
  if (telephone_1 && telephone_1.number && telephone_1.type) {
    phones.push({
      number: telephone_1.number,
      type: telephone_1.type,
    });
  }
  if (telephone_2 && telephone_2.number && telephone_2.type) {
    phones.push({
      number: telephone_2.number,
      type: telephone_2.type,
    });
  }
  return phones;
}

export function getStepOfNotCompletedApplication(application = {}) {
  const residences = get(application, 'residences', []);
  const { how_did_you_hear } = application;
  const employments = get(application, 'employments', []);
  const { has_payment } = application;

  if (residences && residences.length === 0) return 2;
  //you could not have employments but reached step5. So we check for agree to terms
  if (employments && employments.length === 0) return 3;
  if (!how_did_you_hear) return 4;
  if (!has_payment) return 5;
  return '';
}

export function getLeadSourceLabel(source) {
  switch (source) {
    case 'INVITE_APPLY':
      return 'Invite to Apply';
    case 'INVITE_APPLY_SMS':
      return 'Invite to Apply via SMS';
    case 'INVITE_APPLY_SMS_EMAIL':
      return 'Invite to Apply via Email & SMS';
    case 'CONTACT_LANDLORD':
      return 'Property Listing Contact Form';
    case 'SHARE_LISTING':
      return 'Shared Listing via Email';
    case 'SHARE_LISTING_SMS':
      return 'Shared Listing via SMS';
    case 'SHARE_LISTING_SMS_EMAIL':
      return 'Shared Listing via Email & SMS';
    case 'MANUAL':
      return 'Manually Created';
    case 'MP_HOMES':
      return 'Homes.com';
    case 'MP_APARTMENTS':
      return 'Apartments.com';
    case 'MP_REALTOR':
      return 'Realtor.com';
    case 'MP_APARTMENT_LIST':
      return 'Apartment List';
    case 'MP_WALK_SCORE':
      return 'Walk Score';
    case 'MP_ZILLOW':
      return 'Zillow Rental Network';
    case 'ZILLOW':
      return 'Zillow Rental Network';
    case 'FACEBOOK':
      return 'Facebook Marketplace';
    case 'MP_SHOW_ME_THE_RENT':
      return 'Show Me The Rent';
    case 'MP_ZUMPER':
      return 'Zumper';
    case 'MP_RENT_JUNGLE':
      return 'RentJungle.com';
    case 'MP_HUNT':
      return 'Hunt';
    case 'MP_CAMPUS_CRIBZ':
      return 'CampusCribz.com';
    case 'MP_CAMPUS_FRBO':
      return 'FRBO';
    case 'MP_EVERY_RENT':
      return 'EveryRent';
    case 'MP_RADPAD':
      return 'RadPad';
    case 'MP_RENT_PATH':
      return 'RentPath';
    case 'MP_RENTAL_SOURCE':
      return 'RentalSource';
    case 'MP_CRAIGSLIST':
      return 'Craigslist';
    case 'MP_ULOOP':
      return 'Uloop';
    case 'MP_DWELLSY':
      return 'Dwellsy';
    case 'GENERIC_PRESCREENER':
      return 'Pre-screener';
    case 'MP_COLLEGE_STUDENT_APARTMENTS':
      return 'College Student Apartments';
    case 'MP_COLLEGE_RENTALS':
      return 'College Rentals';
    case 'RENTBERRY':
      return 'Rentberry';
    case 'RENTHOP':
      return 'RentHop';
    case 'APARTMENT_ADVISOR':
      return 'Apartment Advisor';
    case 'REFERRAL':
      return 'Referral';
    case 'YARD_SIGN':
      return 'Yard Sign';
    case 'SPAREROOM':
      return 'SpareRoom';
    case 'UNKNOWN':
      return 'UNKNOWN';
    case 'OTHER':
      return 'Pre-screener';
    default:
      return '';
  }
}

const rentersDefaultFilters = {
  search: (data, value) => {
    const renter = data.renter || data;
    if (!value) return true;
    const splittedValues = value.split(/ +/g);
    return splittedValues.every((value) => {
      const string = ['first_name', 'last_name', 'email', 'phone'].reduce(
        (acc, fieldName) => {
          const value =
            renter[fieldName] || (renter.renter && renter.renter[fieldName]);
          return value ? `${acc} ${renter[fieldName]}` : acc;
        },
        '',
      );
      return string.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    });
  },
  propertyIdFilter: (renter, filter) => {
    const listing =
      (renter.application && renter.application.listing) ||
      (renter.leases && get(renter, 'leases[0].listing', false)) ||
      renter.listing;
    if (Array.isArray(filter) && listing) {
      return filter.includes(listing?.id || listing);
    }
    return filter === '' || (listing && listing.id === filter);
  },
  leadStatusFilter: (renter, filter) => {
    return !filter || renter.status === filter;
  },
  prescreened: (renter, filter) => {
    return (
      !filter ||
      filter === 'false' ||
      (filter === 'true' && !!renter.questionnaire)
    );
  },
};

export function getFilteredRenters({ renters, filters = {}, values }) {
  const filtersToApply = {
    ...rentersDefaultFilters,
    ...filters,
  };
  const filtersWithValue = Object.keys(values).filter((item) => !!values[item]); // keep filters only with values
  if (filtersWithValue.length === 0) return renters; // if there is no filters just return the array
  return renters.filter((renter) => {
    return filtersWithValue.every((filter) => {
      if (typeof filtersToApply[filter] !== 'function') return true;
      return filtersToApply[filter](renter, values[filter]);
    });
  });
}

export function cleanRentersSearchParams() {
  const searchParamsToClean = ['search', 'propertyIdFilter'];
  const { location } = browserHistory;
  const queryParams = parse(location.search);
  const newParams = stringify(omit(queryParams, searchParamsToClean));
  browserHistory.push(`${location.pathname}?${newParams}`);
}

export const formatUserLabel = (userId) => {
  const { IS_OWNER } = getConfig();
  return `${IS_OWNER ? 'Owner' : 'Renter'}:${userId}`;
};

export const gtmDataLayerPush = (pageViewData) => {
  try {
    window.dataLayer.push({
      ...pageViewData,
    });
  } catch {
    console.log('dataLayer not initialized');
  }
};

export const impactTrackConversion = (ownerId) => {
  try {
    const { IMPACT_ACCOUNT_CREATION_EVENT_ID } = getConfig();
    window.ire('trackConversion', IMPACT_ACCOUNT_CREATION_EVENT_ID, {
      customerId: ownerId,
      orderId: ownerId,
    });
  } catch {
    console.log('IRE not initialized');
  }
};

export const getSubdomain = () => {
  try {
    const host = window.location.host;
    const subdomain = host.split('.')[0];
    return subdomain ? subdomain : 'none';
  } catch {
    console.log('Error while pulling the subdomain');
  }
};

export const getCookieDomainName = () => {
  try {
    const [tld, domain] = window.location.hostname.split('.').reverse();
    if (!domain) {
      return tld;
    }
    // .turbotenant.com
    return `.${domain}.${tld}`;
  } catch {
    return '.turbotenant.com';
  }
};

export function trim(value) {
  try {
    return value.trim();
  } catch {
    return value;
  }
}

export const gettingStartedItems = (process) => {
  const items = {
    VIEW_AN_EXAMPLE: {
      id: 'sample-listing',
      title: 'View an Example of a Great Listing Page',
      description: 'See how to help your rental get more attention.',
      checkedOnClick: true,
    },
    ADD_FIRST_PROPERTY: {
      id: 'first-listing',
      title: 'Add your 1st Property',
      description: 'To attract potential tenants, add your property info.',
    },
    POST_YOUR_PROPERTY: {
      id: 'marketing-on',
      title: 'Turn Marketing Online On',
      description: 'Post your property on dozens of listing sites.',
    },
    SHARE_LISTING: {
      id: 'share-listing',
      title: 'Share your Listing by Text or Email',
      description: 'Automatically send renters more info about your rental.',
    },
    POST_TO_FACEBOOK: {
      id: 'post-to-facebook',
      title: 'Post to Facebook',
      description: 'Utilize your personal network to fill your vacancy.',
      checkedOnClick: true,
    },
    PRINT_FLYERS: {
      id: 'print-flyers',
      title: 'Print flyers',
      description: 'Get maximum exposure in the local community.',
      checkedOnClick: true,
    },
    ORDER_YARD_SIGNS: {
      id: 'order-yard',
      title: 'Order Yard Signs',
      description: 'Catch the eyes of local renters passing by your property.',
      checkedOnClick: true,
    },
    LEARN_MANAGING_LEADS: {
      id: 'learn-leads',
      title: 'Learn about Managing Leads',
      description:
        'Automatically track who expresses interest in your property.',
      checkedOnClick: true,
    },
    LEARN_RENTAL_APPLICATION: {
      id: 'learn-rr',
      title: 'Learn about our Rental Application',
      description: 'Get answers to all the industry standard questions.',
      checkedOnClick: true,
    },
    INVITE_RENTER: {
      id: 'invite-renter',
      title: 'Invite a Renter to Apply',
      description:
        'Quickly send any renter a link to apply online by text or email.',
    },
    ADD_PHONE_ADDRESS: {
      id: 'add-phone-address',
      title:
        'Add Phone & Mailing Address to get Screening Reports Back Instantly',
      description:
        'Instead of waiting, get the information you need in seconds.',
    },
    ADD_CUSTOM_APPLICATION_QUESTIONS: {
      id: 'add-questions',
      title: 'Add Custom Application Questions',
      description: 'Customize your application to fit your needs.',
      checkedOnClick: true,
    },
    SCREEN_RENTER: {
      id: 'screen-renter',
      title: 'Screen A Renter',
      description:
        "Need to check someone's credit, criminial, and eviction history?",
      checkedOnClick: true,
    },
    COMPLETE_PROPERTY_INFO: {
      id: 'complete-listing',
      title: 'Complete Your Property Info',
      description: 'Streamline your marketing, online applications and more!',
    },
    POST_TO_CRAIGLIST: {
      id: 'post-to-craigslist',
      title: 'Post your Auto-Generated Ad to Craigslist',
      description: 'Reach more renters by posting on Craigslist.',
      checkedOnClick: true,
    },
    LEARN_FILL_VACANCY_FAST: {
      id: 'learn-vacancy',
      title: 'Learn how to fill your vacancy fast!',
      description:
        'In case you need to find more renters, learn about Marketing Online.',
      checkedOnClick: true,
    },
    LEARN_SCREENING_REPORT: {
      id: 'learn-screening',
      title: 'Learn how to read a screening report',
      description:
        "Tips for understanding the credit and background check you'll receive from your renter.",
      checkedOnClick: true,
    },
    SETUP_RENTER_INSURANCE: {
      id: 'setup-insurance',
      title: 'Set up Renters Insurance',
      description:
        'Sleep easy knowing that both you and your tenants are covered no matter what happens.',
      checkedOnClick: true,
    },
  };

  if (process === 'RECEIVE_APPLICATION') {
    items.ADD_FIRST_PROPERTY.description =
      'To invite a renter to apply online, add your first property.';
  }

  return items;
};

const parseNumber = (number) => {
  return Number(number) || 0;
};

export const getCurrentTotalIncome = (application) => {
  const employments = get(application, 'employments', []) || [];
  const incomeSources = get(application, 'income_sources', []) || [];
  const currentEmploymentTotalIncome = employments.reduce(
    (total, next) =>
      next.current ? total + parseNumber(next.monthly_income) : total,
    0,
  );
  const otherTotalIncome = incomeSources.reduce(
    (total, { monthly_income }) => total + parseNumber(monthly_income),
    0,
  );
  return currentEmploymentTotalIncome + otherTotalIncome;
};

export const capitalizeFirstLetter = (string) => {
  if (!string) {
    return '';
  }
  string = string.toLowerCase();
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const capitalizeFirstLetterEachWord = (str) => {
  if (!str) return '';
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
};

export const checkIfRenterHasSigned = (signatures, renterId) => {
  const signed =
    signatures.filter((s) => s.signed_at && s.renter_id === renterId).length >
    0;
  return signed;
};

export const llHasMailingAddress = (user) => {
  const mailingAddress = get(user, 'mailing_address', null);
  if (!mailingAddress) return false;
  return (
    Object.keys(mailingAddress).every((key) => {
      if (key === 'unit') {
        return true;
      }
      return !!mailingAddress[key];
    }) && validateMailingAddress(mailingAddress)
  );
};

export const getDaysRemaining = (expirationDate) =>
  Math.round(moment.duration(moment(expirationDate).diff(moment())).asDays());

export const getFileNameWithoutExtension = (fileName = '') => {
  return fileName.split('.').slice(0, -1).join('.');
};

export const renterInsuranceNotificationSent = (renter) => {
  if (renter && renter.id) {
    const decodedId = atob(renter.id).split(':').pop();
    const renterId = btoa(`Renter:${decodedId}`);
    const renterNotified =
      renter.leases &&
      renter.leases.length > 0 &&
      renter.leases.some(
        (l) =>
          l.insurance_notification_sent &&
          l.insurance_notification_sent.some(
            (r) => r.renter_id === renterId && r.notification_sent,
          ),
      );
    return renterNotified;
  }
  return null;
};

export const getCardinalEndingString = (number) => {
  if (number === 11 || number === 12 || number === 13) {
    return 'th';
  }

  const lastNum = number % 10;
  switch (lastNum) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

const getListingsFilter = (query) => {
  if (query?.propertyIdFilter) {
    if (Array.isArray(query.propertyIdFilter)) {
      return query.propertyIdFilter;
    }
    return [query.propertyIdFilter];
  }
  return [];
};

export const getLeadsQueryInfo = (search, archived = false) => {
  const { LEADS_TABLE_PAGE_SIZE, USER_PROFILE_POLL_INTERVAL } = getConfig();
  let variables = {
    archived,
    first: LEADS_TABLE_PAGE_SIZE,
    listingFilter: [],
    offset: 0,
    prescreenFilter: false,
    searchFilter: null,
    statusFilter: null,
  };
  if (search) {
    const query = parse(search, { arrayFormat: 'comma' });
    variables = {
      ...variables,
      prescreenFilter: query.prescreened === 'true',
      statusFilter: query.leadStatusFilter || null,
      archived,
      searchFilter:
        query.search && query.search.length > 2 ? query.search : null,
      listingFilter: getListingsFilter(query),
      offset: (Number(query.page) || 0) * LEADS_TABLE_PAGE_SIZE,
    };
  }
  const fetchPolicy = {};
  if (
    Object.keys(variables).filter((k) => k !== 'first' && !!variables[k])
      .length > 0
  ) {
    // Only cache the first page of active leads, the rest of the pages are being cached on the server
    fetchPolicy.fetchPolicy = 'network-only';
  }
  return {
    pollInterval: USER_PROFILE_POLL_INTERVAL,
    ...fetchPolicy, // Only cache the first page of active leads
    variables,
    context: {
      pollInterval: USER_PROFILE_POLL_INTERVAL,
    },
  };
};

export const getPublicListingUrl = (id) => {
  const { SHORT_LINKS_ENABLED, SHORT_LINKS, PUBLIC_LISTING_URL } = getConfig();
  let listingId = id;
  if (isNaN(parseInt(id))) {
    const regex = /\d+/g;
    listingId = atob(id).match(regex)[0];
  }

  return SHORT_LINKS_ENABLED
    ? SHORT_LINKS`${listingId}p`
    : PUBLIC_LISTING_URL + listingId;
};

export const scrollToBottom = () =>
  window.scrollTo(0, document.body.scrollHeight);

export const getRentPaymentCategoryLabel = (category) => {
  switch (category) {
    case EnumRentChargeType.LATE_FEE:
      return 'Late Fee';
    case EnumRentChargeType.NSF_RETURNED_PAYMENT:
      return 'NSF Fee';
    case EnumRentChargeType.RENT:
      return 'Rent';
    case EnumRentChargeType.SECURITY_DEPOSIT:
      return 'Security Deposit';
    case EnumRentChargeType.OTHER:
      return 'Other';
    case EnumRentChargeType.UTILITY_CHARGE:
      return 'Utility Charge';
  }
};

export const leasesHasPaymentRequests = (leases) =>
  leases &&
  leases.some((l) => l.payment_requests && l.payment_requests.length > 0);

export const parseId = (id) => {
  try {
    return atob(id).split(':');
  } catch {
    console.warn(`This id, is not parsable: ${id}`);
    return '';
  }
};

// Assumes the renter parameter has a `leases` property that contains only active leases.
export function isTenantOnLeaseForListing(renter, listing_id) {
  return (
    renter &&
    get(renter, 'leases', []).some((lease) => lease.listing.id === listing_id)
  );
}

export const getStateArticle = (state) => {
  if (
    [
      'AK',
      'AL',
      'AR',
      'AZ',
      'IA',
      'ID',
      'IL',
      'IN',
      'OH',
      'OK',
      'OR',
      'AS',
    ].includes(state)
  ) {
    return 'an';
  }
  return 'a';
};

export const getStateFullName = (code, subDivision, showEmptyState = true) => {
  if (subDivision && SUB_DIVISION_NAMES[code]?.[subDivision]) {
    return `${SUB_DIVISION_NAMES[code]?.[subDivision]}, ${STATE_NAMES[code]}`;
  }
  if (STATE_NAMES[code]) {
    return STATE_NAMES[code];
  }
  return showEmptyState ? 'XXX' : null;
};

export const getBackgroundCheckTitle = (value) => {
  if (value === null) {
    return '';
  }
  return value ? 'Yes' : 'No';
};

export const parseStringBool = (value) => {
  try {
    return JSON.parse(value);
  } catch {
    return value;
  }
};

// the listing it's inside the renter because of how the applicants list is created in this file EditLeaseTenantsContainer
export const getAppliedToAddress = (renter) => {
  try {
    let listing;
    if (isTenant(renter)) {
      if (!renter.application) {
        return 'No Online Application';
      }
      listing = renter.application.listing;
    } else {
      listing = renter.application.listing;
    }
    return getAppliedTo(listing);
  } catch {
    return null;
  }
};

export const getAppliedWith = (renter) =>
  get(renter, 'application.co_applicant_description', null);

export const findRenterInLease = (lease, renterId) => {
  try {
    return lease.renters.find((r) => r.id === renterId) || {};
  } catch {
    return {};
  }
};
/*
  NOTE: This logic is used accross the app,
  All places where it's used should be updated
*/
export const getPropertyPhotoData = (property) =>
  get(property, 'photos.length', 0) > 0
    ? {
        url: [
          property.photos[0].picture_midsize,
          property.photos[0].picture_full,
          property.photos[0].picture,
        ],
        rotation: property.photos[0].rotation,
      }
    : {};

export const graphqlCacheKeysPatterns = {
  leases: /UserData.[^.]+.leases/,
  tenants: /UserData.[^.]+.tenants/,
  applicants: /UserData.[^.]+.applicants/,
  leads: /UserData.[^.]+.leads/,
  archivedData: /viewer.archivedData/,
  listings: /UserData.[^.]+.listings/,
  maintenanceRequests: /UserData.[^.]+.maintenance_requests/,
  paymentRequests: /UserData.[^.]+.payment_requests/,
  paymentRequestRules: /UserData.[^.]+.payment_request_rules/,
  userProfile: /UserData.[^.]+.basicProfile/,
  renterProfile: /RenterProfile\.[^.]+/,
  activeTenants: /UserData.[^.]+.active_tenants/,
  archivedTenants: /UserData.[^.]+.archived_tenants/,
  pastTenants: /UserData.[^.]+.past_tenants/,
  userSettings: /UserData.[^.]+.settingsAndBilling/,
  expenses: /UserData.[^.]+.expenses/,
  allDocuments: /^allDocuments/,
  filters: /^filters/,
  signatureRequests: /^getAllSignatureRequestsForOwner/,
};

export const removeFromApolloCache = (keys, cache) => {
  const arrayOfKeys = Array.isArray(keys) ? keys : [keys];
  const patterns = arrayOfKeys.reduce((acc, next) => {
    const p = graphqlCacheKeysPatterns[next];
    if (!p) {
      console.log(`No pattern for key: ${next}`);
      return acc;
    }
    acc.push(p);
    return acc;
  }, []);

  Object.keys(cache.data.data).forEach((key) => {
    if (patterns.some((p) => key.match(p))) {
      cache.data.delete && cache.data.delete(key);
    }
  });

  Object.keys(cache.data.data?.ROOT_QUERY).forEach((key) => {
    if (patterns.some((p) => key.match(p))) {
      cache.evict({
        fieldName: key,
      });
    }
  });

  const userDataKeysToDelete = arrayOfKeys.map((k) =>
    kebabCase(k).replace(/-/g, '_'),
  );

  if (arrayOfKeys.indexOf('userProfile')) {
    userDataKeysToDelete.push('basicProfile');
  }

  Object.keys(cache.data.data)
    .filter((key) => key.match(/UserData\..+$/))
    .forEach((userDataKey) => {
      const obj = cache.data.data[userDataKey];
      const keysToDelete = [];
      Object.keys(obj).forEach((k) => {
        if (userDataKeysToDelete.find((key) => k.startsWith(key))) {
          keysToDelete.push(k);
        }
      });
      keysToDelete.forEach((keyToDelete) =>
        cache.evict({ id: userDataKey, fieldName: keyToDelete }),
      );
    });
  cache.gc();
};

// NOTE, this should go into commons once we are able to use it inside API side too
export function parseListingSearchString(searchString) {
  const [, stNumber, street, , parsedCity, , parsedState, parsedZip] = (
    searchString || ''
  )
    .trim()
    .match(/^(\d+ +)?([\w ']+)(, *([\w ]+))?(, *([A-Z]{2})( +\d{5})?)?/);
  const address = `${(stNumber || '').trim()} ${(street || '').trim()}`.trim();
  let state = parsedState,
    zip = parsedZip,
    city = parsedCity;
  if (!state && city) {
    [, state, zip] = city.match(/([A-Z]{2})( +\d{5})? *$/) || [];
    city = parsedCity.replace(/([A-Z]{2})( +\d{5})? *$/g, '');
  }
  return {
    address,
    ...(city && { city: city.trim() }),
    ...(state && { state }),
    ...(zip && { zip: zip.trim() }),
  };
}

export const getFormattedUnixDate = (date, format = 'MM/DD/YYYY') => {
  return moment.unix(date).format(format);
};

export const isSegmentAllowedRoute = (pathname) =>
  pathname.startsWith('/applications') || pathname.startsWith('/auth/signup');

export const isIntercomAllowedRoute = (pathname) => {
  const messagesPage = pathname.indexOf('/messages') !== -1;
  return !messagesPage;
};

export const propertyTypes = ({ withEmptyState }) => {
  const options = [
    { title: 'Single-Family', value: 'SINGLE_FAMILY' },
    { title: 'Apartment', value: 'APARTMENT' },
    { title: 'Condo', value: 'CONDO' },
    { title: 'Townhouse', value: 'TOWNHOUSE' },
    { title: 'Co-op', value: 'CO_OP' },
    { title: 'Multi-Family', value: 'MULTI_FAMILY' },
    { title: 'Manufactured', value: 'MANUFACTURED' },
    { title: 'Other', value: 'OTHER' },
  ];
  if (withEmptyState) {
    return [{ title: '', value: '' }, ...options];
  }
  return options;
};

export const getRenterActiveListing = (user) => {
  const activeListingLeases = get(user, 'leases', []) || [];
  let activeListingLease = activeListingLeases.find(
    (lease) => lease.status === 'CURRENT' || lease.status === 'ENDING_SOON',
  );
  if (!activeListingLease) {
    activeListingLease = activeListingLeases.find(
      (lease) => lease.status === 'UPCOMING',
    );
  }
  return activeListingLease || {};
};

export const getDueDates = () => {
  const dates = [
    {
      title: '',
      value: '',
    },
  ];
  for (let i = 1; i <= 28; i++) {
    let suffix;
    switch (i) {
      case 1:
      case 21:
        suffix = 'st';
        break;
      case 2:
      case 22:
        suffix = 'nd';
        break;
      case 3:
      case 23:
        suffix = 'rd';
        break;
      default:
        suffix = 'th';
    }
    dates.push({
      title: `${i}${suffix}`,
      value: i,
    });
  }
  return dates;
};

export const MONTHS_ENUM = [
  { title: 'January', value: 0 },
  { title: 'February', value: 1 },
  { title: 'March', value: 2 },
  { title: 'April', value: 3 },
  { title: 'May', value: 4 },
  { title: 'June', value: 5 },
  { title: 'July', value: 6 },
  { title: 'August', value: 7 },
  { title: 'September', value: 8 },
  { title: 'October', value: 9 },
  { title: 'November', value: 10 },
  { title: 'December', value: 11 },
];

export const MONTH_DICTIONARY = MONTHS_ENUM.reduce(
  (dic, { value, title }) => ({
    ...dic,
    [value]: title,
  }),
  {},
);

export const getYears = () => {
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let i = 0; i < 10; i++) {
    const year = currentYear + i;
    years.push({
      title: year,
      value: year,
    });
  }
  return years;
};

export const ellipsis = (str, length) => {
  const formatedStr = str.trim().replace(/\s+/g, ' ');
  const cutString = formatedStr.substr(0, length);
  let addDots;
  if (cutString.substr(-3) === '...') addDots = '';
  else if (cutString.substr(-2) === '..') addDots = '.';
  else if (cutString.substr(-1) === '.') addDots = '..';
  else addDots = '...';
  return `${cutString.substr(0, length)}${
    formatedStr.length > length ? addDots : ''
  }`;
};

export const toTitleCase = (str = '') => {
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
