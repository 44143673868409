import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';

import MAINTENANCE_CATEGORIES from '../../../../constants/maintenanceCategories';
import composeValidators from '../../../../validators/composeValidators';
import fieldRequired from '../../../../validators/fieldRequired';
import validEmail from '../../../../validators/validEmail';
import validPhone from '../../../../validators/validPhone';
import Button from '../../../Button';
import Input from '../../../Input';
import MultiSelectDropdown from '../../../MultiSelectDropdown';
import PhoneField from '../../../PhoneField';
import { Tooltip } from '../../../Tooltip';

import { shouldShowSendWorkOrderTooltip } from './shouldShowSendWorkOrderTooltip';

import styles from './NewProForm.module.scss';

const NewProForm = ({ onSave, onClear, vendorsCount }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    setShowTooltip(vendorsCount === 0 && shouldShowSendWorkOrderTooltip());
  }, [vendorsCount]);

  const handleTooltipClose = () => {
    setShowTooltip(false);
  };

  return (
    <Form
      onSubmit={onSave}
      subscription={{
        submitting: true,
        values: true,
      }}
    >
      {({ handleSubmit, submitting, form }) => (
        <form onSubmit={handleSubmit} className={styles.container}>
          <h3 className={styles.title} data-tooltip-id="new-pro-tooltip">
            NEW MAINTENANCE PRO
          </h3>
          {showTooltip && (
            <Tooltip
              id="new-pro-tooltip"
              place="left"
              isOpen={true}
              clickable={true}
              type="default"
            >
              <div className={styles.tooltipContent}>
                <h4 className={styles.tooltipTitle}>New!</h4>
                <p className={styles.tooltipText}>
                  You can now save your maintenance pros for future work orders
                  with more contact details.
                </p>
                <Button
                  secondary
                  small
                  onClick={handleTooltipClose}
                  className={styles.tooltipButton}
                  variant="reverse"
                >
                  Sounds good
                </Button>
              </div>
            </Tooltip>
          )}
          <Field
            className={styles.fullName}
            label="Full Name"
            component={Input}
            name="full_name"
            id="full_name"
            maxLength={100}
            validate={fieldRequired}
          />
          <Field
            className={styles.companyName}
            label="Company Name"
            hint="(Optional)"
            component={Input}
            name="company_name"
            id="company_name"
            maxLength={150}
          />
          <Field
            className={styles.email}
            label="Email"
            component={Input}
            type="email"
            name="email"
            id="email"
            validate={composeValidators(fieldRequired, validEmail)}
          />
          <Field
            className={styles.phone}
            label="Phone Number"
            component={PhoneField}
            type="tel"
            name="phone"
            id="phone"
            validate={composeValidators(fieldRequired, validPhone)}
          />
          <Field
            className={styles.category}
            label="Category"
            hint="(Optional)"
            component={MultiSelectDropdown}
            name="category"
            id="category"
            options={MAINTENANCE_CATEGORIES}
          />
          <div className={styles.actions}>
            <Button
              className={styles.btn}
              secondary
              onClick={() => {
                form.reset();
                onClear();
              }}
              disabled={submitting}
            >
              CLEAR ALL
            </Button>
            <Button
              className={styles.btn}
              /** Notice: According to what I found reading stackoverflow questions and other sources
               *          we should not have a <form /> tag inside a <form /> tag. For now, I'm just changing
               *          the type of the button and submitting the form manually. We may need to refactor this
               *          component.
               */
              onClick={() => {
                form.submit();
              }}
              loading={submitting}
            >
              SAVE
            </Button>
          </div>
        </form>
      )}
    </Form>
  );
};

NewProForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  vendorsCount: PropTypes.number,
};

export default NewProForm;
