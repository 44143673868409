import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Esign = ({ color, ...rest }) => (
  <svg {...rest} width="152" height="152" viewBox="0 0 152 152" fill="none">
    <g>
      <path
        opacity="0.06"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.482 136.129C59.9398 130.424 29.7926 133.26 13.7585 113.629C-21.4713 58.7059 75.1681 -19.8387 116.243 15.6905C144.412 40.0564 153.494 87.1161 140.108 124.819C129.633 154.322 92.2683 144.043 73.482 136.129Z"
        fill="#2370A3"
      />
      <g id="Vector">
        <path
          d="M107.714 30.9996H45.185C43.9783 30.9996 43 31.9779 43 33.1846V116.063C43 117.269 43.9783 118.248 45.185 118.248H107.714C108.921 118.248 109.899 117.269 109.899 116.063V33.1846C109.899 31.9779 108.921 30.9996 107.714 30.9996Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.185 31.8596C44.4532 31.8596 43.86 32.4529 43.86 33.1846V116.063C43.86 116.794 44.4532 117.388 45.185 117.388H107.714C108.446 117.388 109.039 116.794 109.039 116.063V33.1846C109.039 32.4529 108.446 31.8596 107.714 31.8596H45.185ZM42.14 33.1846C42.14 31.5029 43.5033 30.1396 45.185 30.1396H107.714C109.396 30.1396 110.759 31.5029 110.759 33.1846V116.063C110.759 117.744 109.396 119.108 107.714 119.108H45.185C43.5033 119.108 42.14 117.744 42.14 116.063V33.1846Z"
          fill="#042238"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51 84.9996C51 84.4474 51.4477 83.9996 52 83.9996H95C95.5523 83.9996 96 84.4474 96 84.9996C96 85.5519 95.5523 85.9996 95 85.9996H52C51.4477 85.9996 51 85.5519 51 84.9996Z"
          fill="#042238"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.5 54.4996C51.5 53.9474 51.9477 53.4996 52.5 53.4996H96C96.5523 53.4996 97 53.9474 97 54.4996C97 55.0519 96.5523 55.4996 96 55.4996H52.5C51.9477 55.4996 51.5 55.0519 51.5 54.4996Z"
          fill="#042238"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51 66.9996C51 66.4474 51.4477 65.9996 52 65.9996H91C91.5523 65.9996 92 66.4474 92 66.9996C92 67.5519 91.5523 67.9996 91 67.9996H52C51.4477 67.9996 51 67.5519 51 66.9996Z"
          fill="#042238"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51 72.9996C51 72.4474 51.4477 71.9996 52 71.9996H94C94.5523 71.9996 95 72.4474 95 72.9996C95 73.5519 94.5523 73.9996 94 73.9996H52C51.4477 73.9996 51 73.5519 51 72.9996Z"
          fill="#042238"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51 78.9996C51 78.4474 51.4477 77.9996 52 77.9996H83C83.5523 77.9996 84 78.4474 84 78.9996C84 79.5519 83.5523 79.9996 83 79.9996H52C51.4477 79.9996 51 79.5519 51 78.9996Z"
          fill="#042238"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51 90.9996C51 90.4474 51.4477 89.9996 52 89.9996H79C79.5523 89.9996 80 90.4474 80 90.9996C80 91.5519 79.5523 91.9996 79 91.9996H52C51.4477 91.9996 51 91.5519 51 90.9996Z"
          fill="#042238"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51 60.9996C51 60.4474 51.4477 59.9996 52 59.9996H101C101.552 59.9996 102 60.4474 102 60.9996C102 61.5519 101.552 61.9996 101 61.9996H52C51.4477 61.9996 51 61.5519 51 60.9996Z"
          fill="#042238"
        />
        <path
          d="M98.974 39.7207H53.906C52.6993 39.7207 51.721 40.699 51.721 41.9057V46.2567C51.721 47.4635 52.6993 48.4417 53.906 48.4417H98.974C100.181 48.4417 101.159 47.4635 101.159 46.2567V41.9057C101.159 40.699 100.181 39.7207 98.974 39.7207Z"
          fill="#7FE3FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.906 40.5807C53.1742 40.5807 52.581 41.174 52.581 41.9057V46.2567C52.581 46.9885 53.1742 47.5817 53.906 47.5817H98.974C99.7058 47.5817 100.299 46.9885 100.299 46.2567V41.9057C100.299 41.174 99.7058 40.5807 98.974 40.5807H53.906ZM50.861 41.9057C50.861 40.224 52.2243 38.8607 53.906 38.8607H98.974C100.656 38.8607 102.019 40.224 102.019 41.9057V46.2567C102.019 47.9384 100.656 49.3017 98.974 49.3017H53.906C52.2243 49.3017 50.861 47.9384 50.861 46.2567V41.9057Z"
          fill="#042238"
        />
      </g>
      <path
        id="Shape"
        d="M130 71.5L138.196 64.0653L118.826 52.9131L118 63L130 71.5Z"
        fill="#7FE3FF"
      />
      <g id="Vector_2">
        <path
          d="M129.5 86.5L101 107.5H100L104 71L118 63.5L130 71L129.5 86.5Z"
          fill="white"
        />
        <path
          d="M121.218 80.2055C119.986 82.2817 117.323 82.9774 115.27 81.7593C113.217 80.5413 112.551 77.8707 113.782 75.7945C115.014 73.7183 117.677 73.0226 119.73 74.2407C121.783 75.4587 122.449 78.1293 121.218 80.2055Z"
          fill="#7FE3FF"
        />
      </g>
      <g id="Vector_3">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.534 81.4839C117.078 82.3551 119.38 81.7901 120.548 79.7205C121.716 77.6508 121.01 75.388 119.466 74.5167C117.922 73.6455 115.62 74.2105 114.452 76.2801C113.284 78.3498 113.99 80.6126 115.534 81.4839ZM114.551 83.2257C117.197 84.7185 120.661 83.5893 122.29 80.7034C123.919 77.8175 123.094 74.2678 120.449 72.7749C117.803 71.2821 114.339 72.4113 112.71 75.2972C111.081 78.1831 111.906 81.7328 114.551 83.2257Z"
          fill="#042238"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M117.847 54.4771C117.994 53.0142 119.615 52.2038 120.873 52.9651L137.302 62.9091C138.486 63.6258 138.602 65.2996 137.528 66.1723L131 71.4763V85.9953C131 86.6304 130.698 87.2278 130.187 87.6048L99.8289 110H53C52.4477 110 52 109.553 52 109C52 108.448 52.4477 108 53 108H98.6114L102.948 71.3833C103.022 70.7536 103.391 70.1966 103.942 69.882L117.056 62.3878L117.847 54.4771ZM117.96 64.1752L104.934 71.6185L101.077 104.184L113.63 82.0077C113.902 81.5271 114.512 81.358 114.993 81.63C115.473 81.9021 115.642 82.5123 115.37 82.9929L102.681 105.411L129 85.9953V71.5355L117.96 64.1752ZM129.941 69.7594L136.267 64.6201L119.837 54.6761L119.055 62.5017L129.941 69.7594Z"
          fill="#042238"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M59.0034 95.7791C60.9098 95.0768 63.269 95.3315 65.6254 97.22C66.3828 97.827 66.8895 98.5525 67.1145 99.382C67.3376 100.204 67.2595 101.039 67.0048 101.831C66.7949 102.483 66.4564 103.134 66.0288 103.772C66.6422 103.554 67.2871 103.305 67.9352 103.042C69.8339 102.271 71.6219 101.432 72.4315 101.044C73.1887 100.682 74.0857 101.009 74.44 101.76C74.7868 102.494 75.2725 103.417 75.9774 104.115C76.653 104.785 77.4942 105.218 78.641 105.086C79.688 104.966 80.7723 104.404 81.8032 103.618C82.8227 102.841 83.7102 101.907 84.3593 101.161C84.6186 100.862 85.2643 100.382 85.9962 100.763C86.7835 101.173 86.6831 102.035 86.5513 102.41C86.2567 103.25 85.9713 104.113 85.931 104.748C85.9203 104.917 85.9312 105.021 85.942 105.078C85.9576 105.079 85.9768 105.08 86 105.08C86.5523 105.08 87 105.527 87 106.08C87 106.632 86.5523 107.08 86 107.08C85.3108 107.08 84.6732 106.826 84.282 106.23C83.9415 105.71 83.9048 105.097 83.9351 104.621C83.939 104.56 83.9442 104.497 83.9507 104.435C83.655 104.699 83.3425 104.96 83.0156 105.209C81.8531 106.095 80.4272 106.895 78.8685 107.073C76.9829 107.289 75.5687 106.526 74.57 105.536C73.7734 104.747 73.2211 103.798 72.849 103.06C71.9069 103.505 70.3417 104.223 68.6878 104.895C66.9785 105.589 65.0593 106.283 63.635 106.525C60.7893 109.23 56.8583 111.687 54.4478 112.894C53.954 113.142 53.3532 112.942 53.1059 112.448C52.8586 111.954 53.0584 111.354 53.5522 111.106C55.399 110.181 58.197 108.472 60.6071 106.518C59.1048 106.26 57.858 105.617 56.9407 104.711C55.5499 103.336 54.9813 101.405 55.4449 99.5473C55.8317 97.9972 57.1696 96.4547 59.0034 95.7791ZM62.721 104.621C63.9363 103.394 64.7772 102.224 65.1009 101.218C65.2676 100.7 65.2829 100.269 65.1843 99.9056C65.0875 99.549 64.8571 99.1673 64.3746 98.7806C62.5046 97.282 60.8732 97.2216 59.6948 97.6558C58.4437 98.1167 57.6022 99.1624 57.3853 100.032C57.1 101.175 57.437 102.389 58.3467 103.288C59.2181 104.15 60.6759 104.771 62.721 104.621Z"
          fill="#042238"
        />
      </g>
    </g>
  </svg>
);

// eslint-disable-next-line local/no-default-props
Esign.defaultProps = {
  color: colors.text,
  width: 24,
  height: 25,
};

Esign.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Esign);
