import React from 'react';
import PropTypes from 'prop-types';

import AccountFind from '../../icons/AccountFind';
import SectionTitleWithIconAndAction from '../SectionTitleWithIconAndAction';

import styles from './PullingReportMessage.module.scss';

const PullingReportMessage = ({ title, titleDescription }) => {
  return (
    <div className={styles.container}>
      <SectionTitleWithIconAndAction
        className={styles.title}
        title={title}
        text={titleDescription}
        icon={AccountFind}
      />
      <div className={styles.descriptionContainer}>
        <span className={styles.description}>
          We are waiting for TransUnion to send the report. This can take up to
          48 hours but we’ll email you as soon as it’s ready to view!
        </span>
      </div>
    </div>
  );
};

PullingReportMessage.propTypes = {
  title: PropTypes.string,
  titleDescription: PropTypes.string,
};

export default PullingReportMessage;
