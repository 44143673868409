import selection from './selection.json';

export const allowedSizes = [
  'sm',
  'md',
  'lg',
  'xl',
  'xxl',
  'xxxl',
  'huge',
  'max',
];

export const iconNames = selection.icons.map((icon) => icon.properties.name);
