import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Experiments } from '../../constants/experiments';
import getLandlordCountQueryGQL from '../../graphql/queries/general_insights/getLandlordCount.graphql';
import { useIsSmallScreen } from '../../hooks/useIsSmallScreen';
import PremiumBadge from '../../icons/PremiumBadge';
import { useConfig } from '../../providers/ConfigProvider';
import { segmentTracking } from '../../services/utilities';
import Modal from '../Modal';
import { MODAL_LOCATIONS } from '../PremiumSubscription/constants';
import SinglePaymentCard from '../PremiumSubscription/PremiumSubscriptionModalOdd/SinglePaymentCard';
import PremiumTestimonial from '../PremiumSubscription/PremiumTestimonial';

import { calculateTotalCost } from './calculateTotalCost';
import EditUnitsModal from './EditUnitsModal';
import { getModalDetails, getModalVersion } from './helpers';
import SubscriptionAddOn from './SubscriptionAddOn';

import styles from './GBBPremiumModal.module.scss';

const GBBPremiumModal = ({
  onClose,
  variantToRender,
  openLocation,
  user,
  singlePaymentAction,
  singlePaymentType,
  hideButtons,
  isMobileApp,
  segmentProperties = {},
  wantsCosignerAgreement,
  setTotalCost,
  setRentalAccountingActive,
  setRentalAccountingUnits,
  preAppliedCoupon,
  ...props
}) => {
  const isSmallScreen = useIsSmallScreen();

  // SUBSCRIPTION ADD ON LOGIC FOR EXPERIMENT
  const units = user.listings_count || user.onboarding_data.properties;
  const [isRentalAccountingChecked, setIsRentalAccountingChecked] =
    useState(false);
  const [numberOfUnits, setNumberOfUnits] = useState(units);
  const [isEditUnitsModalOpen, setIsEditUnitsModalOpen] = useState(false);
  const totalCost = calculateTotalCost(numberOfUnits);

  useEffect(() => {
    if (setTotalCost) {
      setTotalCost(totalCost);
    }
  }, [totalCost, setTotalCost]);

  useEffect(() => {
    if (setRentalAccountingActive) {
      setRentalAccountingActive(isRentalAccountingChecked);
    }
  }, [isRentalAccountingChecked, setRentalAccountingActive]);

  useEffect(() => {
    if (setRentalAccountingUnits) {
      setRentalAccountingUnits(numberOfUnits);
    }
  }, [numberOfUnits, setRentalAccountingUnits]);

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    segmentTracking('accounting_addon clicked', {
      ...segmentProperties,
    });
    setIsRentalAccountingChecked(checked);
  };
  const openEditUnitsModal = () => {
    setIsEditUnitsModalOpen(true);
    segmentTracking('update_unit_count_modal loaded', {
      ...segmentProperties,
    });
  };

  const closeEditUnitsModal = () => {
    segmentTracking('close clicked', {
      location: 'Update Unit Count Modal',
    });
    setIsEditUnitsModalOpen(false);
  };

  // --------
  const hasReiHub = user?.reihub_subscription_subscribed;
  const ModalContent = getModalVersion(variantToRender, hasReiHub);

  const config = useConfig();
  const { data: landlordCountData, loading: loadingLandlordCount } = useQuery(
    getLandlordCountQueryGQL,
  );
  const landlordCount = landlordCountData?.general_insights?.landlordCount;

  const { title, subtitle, benefits, testimonial, defaultPricing } =
    getModalDetails(openLocation, user, config, variantToRender);

  const [currentSection, setCurrentSection] = useState(benefits?.[0]?.key);

  const isPremiumPlusAndNotHavingReiHub =
    (variantToRender === Experiments.GoodBetterBest.variants.D ||
      variantToRender === Experiments.GoodBetterBest.variants.E) &&
    !hasReiHub;

  const isVariantA = variantToRender === Experiments.GoodBetterBest.variants.A;
  const isVariantG = variantToRender === Experiments.GoodBetterBest.variants.G;
  const isVariantH = variantToRender === Experiments.GoodBetterBest.variants.H;

  return (
    <Modal
      open
      onClose={onClose}
      showCloseIcon
      className={cx(styles.modal, {
        [styles.tabVariant]:
          isVariantA ||
          isVariantG ||
          ((Experiments.GoodBetterBest.variants.D ||
            Experiments.GoodBetterBest.variants.E) &&
            hasReiHub),
        [styles.tabVariantPremiumPlus]: isPremiumPlusAndNotHavingReiHub,
        [styles.tabVariantH]: isVariantH,
      })}
      disableOutsideClick
      fullScreen={isSmallScreen}
      id="GBBPremiumModal"
      closeIconClassName={styles.closeIcon}
    >
      <div className={styles.container}>
        <PremiumBadge className={styles.premiumBadge} />
        <h2 className={isVariantH ? styles.titleWithMargin : styles.title}>
          {title}
        </h2>
        <p className={isVariantH ? styles.subtitleWithMargin : styles.subtitle}>
          {subtitle}
        </p>
        <ModalContent
          {...props}
          preAppliedCoupon={preAppliedCoupon}
          className={cx(styles.content, {
            [styles.noPaymentCard]: !singlePaymentType,
          })}
          user={user}
          allBenefits={benefits}
          benefits={benefits.find(({ key }) => key === currentSection)}
          sections={benefits.map(({ key, title, titleShort }) => ({
            key,
            title: titleShort || title,
          }))}
          onSectionClick={(key) => setCurrentSection(key)}
          defaultPricing={defaultPricing}
          segmentProperties={segmentProperties}
          displayMonthlyPrice={
            openLocation === MODAL_LOCATIONS.MARKETING ||
            props.displayMonthlyPrice
          }
          subscriptionAddOnProps={{
            isChecked: isRentalAccountingChecked,
            onCheckboxChange: handleCheckboxChange,
            totalCost: totalCost,
            numberOfUnits: numberOfUnits,
            onEditClick: openEditUnitsModal,
          }}
        />

        {singlePaymentType && (
          <SinglePaymentCard
            className={cx(styles.singlePaymentCard, {
              [styles.singlePaymentInTabVariant]:
                isVariantA || isVariantG || isPremiumPlusAndNotHavingReiHub,
            })}
            openLocation={singlePaymentType}
            hideButtons={hideButtons}
            action={() => {
              segmentTracking('continue clicked', {
                ...segmentProperties,
              });
              singlePaymentAction();
            }}
            wantsCosignerAgreement={wantsCosignerAgreement}
          />
        )}

        {isVariantG && (
          <SubscriptionAddOn
            isChecked={isRentalAccountingChecked}
            onCheckboxChange={handleCheckboxChange}
            totalCost={totalCost}
            numberOfUnits={numberOfUnits}
            onEditClick={openEditUnitsModal}
          />
        )}
        <PremiumTestimonial
          showReviews
          className={styles.testimonial}
          paragraph={testimonial.paragraph}
          name={testimonial.name}
          landlordOf={testimonial.landlordOf}
          landlordCount={landlordCount}
          loading={loadingLandlordCount}
        />
      </div>

      {isVariantG && (
        <EditUnitsModal
          isOpen={isEditUnitsModalOpen}
          onClose={closeEditUnitsModal}
          initialValue={numberOfUnits}
          onSave={(newValue) => {
            setNumberOfUnits(newValue);
            closeEditUnitsModal();
            segmentTracking('save clicked', {
              location: 'Update Unit Count Modal',
            });
          }}
          min={units}
          max={999}
        />
      )}
    </Modal>
  );
};

GBBPremiumModal.propTypes = {
  onClose: PropTypes.func,
  openLocation: PropTypes.string,
  variantToRender: PropTypes.string,
  user: PropTypes.object.isRequired,
  onGetPremium: PropTypes.func.isRequired,
  onGetPro: PropTypes.func.isRequired,
  singlePaymentAction: PropTypes.func,
  singlePaymentType: PropTypes.oneOf([
    MODAL_LOCATIONS.FORMS,
    MODAL_LOCATIONS.LEASE,
    MODAL_LOCATIONS.ADDENDUM,
    MODAL_LOCATIONS.E_SIGN,
  ]),
  hideButtons: PropTypes.bool,
  isMobileApp: PropTypes.bool,
  segmentProperties: PropTypes.object,
  displayMonthlyPrice: PropTypes.bool,
  wantsCosignerAgreement: PropTypes.bool,
  setTotalCost: PropTypes.func,
  setRentalAccountingActive: PropTypes.func,
  setRentalAccountingUnits: PropTypes.func,
  preAppliedCoupon: PropTypes.object,
};

export default GBBPremiumModal;
