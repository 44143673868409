import { useCallback, useEffect, useState } from 'react';
import qs from 'query-string';

import * as AuthService from '../../../core/auth/authService';
import { getConfig } from '../../../services/configService';

export const useTokenStatus = (applicationId) => {
  const { API_URL } = getConfig();
  const queryParams = qs.parse(location.search);
  const token = queryParams.token || queryParams.confirmationToken;
  const isConfirmationToken = !!queryParams.confirmationToken;

  const [response, setResponse] = useState({
    loading: true,
  });

  const verifyMagicLink = useCallback(
    async (token, applicationId, isConfirmationToken) => {
      setResponse({ loading: true });

      try {
        const res = await fetch(`${API_URL}/public/verify_magic_link`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token,
            applicationId,
            isConfirmationToken,
          }),
        });

        const apiResponse = await res.json();

        const shouldAuthenticate =
          (apiResponse.token || apiResponse.rr_token) && !apiResponse.isInvalid;

        if (shouldAuthenticate) {
          const { rr_token, token } = apiResponse;

          AuthService.silentLogin(token || rr_token);
        }

        setResponse({ loading: false, ...apiResponse });
      } catch {
        setResponse({ loading: false });
      }
    },
    [],
  );

  useEffect(() => {
    if (!applicationId || !token) {
      if (!token) setResponse({ ...response, loading: false });
      return;
    }

    verifyMagicLink(token, applicationId, isConfirmationToken);
  }, [applicationId, token]);

  return response;
};

export default useTokenStatus;
