import get from 'lodash.get';
import queryString from 'query-string';

const stringify = (params) => {
  return Object.keys(params)
    .filter((key) => params[key] !== null)
    .map((key) => (params[key] ? `${key}=${params[key]}` : key))
    .join('&');
};

const queryState = (navigate, location, key) => {
  const search = get(location, 'search', '');
  const pathname = get(location, 'pathname', '');
  const params = queryString.parse(search);
  const state = key in params;

  const change = (newState) => {
    const newSearch = stringify({
      ...params,
      [key]: newState ? '' : null,
    });

    navigate(
      {
        pathname,
        search: newSearch ? `?${newSearch}` : '',
      },
      { replace: !newState }, // mimic push for true, replace for false
    );
  };

  return [state, change];
};

export default queryState;
