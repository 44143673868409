import React from 'react';
import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import PropTypes from 'prop-types';

import SubscriptionTitleToProductId from '../../../../../constants/enums/SubscriptionTitleToProductId';
import getDiscountedPrice from '../../../../../helpers/getDiscountedPrice';
import Button from '../../../../Button';
import Tag from '../../../../Tag';
import { H3, Paragraph } from '../../../../Typography';

import styles from './PackageHeader.module.scss';

const PackageHeader = ({
  title,
  price,
  displayMonthlyPrice,
  onButtonClick,
  isPro = false,
  preAppliedCoupon,
}) => {
  const productId = SubscriptionTitleToProductId[title];

  const discountedPrice = getDiscountedPrice(
    price,
    preAppliedCoupon,
    productId,
  );
  const displayPrice = displayMonthlyPrice ? (price / 12).toFixed(2) : price;
  const displayDiscountedPrice = displayMonthlyPrice
    ? (discountedPrice / 12).toFixed(2)
    : discountedPrice;

  const hasDiscount = discountedPrice !== price;

  return (
    <div
      className={cx(styles.packHeader, {
        [styles.proHeader]: isPro,
        [styles.premiumHeader]: !isPro,
      })}
    >
      <div className={styles.packTitleWrapper}>
        <H3 className={styles.packTitle}>{title}</H3>
        {!isPro && (
          <Tag accent uppercase={false}>
            Best Value
          </Tag>
        )}
      </div>
      <Paragraph className={styles.price}>
        <AnimatePresence>
          {hasDiscount && (
            <motion.span
              className={styles.originalPrice}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              ${displayPrice}
            </motion.span>
          )}
        </AnimatePresence>
        <motion.span
          key={displayDiscountedPrice}
          initial={hasDiscount ? { scale: 0.8, opacity: 0 } : false}
          animate={hasDiscount ? { scale: 1, opacity: 1 } : false}
          transition={{ duration: 0.3 }}
        >
          ${displayDiscountedPrice}
        </motion.span>
        <span className={styles.per}>
          {displayMonthlyPrice ? 'Per month' : 'Per year'}
        </span>
        {displayMonthlyPrice && (
          <span className={styles.annually}>Billed annually</span>
        )}
      </Paragraph>
      <Button
        className={styles.btn}
        onClick={onButtonClick}
        {...(isPro ? { secondary: true } : {})}
      >
        Get {title}
      </Button>
    </div>
  );
};

PackageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  displayMonthlyPrice: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  isPro: PropTypes.bool,
  preAppliedCoupon: PropTypes.object,
};

export default PackageHeader;
