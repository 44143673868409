import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const SearchDocument = ({ color, ...rest }) => (
  <svg {...rest} width="152" height="152" viewBox="0 0 152 152" fill="none">
    <g id="Illustration / Document Search">
      <path
        id="Blob 1"
        opacity="0.06"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M79.9241 6.02399C106.403 6.44702 135.85 14.5508 144.069 44.3112C152.312 74.1599 132.793 101.563 111.487 120.31C89.1231 139.988 60.7137 156.212 37.3671 138.2C10.9357 117.807 -1.01604 77.0452 10.212 42.1726C20.1918 11.177 51.8735 5.57585 79.9241 6.02399Z"
        fill="#2370A3"
      />
      <g id="Shape">
        <path
          d="M82.0846 18.1905L22.0873 35.8031C20.9294 36.143 20.2663 37.3572 20.6062 38.5151L43.9506 118.037C44.2905 119.195 45.5047 119.858 46.6626 119.519L106.66 101.906C107.818 101.566 108.481 100.352 108.141 99.1939L84.7966 19.6716C84.4566 18.5137 83.2425 17.8506 82.0846 18.1905Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.3296 36.6283C21.6274 36.8344 21.2253 37.5707 21.4314 38.2729L44.7758 117.795C44.9819 118.497 45.7182 118.899 46.4204 118.693L106.418 101.081C107.12 100.875 107.522 100.138 107.316 99.4361L83.9714 19.9138C83.7653 19.2116 83.029 18.8095 82.3268 19.0157L22.3296 36.6283ZM19.7811 38.7573C19.3074 37.1437 20.2315 35.4516 21.8451 34.9779L81.8423 17.3653C83.4559 16.8916 85.148 17.8157 85.6217 19.4293L108.966 98.9517C109.44 100.565 108.516 102.257 106.902 102.731L46.9049 120.344C45.2912 120.817 43.5991 119.893 43.1255 118.28L19.7811 38.7573Z"
          fill="#033A6D"
        />
        <path
          d="M97.413 23.1227H34.884C33.6773 23.1227 32.699 24.101 32.699 25.3077V108.186C32.699 109.392 33.6773 110.371 34.884 110.371H97.413C98.6197 110.371 99.598 109.392 99.598 108.186V25.3077C99.598 24.101 98.6197 23.1227 97.413 23.1227Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.884 23.9827C34.1522 23.9827 33.559 24.5759 33.559 25.3077V108.186C33.559 108.917 34.1522 109.511 34.884 109.511H97.413C98.1448 109.511 98.738 108.917 98.738 108.186V25.3077C98.738 24.5759 98.1448 23.9827 97.413 23.9827H34.884ZM31.839 25.3077C31.839 23.626 33.2023 22.2627 34.884 22.2627H97.413C99.0947 22.2627 100.458 23.626 100.458 25.3077V108.186C100.458 109.867 99.0947 111.231 97.413 111.231H34.884C33.2023 111.231 31.839 109.867 31.839 108.186V25.3077Z"
          fill="#033A6D"
        />
        <path
          d="M89.6729 31.8438H42.605C41.3982 31.8438 41.42 31.8221 41.42 33.0288V39.3798C41.42 40.5865 41.3982 40.5648 42.605 40.5648H89.6729C90.8797 40.5648 90.8579 40.5865 90.8579 39.3798V33.0288C90.8579 31.8221 90.8797 31.8438 89.6729 31.8438Z"
          fill="#7FE3FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.2799 32.9647C42.28 32.9854 42.28 33.0068 42.28 33.0288V39.3798C42.28 39.4018 42.28 39.4232 42.2799 39.444C42.2799 39.5429 42.2799 39.6279 42.2806 39.7042C42.3569 39.7049 42.4419 39.7049 42.5408 39.7048C42.5616 39.7048 42.5829 39.7048 42.605 39.7048L89.7371 39.7048C89.836 39.7049 89.9211 39.7049 89.9974 39.7042C89.998 39.628 89.998 39.543 89.998 39.4442V32.9644C89.998 32.8656 89.998 32.7806 89.9974 32.7044C89.9211 32.7037 89.836 32.7038 89.7371 32.7038L42.605 32.7038C42.5829 32.7038 42.5616 32.7038 42.5408 32.7038C42.4419 32.7038 42.3569 32.7037 42.2806 32.7044C42.2799 32.7807 42.2799 32.8658 42.2799 32.9647ZM42.5282 30.9838C42.5538 30.9838 42.5794 30.9838 42.605 30.9838H89.673C89.6985 30.9838 89.7241 30.9838 89.7497 30.9838C90.0012 30.9836 90.2515 30.9833 90.453 31.0036C90.6651 31.0248 91.0333 31.0829 91.3261 31.3757C91.6189 31.6684 91.6769 32.0367 91.6982 32.2487C91.7184 32.4503 91.7182 32.7006 91.718 32.9521C91.718 32.9777 91.718 33.0033 91.718 33.0288V39.3798C91.718 39.4054 91.718 39.431 91.718 39.4566C91.7182 39.7081 91.7184 39.9584 91.6982 40.1599C91.6769 40.372 91.6189 40.7402 91.3261 41.033C91.0333 41.3257 90.6651 41.3838 90.453 41.4051C90.2515 41.4253 90.0012 41.4251 89.7497 41.4249C89.7241 41.4248 89.6985 41.4248 89.673 41.4248H42.605C42.5794 41.4248 42.5538 41.4248 42.5282 41.4249C42.2767 41.4251 42.0264 41.4253 41.8249 41.4051C41.6128 41.3838 41.2446 41.3257 40.9518 41.0329C40.659 40.7402 40.601 40.372 40.5797 40.1599C40.5595 39.9583 40.5597 39.708 40.5599 39.4565C40.5599 39.431 40.56 39.4054 40.56 39.3798V33.0288C40.56 33.0033 40.5599 32.9777 40.5599 32.9521C40.5597 32.7006 40.5595 32.4503 40.5797 32.2487C40.601 32.0367 40.659 31.6684 40.9518 31.3757C41.2446 31.0829 41.6128 31.0248 41.8249 31.0036C42.0264 30.9833 42.2767 30.9836 42.5282 30.9838Z"
          fill="#033A6D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.14 47.9998C41.14 47.5248 41.525 47.1398 42 47.1398H60C60.475 47.1398 60.86 47.5248 60.86 47.9998C60.86 48.4747 60.475 48.8598 60 48.8598H42C41.525 48.8598 41.14 48.4747 41.14 47.9998Z"
          fill="#033A6D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.14 54.9998C41.14 54.5248 41.525 54.1398 42 54.1398H91C91.475 54.1398 91.86 54.5248 91.86 54.9998C91.86 55.4747 91.475 55.8598 91 55.8598H42C41.525 55.8598 41.14 55.4747 41.14 54.9998Z"
          fill="#033A6D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.14 61.9998C41.14 61.5248 41.525 61.1398 42 61.1398H91C91.475 61.1398 91.86 61.5248 91.86 61.9998C91.86 62.4747 91.475 62.8598 91 62.8598H42C41.525 62.8598 41.14 62.4747 41.14 61.9998Z"
          fill="#033A6D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.14 68.9998C41.14 68.5248 41.525 68.1398 42 68.1398H91C91.475 68.1398 91.86 68.5248 91.86 68.9998C91.86 69.4747 91.475 69.8598 91 69.8598H42C41.525 69.8598 41.14 69.4747 41.14 68.9998Z"
          fill="#033A6D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.14 75.9998C41.14 75.5248 41.525 75.1398 42 75.1398H88C88.475 75.1398 88.86 75.5248 88.86 75.9998C88.86 76.4747 88.475 76.8598 88 76.8598H42C41.525 76.8598 41.14 76.4747 41.14 75.9998Z"
          fill="#033A6D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.14 82.9998C41.14 82.5248 41.525 82.1398 42 82.1398H79C79.475 82.1398 79.86 82.5248 79.86 82.9998C79.86 83.4747 79.475 83.8598 79 83.8598H42C41.525 83.8598 41.14 83.4747 41.14 82.9998Z"
          fill="#033A6D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.14 89.9998C41.14 89.5248 41.525 89.1398 42 89.1398H79C79.475 89.1398 79.86 89.5248 79.86 89.9998C79.86 90.4747 79.475 90.8598 79 90.8598H42C41.525 90.8598 41.14 90.4747 41.14 89.9998Z"
          fill="#033A6D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.14 96.9998C41.14 96.5248 41.525 96.1398 42 96.1398H79C79.475 96.1398 79.86 96.5248 79.86 96.9998C79.86 97.4747 79.475 97.8598 79 97.8598H42C41.525 97.8598 41.14 97.4747 41.14 96.9998Z"
          fill="#033A6D"
        />
        <path
          d="M129.865 123.576C130.677 124.617 132.081 125.681 132 127C131.919 128.318 130.358 129.8 129.424 130.735C128.49 131.669 126.318 133.419 125 133.5C123.682 133.581 123.307 131.988 122.265 131.176L107.882 116.793L115.482 109.193L129.865 123.576Z"
          fill="#7FE3FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.732 108.808C115.068 108.472 115.612 108.472 115.948 108.808L132.452 125.312C132.477 125.337 132.501 125.363 132.523 125.391C132.644 125.547 132.702 125.724 132.731 125.832C132.768 125.967 132.793 126.123 132.801 126.295C132.818 126.641 132.772 127.072 132.613 127.58C132.295 128.596 131.527 129.928 129.89 131.566C128.253 133.203 126.921 133.971 125.905 134.288C125.396 134.447 124.965 134.494 124.619 134.477C124.447 134.468 124.292 134.444 124.156 134.407C124.048 134.378 123.872 134.32 123.715 134.198C123.688 134.177 123.661 134.153 123.636 134.128L107.132 117.624C106.796 117.288 106.796 116.743 107.132 116.408L114.732 108.808ZM115.34 110.632L108.956 117.016L124.699 132.759L124.704 132.759C124.827 132.765 125.055 132.752 125.391 132.647C126.066 132.436 127.167 131.856 128.674 130.349C130.18 128.843 130.76 127.741 130.971 127.067C131.076 126.731 131.089 126.503 131.083 126.379L131.083 126.375L115.34 110.632ZM131.071 126.281L131.072 126.284C131.068 126.273 131.068 126.271 131.071 126.281ZM124.606 132.747C124.596 132.744 124.598 132.744 124.609 132.748L124.606 132.747Z"
          fill="#033A6D"
        />
        <path
          d="M103.994 101.529L100.166 105.358L109.76 114.949L113.588 111.12L103.994 101.529Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99.5579 105.967C99.2219 105.631 99.2218 105.086 99.5576 104.75L103.386 100.921C103.722 100.585 104.266 100.585 104.602 100.921L114.196 110.512C114.532 110.847 114.532 111.392 114.197 111.728L110.368 115.557C110.033 115.893 109.488 115.893 109.152 115.558L99.5579 105.967ZM101.382 105.358L109.76 113.733L112.372 111.12L103.994 102.745L101.382 105.358Z"
          fill="#033A6D"
        />
        <path
          d="M104.956 71.8768C108.371 75.291 110.697 79.6413 111.64 84.3775C112.582 89.1138 112.099 94.0232 110.252 98.4849C108.404 102.947 105.275 106.76 101.259 109.443C97.2443 112.126 92.5236 113.558 87.6945 113.558C82.8654 113.558 78.1447 112.126 74.1295 109.443C70.1143 106.76 66.985 102.947 65.1373 98.4849C63.2896 94.0232 62.8065 89.1138 63.7492 84.3775C64.6918 79.6413 67.0178 75.291 70.433 71.8768C75.014 67.3042 81.222 64.7361 87.6945 64.7361C94.167 64.7361 100.375 67.3042 104.956 71.8768Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.6945 65.5961C81.4498 65.5961 75.4603 68.0739 71.0406 72.4854C67.7459 75.7793 65.502 79.9762 64.5926 84.5454C63.6832 89.1148 64.1492 93.8513 65.9319 98.1558C67.7145 102.46 70.7336 106.14 74.6073 108.728C78.4811 111.317 83.0355 112.698 87.6945 112.698C92.3535 112.698 96.9079 111.317 100.782 108.728C104.655 106.14 107.675 102.46 109.457 98.1558C111.24 93.8513 111.706 89.1148 110.796 84.5454C109.887 79.9761 107.643 75.7791 104.348 72.4852C99.9285 68.0738 93.9391 65.5961 87.6945 65.5961ZM69.8255 71.2681C74.5676 66.5346 80.9942 63.8761 87.6945 63.8761C94.3948 63.8761 100.821 66.5346 105.564 71.2681C109.099 74.8026 111.507 79.3066 112.483 84.2097C113.459 89.1127 112.959 94.1951 111.046 98.8139C109.133 103.433 105.894 107.381 101.737 110.158C97.5807 112.936 92.6937 114.418 87.6945 114.418C82.6953 114.418 77.8083 112.936 73.6517 110.158C69.4951 107.381 66.2555 103.433 64.3427 98.8139C62.43 94.1951 61.9299 89.1127 62.9057 84.2097C63.8815 79.3066 66.2899 74.8026 69.8255 71.2681Z"
          fill="#033A6D"
        />
        <path
          d="M100.149 76.6077C102.612 79.071 104.29 82.2093 104.969 85.6259C105.649 89.0425 105.3 92.5838 103.967 95.8021C102.634 99.0205 100.376 101.771 97.4797 103.707C94.5833 105.642 91.178 106.675 87.6945 106.675C84.211 106.675 80.8058 105.642 77.9093 103.707C75.0129 101.771 72.7554 99.0205 71.4223 95.8021C70.0892 92.5838 69.7404 89.0425 70.4199 85.6259C71.0995 82.2093 72.7769 79.071 75.24 76.6077C76.873 74.9676 78.8138 73.6662 80.9511 72.7782C83.0884 71.8901 85.3801 71.433 87.6945 71.433C90.009 71.433 92.3006 71.8901 94.4379 72.7782C96.5752 73.6662 98.516 74.9676 100.149 76.6077Z"
          fill="#7FE3FF"
        />
        <path
          d="M100.149 76.6077C98.5135 74.9722 96.5718 73.6748 94.4348 72.7897C92.2979 71.9045 90.0075 71.4489 87.6945 71.4489C83.0232 71.4489 78.5432 73.3046 75.24 76.6077C73.6045 78.2433 72.3071 80.185 71.4219 82.3219C70.5368 84.4589 70.0812 86.7492 70.0812 89.0623C70.0812 93.7336 71.9369 98.2136 75.24 101.517C75.24 101.612 75.411 101.669 75.487 101.764L100.377 76.8548L100.149 76.6077Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.6945 72.293C85.4933 72.293 83.3138 72.7278 81.2811 73.5723C79.2484 74.4169 77.4025 75.6547 75.8495 77.2145L75.8481 77.2158C73.5053 79.5588 71.9098 82.5439 71.2634 85.7937C70.617 89.0434 70.9488 92.4119 72.2168 95.473C73.4848 98.5342 75.6321 101.151 78.3871 102.991C81.1421 104.832 84.3811 105.815 87.6945 105.815C91.0079 105.815 94.2469 104.832 97.0019 102.991C99.7569 101.151 101.904 98.5342 103.172 95.473C104.44 92.4119 104.772 89.0434 104.126 85.7937C103.479 82.5439 101.884 79.5588 99.5409 77.2158L99.5396 77.2145C97.9865 75.6547 96.1406 74.4169 94.1079 73.5723C92.0752 72.7278 89.8957 72.293 87.6945 72.293ZM80.6212 71.984C82.863 71.0525 85.2668 70.573 87.6945 70.573C90.1222 70.573 92.526 71.0525 94.7679 71.984C97.0095 72.9153 99.0451 74.2802 100.758 76.0003C103.341 78.5837 105.1 81.875 105.813 85.4581C106.525 89.0415 106.159 92.7558 104.761 96.1313C103.363 99.5067 100.995 102.392 97.9575 104.422C94.9196 106.451 91.3481 107.535 87.6945 107.535C84.0409 107.535 80.4694 106.451 77.4315 104.422C74.3937 102.392 72.026 99.5067 70.6278 96.1313C69.2296 92.7558 68.8637 89.0415 69.5764 85.4581C70.2891 81.875 72.0482 78.5836 74.6314 76.0002C76.3441 74.2802 78.3796 72.9153 80.6212 71.984Z"
          fill="#033A6D"
        />
      </g>
      <path
        id="Shape_2"
        d="M75.449 142.101C88.7232 142.101 99.484 140.638 99.484 138.833C99.484 137.029 88.7232 135.565 75.449 135.565C62.1748 135.565 51.414 137.029 51.414 138.833C51.414 140.638 62.1748 142.101 75.449 142.101Z"
        fill="#7FE3FF"
      />
    </g>
  </svg>
);

// eslint-disable-next-line local/no-default-props
SearchDocument.defaultProps = {
  color: colors.text,
  width: 24,
  height: 25,
};

SearchDocument.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(SearchDocument);
