import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import cx from 'classnames';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import PremiumBannerIcon from '../../../assets/illustrations/PremiumBanner';
import RadialCircleIcon from '../../../assets/illustrations/RadialCircle';
import Button from '../../../components/Button';
import Card from '../../../components/Card';
import IconTooltip from '../../../components/IconTooltip';
import LeaseDetailsCreateForm from '../../../components/LeaseDetailsCreateForm';
import LoadingScreen from '../../../components/LoadingScreen';
import {
  MODAL_LOCATIONS,
  SUBSCRIPTION_FEATURES,
} from '../../../components/PremiumSubscription/constants';
import UpgradeToPremium from '../../../components/PremiumSubscription/UpgradeToPremium';
import Select from '../../../components/SelectField';
import SuccessModal from '../../../components/SuccessModal';
import { useErrorToast } from '../../../components/Toast';
import colors from '../../../constants/colors';
import { useUserProfile } from '../../../core/TTgraphql';
import basicUserProfile from '../../../graphql/basicUserProfile.graphql';
import updateOwnerSubscriptionPlanGQL from '../../../graphql/mutations/updateOwnerSubscriptionPlan.graphql';
import getActiveSignatureRequestsQueryGQL from '../../../graphql/queries/getActiveSignatureRequests.graphql';
import landlordFormsQuery from '../../../graphql/queries/landlordForms.graphql';
import ownerSettingsQuery from '../../../graphql/queries/ownerSettingsQuery.graphql';
import { getGBBPremiumPlanId } from '../../../helpers/getPremiumPlanId';
import { useRenderOutside } from '../../../helpers/render-outside';
import usePremiumSubscriptionModal from '../../../hooks/usePremiumSubscriptionModal';
import { useConfig } from '../../../providers/ConfigProvider';
import { segmentTracking } from '../../../services/utilities';

import DraftEsigns from './DraftEsigns';
import waitingOnSignaturesDesktopImg from './waiting-on-signatures-desktop.png';
import waitingOnSignaturesMobileImg from './waiting-on-signatures-mobile.png';

import styles from './HowESignWorks.module.scss';

const NEW_LEASE_ID = 'add-new-lease';

const HowESignWorks = ({
  history,
  location,
  leaseId,
  signatureRequest,
  signatureRequestId,
  leases,
}) => {
  const showLeasePicker = !!leases;
  const [showUpgradeToPremium, setShowUpgradeToPremium] = useState(false);
  const renderOutside = useRenderOutside();
  const { user, loading: loadingUser } = useUserProfile();
  const newPlanId = getGBBPremiumPlanId(user);
  const { PRIVATE_BASE_PATH } = useConfig();
  const { data, loading: loadingSignatures } = useQuery(
    getActiveSignatureRequestsQueryGQL,
    {
      variables: { leaseId },
    },
  );
  const { data: ownerSettingsData } = useQuery(ownerSettingsQuery);
  const [updateOwnerSubscriptionPlanMutation] = useMutation(
    updateOwnerSubscriptionPlanGQL,
  );
  const showErrorMessage = useErrorToast();
  const searchParams = queryString.parse(history.location.search);
  const documentToSignParam = searchParams?.documentToSign
    ? `?documentToSign=${searchParams.documentToSign}`
    : '';

  const hasSubscription = user?.packageFeatures?.length > 0;
  const premiumPriceSignup = user?.premium_price_signup;
  const { premiumPlusSubscriptionInfo } =
    ownerSettingsData?.userData?.settingsAndBilling?.data || {};
  const activeSubscriptionEnd =
    premiumPlusSubscriptionInfo?.current?.current_period_end;

  const handleLeasePicker = (pickedLeaseId) => {
    return history.replace(
      `${PRIVATE_BASE_PATH}e-sign-lease/${pickedLeaseId}/new/pay`,
    );
  };

  const renderPremiumModal = usePremiumSubscriptionModal({
    openLocation: MODAL_LOCATIONS.E_SIGN_DASHBOARD,
    segmentLocation: 'E-sign How It Works',
    segmentProperties: '[How E-sign Works]',
    refetchQueries: [basicUserProfile, landlordFormsQuery, ownerSettingsQuery],
    copy: 'forms',
    afterOnSuccess: ({ pickedLeaseId }) => {
      if (showLeasePicker && pickedLeaseId) {
        return handleLeasePicker(pickedLeaseId);
      }
      return history.replace(
        `${PRIVATE_BASE_PATH}e-sign-lease/${leaseId}/new/pay${documentToSignParam}`,
      );
    },
  });

  const activeSignatureRequests =
    data?.userData?.active_signature_requests || [];

  const [selectedLease, setSelectedLease] = useState('');

  const leasesLength = leases?.length || 0;
  useEffect(() => {
    if (leasesLength === 0) {
      setSelectedLease(NEW_LEASE_ID);
    }

    // Preselect value if only one property/lease is present
    if (leasesLength === 1) {
      setSelectedLease(leases[0].id);
    }
  }, [leasesLength]);

  const isEsignEnabled = user?.packageFeatures?.includes(
    SUBSCRIPTION_FEATURES.UNLIMITED_ESIGNATURES,
  );

  const handleRequestSignatures = useCallback(
    (newLaseFromForm) => {
      const pickedLeaseId = newLaseFromForm ? newLaseFromForm : selectedLease;

      if (!isEsignEnabled) {
        segmentTracking('get_started clicked', {
          location: 'E-sign How It Works',
          properties: '[How E-sign Works]',
        });

        return hasSubscription
          ? setShowUpgradeToPremium(true)
          : renderPremiumModal({
              afterOnSuccessProps: {
                pickedLeaseId,
              },
            });
      }

      segmentTracking('request_signatures clicked', {
        location: 'Leases',
        properties: '[How E-sign Works]',
      });
      // have signature request that is in the progress
      // and it's already paid for
      if (signatureRequest && signatureRequest.created_at) {
        history.replace(
          `${PRIVATE_BASE_PATH}e-sign-lease/${leaseId}/${signatureRequestId}/add-documents${documentToSignParam}`,
        );
        return;
      }
      if (showLeasePicker) {
        return history.replace(
          `${PRIVATE_BASE_PATH}e-sign-lease/${pickedLeaseId}/new/pay?redirectTo=${encodeURIComponent(
            `${location.pathname}?selectedLease=${pickedLeaseId}`,
          )}`,
        );
      }
      history.replace(
        `${PRIVATE_BASE_PATH}e-sign-lease/${leaseId}/${signatureRequestId}/pay${documentToSignParam}`,
      );
    },
    [leaseId, signatureRequest, showLeasePicker, selectedLease, isEsignEnabled],
  );

  const upgradeToPremium = async () => {
    try {
      await updateOwnerSubscriptionPlanMutation({
        variables: {
          newPlanId,
          referral: 'Upgrade Modal',
          action: 'Upgrade',
        },
      });

      setShowUpgradeToPremium(false);

      renderOutside((done) => (
        <SuccessModal
          open
          showCheckMark
          showAnimation={false}
          title="Upgrade complete!"
          primaryLabel="Sounds Good"
          primaryAction={() => {
            segmentTracking('sounds_good clicked', {
              location: 'Plan Upgraded Success Modal',
            });
            done();
          }}
          className={styles.upgradeModal}
          classNameTitle={styles.title}
          classNameActions={styles.actions}
        >
          <p className={styles.text}>
            Enjoy access to all the features that come with your new plan.
          </p>
        </SuccessModal>
      ));
    } catch {
      showErrorMessage('Failed to upgrade to premium');
    }
  };

  const onLeaseFormSubmit = ({ leaseId }) => {
    handleRequestSignatures(leaseId);
  };

  const leaseOptions =
    leases
      ?.map(({ title, id }) => ({
        label: title,
        value: id,
      }))
      .sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1;
        }
        return 1;
      }) || [];

  if (loadingUser || loadingSignatures) {
    return <LoadingScreen loading={loadingUser || loadingSignatures} />;
  }

  return (
    <Card className={styles.container}>
      <div className={styles.header}>
        <PremiumBannerIcon
          className={styles.banner}
          starColor={colors.orangeLight}
          color={colors.secondary}
          height={40}
          width={28}
        />
        <RadialCircleIcon className={styles.circles} height={120} width={120} />
        <img
          className={styles.image}
          src={waitingOnSignaturesDesktopImg}
          alt="Waiting on signatures preview"
        />
        <img
          className={cx(styles.image, styles.imageDesktop)}
          src={waitingOnSignaturesMobileImg}
          alt="Waiting on signatures preview"
        />
      </div>

      <div className={styles.content}>
        <h1 className={styles.title}>
          {isEsignEnabled
            ? 'Save time and hassle with e-sign - included with your Premium membership!'
            : 'Upgrade to Premium now to save time and hassle with e-sign!'}
        </h1>
        <p className={styles.text}>
          Get documents signed faster when you don't have to meet your tenants
          in person or deal with a printer.
        </p>
        <DraftEsigns
          className={styles.draftContainer}
          activeSignatureRequests={activeSignatureRequests}
        />
        <p className={cx(styles.text, styles.listTitle)}>
          It takes less than 3 minutes to set up:
        </p>
        <div className={styles.setUpItem}>
          <span className={styles.setUpItemNumber}>1</span>
          <p className={styles.setUpItemText}>
            Upload the document(s) and select where everyone signs and dates.
          </p>
        </div>
        <div className={styles.setUpItem}>
          <span className={styles.setUpItemNumber}>2</span>
          <p className={styles.setUpItemText}>
            We send it off to your tenants.
          </p>
        </div>
        <div className={styles.setUpItem}>
          <span className={styles.setUpItemNumber}>3</span>
          <p className={styles.setUpItemText}>
            You sign once they're done, then everyone gets emailed a copy! We'll
            save it to your account too.
          </p>
        </div>
        {showLeasePicker && (
          <>
            <Select
              label={
                <div className={styles.tooltipWrapper}>
                  Which lease needs a document signed?
                  <IconTooltip
                    tip={
                      <span>
                        This keeps your signed document organized with the right
                        tenants and property.
                      </span>
                    }
                  />
                </div>
              }
              value={selectedLease}
              className={styles.select}
              onChange={(e) => {
                setSelectedLease(e.target.value);
              }}
              options={[
                {
                  label: '',
                  value: '',
                },
                ...leaseOptions,
                {
                  label: '+ Add New Lease',
                  value: NEW_LEASE_ID,
                },
              ]}
            />
            {selectedLease === NEW_LEASE_ID ? (
              <LeaseDetailsCreateForm
                onCancel={() => setSelectedLease('')}
                onSubmit={onLeaseFormSubmit}
                hideCancel
              />
            ) : (
              <Button
                className={styles.btn}
                onClick={() => handleRequestSignatures()}
                id="continue"
                disabled={!selectedLease}
              >
                Get It Signed Fast
              </Button>
            )}
          </>
        )}
        {!showLeasePicker && (
          <Button
            className={styles.btn}
            onClick={() => handleRequestSignatures()}
            id="get_started"
            disabled={!selectedLease}
          >
            Get It Signed Fast
          </Button>
        )}
      </div>
      {showUpgradeToPremium && (
        <UpgradeToPremium
          open
          onClose={() => setShowUpgradeToPremium(false)}
          onUpgrade={upgradeToPremium}
          premiumPrice={premiumPriceSignup}
          subscriptionEnd={activeSubscriptionEnd}
        />
      )}
    </Card>
  );
};

HowESignWorks.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  leaseId: PropTypes.string,
  signatureRequest: PropTypes.object,
  signatureRequestId: PropTypes.string,
  leases: PropTypes.array,
};

export default withRouter(HowESignWorks);
