const storePromoCodesFromUrl = (searchString) => {
  const searchParams = new URLSearchParams(searchString);
  const promoParams = Array.from(searchParams.entries()).filter(([key]) =>
    key.startsWith('promo_'),
  );

  if (promoParams.length > 0) {
    const oneDay = 24 * 60 * 60 * 1000;
    promoParams.forEach(([key, promoCode]) => {
      document.cookie = `${key}=${promoCode};path=/;max-age=${oneDay / 1000}`;
    });
  }
};

export default storePromoCodesFromUrl;
