import moment from 'moment';

import { SQL_DATE_FORMAT } from '../../../constants/charges_date_formats';
import LEASE_END_ACTIONS from '../../../constants/lease_agreement/lease-end-actions';

const determineStartDate = (date) => {
  const dayOfTheMonth = moment(date).date();
  if (dayOfTheMonth === 1) {
    return moment(date);
  }
  return moment(date).add(1, 'month');
};

const determineEndDate = (date) => {
  const dayOfTheMonth = moment(date).date();
  if (dayOfTheMonth === 1) {
    return moment(date).subtract(1, 'month');
  }
  return moment(date);
};

const isInPast = (date) => {
  if (!date) return false;

  const now = moment();
  return (moment.isMoment(date) ? date : moment(date)).isBefore(now);
};

const prepareMonthlyChargeDates = (charge, lease) => {
  let startDate;
  let endDate = null;
  let noEndDate =
    typeof charge?.no_end_date === 'boolean' ? charge.no_end_date : true;
  const now = moment();
  // if there is a charge object, it means it's the edit form
  // and we should use those values
  if (charge && (charge.start_date || charge.end_date)) {
    startDate = moment(charge.start_date);
    endDate = charge.end_date
      ? moment(charge.end_date)
      : moment().add(11, 'months');
  }
  // or when there's a lease object, try to determine based on lease start/end dates
  else if (lease) {
    if (lease.start_date) {
      const startedInPast = isInPast(lease.start_date);

      if (startedInPast) {
        startDate = determineStartDate(now);
      } else {
        startDate = determineStartDate(lease.start_date);
      }
    } else {
      startDate = determineStartDate(now);
    }

    if (
      !lease.end_date ||
      lease.end_action === LEASE_END_ACTIONS.CONTINUE_MONTHLY
    ) {
      endDate = moment(startDate).add(11, 'months');
      // noEndDate = true;
    } else if (
      lease.end_date &&
      moment(lease.end_date).isAfter(now) &&
      lease.end_action === LEASE_END_ACTIONS.TERMINATE
    ) {
      endDate = determineEndDate(lease.end_date);
    } else if (
      lease.end_date &&
      isInPast(lease.end_date) &&
      lease.end_action === LEASE_END_ACTIONS.TERMINATE
    ) {
      endDate = moment(startDate).add(11, 'months');
    }
  } else {
    startDate = moment().add(1, 'month');
    endDate = moment().subtract(1, 'month').add(1, 'year');
  }

  return {
    startDate: startDate?.format(SQL_DATE_FORMAT),
    endDate: endDate?.format(SQL_DATE_FORMAT),
    noEndDate: noEndDate,
  };
};

export default prepareMonthlyChargeDates;
