import React, { memo } from 'react';
import PropTypes from 'prop-types';

const DocumentPreview = ({ color, ...props }) => (
  <svg viewBox="0 0 24 24" {...props} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.302 12.213c2.064 0 3.737 1.736 3.737 3.878 0 .84-.257 1.617-.695 2.252l2.476 2.567c.24.25.24.654 0 .903a.599.599 0 0 1-.802.062l-.069-.062-2.475-2.568a3.632 3.632 0 0 1-2.172.722c-2.064 0-3.738-1.735-3.738-3.876 0-2.143 1.673-3.878 3.738-3.878ZM14.94 2a.6.6 0 0 1 .359.12l.073.064 3.497 3.584.009.01a.613.613 0 0 1 .032.037l.023.03.006.01a.7.7 0 0 1 .045.076l.004.01a.652.652 0 0 1 .056.18l.002.015.006.086v5.974a.627.627 0 0 1-.616.638.623.623 0 0 1-.61-.552l-.005-.086-.002-5.336h-3.462a.624.624 0 0 1-.61-.551l-.006-.087V3.276h-7.51V19.92h5.212c.312 0 .569.24.61.552l.005.086c0 .324-.231.59-.531.633l-.084.006H5.615a.624.624 0 0 1-.61-.552L5 20.558V2.638c0-.323.232-.59.532-.632L5.615 2h9.325Zm.362 11.49c-1.385 0-2.507 1.163-2.507 2.601 0 1.436 1.122 2.6 2.507 2.6 1.384 0 2.506-1.164 2.506-2.6 0-1.438-1.122-2.602-2.506-2.602Zm-4.894 2.846c.34 0 .615.286.615.638 0 .324-.231.59-.531.633l-.084.006H7.946a.627.627 0 0 1-.615-.639c0-.323.232-.59.532-.632l.083-.006h2.462Zm0-2.39c.34 0 .615.287.615.64 0 .322-.231.59-.531.632l-.084.005H7.946a.627.627 0 0 1-.615-.638c0-.323.232-.59.532-.632l.083-.006h2.462Zm3.92-4.009c.34 0 .616.286.616.639 0 .323-.232.59-.532.632l-.084.006H7.946a.627.627 0 0 1-.615-.639c0-.323.232-.59.532-.632l.083-.006h6.382Zm0-2.39c.34 0 .616.287.616.64 0 .322-.232.59-.532.632l-.084.005H7.946a.627.627 0 0 1-.615-.638c0-.323.232-.59.532-.632l.083-.006h6.382ZM10.832 5.16c.34 0 .615.285.615.638 0 .323-.231.59-.532.632l-.083.006H7.946a.627.627 0 0 1-.615-.638c0-.323.232-.59.532-.633l.083-.005h2.886Zm4.14-1.591v2.016h1.965l-1.965-2.016Z"
      fill={color}
    />
  </svg>
);

DocumentPreview.defaultProps = {
  width: 24,
  height: 24,
  color: 'currentColor',
};

DocumentPreview.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(DocumentPreview);
