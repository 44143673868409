import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { STATUS_LABELS } from '../leases/view/Tabs/ConditionReporting/ConditionDocumentTableInfo/ConditionDocumentTableInfo';

import styles from './ConditionReportsList.module.scss';

const ConditionReportStatusTag = ({ status }) => (
  <div
    className={cx(styles.reportStatusTag, {
      [styles[status.toLowerCase()]]: true,
    })}
  >
    {STATUS_LABELS[status]}
  </div>
);

ConditionReportStatusTag.propTypes = {
  status: PropTypes.string.isRequired,
};

export default ConditionReportStatusTag;
