import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import calculateProratedAmountInCents from '../../../helpers/calculateProratedAmountInCents';
import getFormattedDateLocalTZ from '../../../helpers/format-date-local';
import CheckMarkFilled from '../../../icons/CheckMarkFilled';
import StripeElementsModal from '../../../pages/common/stripe/StripeElementsModal';
import { useConfig } from '../../../providers/ConfigProvider';
import { segmentTracking } from '../../../services/utilities';
import Button from '../../Button';
import Divider from '../../Divider';
import Modal from '../../Modal';
import { H2, H3, Paragraph, Span } from '../../Typography';

import styles from './UpgradeToPremium.module.scss';

const UpgradeToPremium = ({
  open,
  onClose,
  onUpgrade,
  premiumPrice,
  subscriptionEnd,
  location,
}) => {
  const { PREMIUM_PLAN_PRICE_DEFAULT, GBB_PRO_YEARLY_119_PRICE } = useConfig();

  const [showStripe, setShowStripe] = useState(false);

  const priceDifference =
    (premiumPrice ? premiumPrice : PREMIUM_PLAN_PRICE_DEFAULT) -
    GBB_PRO_YEARLY_119_PRICE;

  useEffect(() => {
    segmentTracking('upgrade_your_plan_modal loaded', {
      location: location ?? 'Billing Settings',
    });
  }, []);

  const daysLeftInSubscription = moment(subscriptionEnd).diff(moment(), 'days');

  const proratedAmount = useMemo(() => {
    const startDate = new Date().toISOString();

    const priceInCents = calculateProratedAmountInCents(
      startDate,
      subscriptionEnd,
      priceDifference,
    );

    return Math.round(priceInCents) / 100;
  }, [subscriptionEnd]);

  const onMaybeLater = () => {
    segmentTracking('maybe_later clicked', {
      location: 'Upgrade Your Plan Modal',
    });

    onClose();
  };

  const onUpgradeToPremiumClick = () => {
    segmentTracking('upgrade_to_premium clicked', {
      location: 'Upgrade Your Plan Modal',
    });

    setShowStripe(true);
  };

  const onToken = useCallback(
    async (_token) => {
      await onUpgrade();
      setShowStripe(false);
    },
    [premiumPrice, subscriptionEnd],
  );

  if (showStripe) {
    return (
      <StripeElementsModal
        open
        onClose={() => {
          setShowStripe(false);
        }}
        title="Upgrade to Premium"
        disclaimer={`I understand that this Premium Plan will auto-renew for the full price on ${getFormattedDateLocalTZ(
          subscriptionEnd,
          'll',
        )}.`}
        amount={proratedAmount}
        buttonLabel={`Pay $${proratedAmount.toFixed(2)}`}
        onToken={onToken}
        onPayClicked={() => {
          segmentTracking('pay clicked', {
            location: 'Upgrade Your Plan Purchase Modal',
          });
        }}
      />
    );
  }

  return (
    <Modal className={styles.container} open={open} size="large">
      <H2 className={styles.title}>
        Unlock even <i>more</i> when you get Premium
      </H2>

      <div className={styles.premiumSection}>
        <H3 className="mb-16">Premium Plan</H3>
        <div className={styles.premiumItem}>
          <CheckMarkFilled color="#009E74" />
          <Paragraph>
            <Span weight="bold">Income Insights</Span> — Verify a renter’s
            income
          </Paragraph>
        </div>
        <div className={styles.premiumItem}>
          <CheckMarkFilled color="#009E74" />
          <Paragraph>
            <Span weight="bold">Lower Screening Fees</Span> — Renters pay $45
            instead of $55
          </Paragraph>
        </div>
        <div className={styles.premiumItem}>
          <CheckMarkFilled color="#009E74" />
          <Paragraph weight="bold">Unlimited E-Signatures</Paragraph>
        </div>
        <div className={styles.premiumItem}>
          <CheckMarkFilled color="#009E74" />
          <Paragraph>
            <Span weight="bold">Free ACH Payments</Span> — Normally $2 fee for
            renters
          </Paragraph>
        </div>
        <div className={styles.premiumItem}>
          <CheckMarkFilled color="#009E74" />
          <Paragraph>
            <Span weight="bold">Unlimited Bank Accounts</Span> — Deposits into
            unlimited number of bank accounts
          </Paragraph>
        </div>
        <div className={styles.premiumItem}>
          <CheckMarkFilled color="#009E74" />
          <Paragraph>
            <Span weight="bold"> Marketing Call Forwarding</Span> — Keep your
            phone number private
          </Paragraph>
        </div>
      </div>

      <div className={styles.bottomSection}>
        <div className={styles.bottomRow}>
          <Paragraph
            className={cx(styles.semiBold, styles.poppins)}
            size="large"
          >
            Subscription Upgrade
          </Paragraph>
          <Paragraph className="mt-0" size="large">
            ${priceDifference.toFixed(2)}
          </Paragraph>
        </div>
        <Paragraph className="mb-8" size="small">
          Based on the annual Premium price of ${premiumPrice}
        </Paragraph>
        <div className={styles.bottomRow}>
          <Paragraph
            className={cx(styles.semiBold, styles.poppins)}
            size="large"
          >
            Prorated Credit
          </Paragraph>
          <Paragraph className="mt-0" size="large">
            (${(priceDifference - proratedAmount).toFixed(2)})
          </Paragraph>
        </div>
        <Paragraph size="small">
          Based on {daysLeftInSubscription} days left in your subscription
        </Paragraph>

        <Divider className={styles.divider} text="" />

        <div className={styles.bottomRow}>
          <Paragraph className={styles.poppins} size="large" weight="semi">
            Total Due Today
          </Paragraph>
          <H2 className={cx('mt-0', 'mb-0', styles.poppins)} weight="bold">
            ${proratedAmount.toFixed(2)}
          </H2>
        </div>
      </div>

      <div className={styles.actions}>
        <Button className={styles.btn} secondary onClick={onMaybeLater}>
          Maybe Later
        </Button>
        <Button className={styles.btn} onClick={onUpgradeToPremiumClick}>
          Upgrade to Premium
        </Button>
      </div>
    </Modal>
  );
};

UpgradeToPremium.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onUpgrade: PropTypes.func,
  premiumPrice: PropTypes.number,
  subscriptionEnd: PropTypes.string,
  location: PropTypes.string,
};

export default UpgradeToPremium;
