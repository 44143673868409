import addUnitSuffix from './addUnitSuffix';
import PROPERTY_ROLES from './propertyRolesEnum';

const getPropertyName = (property) => {
  const suffix = addUnitSuffix(property);

  let name = property.address;

  if (property.nickname) name = property.nickname;

  if (property.property_role === PROPERTY_ROLES.UNIT) name = property.unit;

  if (property.property_role === PROPERTY_ROLES.ROOM) name = property.room_name;

  return name + suffix;
};

export default getPropertyName;
