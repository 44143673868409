import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';

import styles from './CancelSignatureRequestModal.module.scss';

const CancelSignatureRequestModal = ({
  className,
  containerClassName,
  open,
  onClose,
  onGoBack,
  onCancelRequest,
}) => {
  return (
    <Modal
      open={open}
      className={cx(styles.modal, className)}
      containerClassName={cx(styles.modalContainer, containerClassName)}
      onClose={onClose}
    >
      <h2 className={styles.title}>Cancel this signature request?</h2>
      <p className={styles.text}>
        Ran into an issue? You'll be able to redo the e-sign request for{' '}
        <b>no extra cost</b>.
      </p>
      <div className={styles.btnWrapper}>
        <Button className={styles.btn} secondary onClick={onGoBack}>
          Go Back
        </Button>
        <Button className={styles.btn} onClick={onCancelRequest}>
          Cancel request
        </Button>
      </div>
    </Modal>
  );
};

CancelSignatureRequestModal.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onGoBack: PropTypes.func.isRequired,
  onCancelRequest: PropTypes.func.isRequired,
};

export default CancelSignatureRequestModal;
