export const colors = [
  'navyBlue',
  'blue',
  'red',
  'orange',
  'yellow',
  'green',
  'lightGrey',
  'darkGrey',
  'outline',
];

export const types = ['default', 'dropdown', 'ribbonLeft', 'ribbonRight'];
