import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import getCardBrand from '../../../../helpers/getCardBrand';
import Bank from '../../../../icons/streamline/Bank';
import paymentMethodPropTypes from '../../../../prop-types/payment-method-prop-types';
import { useSegmentTracker } from '../../../../providers/SegmentTrackingProvider/context';
import PaymentCardIcon from '../PaymentCardIcon';

import getBankFeeDescription from './AddPaymentMethods/getBankFeeDescription';

import styles from './ExistingPaymentMethod.module.scss';

const getStatus = (status) => {
  switch (status.toLowerCase()) {
    case 'verified':
      return null;
    case 'verification_failed':
      return 'Verification Failed';
    case 'errored':
      return 'Error';
    case 'login_expired':
      return 'Login Expired';
    default:
      return 'Unverified';
  }
};

const ExistingPaymentMethod = ({
  user,
  userLoading,
  paymentMethod,
  to,
  onClickAction,
}) => {
  const { trackAction } = useSegmentTracker();
  const isCard = paymentMethod.type === 'CARD';
  const buttonProps = paymentMethod.used_for_rent_payments
    ? {
        to,
        onClick: () => {
          trackAction('useExistingCard', {
            paymentMethod: paymentMethod.type,
          });
        },
      }
    : {
        onClick: onClickAction,
      };
  if (isCard) {
    return (
      <Card className={styles.container}>
        <div className={styles.infoWrap}>
          <p className={styles.title}>card on file</p>
          <div className={styles.creditCardWrap}>
            <PaymentCardIcon
              brand={paymentMethod.brand}
              className={styles.icon}
            />
            <p className={styles.cardInfo}>{`${getCardBrand(
              paymentMethod.brand,
            )} ending in ${paymentMethod.last4}`}</p>
          </div>
        </div>
        <div className={cx(styles.buttonWrap)}>
          <Button small className={cx(styles.button)} {...buttonProps}>
            use this card
          </Button>
          <div className={styles.description}>3.49% convenience fee</div>
        </div>
      </Card>
    );
  }

  const paymentMethodStatus = getStatus(paymentMethod.source_status);
  return (
    <Card className={styles.container}>
      <div className={styles.infoWrap}>
        <p className={styles.title}>account on file</p>
        <div className={styles.creditCardWrap}>
          <Bank width={24} height={24} className={styles.bankIcon} />
          <p className={styles.cardInfo}>
            Bank Account ending in {paymentMethod.last4}{' '}
            {paymentMethodStatus && `- ${paymentMethodStatus}`}
          </p>
        </div>
      </div>
      <div className={cx(styles.buttonWrap)}>
        <Button small className={cx(styles.button)} {...buttonProps}>
          use this account
        </Button>
        <div className={styles.description}>
          {getBankFeeDescription(user, userLoading)}
        </div>
      </div>
    </Card>
  );
};

ExistingPaymentMethod.propTypes = {
  onClickAction: PropTypes.func,
  to: PropTypes.string,
  paymentMethod: paymentMethodPropTypes,
  user: PropTypes.object,
  userLoading: PropTypes.bool,
};

export default ExistingPaymentMethod;
