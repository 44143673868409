import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import Modal from '../../../../components/Modal';
import colors from '../../../../constants/colors';
import CheckMarkFilled from '../../../../icons/CheckMarkFilled';

import styles from './SignatureRequestCanceledModal.module.scss';

const SignatureRequestCanceledModal = ({
  className,
  containerClassName,
  open,
  onClose,
  onDone,
}) => {
  return (
    <Modal
      open={open}
      className={cx(styles.modal, className)}
      containerClassName={cx(styles.modalContainer, containerClassName)}
      onClose={onClose}
    >
      <CheckMarkFilled
        className={styles.icon}
        color={colors.lightGreenAlt}
        width={67}
        height={67}
      />
      <h2 className={styles.title}>Signature Request Canceled</h2>
      <p className={styles.text}>
        You can now redo the signature request and resend it to your signers.
      </p>
      <Button className={styles.btn} onClick={onDone}>
        Done
      </Button>
    </Modal>
  );
};

SignatureRequestCanceledModal.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onDone: PropTypes.func.isRequired,
};

export default SignatureRequestCanceledModal;
