// - Common boilerplate
import React, { Suspense } from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { ErrorBoundary } from 'react-error-boundary';
import { Router } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { ApolloProvider } from '@apollo/client';
import { Toaster } from 'sonner';

import browserHistory from '../browserHistory';
import AddAppHomeScreen from '../components/AddAppHomeScreen';
import { ModalProvider } from '../components/Modal/ModalProvider';
import initCometChat from '../core/CometChatInitialization';
import { initializeHelloSign } from '../core/helloSign';
import { TTInitializer } from '../core/initialization';
import UserEventSubscriptionHandler from '../core/UserEventSubscriptionHandler';
import { OutsideProvider } from '../helpers/render-outside';
import ActiveExperimentsProvider from '../providers/ActiveExperimentsProvider';
import { useConfig } from '../providers/ConfigProvider';
import GlobalBannerProvider from '../providers/GlobalBannerProvider';
import LayoutProvider, { CurrentLayout } from '../providers/LayoutProvider';
import TTAccountProvider from '../providers/TTAccountProvider';
import UniversalMessagingProvider from '../providers/UniversalMessagingProvider';

import ErrorHandler from './errorHandler';
import GlobalComponents from './GlobalComponents';
import MainRoutes from './routes.main';

// ------------------------------
// Bootstrapping
const initializer = new TTInitializer();

const App = () => {
  const config = useConfig();
  const client = initializer.getApolloClient(config);
  initCometChat(config);
  initializeHelloSign();

  return (
    <DndProvider backend={HTML5Backend}>
      <ApolloProvider client={client}>
        <ErrorBoundary FallbackComponent={ErrorHandler}>
          <Router history={browserHistory}>
            <CompatRouter>
              <TTAccountProvider>
                <ActiveExperimentsProvider>
                  <GlobalBannerProvider>
                    <Toaster />
                    <UserEventSubscriptionHandler />
                    <ModalProvider>
                      <OutsideProvider>
                        <UniversalMessagingProvider>
                          <LayoutProvider>
                            <CurrentLayout>
                              <Suspense fallback={null}>
                                <MainRoutes />
                              </Suspense>
                            </CurrentLayout>
                            <GlobalComponents />
                          </LayoutProvider>
                        </UniversalMessagingProvider>
                        <AddAppHomeScreen />
                      </OutsideProvider>
                    </ModalProvider>
                  </GlobalBannerProvider>
                </ActiveExperimentsProvider>
              </TTAccountProvider>
            </CompatRouter>
          </Router>
        </ErrorBoundary>
      </ApolloProvider>
    </DndProvider>
  );
};

export default App;
