const TENANT_PORTAL_MESSAGES_POLLING = 30000; // Decreased from 30secs because polling only when active
const TENANT_APPLICANT_POLL_INTERVAL = 30000; // Decreased from 20secs because polling only when active
const LEASE_VIEW_POLL_INTERVAL = 30000; // Decreased from 25secs because polling only when active
const USER_PROFILE_POLL_INTERVAL = 25000;
const MARKETING_PENDING_POLL_INTERVAL = 25000;
const SHORT_LINKS_ENABLED = true;
const WOOTRICS_ACCOUNT_TOKEN = 'NPS-51a2fdb4';
const INSURANCE_PRICE = 11;
const CARD_FEE = 0.0349;

// __GLOBALS__ comes from webpack.config.js
/*global __GLOBALS__ */

const NODE_ENV = __GLOBALS__.NODE_ENV;
const ENV_NAME = __GLOBALS__.ENV_NAME;
const __DEV__ = __GLOBALS__.__DEV__;
const API_URL = __GLOBALS__.API_URL;
const WS_URL = __GLOBALS__.WS_URL;
const OWNERS_URL = __GLOBALS__.OWNERS_URL;
const RENTERS_URL = __GLOBALS__.RENTERS_URL;
const PUBLIC_LISTING_URL = __GLOBALS__.PUBLIC_LISTING_URL;
const STRIPE_PUBLISHED_KEY = __GLOBALS__.STRIPE_PUBLISHED_KEY;
const STRIPE_AZIBO_PUBLISHED_KEY = __GLOBALS__.STRIPE_AZIBO_PUBLISHED_KEY;
const SURE_PUBLISHED_KEY = __GLOBALS__.SURE_PUBLISHED_KEY;
const USER_TYPE = __GLOBALS__.USER_TYPE;
const RECAPTCHA_SITE_KEY = __GLOBALS__.RECAPTCHA_SITE_KEY;
const SURE_IFRAME_URL = __GLOBALS__.SURE_IFRAME_URL;
const HEAP_APP_ID = __GLOBALS__.HEAP_APP_ID;
const GOOGLE_OAUTH_CLIENT_ID = __GLOBALS__.GOOGLE_OAUTH_CLIENT_ID;
const FACEBOOK_OAUTH_CLIENT_ID = __GLOBALS__.FACEBOOK_OAUTH_CLIENT_ID;
const SAMPLE_LISTING_ID = __GLOBALS__.SAMPLE_LISTING_ID;
const SMARTY_STREETS_AUTH_ID = __GLOBALS__.SMARTY_STREETS_AUTH_ID;
const COMETCHAT_APP_ID = __GLOBALS__.COMETCHAT_APP_ID;
const COMETCHAT_REGION = __GLOBALS__.COMETCHAT_REGION;
const ESIGNATURE_FEE = 15;
const LEASE_AGREEMENT_FEE = 59;
const FORMS_PACK_PRICE = 199;
const LINK_YOUR_SCREENING_APP_PRICE = 45;
const FORMS_PACK_NUMBER = 33;
const TABLE_VIEW_PAGE_SIZE = 25;
const LEADS_TABLE_PAGE_SIZE = 100;
const EXPENSES_TABLE_PAGE_SIZE = 100;
const PREMIUM_PLAN_TEST_109_PRICE = 109;
const PREMIUM_PLAN_TEST_119_PRICE = 119;
const PREMIUM_PLAN_TEST_139_PRICE = 139;
const PREMIUM_PLAN_TEST_149_PRICE = 149;
const PREMIUM_PLAN_TEST_199_PRICE = 199;
const GBB_PRO_YEARLY_119_PRICE = 119;
const GBB_PREMIUM_PLUS_YEARLY_249_PRICE = 249;
const GBB_PREMIUM_PLUS_YEARLY_299_PRICE = 299;
const GBB_PREMIUM_YEARLY_149_PRICE = 149;
const GBB_PREMIUM_YEARLY_179_PRICE = 179;
const GBB_PREMIUM_YEARLY_199_PRICE = 199;
const VIP_SUPPORT_ADDON_PLAN_PRICE_DEFAULT = 49;
const MAINTENANCE_PLUS_PLAN_PRICE = 149;

const PREMIUM_PLAN_PRICE_DEFAULT = GBB_PREMIUM_YEARLY_149_PRICE;

const LEASE_ADDENDUM_FEE = 19;
const DEFAULT_APPLICATION_FEE = 55;
const ACH_FEE = 2;
const REI_HUB_PRICING = {
  first: 15,
  rest: 5,
  billableProperties: 15,
};
const MAINTENANCE_PLUS_FEE = 149;
const MAINTENANCE_PLUS_PRODUCT_ID = 'tt-maintenance-plus';
const APPLICATION_DISCOUNT_COUPON_AMOUNT = 5;
const TURBOLIST_EXTENSION_ID = 'iimgbjogdeidpgemhinilpmbpmbpcojf';
const TURBOLIST_URL =
  'https://chrome.google.com/webstore/detail/turbolist-by-turbotenant/iimgbjogdeidpgemhinilpmbpmbpcojf';
const HELLO_SIGN_CLIENT_ID = __GLOBALS__.HELLO_SIGN_CLIENT_ID;
const SIFT_SCIENCE_BEACON_KEY = __GLOBALS__.SIFT_SCIENCE_BEACON_KEY;
const INTERCOM_API_KEY = __GLOBALS__.INTERCOM_API_KEY;
const LEAD_INTAKE_SHORT = 'https://turbo.rent/li';

const S3_BUCKET = __GLOBALS__.S3_BUCKET;

const SEARCH_WITH_BUTTON = __GLOBALS__.SEARCH_WITH_BUTTON;
const RECAPTCHA_LOGIN_SITE_KEY = __GLOBALS__.RECAPTCHA_LOGIN_SITE_KEY;

const LEASE_ENDING_SOON_THRESHOLD =
  __GLOBALS__.LEASE_ENDING_SOON_THRESHOLD || 121;

const RECAPTCHA_REQUIRED_AFTER_LOGIN_FAILED_MS = 10 * 60 * 1000; // 10 minutes
const IMPACT_ACCOUNT_CREATION_EVENT_ID = 32480;
const RENTER_CREDIT_REPORTING_MONTHLY_SUBSCRIPTION_PRICE = 4.99;
const RENTER_CREDIT_REPORTING_ANNUAL_SUBSCRIPTION_PRICE = 47.88;

//******* GBB PREMIUM SUBSCRIPTION PLAN IDS  */
const GBB_SINGLE_LEASE_YEARLY_59_PLAN_ID = 'tt-single-lease-yearly-plan-59';
const GBB_PRO_YEARLY_119_PLAN_ID = 'tt-premium-pro-yearly-plan-119';
const GBB_PREMIUM_PLUS_YEARLY_249_PLAN_ID = 'tt-premium-plus-yearly-plan-249';
const GBB_PREMIUM_PLUS_YEARLY_299_PLAN_ID = 'tt-premium-plus-yearly-plan-299';
const GBB_PREMIUM_YEARLY_149_PLAN_ID = 'tt-premium-yearly-plan-149';
const GBB_PREMIUM_YEARLY_179_PLAN_ID = 'tt-premium-yearly-plan-179';
const GBB_PREMIUM_YEARLY_199_PLAN_ID = 'tt-premium-yearly-plan-199';

const PREMIUM_PLAN_ID_DEFAULT = GBB_PREMIUM_YEARLY_149_PLAN_ID;

//******* PREMIUM SUBSCRIPTION CODES  */
/**
 * This short codes are later mapped in the BE to the actual subscription short code, most of the time they are the same
 * but for example the TT_PREMIUM_YEARLY, that's the default one, and it is mapped to the current yearly subscription in the BE
 * but when it changes, we don't change it here in the FE.
 */
const PREMIUM_VACANCY_PRO_SHORT_CODE = 'TT_VACANCY_PRO_YEARLY';
const PREMIUM_MANAGE_PRO_SHORT_CODE = 'TT_MANAGE_PRO_YEARLY';
const PREMIUM_ALL_IN_ONE_SHORT_CODE = 'TT_ALL_IN_ONE_YEARLY';
const PREMIUM_YEARLY_TEST_109_SHORT_CODE = 'TT_PREMIUM_YEARLY_TEST_109';
const PREMIUM_YEARLY_TEST_119_SHORT_CODE = 'TT_PREMIUM_YEARLY_TEST_119';
const PREMIUM_YEARLY_TEST_139_SHORT_CODE = 'TT_PREMIUM_YEARLY_TEST_139';
const PREMIUM_YEARLY_TEST_149_SHORT_CODE = 'TT_PREMIUM_YEARLY_TEST_149';
const PREMIUM_YEARLY_TEST_199_SHORT_CODE = 'TT_PREMIUM_YEARLY_TEST_199';

const GBB_SINGLE_LEASE_YEARLY_59_SHORT_CODE =
  'TT_PREMIUM_SINGLE_LEASE_YEARLY_59';

const GBB_FORMS_PACK_YEARLY_199_SHORT_CODE = 'TT_PREMIUM_FORMS_PACK_YEARLY_199';

const GBB_PRO_YEARLY_119_SHORT_CODE = 'TT_PREMIUM_PRO_YEARLY_119';
const GBB_PREMIUM_PLUS_YEARLY_299_SHORT_CODE = 'TT_PREMIUM_PLUS_YEARLY_299';
const GBB_PREMIUM_PLUS_YEARLY_249_SHORT_CODE = 'TT_PREMIUM_PLUS_YEARLY_249';
const GBB_PREMIUM_YEARLY_149_SHORT_CODE = 'TT_PREMIUM_YEARLY_149';
const GBB_PREMIUM_YEARLY_179_SHORT_CODE = 'TT_PREMIUM_YEARLY_179';
const GBB_PREMIUM_YEARLY_199_SHORT_CODE = 'TT_PREMIUM_YEARLY_199';

// This default SHORT CODE is mapped in the BE to the current yearly subscription
// that we are using.
// For more info check PremiumPlusPlansType in api/src/graphql/types/premium_plus_subscription.js
const PREMIUM_YEARLY_DEFAULT_SHORT_CODE = 'TT_PREMIUM_YEARLY';

//******* END PREMIUM SUBSCRIPTION CODES  */

const REI_HUB_PLAN_ID = 'rei-hub-monthly-price';
const REI_HUB_ANNUAL_PLAN_ID = 'rei-hub-annual-offer-price';
const REI_HUB_ANNUAL_PRICE_FULL_PLAN_ID = 'rei-hub-annual-price-full';
const REI_HUB_INCLUDED_IN_PREMIUM_PLAN_ID = 'rei-hub-included-in-premium';
const REI_HUB_INCLUDED_IN_PREMIUM_SHORT_CODE = 'TT_REI_HUB_INCLUDED_IN_PREMIUM';

const PREMIUM_Q1_OFFER_COUPON_CODE = 'PREMIUMQ12025';

const UPDATER_HOST = __GLOBALS__.UPDATER_HOST;
const MOVERS_URL = 'https://turbotenant.wemoveai.com/start-move';
const NORTH_WEST_LLC_URL =
  'https://shareasale.com/r.cfm?b=2287116&u=3119498&m=69959&urllink=&afftrack=';
const LEGAL_ZOOM_URL = 'https://legalzoomcominc.pxf.io/c/3756074/2185122/26746';
const STATE_LEASE_HELP_ARTICLE_URL =
  'https://support.turbotenant.com/en/articles/10092076-turbotenant-state-specific-lease-agreements';
const EZ_EVICTION_USA_URL =
  'https://ezevictusa.com/schedule-demo/turbo-tenant/';
const KLAVIYO_UNSUBSCRIBE_URL =
  'https://manage.kmail-lists.com/subscriptions/unsubscribe?cy=YudxC2';
const PRESCREENER_FAKEDOOR_TYPEFORM =
  'https://turbotenant.typeform.com/to/dwleSJzy';
const SEGMENT_WRITE_KEY = __GLOBALS__.SEGMENT_WRITE_KEY;
const SEGMENT_WRITE_KEY_RENTER = __GLOBALS__.SEGMENT_WRITE_KEY_RENTER;
const ATATUS_API_KEY = __GLOBALS__.ATATUS_API_KEY;

const ASSET_BUCKET_URL = __GLOBALS__.ASSET_BUCKET_URL;

const LL_MOBILE_APP_DOWNLOAD_URL =
  'https://turbo-tenant.app.link/e/landlord-dashboard-mobile-web-get-or-open-app';

const RENTER_MOBILE_APP_DOWNLOAD_URL =
  'https://turbo-tenant-renter.app.link/renter-login';

const TURBOTENANT_FORUM_URL = 'https://www.turbotenant.com/community/';

// Please use getConfig or fetchConfig instead of accessing this directly
export const getGlobals = (jsonConfig) => ({
  // Predefined globals
  TENANT_PORTAL_MESSAGES_POLLING,
  TENANT_APPLICANT_POLL_INTERVAL,
  LEASE_VIEW_POLL_INTERVAL,
  USER_PROFILE_POLL_INTERVAL,
  MARKETING_PENDING_POLL_INTERVAL,
  SHORT_LINKS_ENABLED,
  WOOTRICS_ACCOUNT_TOKEN,
  INSURANCE_PRICE,
  CARD_FEE,
  NODE_ENV,
  ENV_NAME,
  __DEV__,
  API_URL,
  WS_URL,
  OWNERS_URL,
  RENTERS_URL,
  PUBLIC_LISTING_URL,
  STRIPE_PUBLISHED_KEY,
  STRIPE_AZIBO_PUBLISHED_KEY,
  SURE_PUBLISHED_KEY,
  USER_TYPE,
  RECAPTCHA_SITE_KEY,
  SURE_IFRAME_URL,
  HEAP_APP_ID,
  GOOGLE_OAUTH_CLIENT_ID,
  FACEBOOK_OAUTH_CLIENT_ID,
  SAMPLE_LISTING_ID,
  SMARTY_STREETS_AUTH_ID,
  COMETCHAT_APP_ID,
  COMETCHAT_REGION,
  ESIGNATURE_FEE,
  LEASE_AGREEMENT_FEE,
  FORMS_PACK_PRICE,
  LINK_YOUR_SCREENING_APP_PRICE,
  FORMS_PACK_NUMBER,
  TABLE_VIEW_PAGE_SIZE,
  LEADS_TABLE_PAGE_SIZE,
  EXPENSES_TABLE_PAGE_SIZE,
  PREMIUM_PLAN_PRICE_DEFAULT,
  VIP_SUPPORT_ADDON_PLAN_PRICE_DEFAULT,
  MAINTENANCE_PLUS_PLAN_PRICE,
  LEASE_ADDENDUM_FEE,
  DEFAULT_APPLICATION_FEE,
  ACH_FEE,
  REI_HUB_PRICING,
  MAINTENANCE_PLUS_FEE,
  MAINTENANCE_PLUS_PRODUCT_ID,
  APPLICATION_DISCOUNT_COUPON_AMOUNT,
  TURBOLIST_EXTENSION_ID,
  TURBOLIST_URL,
  HELLO_SIGN_CLIENT_ID,
  SIFT_SCIENCE_BEACON_KEY,
  INTERCOM_API_KEY,
  LEAD_INTAKE_SHORT,
  S3_BUCKET,
  SEARCH_WITH_BUTTON,
  RECAPTCHA_LOGIN_SITE_KEY,
  RECAPTCHA_REQUIRED_AFTER_LOGIN_FAILED_MS,
  IMPACT_ACCOUNT_CREATION_EVENT_ID,
  PREMIUM_YEARLY_DEFAULT_SHORT_CODE,
  PREMIUM_VACANCY_PRO_SHORT_CODE,
  PREMIUM_ALL_IN_ONE_SHORT_CODE,
  PREMIUM_MANAGE_PRO_SHORT_CODE,
  PREMIUM_YEARLY_TEST_109_SHORT_CODE,
  PREMIUM_YEARLY_TEST_119_SHORT_CODE,
  PREMIUM_YEARLY_TEST_139_SHORT_CODE,
  PREMIUM_YEARLY_TEST_149_SHORT_CODE,
  PREMIUM_YEARLY_TEST_199_SHORT_CODE,
  GBB_SINGLE_LEASE_YEARLY_59_SHORT_CODE,
  GBB_FORMS_PACK_YEARLY_199_SHORT_CODE,
  GBB_PRO_YEARLY_119_SHORT_CODE,
  GBB_PREMIUM_PLUS_YEARLY_299_SHORT_CODE,
  GBB_PREMIUM_PLUS_YEARLY_249_SHORT_CODE,
  GBB_PREMIUM_YEARLY_149_SHORT_CODE,
  GBB_PREMIUM_YEARLY_179_SHORT_CODE,
  GBB_PREMIUM_YEARLY_199_SHORT_CODE,
  PREMIUM_PLAN_TEST_109_PRICE,
  PREMIUM_PLAN_TEST_119_PRICE,
  PREMIUM_PLAN_TEST_139_PRICE,
  PREMIUM_PLAN_TEST_149_PRICE,
  PREMIUM_PLAN_TEST_199_PRICE,
  GBB_PRO_YEARLY_119_PRICE,
  GBB_PREMIUM_PLUS_YEARLY_299_PRICE,
  GBB_PREMIUM_PLUS_YEARLY_249_PRICE,
  GBB_PREMIUM_YEARLY_149_PRICE,
  GBB_PREMIUM_YEARLY_179_PRICE,
  GBB_PREMIUM_YEARLY_199_PRICE,
  REI_HUB_PLAN_ID,
  REI_HUB_ANNUAL_PLAN_ID,
  REI_HUB_ANNUAL_PRICE_FULL_PLAN_ID,
  REI_HUB_INCLUDED_IN_PREMIUM_PLAN_ID,
  REI_HUB_INCLUDED_IN_PREMIUM_SHORT_CODE,
  GBB_SINGLE_LEASE_YEARLY_59_PLAN_ID,
  GBB_PRO_YEARLY_119_PLAN_ID,
  GBB_PREMIUM_PLUS_YEARLY_299_PLAN_ID,
  GBB_PREMIUM_PLUS_YEARLY_249_PLAN_ID,
  GBB_PREMIUM_YEARLY_149_PLAN_ID,
  GBB_PREMIUM_YEARLY_179_PLAN_ID,
  GBB_PREMIUM_YEARLY_199_PLAN_ID,
  PREMIUM_PLAN_ID_DEFAULT,
  UPDATER_HOST,
  MOVERS_URL,
  NORTH_WEST_LLC_URL,
  LEGAL_ZOOM_URL,
  STATE_LEASE_HELP_ARTICLE_URL,
  EZ_EVICTION_USA_URL,
  KLAVIYO_UNSUBSCRIBE_URL,
  PRESCREENER_FAKEDOOR_TYPEFORM,
  SEGMENT_WRITE_KEY,
  SEGMENT_WRITE_KEY_RENTER,
  ATATUS_API_KEY,
  RENTER_CREDIT_REPORTING_ANNUAL_SUBSCRIPTION_PRICE,
  RENTER_CREDIT_REPORTING_MONTHLY_SUBSCRIPTION_PRICE,
  LEASE_ENDING_SOON_THRESHOLD,
  ...jsonConfig,
  // Derived globals
  IS_PROD: (jsonConfig?.ENV_NAME || ENV_NAME) === 'prod',
  PRIVATE_BASE_PATH: `/${(jsonConfig?.USER_TYPE || USER_TYPE).toLowerCase()}s/`,
  IS_OWNER: (jsonConfig?.USER_TYPE || USER_TYPE) === 'OWNER',
  IS_RENTER: (jsonConfig?.USER_TYPE || USER_TYPE) === 'RENTER',
  IS_ADMIN: (jsonConfig?.USER_TYPE || USER_TYPE) === 'ADMIN',
  STEADILY_FORM_URL:
    (jsonConfig?.ENV_NAME || ENV_NAME) === 'prod'
      ? 'https://turbotenant.steadilypartner.com/partner/prefill'
      : 'https://demo.steadilypartner.com/partner/prefill',
  REIHUB_DASHBOARD_LINK:
    (jsonConfig?.ENV_NAME || ENV_NAME) === 'prod'
      ? 'https://reihub.net/dashboard'
      : 'https://dev.reihub.net/dashboard',
  SHORT_LINKS: (strings, ...values) =>
    `https://turbo.rent/s/${
      (jsonConfig?.ENV_NAME || ENV_NAME) === 'prod'
        ? ''
        : `${(jsonConfig?.ENV_NAME || ENV_NAME).toUpperCase()}-`
    }${strings.map((s, idx) => `${s}${values[idx] || ''}`).join('')}`,
  ASSET_BUCKET_URL,
  LL_MOBILE_APP_DOWNLOAD_URL,
  RENTER_MOBILE_APP_DOWNLOAD_URL,
  TURBOTENANT_FORUM_URL,
  PREMIUM_Q1_OFFER_COUPON_CODE,
});
