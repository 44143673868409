import React from 'react';

import { MODAL_LOCATIONS, TESTIMONIALS } from './constants';
import getModalBenefits from './get-modal-benefits';

const DEFAULT_PRICING = {
  [MODAL_LOCATIONS.INCOME_INSIGHT_BANNER]: 'premium',
  [MODAL_LOCATIONS.STANDALONE_SCREENING]: 'premium',
  [MODAL_LOCATIONS.PUBLIC_SCREENING]: 'premium',
  [MODAL_LOCATIONS.HELP_CENTER_FAQ]: 'pro',
  [MODAL_LOCATIONS.HELP_CENTER_CALL]: 'pro',
  [MODAL_LOCATIONS.BILLING]: 'pro',
  [MODAL_LOCATIONS.DASHBOARD]: 'pro',
  [MODAL_LOCATIONS.SIDEBAR_NAVIGATION_TAG]: 'pro',
  [MODAL_LOCATIONS.RESUBSCRIBE]: 'pro',
  [MODAL_LOCATIONS.PROPERTIES]: 'pro',
  [MODAL_LOCATIONS.TOOLBOX]: 'pro',
  [MODAL_LOCATIONS.MOBILE_APP]: 'pro',
  [MODAL_LOCATIONS.LEASE]: 'pro',
  [MODAL_LOCATIONS.LEASE_REVIEW]: 'pro',
  [MODAL_LOCATIONS.LEASE_IN_PROGRESS]: 'pro',
  [MODAL_LOCATIONS.ADDENDUM_IN_PROGRESS]: 'pro',
  [MODAL_LOCATIONS.ADDENDUM]: 'pro',
  [MODAL_LOCATIONS.E_SIGN_DASHBOARD]: 'premium',
  [MODAL_LOCATIONS.GET_FORMS_PACK]: 'pro',
  [MODAL_LOCATIONS.MARKETING]: 'pro',
  [MODAL_LOCATIONS.MARKETING_CALL_FORWARDING]: 'pro',
  [MODAL_LOCATIONS.PAYMENTS_OVERVIEW]: 'pro',
  [MODAL_LOCATIONS.PAYMENTS_DASHBOARD]: 'pro',
  [MODAL_LOCATIONS.PAYMENTS_MBA]: 'premium',
  [MODAL_LOCATIONS.PAYMENTS_MBA_ONBOARDING]: 'premium',
  [MODAL_LOCATIONS.PREMIUM_UPSELL_PAGE]: 'premium',
  [MODAL_LOCATIONS.BANK_ACCOUNTS]: 'premium',
  [MODAL_LOCATIONS.ENTITY_BANK_ACCOUNTS]: 'premium',
  [MODAL_LOCATIONS.USE_YOUR_OWN_APP]: 'pro',
  [MODAL_LOCATIONS.INVITE_TO_APPLY]: 'premium',
  [MODAL_LOCATIONS.INVITE_TO_APPLY_ONBOARDING]: 'premium',
  [MODAL_LOCATIONS.LEADS_BULK_INVITE_TO_APPLY]: 'premium',
  [MODAL_LOCATIONS.PRESCREENER_TAB]: 'pro',
  [MODAL_LOCATIONS.APPLICATION_TAB]: 'pro',
};

const getModalContent = (openLocation, user, config) => {
  let content;

  // In this switch case we see which openLocation param is sent by
  // the modal to determine order and content of benefits displayed

  switch (openLocation) {
    case MODAL_LOCATIONS.INCOME_INSIGHT_BANNER:
    case MODAL_LOCATIONS.STANDALONE_SCREENING:
    case MODAL_LOCATIONS.PUBLIC_SCREENING:
      content = {
        title: "Get the Truth about your Applicant's Income, Faster",
        subtitle: 'Get access to everything you need to be a great landlord.',
        testimonial: { ...TESTIMONIALS.TERESA },
      };

      break;

    case MODAL_LOCATIONS.HELP_CENTER_FAQ:
    case MODAL_LOCATIONS.HELP_CENTER_CALL:
      content = {
        title: (
          <>
            One Price. <br />
            Unlimited Uses.
          </>
        ),
        subtitle: 'Get access to everything you need to be a great landlord.',
        testimonial: { ...TESTIMONIALS.DUYEN },
      };
      break;

    case MODAL_LOCATIONS.BILLING:
    case MODAL_LOCATIONS.DASHBOARD:
    case MODAL_LOCATIONS.SIDEBAR_NAVIGATION_TAG:
    case MODAL_LOCATIONS.RESUBSCRIBE:
    case MODAL_LOCATIONS.PROPERTIES:
    case MODAL_LOCATIONS.TOOLBOX:
    case MODAL_LOCATIONS.MOBILE_APP:
    case MODAL_LOCATIONS.URL_PARAM:
      content = {
        title: (
          <>
            One Price. <br />
            Unlimited Uses.
          </>
        ),
        subtitle:
          'Get the tools you need to manage your property professionally, efficiently, and confidently.',
        testimonial: { ...TESTIMONIALS.FRANK },
      };
      break;
    case MODAL_LOCATIONS.LEASE:
    case MODAL_LOCATIONS.LEASE_REVIEW:
    case MODAL_LOCATIONS.LEASE_IN_PROGRESS:
    case MODAL_LOCATIONS.ADDENDUM_IN_PROGRESS:
    case MODAL_LOCATIONS.ADDENDUM:
    case MODAL_LOCATIONS.E_SIGN_DASHBOARD:
    case MODAL_LOCATIONS.GET_FORMS_PACK:
      content = {
        title: 'Professionalize your Rental Business',
        subtitle:
          'Stop searching for landlord documents and tools. It’s all here.',
        testimonial: { ...TESTIMONIALS.SCOTT },
      };
      break;

    case MODAL_LOCATIONS.MARKETING:
    case MODAL_LOCATIONS.MARKETING_CALL_FORWARDING:
      content = {
        title: (
          <>
            Better Marketing. <br /> Better Everything.
          </>
        ),
        subtitle: (
          <>
            Get the tools you need to manage your property professionally,
            efficiently, and <strong>confidently</strong>.
          </>
        ),
        testimonial: { ...TESTIMONIALS.MICHAEL },
      };
      break;

    case MODAL_LOCATIONS.PAYMENTS_OVERVIEW:
    case MODAL_LOCATIONS.PAYMENTS_DASHBOARD:
      content = {
        title: (
          <>
            Better Rent Payments. <br /> Better Everything.
          </>
        ),
        subtitle:
          'Upgrade and get everything you need to make landlording easier.',
        testimonial: { ...TESTIMONIALS.DUYEN },
      };
      break;

    case MODAL_LOCATIONS.PAYMENTS_MBA:
    case MODAL_LOCATIONS.PAYMENTS_MBA_ONBOARDING:
    case MODAL_LOCATIONS.PREMIUM_UPSELL_PAGE:
    case MODAL_LOCATIONS.BANK_ACCOUNTS:
    case MODAL_LOCATIONS.ENTITY_BANK_ACCOUNTS:
      content = {
        title: (
          <>
            Better Rent Payments. <br /> Better Everything.
          </>
        ),
        subtitle:
          'Upgrade and get everything you need to make landlording easier.',
        testimonial: { ...TESTIMONIALS.MICHAEL },
      };
      break;

    case MODAL_LOCATIONS.USE_YOUR_OWN_APP:
      content = {
        title: 'Flexible screening, shorter vacancy, better tenants.',
        subtitle:
          'Get all the flexibility and customization your entire rental process needs when you get Premium.',
        testimonial: { ...TESTIMONIALS.FRANK },
      };
      break;
    case MODAL_LOCATIONS.INVITE_TO_APPLY:
    case MODAL_LOCATIONS.INVITE_TO_APPLY_ONBOARDING:
    case MODAL_LOCATIONS.LEADS_BULK_INVITE_TO_APPLY:
      content = {
        title: (
          <>
            Better Screening,
            <br /> Better Everything
          </>
        ),
        subtitle:
          'Upgrade and get what you need to fill your rental quickly with the best tenant.',
        testimonial: { ...TESTIMONIALS.AMY },
      };
      break;
    case MODAL_LOCATIONS.PRESCREENER_TAB:
    case MODAL_LOCATIONS.APPLICATION_TAB:
      content = {
        title: (
          <>
            Better Screening,
            <br /> Better Everything
          </>
        ),
        subtitle:
          'Upgrade and get what you need to fill your rental quickly with the best tenant.',
        testimonial: { ...TESTIMONIALS.AMY },
      };
      break;
    default:
      content = {
        title: (
          <>
            Better Screening,
            <br /> Better Everything
          </>
        ),
        subtitle:
          'Upgrade and get what you need to fill your rental quickly with the best tenant.',
        testimonial: { ...TESTIMONIALS.AMY },
      };

      break;
  }

  content.defaultPricing = DEFAULT_PRICING[openLocation] || 'pro';

  if (user) {
    content.benefits = content.hideBenefits
      ? []
      : getModalBenefits(openLocation, user, config);
  }

  return content;
};

export default getModalContent;
