import React, { useEffect, useReducer, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import DropdownMenu from '../../../components/DropdownMenu';
import FilterGeneric from '../../../components/FilterGeneric';
import FilterRadio from '../../../components/FilterRadio';
import FlatButton from '../../../components/FlatButton';
import PlainInputWithIcon from '../../../components/PlainInputWithIcon';
import PropertiesFilter from '../../../components/PropertiesFilter';
import Skeleton from '../../../components/Skeleton';
import documentsFiltersQuery from '../../../graphql/queries/all_documents/allDocumentsFilters.graphql';
import parseListingsFilters from '../../../helpers/parseListingsFilters';
import Lease from '../../../icons/Lease';
import Renters from '../../../icons/Renters';
import Search from '../../../icons/Search';
import Esign from '../../../icons/streamline/Esign';

import styles from './EsignFilters.module.scss';

const sortCriterias = {
  PRIORITY: 'PRIORITY',
  NEWEST: 'NEWEST',
  OLDEST: 'OLDEST',
};

const sortingOptions = [
  {
    value: sortCriterias.PRIORITY,
    label: 'Priority',
  },
  {
    value: sortCriterias.NEWEST,
    label: 'Newest',
  },
  {
    value: sortCriterias.OLDEST,
    label: 'Oldest',
  },
];

const statusOptions = [
  {
    value: 'WAITING_ON_SIGNATURES',
    label: 'Waiting on Signatures',
  },
  {
    value: 'READY_TO_SIGN',
    label: 'Ready to Sign',
  },
];

const sortInitialState = sortingOptions[0];
const filtersInitialState = {
  listing_ids: [],
  lease_ids: [],
  signers: [],
  status: null,
  search: '',
};

export const useEsignFilters = () => {
  const [filters, setFilters] = useReducer(
    (oldFilters, newFilters) => ({
      ...oldFilters,
      ...newFilters,
    }),
    filtersInitialState,
  );
  const [sortConfig, setSortConfig] = useState(sortInitialState);

  const handleSearch = (search) => {
    if (!!search.length || search === '') {
      setFilters({ search });
    }
  };

  const onListingsFilterChange = (ids) => {
    setFilters({ listing_ids: ids });
  };

  const onLeasesFilterChange = (ids) => {
    setFilters({ lease_ids: ids });
  };

  const onSignersFilterChange = (ids) => {
    setFilters({ signers: ids });
  };

  const onStatusFilterChange = (status) => {
    setFilters({ status });
  };

  const resetFilters = () => {
    setFilters(filtersInitialState);
    setSortConfig(sortInitialState);
  };

  return {
    filters,
    resetFilters,
    sortConfig,
    onListingsFilterChange,
    onLeasesFilterChange,
    onSignersFilterChange,
    onStatusFilterChange,
    handleSearch,
    setSortConfig,
  };
};

const EsignFilters = ({
  filters,
  sortConfig,
  handleSearch,
  setSortConfig,
  onListingsFilterChange,
  onLeasesFilterChange,
  onSignersFilterChange,
  onStatusFilterChange,
  resetFilters,
}) => {
  const [getFilters, { loading, error, data }] = useLazyQuery(
    documentsFiltersQuery,
  );

  useEffect(() => {
    if (loading || error || data) return;

    getFilters();
  }, []);

  const mapFiltersToOptions = (filters) =>
    filters.map((filter) => ({
      value: filter.id,
      label: filter.name,
    }));

  const listings = get(data, 'filters.documentsPage.listings', []);
  const leases = get(data, 'filters.documentsPage.leases', []);
  const signers = get(data, 'filters.documentsPage.signers', []);

  if (loading) {
    return (
      <Skeleton className={styles.filterSkeleton}>
        <Skeleton.Section align="center" gap="sm" wrap>
          <Skeleton.Button size="large" />
          <Skeleton.Button />
          <Skeleton.Button />
          <Skeleton.Button />
          <Skeleton.Button />
        </Skeleton.Section>
      </Skeleton>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.filterWrapper}>
        <PlainInputWithIcon
          value={filters.search}
          onChange={(e) => {
            handleSearch(e.target.value);
          }}
          placeholder="Search"
          icon={Search}
          className={styles.searchField}
          id="searchbox"
        />
        <div className={styles.filtersRow}>
          {/* <PropertiesFilter
            icon={Key}
            filterName="Rentals"
            staticFilterName
            options={parseListingsFilters(listings)}
            onChange={onListingsFilterChange}
            selectedIds={filters.listing_ids}
            loading={loading}
          /> */}
          <PropertiesFilter
            filterName="Rentals"
            properties={parseListingsFilters(listings)}
            selectedIds={filters.listing_ids}
            onApplyFilters={onListingsFilterChange}
          />
          <FilterGeneric
            className={styles.documentsFilter}
            icon={Lease}
            filterName="Lease"
            staticFilterName
            options={mapFiltersToOptions(leases)}
            onChange={onLeasesFilterChange}
            selected={filters.lease_ids}
            showApplyFiltersButton
            closeOnButtonsClick
          />
          <FilterGeneric
            className={styles.documentsFilter}
            icon={Renters}
            filterName="Signers"
            staticFilterName
            hideSearch
            options={mapFiltersToOptions(signers)}
            onChange={onSignersFilterChange}
            selected={filters.signers}
            showApplyFiltersButton
            closeOnButtonsClick
          />
          <FilterRadio
            className={styles.documentsFilter}
            icon={Esign}
            filterName="Status"
            staticFilterName
            options={statusOptions}
            onChange={onStatusFilterChange}
            value={filters.status}
            closeOnButtonsClick
          />
          {(filters.search ||
            filters.listing_ids.length ||
            filters.lease_ids.length ||
            filters.signers.length ||
            filters.status) && (
            <FlatButton
              className={styles.resetFiltersButton}
              onClick={resetFilters}
            >
              Reset all
            </FlatButton>
          )}
        </div>
      </div>

      <DropdownMenu closeOnClick className={styles.sortDropdown}>
        <DropdownMenu.Trigger>
          <div className={styles.dropdownTrigger}>
            Sort by<b>{sortConfig?.label}</b>
            <DropdownMenu.Arrow />
          </div>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          {sortingOptions.map((option) => {
            return (
              <DropdownMenu.Item
                key={`${option.value}${option.direction}`}
                as="button"
                className={styles.sortOption}
                onClick={() => {
                  setSortConfig(option);
                }}
              >
                {option.label}
              </DropdownMenu.Item>
            );
          })}
        </DropdownMenu.Content>
      </DropdownMenu>
    </div>
  );
};

EsignFilters.propTypes = {
  filters: PropTypes.object.isRequired,
  sortConfig: PropTypes.object.isRequired,
  handleSearch: PropTypes.func.isRequired,
  setSortConfig: PropTypes.func.isRequired,
  onListingsFilterChange: PropTypes.func.isRequired,
  onLeasesFilterChange: PropTypes.func.isRequired,
  onSignersFilterChange: PropTypes.func.isRequired,
  onStatusFilterChange: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
};

export default EsignFilters;
