import React from 'react';
import useWindowSize from '@rooks/use-window-size';
import PropTypes from 'prop-types';

import CONDITION_REPORT_STATUS from '../../../constants/condition_reports/status';
import { smallScreenWidth } from '../../../constants/media-breakpoints';
import useNavigator from '../../../hooks/useNavigator';
import { useConfig } from '../../../providers/ConfigProvider';
import { segmentTracking } from '../../../services/utilities';

import ConditionReportsTableDesktop from './ConditionReportsTableDesktop';
import ConditionReportsTableMobile from './ConditionReportsTableMobile';
const ConditionReportsTable = ({
  className,
  classNameMobile,
  conditionReports,
  user,
  hideTitle,
}) => {
  const { innerWidth } = useWindowSize();
  const navigate = useNavigator();
  const { PRIVATE_BASE_PATH } = useConfig();

  const goToLeasePage = (leaseId) => {
    segmentTracking('lease clicked', {
      location: 'Condition Report Index Page',
    });
    navigate(`${PRIVATE_BASE_PATH}leases/view/${leaseId}/summary`);
  };
  const goToConditionReportLeaseTab = (leaseId, openCreateModal) =>
    navigate(`${PRIVATE_BASE_PATH}leases/view/${leaseId}/condition_reporting`, {
      state: { openCreateModal },
    });
  const onRowClick = (leaseId, conditionReportId, status, isSelfAssigned) => {
    segmentTracking('cr_row clicked', {
      location: 'Condition Report Index Page',
    });
    if (
      isSelfAssigned &&
      [
        CONDITION_REPORT_STATUS.NOT_STARTED,
        CONDITION_REPORT_STATUS.IN_PROGRESS,
      ].includes(status)
    ) {
      return navigate(
        `${PRIVATE_BASE_PATH}leases/${leaseId}/condition_reports/${conditionReportId}/fill-out`,
      );
    }

    navigate(
      `${PRIVATE_BASE_PATH}leases/${leaseId}/condition_reports/${conditionReportId}/review`,
    );
  };

  const createNewReportHandler = (leaseId, topOfPageCTA) => {
    segmentTracking('create_new_report clicked', {
      location: `Condition Report Index Page ${
        topOfPageCTA ? ' - Top of Page CTA' : ''
      }`,
    });
    const leasesCount = user.active_leases_count;
    const propertyCount = user.listings_count;
    if (leasesCount === 0 && propertyCount === 0)
      return navigate(`${PRIVATE_BASE_PATH}properties`);
    if (leasesCount === 0)
      return navigate(`${PRIVATE_BASE_PATH}condition-reports-select-lease`, {
        state: { selectNew: true },
      });
    if (leasesCount === 1) return goToConditionReportLeaseTab(leaseId, true);
    if (leasesCount >= 2)
      return navigate(`${PRIVATE_BASE_PATH}condition-reports-select-lease`);
  };

  const isSmallerScreen = innerWidth <= smallScreenWidth;
  if (isSmallerScreen)
    return (
      <ConditionReportsTableMobile
        className={classNameMobile}
        conditionReports={conditionReports}
        goToLeasePage={goToLeasePage}
        onRowClick={onRowClick}
        createNewReportHandler={createNewReportHandler}
        hideTitle={hideTitle}
      />
    );
  return (
    <ConditionReportsTableDesktop
      className={className}
      conditionReports={conditionReports}
      goToLeasePage={goToLeasePage}
      onRowClick={onRowClick}
      createNewReportHandler={createNewReportHandler}
      hideTitle={hideTitle}
    />
  );
};

ConditionReportsTable.propTypes = {
  className: PropTypes.string,
  classNameMobile: PropTypes.string,
  conditionReports: PropTypes.arrayOf(PropTypes.object),
  user: PropTypes.object,
  hideTitle: PropTypes.bool,
};

export default ConditionReportsTable;
