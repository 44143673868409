import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../../constants/colors';

const Documents = ({ color, ...rest }) => (
  <svg viewBox="0 0 32 32" {...rest} fill={color}>
    <path d="M18.123 2.666a.816.816 0 0 1 .577.24l6.539 6.538a.851.851 0 0 1 .038.042l-.047-.05a.823.823 0 0 1 .231.459l.009.118v18.503c0 .451-.366.817-.817.817H6.15a.816.816 0 0 1-.817-.817V3.482c0-.45.366-.816.817-.816h11.973Zm-.818 1.632H6.966V27.7h16.87V10.829h-5.713a.816.816 0 0 1-.817-.816V4.298ZM20.3 23.346a.816.816 0 0 1 0 1.633h-9.795a.816.816 0 1 1 0-1.633h9.795Zm0-3.265a.816.816 0 0 1 0 1.633h-9.795a.816.816 0 1 1 0-1.633h9.795Zm0-3.265a.816.816 0 0 1 0 1.632h-9.795a.816.816 0 1 1 0-1.632h9.795Zm0-3.266a.816.816 0 0 1 0 1.633h-9.795a.816.816 0 1 1 0-1.633h9.795Zm-4.897-3.265a.816.816 0 1 1 0 1.633h-4.898a.816.816 0 1 1 0-1.633h4.898Zm3.537-4.831v3.743l3.741-.002-3.741-3.741Z" />
  </svg>
);

// eslint-disable-next-line local/no-default-props
Documents.defaultProps = {
  width: 32,
  height: 32,
  color: colors.text,
};

Documents.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Documents);
