import React from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import CONDITION_REPORT_STATUS from '../../../../../../../constants/condition_reports/status';
import { useConfig } from '../../../../../../../providers/ConfigProvider';

// import EditConditionalReportingDocumentsModal from '../../../../documents/AddConditionReportingDocuments/EditConditionalReportingDocumentsModal';
// import ConditionalReportingDeleteModal from '../ConditionalReportingDeleteModal/ConditionalReportingDeleteModal';
import styles from './ConditionDocumentTableInfo.module.scss';

export const STATUS_LABELS = {
  [CONDITION_REPORT_STATUS.NOT_STARTED]: 'Not Started',
  [CONDITION_REPORT_STATUS.IN_PROGRESS]: 'In Progress',
  [CONDITION_REPORT_STATUS.NEEDS_APPROVAL]: 'Needs Approval',
  [CONDITION_REPORT_STATUS.COMPLETE]: 'Complete',
  [CONDITION_REPORT_STATUS.UPLOADED]: 'Uploaded',
};

const ConditionDocumentTableInfo = ({ lease, documents = [], type }) => {
  const history = useHistory();
  const { PRIVATE_BASE_PATH } = useConfig();

  const getPeopleOnCRLabel = (cr) => {
    if (!cr) {
      return '';
    }

    const {
      assigned_to: assignedTo,
      sent_by: sentBy,
      status,
      completed_by,
    } = cr;

    const actionLabel =
      status === CONDITION_REPORT_STATUS.COMPLETE
        ? 'Completed by'
        : 'Assigned to';

    const selfAssigned = assignedTo[0]?.email === sentBy.email;
    if (selfAssigned) {
      return `${actionLabel} You`;
    }

    if (status === CONDITION_REPORT_STATUS.COMPLETE) {
      const completer = `${completed_by.first_name} ${completed_by.last_name}`;

      return `${actionLabel} ${completer}`;
    }

    const assignees = assignedTo
      .map((assignee) => `${assignee.first_name} ${assignee.last_name}`)
      .join(', ');

    return `${actionLabel} ${assignees}`;
  };

  const onRowClick = ({
    leaseId,
    conditionReportId,
    status,
    isSelfAssigned,
  }) => {
    if (
      isSelfAssigned &&
      [
        CONDITION_REPORT_STATUS.NOT_STARTED,
        CONDITION_REPORT_STATUS.IN_PROGRESS,
      ].includes(status)
    ) {
      return history.push(
        `${PRIVATE_BASE_PATH}leases/${leaseId}/condition_reports/${conditionReportId}/fill-out`,
      );
    }

    history.push(
      `${PRIVATE_BASE_PATH}leases/${leaseId}/condition_reports/${conditionReportId}/review`,
    );
  };

  const renderConditionReports = ({ reportDocuments, isMobile } = {}) => {
    if (isMobile) {
      return reportDocuments.map((doc) => {
        let key = doc.id;
        const title = doc?.title || 'Condition Report';
        const conditionReport = doc?.condition_report;

        let due_date;
        let completed_at;
        let status;
        let peopleOnCR;
        let isSelfAssigned = false;

        if (!conditionReport) {
          due_date = 'N/A';
          completed_at = moment(doc?.created_at).format('MMM D, YYYY') || '---';
          status = CONDITION_REPORT_STATUS.UPLOADED;
        } else {
          key = conditionReport.id;
          due_date = conditionReport?.due_date
            ? moment(conditionReport?.due_date).format('MMM D, YYYY')
            : '---';
          completed_at = conditionReport?.completed_at
            ? moment(conditionReport?.completed_at).format('MMM D, YYYY')
            : '---';
          status = conditionReport?.status;
          peopleOnCR = getPeopleOnCRLabel(conditionReport);
          isSelfAssigned =
            conditionReport?.assigned_to?.[0]?.email ===
            conditionReport?.sent_by?.email;
        }

        return (
          <div
            className={styles.mobileTableContent}
            key={key}
            data-qa={`${type}_${key}`}
            onClick={() => {
              onRowClick({
                leaseId: lease?.id,
                conditionReportId: conditionReport?.id ?? doc.id,
                status,
                isSelfAssigned,
              });
            }}
          >
            <div className={styles.mobileTableValues}>
              <div className={styles.fileNameRow}>
                <span className={styles.title}>{title}</span>
                <span className={styles.assignees}>{peopleOnCR}</span>
              </div>
              <div>Due Date: {due_date}</div>
              <div>Completed: {completed_at}</div>
              <div
                className={cx(styles.reportStatusTag, {
                  [styles[status.toLowerCase()]]: true,
                })}
              >
                {STATUS_LABELS[status]}
              </div>
            </div>
          </div>
        );
      });
    }

    return reportDocuments.map((doc) => {
      let key = doc.id;
      const title = doc?.title || 'Condition Report';
      const conditionReport = doc?.condition_report; // Uploaded documents do not have this field
      let due_date;
      let completed_at;
      let status;
      let peopleOnCR;
      let isSelfAssigned = false;

      if (!conditionReport) {
        due_date = 'N/A';
        completed_at = moment(doc?.created_at).format('MMM D, YYYY') || '---';
        status = CONDITION_REPORT_STATUS.UPLOADED;
      } else {
        key = conditionReport.id;
        due_date = conditionReport?.due_date
          ? moment(conditionReport?.due_date).format('MMM D, YYYY')
          : '---';
        completed_at = conditionReport?.completed_at
          ? moment(conditionReport?.completed_at).format('MMM D, YYYY')
          : '---';
        status = conditionReport?.status;
        peopleOnCR = getPeopleOnCRLabel(conditionReport);
        isSelfAssigned =
          conditionReport?.assigned_to?.[0]?.email ===
          conditionReport?.sent_by?.email;
      }

      return (
        <div
          className={styles.tableValues}
          key={key}
          data-qa={`${type}_${key}`}
          onClick={() => {
            onRowClick({
              leaseId: lease?.id,
              conditionReportId: conditionReport?.id ?? doc.id,
              status,
              isSelfAssigned,
            });
          }}
        >
          <div>
            <span className={styles.title}>{title}</span>
            <span className={styles.assignees}>{peopleOnCR}</span>
          </div>
          <div>{due_date}</div>
          <div>{completed_at}</div>
          <div className={styles.statusColumn}>
            <div
              className={cx(styles.reportStatusTag, {
                [styles[status.toLowerCase()]]: true,
              })}
            >
              {STATUS_LABELS[status]}
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div className={styles.desktopTable}>
        <div className={styles.tableLabels}>
          <div>Name</div>
          <div>Due Date</div>
          <div>Completed</div>
          <div>Status</div>
        </div>
        {renderConditionReports({ reportDocuments: documents })}
      </div>
      <div className={styles.mobileTable}>
        {renderConditionReports({
          reportDocuments: documents,
          isMobile: true,
        })}
      </div>
    </>
  );
};

ConditionDocumentTableInfo.propTypes = {
  lease: PropTypes.object,
  documents: PropTypes.array,
  type: PropTypes.string,
};

export default ConditionDocumentTableInfo;
