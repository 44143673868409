import Decimal from 'jsdecimal';

import removeKeyDeep from '../../../helpers/removeKeyDeep';

export const normalizeLateFeeFields = (charge = {}) => {
  if (!charge.late_fee_amount) {
    charge.late_fee_grace_period = null;
    charge.is_late_fee_percentage = null;
  } else {
    charge.is_late_fee_percentage = charge.is_late_fee_percentage === true;
    charge.late_fee_grace_period =
      parseInt(charge.late_fee_grace_period) || null;
    charge.late_fee_amount = charge.is_late_fee_percentage
      ? parseFloat(charge.late_fee_amount)
      : Decimal(charge.late_fee_amount).mul(100).toFloat();
  }

  return charge;
};

export const prepareChargeForMutation = (charge = {}, isRule = true) => {
  const chargeDataClean = removeKeyDeep(charge, '__typename');
  return normalizeLateFeeFields({
    description: chargeDataClean.description,
    amount: Decimal(chargeDataClean.amount).mul(100).toFloat(),
    category: chargeDataClean.category,
    type: chargeDataClean.rule_type || chargeDataClean.type,
    start_date: chargeDataClean.start_date,
    end_date: chargeDataClean.no_end_date ? null : chargeDataClean.end_date,
    due_day_of_month: chargeDataClean.due_day_of_month
      ? parseInt(
          chargeDataClean.due_day_of_month === 'Last Day'
            ? 31
            : chargeDataClean.due_day_of_month,
        )
      : undefined,
    destination_id: chargeDataClean.destination_id,
    is_late_fee_percentage: chargeDataClean.is_late_fee_percentage,
    late_fee_amount: chargeDataClean.late_fee_amount,
    late_fee_grace_period: chargeDataClean.late_fee_grace_period,
    attachments: chargeDataClean.attachments?.map(({ id }) => id),
    ...(isRule ? { late_fee_policy: chargeDataClean.late_fee_policy } : {}),
  });
};
