import Reviewer1Image from './assets/reviewer-1.png';
import Reviewer2Image from './assets/reviewer-2.png';
import Reviewer3Image from './assets/reviewer-3.png';
import Reviewer4Image from './assets/reviewer-4.png';

export const googleReviews = [
  {
    id: 1,
    image: Reviewer1Image,
    name: 'Beth O.',
    units: 2,
    review: 'QuickBooks doesn’t even come close!!!!',
    rating: 5,
    link: 'https://maps.app.goo.gl/rnkKpDpvUxsnhn8z6',
  },
  {
    id: 3,
    image: Reviewer3Image,
    name: 'Casey S.',
    units: 9,
    review: 'Top notch customer service and response time!',
    rating: 5,
    link: 'https://maps.app.goo.gl/icAcPYqCg9Lon9SaA',
  },
  {
    id: 2,
    image: Reviewer2Image,
    name: 'Charlie D.',
    units: 1,
    review:
      'I find it very easy to use and has everything I need even for taxes.',
    rating: 5,
    link: 'https://maps.app.goo.gl/ue9ZuXjfQ9L2YimB7',
  },
  {
    id: 4,
    image: Reviewer4Image,
    name: 'Kevin O.',
    units: 3,
    review:
      'The best product...with incredible support...real estate specific and scaleable.',
    rating: 5,
    link: 'https://maps.app.goo.gl/ophoDNN1iyZo2NFaA',
  },
];
