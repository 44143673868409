import React from 'react';

import { Experiments } from '../../../../constants/experiments';
import { useActiveExperiments } from '../../../../providers/ActiveExperimentsProvider/context';

import OwnerPaymentsOnboarding from './OwnerPaymentsOnboarding';
import OwnerPaymentsOnboardingTest from './OwnerPaymentsOnboardingTest';

const components = {
  control: OwnerPaymentsOnboarding,
  test: OwnerPaymentsOnboardingTest,
};

const OwnerPaymentsOnboardingWrapper = (props) => {
  const { userExperiments } = useActiveExperiments();

  if (!userExperiments) {
    window.atatus?.notify({
      message:
        'ChargesFirst: userExperiments not found in OwnerPaymentsOnboardingWrapper',
    });
    return null;
  }

  const variant = userExperiments[Experiments.ChargesFirst.name];
  if (!variant) {
    window.atatus?.notify({
      message:
        'ChargesFirst: userExperiments found in OwnerPaymentsOnboardingWrapper, but variant not found',
    });
    return <OwnerPaymentsOnboarding {...props} />;
  }

  const Component = components[variant];

  if (!Component) return null;

  return <Component {...props} />;
};

export default OwnerPaymentsOnboardingWrapper;
