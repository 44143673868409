import React from 'react';
import { Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';

import UnsavedChangesPrompt from '../../../../digital-leases/standalone-wizard/UnsavedChangesPrompt';

import ChargesFirstForm from './ChargesFirstForm';
import useChargesFirst from './useChargesFirst';

const ChargesFirst = ({
  data,
  isPremiumUser,
  bankAccountOptions,
  isEdit,
  onNext,
}) => {
  const { state, ...hooks } = useChargesFirst(data, onNext);

  return (
    <>
      <UnsavedChangesPrompt when={state.dirty && !state.submitted} />
      <Prompt
        when={state.dirty && !state.submitted}
        message="Changes that you made may not be saved."
      />
      <ChargesFirstForm
        isPremiumUser={isPremiumUser}
        bankAccountOptions={bankAccountOptions}
        state={state}
        isEdit={isEdit}
        {...hooks}
      />
    </>
  );
};

ChargesFirst.propTypes = {
  data: PropTypes.object,
  isPremiumUser: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
  bankAccountOptions: PropTypes.array.isRequired,
  onNext: PropTypes.func,
};

export default ChargesFirst;
