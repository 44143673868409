import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import colors from '../../../../../../constants/colors';
import ChevronRight from '../../../../../../icons/streamline/ChevronRight';
import Edit from '../../../../../../icons/Edit';

import styles from './QuickReplyTemplatesModal.module.scss';

const handleTemplateLiterals = (string) =>
  string ? string.replaceAll('{{', '[').replaceAll('}}', ']') : string;

const TemplateCard = ({ template, onChoose, onEdit }) => {
  const { message_body: messageBody, template_name: templateName } =
    template || {};
  const textRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      const lineHeight = parseFloat(
        window.getComputedStyle(textRef.current).lineHeight,
      );
      const maxHeight = lineHeight * 3;
      setIsOverflowing(textRef.current.scrollHeight > maxHeight);
    }
  }, [messageBody]);

  const handleClick = () => {
    onChoose(template);
  };

  return (
    <div className={styles.card}>
      <div className={styles.card__content}>
        <div className={styles.card__header}>
          <span>{templateName}</span>
          <div className={styles.card__icon} onClick={() => onEdit(template)}>
            <Edit width={20} height={20} color={colors.black} />
          </div>
        </div>
        <p
          ref={textRef}
          className={`${styles.card__text} ${
            isOverflowing ? styles.hasGradient : ''
          }`}
        >
          {handleTemplateLiterals(messageBody)}
        </p>
      </div>
      <div className={styles.card__button} onClick={handleClick}>
        USE THIS REPLY
        <ChevronRight width={24} height={24} color={colors.primary} />
      </div>
    </div>
  );
};

TemplateCard.propTypes = {
  template: PropTypes.object,
  onChoose: PropTypes.func,
  onEdit: PropTypes.func,
};

export default TemplateCard;
