import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import CONDITION_REPORT_STATUS from '../../../../constants/condition_reports/status';
import ConditionReportingEmptyState from '../../leases/view/Tabs/ConditionReporting/ConditionReportingEmptyState/ConditionReportingEmptyState';
import ConditionReportStatusTag from '../ConditionReportStatusTag';

import styles from './ConditionReportsTableMobile.module.scss';

const ConditionReportsTableMobile = ({
  className,
  conditionReports,
  onRowClick,
  createNewReportHandler,
  hideTitle,
}) => {
  return (
    <div className={cx(styles.containerMobile, className)}>
      {!hideTitle || conditionReports?.length ? (
        <div className={styles.header}>
          {!hideTitle && <div className={styles.title}>Condition Reports</div>}
          {conditionReports?.length ? (
            <Button
              className={styles.createNewReport}
              onClick={() =>
                createNewReportHandler(conditionReports[0]?.lease_id, true)
              }
            >
              Create New
            </Button>
          ) : null}
        </div>
      ) : null}
      {!conditionReports?.length ? (
        <Card className={styles.getStartedSection}>
          <ConditionReportingEmptyState
            onClick={() =>
              createNewReportHandler(conditionReports[0]?.lease_id)
            }
          />
        </Card>
      ) : (
        <div className={styles.table}>
          {conditionReports?.map((report) => {
            const assignedTo = report.assigned_to
              ?.map((assignee) =>
                assignee.user_role === 'OWNER'
                  ? 'You'
                  : `${assignee.first_name} ${assignee.last_name}`,
              )
              ?.join(', ');

            const isSelfAssigned =
              report.assigned_to?.[0]?.user_role === 'OWNER';

            return (
              <div
                key={report.id}
                onClick={() =>
                  onRowClick(
                    report.lease_id,
                    report.id,
                    report.status,
                    isSelfAssigned,
                  )
                }
                className={styles.tableRow}
              >
                <div className={styles.rowData}>
                  <div className={styles.dueDate}>
                    {report.due_date
                      ? moment(report.due_date).format('MMM D, YYYY')
                      : 'N/A'}
                  </div>
                  <div className={styles.reportTitle}>{report.name}</div>
                  {assignedTo && (
                    <div className={styles.assignedTo}>
                      Assigned to {assignedTo}
                    </div>
                  )}
                </div>
                <div className={styles.rowAction}>
                  <ConditionReportStatusTag
                    status={report.status ?? CONDITION_REPORT_STATUS.UPLOADED}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

ConditionReportsTableMobile.propTypes = {
  className: PropTypes.string,
  conditionReports: PropTypes.arrayOf(PropTypes.object),
  onRowClick: PropTypes.func,
  createNewReportHandler: PropTypes.func,
  hideTitle: PropTypes.bool,
};

export default ConditionReportsTableMobile;
