import React, { useCallback, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { useMutation } from '@apollo/client';
import get from 'lodash/get';
import propTypes from 'prop-types';

import SUPPORTED_FILE_TYPES from '../../../constants/verification_documents/supported-file-types';
import { useUserProfile } from '../../../core/TTgraphql';
import createMaintenanceNoteMutation from '../../../graphql/mutations/createMaintenanceNote.graphql';
import maintenanceRequestQuery from '../../../graphql/queries/maintenanceRequestQuery.graphql';
import {
  getMaintenanceRequestAttachmentUploadURL,
  uploadFileToS3,
} from '../../../services/cloudFilesService';
import fieldRequired from '../../../validators/fieldRequired';
import Button from '../../Button';
import Checkbox from '../../Checkbox';
import Input from '../../Input';
import Modal from '../../Modal';
import PhotoUploadPreview from '../../PhotoUploadPreview';
import { useErrorToast, useInfoToast } from '../../Toast';
import UnsavedChangesModal from '../../UnsavedChangesModal';
import UploadDropZone from '../../UploadDropZone';

import styles from './AddNoteModal.module.scss';

const noteTypeOptions = [
  { label: 'Private', value: 'PRIVATE' },
  { label: 'Shared with Tenants', value: 'SHARED' },
];

const AddNoteModal = ({
  onClose,
  listingId,
  maintenanceRequestId,
  sentToPartner,
}) => {
  const { user } = useUserProfile();

  const isLulaExperimentActive = get(
    user,
    'maintenance_plus_active_experiment',
    false,
  );
  const maintenancePlusSettings =
    user?.maintenance_request_management_settings || false;
  const isEnrolledFreeLula =
    isLulaExperimentActive && !!maintenancePlusSettings;

  const isMaintenancePlus = user?.maintenance_plus_subscription_subscribed;
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [createMaintenanceNote] = useMutation(createMaintenanceNoteMutation, {
    refetchQueries: [
      {
        query: maintenanceRequestQuery,
        variables: {
          id: maintenanceRequestId,
        },
      },
    ],
    awaitRefetchQueries: true,
  });
  const [uploading, setUploading] = useState(false);
  const shouldShowUnsavedChangesModal = useRef(false);
  const showInfoToast = useInfoToast();
  const showErrorToast = useErrorToast();

  const showLulaOption =
    (isMaintenancePlus || isEnrolledFreeLula) && sentToPartner;

  async function handleSubmit({ note_type, ...formData }) {
    const submitData = {
      ...formData,
      note_type: formData.shared_tenants ? 'SHARED' : note_type,
      maintenance_request_id: maintenanceRequestId,
      attachments: (formData.attachments || []).map((a) => ({
        id: a.id,
      })),
    };

    try {
      await createMaintenanceNote({
        variables: { ...submitData },
      });
      onClose();
      showInfoToast('Note saved successfully');
    } catch (e) {
      console.log('Error: ', e);
      showErrorToast(
        'An error occured while saving the note. Please try again.',
      );
    }
  }

  function handleClose() {
    shouldShowUnsavedChangesModal.current
      ? setShowUnsavedChangesModal(true)
      : onClose();
  }

  const uploadFiles = useCallback(
    async (needUpload) => {
      setUploading(true);
      const files = await Promise.all(
        needUpload.map(async (file) => {
          const urlResponse = await getMaintenanceRequestAttachmentUploadURL({
            filename: file.path,
            listing_id: listingId,
          });
          const s3Params = get(urlResponse, 'attachments[0].s3Params');
          await uploadFileToS3(s3Params.url, file);
          return s3Params;
        }),
      );
      setUploading(false);
      return files;
    },
    [setUploading, listingId],
  );

  return (
    <Modal
      className={styles.container}
      open
      showCloseIcon
      onClose={handleClose}
      disableOutsideClick
    >
      {showUnsavedChangesModal && (
        <UnsavedChangesModal
          onConfirm={onClose}
          onCancel={() => setShowUnsavedChangesModal(false)}
        />
      )}
      <Form
        onSubmit={handleSubmit}
        subscription={{
          submitting: true,
          values: true,
          pristine: true,
        }}
        initialValues={{
          note_type: noteTypeOptions[0].value,
        }}
      >
        {({ handleSubmit, submitting, pristine, values, form }) => {
          shouldShowUnsavedChangesModal.current = !pristine;

          return (
            <form onSubmit={handleSubmit}>
              <div className={styles.content}>
                <h3 className={styles.title}>Notes & Attachments</h3>
                <p className={styles.description}>
                  Write a note or add attachments to stay organized and keep
                  accurate records.
                </p>
                <Field
                  component={Input}
                  as="textarea"
                  id="note"
                  name="note"
                  inputProps={{
                    className: styles.textArea,
                  }}
                  validate={fieldRequired}
                  maxLength={1000}
                  help={(val = '') => `${val.length} / 1000 characters used`}
                />
                <div className={styles.uploader}>
                  <label>
                    Attachments <span>(Optional)</span>
                  </label>
                  <Field component={PhotoUploadPreview} name="attachments" />
                  <OnChange name="attachments">
                    {() => {
                      form.change('shared_tenants', false);
                      form.change('shared_lula', false);
                    }}
                  </OnChange>
                  <Field
                    className={styles.dropZone}
                    component={UploadDropZone}
                    uploadFiles={uploadFiles}
                    accept={SUPPORTED_FILE_TYPES.join(',')}
                    name="attachments"
                    id="attachments"
                  />
                </div>

                <div className={styles.sharingOptions}>
                  <div className={styles.label}>Sharing Options</div>
                  <p className={styles.description}>
                    Notes & attachments are <b>private</b> by default unless
                    shared.
                  </p>
                  <Field
                    label="Share with tenants"
                    component={Checkbox}
                    type="checkbox"
                    id="shared_tenants"
                    name="shared_tenants"
                    className={styles.checkbox}
                  />
                  {showLulaOption && (
                    <Field
                      label="Share with Lula"
                      component={Checkbox}
                      type="checkbox"
                      id="shared_lula"
                      name="shared_lula"
                      className={styles.checkbox}
                    />
                  )}
                </div>
                <Button
                  className={styles.addNoteButton}
                  disabled={submitting || uploading}
                  loading={submitting}
                  type="submit"
                >
                  {values.shared_tenants || values.shared_lula
                    ? 'Add & Share'
                    : 'Add'}
                </Button>
              </div>
            </form>
          );
        }}
      </Form>
    </Modal>
  );
};

AddNoteModal.propTypes = {
  onClose: propTypes.func.isRequired,
  listingId: propTypes.string.isRequired,
  maintenanceRequestId: propTypes.string.isRequired,
  sentToPartner: propTypes.bool,
};

export default AddNoteModal;
