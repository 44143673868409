import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  InfiniteLoader,
  List,
  WindowScroller,
} from 'react-virtualized';
import propTypes from 'prop-types';

import styles from './InfiniteList.module.scss';

const useCellMeasurerCache = (defaultHeight) => {
  const cache = useMemo(
    () =>
      new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight,
      }),
    [],
  );

  /**
   * @NOTICE
   *
   * The code below is important.
   *
   * Since the UI is responsive, when the window resizes the rows adapt and change height
   * because the content is re-distributed. So rows need to be re-measured so they are placed
   * properly in the list.
   */
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useLayoutEffect(() => {
    const updateSize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    cache.clearAll(); // clear cache to re-measure all rows
  }, [windowWidth]);

  return cache;
};

const InfiniteList = ({
  data,
  onNeedMoreRows,
  rowStyle,
  rowRenderer: Row,
  rowCount,
  defaultRowHeight,
  overscanRowCount = 0,
}) => {
  const cache = useCellMeasurerCache(defaultRowHeight);

  const rowRenderer = useCallback(
    ({
      index,
      key,
      parent,
      style, // NOTICE: This is important for positioning of the rows
    }) => {
      return (
        <CellMeasurer
          cache={cache}
          columnIndex={0}
          key={key}
          rowIndex={index}
          parent={parent}
        >
          {({ measure, registerChild }) => (
            <div
              ref={registerChild}
              style={{
                ...style,
                ...rowStyle,
              }}
            >
              <Row item={data[index]} onExpand={measure} />
            </div>
          )}
        </CellMeasurer>
      );
    },
    [data, rowStyle, cache],
  );

  return (
    <InfiniteLoader
      isRowLoaded={({ index }) => Boolean(data?.[index])}
      loadMoreRows={onNeedMoreRows}
      rowCount={rowCount || data.length}
      threshold={3}
    >
      {({ onRowsRendered, registerChild }) => (
        <WindowScroller>
          {({ height, onChildScroll, scrollTop }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <List
                  autoHeight
                  ref={registerChild}
                  deferredMeasurementCache={cache}
                  width={width}
                  height={height}
                  onRowsRendered={onRowsRendered}
                  rowCount={data.length}
                  rowHeight={cache?.rowHeight}
                  rowRenderer={rowRenderer}
                  scrollTop={scrollTop}
                  onScroll={onChildScroll}
                  overscanRowCount={overscanRowCount}
                  className={styles.list}
                />
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      )}
    </InfiniteLoader>
  );
};

InfiniteList.propTypes = {
  data: propTypes.array.isRequired,
  onNeedMoreRows: propTypes.func.isRequired,
  rowStyle: propTypes.object,
  rowRenderer: propTypes.func.isRequired,
  rowCount: propTypes.number.isRequired,
  defaultRowHeight: propTypes.number,
  overscanRowCount: propTypes.number,
};

export default InfiniteList;
