import React from 'react';

const SheetColored = (props) => (
  <svg
    {...props}
    viewBox="0 0 152 152"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.06"
      d="M6 120.001C6 75.7166 36.1932 5 75.7643 5C115.335 5 146 38.2621 146 82.5467C146 126.831 113.034 128.769 80.9495 135.827C51.7332 142.254 6 164.286 6 120.001Z"
      fill="#2370A3"
    />
    <path
      d="M97.0938 26.6155L37.0965 44.2281C35.9386 44.568 35.2755 45.7822 35.6155 46.9401L58.9598 126.462C59.2997 127.62 60.5139 128.283 61.6718 127.944L121.669 110.331C122.827 109.991 123.49 108.777 123.15 107.619L99.8058 28.0965C99.4659 26.9387 98.2517 26.2756 97.0938 26.6155Z"
      fill="white"
      stroke="#033A6D"
      strokeWidth="1.72"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M112.422 31.5477H49.893C48.6863 31.5477 47.708 32.526 47.708 33.7327V116.611C47.708 117.817 48.6863 118.796 49.893 118.796H112.422C113.629 118.796 114.607 117.817 114.607 116.611V33.7327C114.607 32.526 113.629 31.5477 112.422 31.5477Z"
      fill="white"
      stroke="#033A6D"
      strokeWidth="1.72"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M103.682 40.2688H58.6142C57.4075 40.2688 56.4292 41.2471 56.4292 42.4538V46.8048C56.4292 48.0115 57.4075 48.9898 58.6142 48.9898H103.682C104.889 48.9898 105.867 48.0115 105.867 46.8048V42.4538C105.867 41.2471 104.889 40.2688 103.682 40.2688Z"
      fill="#7FE3FF"
      stroke="#033A6D"
      strokeWidth="1.72"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102 55.7581C105.681 55.7581 108.667 58.7428 108.667 62.4248C108.667 66.1067 105.681 69.0914 102 69.0914C98.3173 69.0914 95.3333 66.1072 95.3333 62.4248C95.3333 58.7423 98.3173 55.7581 102 55.7581ZM105.471 59.9533C105.211 59.693 104.789 59.693 104.529 59.9533L101 63.4814L99.4713 61.9533L99.4085 61.8979C99.147 61.6945 98.7688 61.713 98.5285 61.9533C98.2682 62.2137 98.2682 62.6358 98.5285 62.8962L100.411 64.7783L100.478 64.8391C100.806 65.1023 101.285 65.082 101.589 64.7783L105.471 60.8962L105.527 60.8334C105.73 60.5718 105.712 60.1937 105.471 59.9533Z"
      fill="#033A6D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102 95.7581C105.681 95.7581 108.667 98.7428 108.667 102.425C108.667 106.107 105.681 109.091 102 109.091C98.3173 109.091 95.3333 106.107 95.3333 102.425C95.3333 98.7423 98.3173 95.7581 102 95.7581ZM105.471 99.9533C105.211 99.693 104.789 99.693 104.529 99.9533L101 103.481L99.4713 101.953L99.4085 101.898C99.147 101.695 98.7688 101.713 98.5285 101.953C98.2682 102.214 98.2682 102.636 98.5285 102.896L100.411 104.778L100.478 104.839C100.806 105.102 101.285 105.082 101.589 104.778L105.471 100.896L105.527 100.833C105.73 100.572 105.712 100.194 105.471 99.9533Z"
      fill="#033A6D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102 75.7581C105.682 75.7581 108.667 78.7429 108.667 82.4248C108.667 86.1066 105.682 89.0914 102 89.0914C98.3181 89.0914 95.3333 86.1066 95.3333 82.4248C95.3333 78.7429 98.3181 75.7581 102 75.7581ZM102 85.0914C101.54 85.0914 101.167 85.4648 101.167 85.9248C101.167 86.3848 101.54 86.7581 102 86.7581C102.46 86.7581 102.833 86.3848 102.833 85.9248C102.833 85.4648 102.46 85.0914 102 85.0914ZM102 78.0914C101.632 78.0914 101.333 78.2958 101.333 78.5479V83.6349L101.339 83.6968C101.383 83.9197 101.662 84.0914 102 84.0914C102.368 84.0914 102.667 83.887 102.667 83.6349V78.5479L102.66 78.486C102.616 78.2632 102.337 78.0914 102 78.0914Z"
      fill="#033A6D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90 60.1447H58C57.8454 60.1447 57.72 60.2701 57.72 60.4247V63.4247C57.72 63.5794 57.8454 63.7047 58 63.7047H90C90.1546 63.7047 90.28 63.5794 90.28 63.4247V60.4247C90.28 60.2701 90.1546 60.1447 90 60.1447ZM58 58.4247C56.8954 58.4247 56 59.3202 56 60.4247V63.4247C56 64.5293 56.8954 65.4247 58 65.4247H90C91.1046 65.4247 92 64.5293 92 63.4247V60.4247C92 59.3202 91.1046 58.4247 90 58.4247H58Z"
      fill="#033A6D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90 100.145H58C57.8454 100.145 57.72 100.27 57.72 100.425V103.425C57.72 103.579 57.8454 103.705 58 103.705H90C90.1546 103.705 90.28 103.579 90.28 103.425V100.425C90.28 100.27 90.1546 100.145 90 100.145ZM58 98.4247C56.8954 98.4247 56 99.3202 56 100.425V103.425C56 104.529 56.8954 105.425 58 105.425H90C91.1046 105.425 92 104.529 92 103.425V100.425C92 99.3202 91.1046 98.4247 90 98.4247H58Z"
      fill="#033A6D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78 80.1447H58C57.8454 80.1447 57.72 80.2701 57.72 80.4247V83.4247C57.72 83.5794 57.8454 83.7047 58 83.7047H78C78.1546 83.7047 78.28 83.5794 78.28 83.4247V80.4247C78.28 80.2701 78.1546 80.1447 78 80.1447ZM58 78.4247C56.8954 78.4247 56 79.3202 56 80.4247V83.4247C56 84.5293 56.8954 85.4247 58 85.4247H78C79.1046 85.4247 80 84.5293 80 83.4247V80.4247C80 79.3202 79.1046 78.4247 78 78.4247H58Z"
      fill="#033A6D"
    />
  </svg>
);

SheetColored.defaultProps = {
  width: 152,
  height: 152,
};

export default SheetColored;
