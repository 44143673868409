import React from 'react';

import { H2, Paragraph } from '../../../../components/Typography';

const OfferInsurance = () => {
  return (
    <>
      <H2 align="center" className="mb-4">
        Protect yourself with Renters Insurance
      </H2>
      <Paragraph align="center" size="large" className="mb-24">
        Get a free quote from our partner, SURE. Upload proof if you already
        have renters insurance.
      </Paragraph>
    </>
  );
};

export default OfferInsurance;
