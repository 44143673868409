import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import LoadingScreen from '../../../components/LoadingScreen';
import Page from '../../../components/page';
import { H2 } from '../../../components/Typography';
import { useUserProfile } from '../../../core/TTgraphql';
import TabsLayout from '../../../layout/TabsLayout';
import { segmentTracking } from '../../../services/utilities';
import FormsRoutes from '../../forms/routes.forms';
import AllDocumentsTab from '../AllDocumentsTab';
import ConditionReportsTab from '../ConditionReportsTab';
import EsignTab from '../EsignTab';

import styles from './DocumentsPage.module.scss';

const DocumentsPage = ({ match }) => {
  const { user, loading } = useUserProfile();

  const getPageTabs = () => [
    {
      id: 'documents',
      label: 'Documents',
      url: {
        pathname: `${match.path}`,
        exact: true,
      },
      dataQa: 'documents-tab_documents',
      onClick: () => {
        segmentTracking('documents_tab clicked', {
          location: 'Docs Esign',
        });
      },
    },
    {
      id: 'e-sign',
      label: 'E-Sign',
      labelCount: user?.esign_count?.active || null,
      url: {
        pathname: `${match.path}/e-sign`,
      },
      dataQa: 'documents-tab_esign',
      onClick: () => {
        segmentTracking('esign_tab clicked', {
          location: 'Docs Esign',
        });
      },
    },
    {
      id: 'condition-reports',
      label: 'Condition Reports',
      url: {
        pathname: `${match.path}/condition-reports`,
      },
      dataQa: 'documents-tab_condition_reports',
      onClick: () => {
        segmentTracking('condition_reports_tab clicked', {
          location: 'Docs Esign',
        });
      },
    },
    {
      id: 'forms',
      label: 'Forms',
      url: {
        pathname: `${match.path}/forms`,
      },
      dataQa: 'documents-tab_forms',
      onClick: () => {
        segmentTracking('forms_tab clicked', {
          location: 'Docs Esign',
        });
      },
    },
  ];

  return (
    <LoadingScreen loading={loading} className={styles.loadingScreen}>
      <Page title="Documents - TurboTenant">
        <TabsLayout
          tabs={getPageTabs()}
          className={styles.container}
          headerWrapperClassName={styles.headerWrapper}
          headerContent={<H2 className={styles.title}>Documents & E-sign</H2>}
          headerClassName={styles.header}
          contentClassName={styles.content}
        >
          <Switch>
            <Route exact from={match.path} component={AllDocumentsTab} />
            <Route path={`${match.path}/e-sign`} component={EsignTab} />
            <Route
              path={`${match.path}/condition-reports`}
              component={ConditionReportsTab}
            />
            <Route path={`${match.path}/forms`} component={FormsRoutes} />
          </Switch>
        </TabsLayout>
      </Page>
    </LoadingScreen>
  );
};

DocumentsPage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};

export default DocumentsPage;
