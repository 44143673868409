import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Form = (props) => (
  <svg {...props} viewBox="0 0 40 36" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34.167 0c.46 0 .833.366.833.818v20.454h4.167c.46 0 .833.367.833.819v13.09a.826.826 0 01-.833.819H.833A.826.826 0 010 35.182V22.09c0-.452.373-.818.833-.818L5 21.272V4.091c0-.417.318-.761.729-.812l.104-.006L15 3.272V.818c0-.417.318-.761.729-.812L15.833 0zm-22.5 22.91h-10v11.454h36.666V22.909h-10v2.455a.826.826 0 01-.833.818h-15a.826.826 0 01-.833-.818v-2.455zM15 4.908H6.667l-.001 16.363H12.5c.46 0 .833.367.833.819v2.454H15V4.909zm18.333-3.273H16.667l-.001 22.909h10v-2.454c0-.452.374-.818.834-.818l5.833-.001V1.636zM30.833 18c.46 0 .834.366.834.818a.826.826 0 01-.834.818H19.167a.826.826 0 01-.834-.818c0-.452.373-.818.834-.818zm0-3.273c.46 0 .834.367.834.818a.826.826 0 01-.834.819H19.167a.826.826 0 01-.834-.819c0-.451.373-.818.834-.818zm0-3.272c.46 0 .834.366.834.818a.826.826 0 01-.834.818H19.167a.826.826 0 01-.834-.818c0-.452.373-.818.834-.818zm0-3.273c.46 0 .834.366.834.818a.826.826 0 01-.834.818H19.167A.826.826 0 0118.333 9c0-.452.373-.818.834-.818zm0-3.273c.46 0 .834.366.834.818a.826.826 0 01-.834.818H22.5a.826.826 0 01-.833-.818c0-.452.373-.818.833-.818z"
      fill="#042238"
      fillRule="evenodd"
    />
  </svg>
);

Form.defaultProps = {
  width: 40,
  height: 36,
  color: colors.text,
};

Form.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Form);
