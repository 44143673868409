import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import colors from '../../../../constants/colors';
import WizardSectionsEnum from '../../../../constants/enums/WizardSectionsEnum';
import DocumentPreview from '../../../../icons/streamline/DocumentPreview';

import LA_LEASE_SPECIFICS_IMAGE from './lease-specifics-step-la-preview.png';
import LA_SUMMARY_IMAGE from './overview-page-la-preview.png';
import PEOPLE_ON_LEASE_IMAGE from './people-on-lease-step-la-preview.png';
import PETS_SMOKING_IMAGE from './pets-smoking-step-la-preview.png';
import PROVISIONS_IMAGE from './provisions-step-la-preview.png';
import RENT_DEPOSIT_FEES_IMAGE from './rent-deposits-step-la-preview.png';
import UTILITIES_IMAGE from './utilities-step-la-preview.png';

import styles from './PreviewCardLA.module.scss';

const imageMap = {
  [WizardSectionsEnum.LEASE_SPECIFICS]: LA_LEASE_SPECIFICS_IMAGE,
  [WizardSectionsEnum.RENT_DEPOSIT_FEE]: RENT_DEPOSIT_FEES_IMAGE,
  [WizardSectionsEnum.PEOPLE]: PEOPLE_ON_LEASE_IMAGE,
  [WizardSectionsEnum.PETS_SMOKING_INSURANCE]: PETS_SMOKING_IMAGE,
  [WizardSectionsEnum.UTILITIES]: UTILITIES_IMAGE,
  [WizardSectionsEnum.PROVISIONS]: PROVISIONS_IMAGE,
  'overview-page': LA_SUMMARY_IMAGE,
};

const PreviewCardLA = ({
  className,
  sectionName = 'overview-page',
  onPreviewClick,
}) => {
  const [currentImage, setCurrentImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Preload all images
    Object.values(imageMap).forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const image = imageMap[sectionName] || LA_SUMMARY_IMAGE;
    const img = new Image();
    img.onload = () => {
      setCurrentImage(image);
      setIsLoading(false);
    };
    img.src = image;
  }, [sectionName]);

  const altText = `Preview of ${sectionName.split('-')}`;

  return (
    <Card className={cx(styles.container, className)}>
      <div className={styles.imgWrapper}>
        {currentImage && (
          <img
            className={cx(styles.img, {
              [styles.imgHidden]: isLoading,
              [styles.imgVisible]: !isLoading,
            })}
            src={currentImage}
            alt={altText}
          />
        )}
      </div>
      <Button
        className={styles.btn}
        variant="ghost"
        icon={DocumentPreview}
        iconProps={{ color: colors.primary }}
        onClick={onPreviewClick}
        type="submit"
      >
        Preview My Lease
      </Button>
    </Card>
  );
};

PreviewCardLA.propTypes = {
  className: PropTypes.string,
  sectionName: PropTypes.string,
  onPreviewClick: PropTypes.func,
};

export default PreviewCardLA;
