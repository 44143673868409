import { v4 as uuidv4 } from 'uuid';

import {
  mapInitialLeaseToState,
  mapInitialListingToState,
  mapInitialMonthlyCharges,
  mapInitialOneTimeCharges,
} from './mappers/initialData';

export const INIT_ACTION = 'INIT';
export const LISTING_UPDATED = 'LISTING_UPDATED';
export const ADD_MONTHLY_CHARGE = 'ADD_MONTHLY_CHARGE';
export const EDIT_MONTHLY_CHARGE = 'EDIT_MONTHLY_CHARGE';
export const ADD_ONE_TIME_CHARGE = 'ADD_ONE_TIME_CHARGE';
export const REMOVE_MONTHLY_CHARGE = 'REMOVE_MONTHLY_CHARGE';
export const MANAGE_LATE_FEE_POLICIES = 'MANAGE_LATE_FEE_POLICIES';
export const EDIT_ONE_TIME_CHARGE = 'EDIT_ONE_TIME_CHARGE';
export const REMOVE_ONE_TIME_CHARGE = 'REMOVE_ONE_TIME_CHARGE';
export const SAVING = 'SAVING';
export const SAVE = 'SAVE';
export const SAVED = 'SAVED';

export const initData = (data) => {
  return {
    dirty: false,
    listingHasErrors: !data?.listing?.id,
    listing: mapInitialListingToState(data?.listing),
    lease: mapInitialLeaseToState(data?.lease),
    monthlyCharges: mapInitialMonthlyCharges(data?.monthly_charges),
    oneTimeCharges: mapInitialOneTimeCharges(data?.one_time_charges),
  };
};

export const init = (data = {}) => ({
  type: INIT_ACTION,
  data: data || {},
});

export const updateListing = (listing, hasErrors) => ({
  type: LISTING_UPDATED,
  data: { listing, hasErrors },
});

export const addMonthlyCharge = (charge) => ({
  type: ADD_MONTHLY_CHARGE,
  data: { id: uuidv4(), type: 'MONTHLY', ...charge },
});

export const addOneTimeCharge = (charge) => ({
  type: ADD_ONE_TIME_CHARGE,
  data: { id: uuidv4(), type: 'ONE_TIME', ...charge },
});

export const removeMonthlyCharge = (id) => ({
  type: REMOVE_MONTHLY_CHARGE,
  data: id,
});

export const removeOneTimeCharge = (id) => ({
  type: REMOVE_ONE_TIME_CHARGE,
  data: id,
});

export const editMonthlyCharge = (charge) => ({
  type: EDIT_MONTHLY_CHARGE,
  data: charge,
});

export const editOneTimeCharge = (charge) => ({
  type: EDIT_ONE_TIME_CHARGE,
  data: charge,
});

export const manageLateFeePolicies = (id, policies) => ({
  type: MANAGE_LATE_FEE_POLICIES,
  data: { id, policies },
});

export const saving = () => ({
  type: SAVING,
});

export const saved = () => ({
  type: SAVED,
});

export const saveData = (response) => ({
  type: SAVE,
  data: response?.data?.upsertListingLeasePaymentRequestRules || {},
});

const chargesFirstReducer = (state, action) => {
  switch (action.type) {
    case INIT_ACTION:
      return {
        dirty: false,
        submitted: false,
        listingHasErrors: true,
        saving: false,
        listing: {},
        monthlyCharges: [],
        oneTimeCharges: [],
        ...action.data,
      };

    case LISTING_UPDATED:
      return {
        ...state,
        dirty: true,
        submitted: false,
        listing: { ...state.listing, ...action.data.listing },
        listingHasErrors: action.data.hasErrors,
      };
    case ADD_MONTHLY_CHARGE:
      return {
        ...state,
        dirty: true,
        submitted: false,
        monthlyCharges: [...state.monthlyCharges, action.data],
      };
    case ADD_ONE_TIME_CHARGE:
      return {
        ...state,
        dirty: true,
        submitted: false,
        oneTimeCharges: [...state.oneTimeCharges, action.data],
      };
    case REMOVE_MONTHLY_CHARGE:
      return {
        ...state,
        dirty: true,
        submitted: false,
        monthlyCharges: state.monthlyCharges.filter(
          (charge) => charge.id !== action.data,
        ),
      };
    case REMOVE_ONE_TIME_CHARGE:
      return {
        ...state,
        dirty: true,
        submitted: false,
        oneTimeCharges: state.oneTimeCharges.filter(
          (charge) => charge.id !== action.data,
        ),
      };
    case EDIT_MONTHLY_CHARGE:
      return {
        ...state,
        dirty: true,
        submitted: false,
        monthlyCharges: state.monthlyCharges.map((charge) =>
          charge.id === action.data.id ? action.data : charge,
        ),
      };
    case EDIT_ONE_TIME_CHARGE:
      return {
        ...state,
        dirty: true,
        submitted: false,
        oneTimeCharges: state.oneTimeCharges.map((charge) =>
          charge.id === action.data.id ? action.data : charge,
        ),
      };
    case MANAGE_LATE_FEE_POLICIES: {
      return {
        ...state,
        dirty: true,
        submitted: false,
        monthlyCharges: state.monthlyCharges.map((charge) => {
          if (charge.id === action.data.id) {
            return { ...charge, late_fee_policy: action.data.policies };
          }
          return charge;
        }),
      };
    }
    case SAVE:
      return {
        ...state,
        saving: false,
        dirty: false,
        submitted: true,
      };
    case SAVING: {
      return { ...state, dirty: false, submitted: true, saving: true };
    }
    case SAVED: {
      return {
        ...state,
        listingHasErrors: false,
        dirty: false,
        submitted: true,
        saving: false,
      };
    }
    default:
      return state;
  }
};

export default chargesFirstReducer;
