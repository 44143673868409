import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import getFieldError from '../../validators/get-field-error';
import FlatButton from '../FlatButton';
import Label from '../Label';
import PasswordStrengthChecker from '../PasswordInput/PasswordStrengthChecker';
import PlainInput from '../PlainInput';

import styles from './PasswordInput.module.scss';

const PasswordInput = ({
  input,
  inputProps,
  label,
  labelProps,
  className,
  inputContainerClassName,
  id,
  meta,
  disabled,
  style,
  hint,
  hintProps,
  showPasswordStrengthChecker,
  passwordStrengthClassName,
  ...props
}) => {
  const error = getFieldError(meta);
  const invalid = !!error;
  const [show, setShow] = useState(false);
  const [displayStrengthChecker, setDisplayStrengthChecker] = useState(false);
  const inputType = show ? 'text' : 'password';
  const toggleButtonLabel = show ? 'Hide' : 'Show';

  const handleFocus = () => {
    showPasswordStrengthChecker && setDisplayStrengthChecker(true);
    if (input.onFocus) {
      input.onFocus();
    }
  };

  const renderHintOrError = () => {
    if (!showPasswordStrengthChecker || !input.value) {
      if (!invalid) {
        return (
          <span className={styles.hint} {...hintProps}>
            {hint}
          </span>
        );
      }
      return (
        <span id={`${id}_errormsg`} className={styles.error}>
          {error}
        </span>
      );
    }
    return null;
  };

  return (
    <div className={cx(styles.container, className)} style={style}>
      {label && (
        <Label htmlFor={id} {...labelProps}>
          {label}
        </Label>
      )}
      <div className={cx(styles.inputContainer, inputContainerClassName)}>
        <PlainInput
          type={inputType}
          id={id}
          disabled={disabled}
          invalid={invalid}
          {...input}
          {...inputProps}
          {...props}
          className={styles.input}
          onFocus={handleFocus}
        />
        <FlatButton
          type="button"
          onClick={() => setShow(!show)}
          className={styles.toggleButton}
        >
          {toggleButtonLabel}
        </FlatButton>
      </div>
      {displayStrengthChecker && (
        <PasswordStrengthChecker
          password={input.value}
          error={invalid}
          className={passwordStrengthClassName}
        />
      )}
      {renderHintOrError()}
    </div>
  );
};

PasswordInput.propTypes = {
  input: PropTypes.object,
  inputProps: PropTypes.object,
  label: PropTypes.string,
  labelProps: PropTypes.object,
  className: PropTypes.string,
  inputContainerClassName: PropTypes.string,
  id: PropTypes.string,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
  hint: PropTypes.string,
  hintProps: PropTypes.object,
  showPasswordStrengthChecker: PropTypes.bool,
  style: PropTypes.object,
  passwordStrengthClassName: PropTypes.string,
};

PasswordInput.defaultProps = {
  label: 'Password',
};

export default PasswordInput;
