import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const CustomQuestionsBadge = memo(
  ({ color = colors.orangeLight, ...props }) => (
    <svg {...props} width="40" height="40">
      <g fill="none" fillRule="evenodd">
        <circle cx="20" cy="20" r="20" />
        <path fill="none" d="M5.455 5.455h29.09v29.09H5.456z" />
        <path
          fill={color}
          d="M23.94 29.09c4.518 0 8.181-4.874 8.181-9.393 0-4.519-3.663-9.394-8.182-9.394-4.518 0-8.182 4.875-8.182 9.394s3.664 9.394 8.182 9.394Z"
        />
        <path
          fill="#042238"
          d="M20 7.879c6.694 0 12.121 5.427 12.121 12.12 0 6.695-5.427 12.122-12.121 12.122-6.694 0-12.121-5.427-12.121-12.121 0-6.694 5.427-12.121 12.12-12.121Zm0 1.054C13.888 8.933 8.933 13.888 8.933 20S13.888 31.067 20 31.067 31.067 26.112 31.067 20 26.112 8.933 20 8.933Zm0 16.337a1.054 1.054 0 1 1 0 2.108 1.054 1.054 0 0 1 0-2.108Zm0-11.594a3.69 3.69 0 0 1 .598 7.33l-.071.01v2.673a.527.527 0 0 1-.432.518l-.095.009a.527.527 0 0 1-.519-.432l-.008-.095v-3.162c0-.291.236-.527.527-.527a2.635 2.635 0 1 0-2.635-2.635.527.527 0 0 1-1.054 0A3.69 3.69 0 0 1 20 13.675Z"
        />
      </g>
    </svg>
  ),
);

CustomQuestionsBadge.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  colorFill: PropTypes.string,
};

export default CustomQuestionsBadge;
