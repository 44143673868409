import moment from 'moment';

function calculateProratedAmountInCents(
  startDateTime,
  endDateTime,
  yearlyPrice,
) {
  const startDate = moment.utc(startDateTime);
  const endDate = moment.utc(endDateTime);

  const oneYearFromStart = startDate.clone().add(1, 'years');
  const secondsInSubscriptionYear = oneYearFromStart.diff(startDate, 'seconds');

  // Calculate the rate per second in cents
  const ratePerSecondInCents = (yearlyPrice * 100) / secondsInSubscriptionYear;

  // Calculate the number of seconds between the start and end date-times
  const secondsDiff = endDate.diff(startDate, 'seconds');

  // Calculate the prorated amount in cents
  const totalCents = Math.round(ratePerSecondInCents * secondsDiff);

  return totalCents;
}

export default calculateProratedAmountInCents;
