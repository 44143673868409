import React, { memo } from 'react';

import colors from '../constants/colors';

const Search = (props) => (
  <svg {...props} viewBox="0 0 18 18">
    <path
      d="M6.429 0a6.428 6.428 0 014.918 10.568l6.492 6.491a.551.551 0 01-.78.78l-6.49-6.492A6.429 6.429 0 116.428 0zm0 1.102a5.326 5.326 0 100 10.653 5.326 5.326 0 000-10.653z"
      fillRule="evenodd"
    />
  </svg>
);

Search.defaultProps = {
  width: 18,
  height: 18,
  fill: colors.text,
};

export default memo(Search);
