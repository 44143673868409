const E_SIGN_STATUSES = {
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  NONE: 'NONE',
  DRAFT: 'DRAFT',
};

export const { IN_PROGRESS, COMPLETED, NONE, DRAFT } = E_SIGN_STATUSES;

export default E_SIGN_STATUSES;
