import React from 'react';
import { Icon } from '@tt/ui';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './VerticalListWithIcons.module.scss';

const VerticalListWithIcons = ({
  className,
  iconWrapperClassName,
  items,
  itemClassName,
}) => {
  const renderItem = (
    { icon: IconDeprecated, iconData, iconProps, content },
    key,
  ) => (
    <div className={cx(styles.item, itemClassName)} key={key}>
      <div className={cx(styles.iconWrapper, iconWrapperClassName)}>
        {iconData?.name ? (
          <Icon
            size={iconData.size ?? 'lg'}
            color={iconData.color ?? 'close-darkbg'}
            name={iconData.name}
            {...iconProps}
          />
        ) : (
          IconDeprecated && (
            <IconDeprecated className={styles.icon} {...iconProps} />
          )
        )}
      </div>
      <div className={styles.content}>{content}</div>
    </div>
  );

  return (
    <div className={cx(styles.container, className)}>
      {items.map(renderItem)}
    </div>
  );
};

VerticalListWithIcons.propTypes = {
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  iconWrapperClassName: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
      iconProps: PropTypes.object,
      content: PropTypes.node,
    }),
  ).isRequired,
};

export default VerticalListWithIcons;
