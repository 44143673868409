import React from 'react';

import StatusTag from '../components/Maintenance/MaintenanceTable/StatusTag';
import {
  COMPLETED,
  DRAFT,
  IN_PROGRESS,
} from '../constants/lease_document/esign-statuses';

const getDocumentStatus = (status) => {
  switch (status) {
    case DRAFT:
      return <StatusTag type="draft" />;
    case IN_PROGRESS:
      return <StatusTag type="in_progress" />;
    case COMPLETED:
      return <StatusTag type="complete" />;
    default:
      return '__';
  }
};

export default getDocumentStatus;
