import React from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import { useUserProfile } from '../../core/TTgraphql';
import { useConfig } from '../../providers/ConfigProvider';
import { SUBSCRIPTION_FEATURES } from '../PremiumSubscription/constants';

import styles from './ShowPremiumPriceForOffer.module.scss';

const ShowPremiumPriceForOffer = ({ isPublicFlow }) => {
  const { user } = useUserProfile({}, 0);
  const { DEFAULT_APPLICATION_FEE } = useConfig();

  const premiumSubscriptionSubscribed = get(
    user,
    'premium_subscription_subscribed',
    false,
  );
  const isIncomeInsightsEnabled = user?.packageFeatures?.includes(
    SUBSCRIPTION_FEATURES.INCOME_INSIGHTS,
  );
  const applicationFee = get(user, 'application_fee', '');
  const appFeePriceExpPremiumPrice = get(
    user,
    'app_fee_price_exp_premium_price',
  );

  if (!appFeePriceExpPremiumPrice || isPublicFlow) {
    return `$${applicationFee}`;
  } else if (!premiumSubscriptionSubscribed || !isIncomeInsightsEnabled) {
    if (parseInt(applicationFee) === parseInt(appFeePriceExpPremiumPrice)) {
      return <span>${applicationFee}</span>;
    }
    return (
      <>
        <span className={styles.withoutPremium}>${applicationFee}</span>{' '}
        <b className={styles.withPremium}>${appFeePriceExpPremiumPrice}</b>
      </>
    );
  }
  if (parseInt(DEFAULT_APPLICATION_FEE) === parseInt(applicationFee)) {
    return <span>${DEFAULT_APPLICATION_FEE}</span>;
  }
  return (
    <>
      <span className={styles.withoutPremium}>${DEFAULT_APPLICATION_FEE}</span>{' '}
      <b className={styles.withPremium}>${applicationFee}</b>
    </>
  );
};

ShowPremiumPriceForOffer.propTypes = {
  isPublicFlow: PropTypes.bool,
};

export default ShowPremiumPriceForOffer;
