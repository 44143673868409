import PropTypes from 'prop-types';

const paymentMethodPropTypes = PropTypes.shape({
  last4: PropTypes.string,
  type: PropTypes.oneOf([
    'BANK_ACCOUNT',
    'CREDIT_CARD',
    'PREPAID',
    'DEBIT_CARD',
    'OFFLINE',
    'LVBLE',
  ]),
  id: PropTypes.string,
  is_default: PropTypes.bool,
  used_for_rent_payments: PropTypes.bool,
  expiration: PropTypes.string,
  brand: PropTypes.string,
  source_status: PropTypes.oneOf([
    'new',
    'validated',
    'verified',
    'verification_failed',
    'errored',
    'login_expired',
  ]),
  source_creation: PropTypes.string,
  preferred_for_rent_payments: PropTypes.bool,
  preferred_for_auto_pay: PropTypes.bool,
});
export default paymentMethodPropTypes;
