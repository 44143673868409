import React, { memo } from 'react';
import PropTypes from 'prop-types';

const ArrowDown = ({ color, ...props }) => (
  <svg viewBox={`0 0 ${props.width} ${props.height}`} {...props}>
    <g stroke="none">
      <g
        id="MUP-B-Filter-2"
        transform="translate(-308.499993, -294.500007)"
        fill={color}
      >
        <g id="Properties" transform="translate(20.000000, 49.000000)">
          <g id="Bullet" transform="translate(16.000000, 236.000000)">
            <g
              id="Icons/Actions/Plus"
              transform="translate(268.000000, 2.000000)"
            >
              <path
                d="M15.7525767,5.34150909 C15.4168702,4.95784451 14.85274,4.89244474 14.4410277,5.17069923 L14.3414954,5.24743701 L7.34149539,11.247437 C6.91869157,11.6173903 6.8884913,12.2545702 7.25089457,12.6626988 L7.34149539,12.7525904 L14.3414954,18.7525904 C14.757132,19.1162724 15.3888946,19.0741549 15.7525767,18.6585183 C16.0882832,18.2748537 16.0782197,17.7070345 15.7477755,17.3359021 L15.6585046,17.247437 L9.519,12.0000137 L15.6585046,6.7525904 C16.0421692,6.41688389 16.107569,5.8527537 15.8293145,5.4410414 L15.7525767,5.34150909 Z"
                id="arrow-left"
                transform="translate(11.500007, 12.000014) rotate(-90.000000) translate(-11.500007, -12.000014) "
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

ArrowDown.defaultProps = {
  width: 15,
  height: 15,
  color: 'currentColor',
};

ArrowDown.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(ArrowDown);
