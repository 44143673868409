import moment from 'moment';

import isUuid from '../services/utilities/isUuid';

import convertCardinalToOrdinal from './cardinal-to-ordinal';
import { isLastDay } from './is-last-day';

const dateToMonthAndYearFormatted = (dateString) =>
  `${moment.utc(dateString).format('MMMM YYYY')}`;

export const getPaymentRequestRuleDueToNarrative = ({
  id,
  due_day_of_month: dueDayOfMonth,
  start_date: startDate,
  end_date: endDate,
  no_end_date: noEndDate,
}) => {
  if (startDate) {
    const dueDay = isLastDay(startDate)
      ? 'last day of the month'
      : `${convertCardinalToOrdinal(dueDayOfMonth)}`;
    let dueNarrative = `Due on the ${dueDay}
    from ${dateToMonthAndYearFormatted(startDate)}`;

    if (isUuid(id) && noEndDate) {
      dueNarrative += ` onwards`;
      return dueNarrative;
    }

    if (endDate) {
      dueNarrative += ` to ${dateToMonthAndYearFormatted(endDate)}`;
    } else dueNarrative += ` onwards`;
    return dueNarrative;
  }

  return `Due on ${moment.utc(endDate).format('MMMM DD, YYYY')}`;
};
