import { useCallback, useReducer } from 'react';
import { useMutation } from '@apollo/client';
import { v4 as uuid } from 'uuid';

import { useErrorToast } from '../../../../../components/Toast';
import upsertMutation from '../../../../../graphql/mutations/upsertListingLeasePaymentRequestRules.graphql';
import leasesQuery from '../../../../../graphql/queries/leasesQuery.graphql';
import paymentRequestRulesQuery from '../../../../../graphql/queries/payment_request_rules.graphql';
import { segmentTracking } from '../../../../../services/utilities';

import {
  monthlyChargesStateToCharges,
  oneTimeChargesStateToCharges,
  stateToLease,
  stateToListing,
} from './mappers/submission';
import chargesFirstReducer, {
  addMonthlyCharge,
  addOneTimeCharge,
  editMonthlyCharge,
  editOneTimeCharge,
  initData,
  removeMonthlyCharge,
  removeOneTimeCharge,
  saved,
  saveData,
  saving,
  updateListing,
} from './reducer';

const useChargesFirst = (data, onNext) => {
  const [mutate] = useMutation(upsertMutation);
  const showErrorMessage = useErrorToast();
  const [state, dispatch] = useReducer(chargesFirstReducer, initData(data));

  const onUpdateListing = useCallback((listing, hasErrors) => {
    dispatch(updateListing(listing, hasErrors));
  }, []);

  const onAddMonthlyCharge = useCallback(
    ({ amount, end_date, no_end_date, ...charge }) => {
      dispatch(
        addMonthlyCharge({
          id: uuid(),
          amount: Number(amount),
          end_date: no_end_date ? null : end_date,
          ...charge,
        }),
      );
    },
    [],
  );

  const onAddOneTimeCharge = useCallback(({ amount, ...charge }) => {
    dispatch(
      addOneTimeCharge({ id: uuid(), amount: Number(amount), ...charge }),
    );
  }, []);

  const onEditMonthlyCharge = useCallback(
    ({ end_date, no_end_date, ...charge }) => {
      dispatch(
        editMonthlyCharge({
          end_date: no_end_date ? null : end_date,
          ...charge,
        }),
      );
    },
    [],
  );

  const onRemoveMonthlyCharge = useCallback(({ id }) => {
    dispatch(removeMonthlyCharge(id));
  }, []);

  const onEditOneTimeCharge = useCallback((charge) => {
    dispatch(editOneTimeCharge(charge));
  }, []);

  const onRemoveOneTimeCharge = useCallback(({ id }) => {
    dispatch(removeOneTimeCharge(id));
  }, []);

  const onSave = useCallback(async () => {
    segmentTracking('property_and_charges_step submit', {
      location: 'rp onboarding property and charges step',
    });
    // when there's no change in form, but we do have lease id
    if (state.dirty === false && state.lease?.id) {
      onNext(state.lease?.id);
      return;
    }

    if (state.listingHasErrors) {
      showErrorMessage('Please populate property information!');
      return;
    }
    dispatch(saving());
    try {
      const res = await mutate({
        variables: {
          listing: stateToListing(state.listing),
          lease: stateToLease(state.lease),
          paymentRequestRules: [
            ...monthlyChargesStateToCharges(state.monthlyCharges),
            ...oneTimeChargesStateToCharges(state.oneTimeCharges),
          ],
        },
        refetchQueries: [
          { query: leasesQuery },
          {
            query: paymentRequestRulesQuery,
            variables: state.lease?.id ? { lease_id: state.lease?.id } : {},
          },
        ],
        awaitRefetchQueries: true,
      });
      dispatch(saveData(res));
      onNext(res.data?.upsertListingLeasePaymentRequestRules?.lease?.id);
    } catch {
      showErrorMessage('There was a problem!');
    } finally {
      dispatch(saved());
    }
  }, [state]);

  return {
    state,
    onUpdateListing,
    onAddMonthlyCharge,
    onEditMonthlyCharge,
    onRemoveMonthlyCharge,
    onAddOneTimeCharge,
    onEditOneTimeCharge,
    onRemoveOneTimeCharge,
    onSave,
  };
};

export default useChargesFirst;
