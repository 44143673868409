import { gtmDataLayerPush } from '../../../../services/utilities';

export default function signupGaTracking({ ownerId, email }) {
  const ownerIdDecoded = atob(ownerId).split(':').pop();
  gtmDataLayerPush({
    event: 'gtmEvent',
    eventCategory: 'owner',
    eventAction: 'signup',
    eventLabel: `owner${ownerIdDecoded}`,
    userId: ownerIdDecoded,
    ownerId: `owner${ownerIdDecoded}`,
    tenantId: 'na',
    sessionType: 'client',
    ownerType: 'signup',
    email,
  });
}
