import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import ChevronRight from '../../../icons/streamline/ChevronRight';

import styles from './AllDocumentsBannerCta.module.scss';

const AllDocumentsBannerCta = ({
  icon: Icon,
  title,
  text,
  to,
  onClick = () => {},
}) => (
  <Link to={to} className={styles.container} onClick={onClick}>
    <span className={styles.iconWrapper}>
      <Icon width={48} height={48} />
    </span>
    <div className={styles.text}>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.description}>{text}</p>
    </div>
    <span className={styles.ctaButton}>
      <ChevronRight width={24} height={24} />
    </span>
  </Link>
);

AllDocumentsBannerCta.propTypes = {
  icon: PropTypes.elementType,
  title: PropTypes.string,
  text: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
};
export default AllDocumentsBannerCta;
