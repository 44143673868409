import React from 'react';
import PropTypes from 'prop-types';

const ESign = ({ color, fillColor, ...props }) => (
  <svg fill="none" viewBox="0 0 48 48" {...props}>
    <path
      fill={fillColor}
      d="m29.54 25.445-8.5 14 8-5 9-6.5v-10l-3.5-2.5-2.55 4.5.55.5 1 2-1 1.5-1 1-2 .5Z"
    />
    <path
      fill={color}
      fillRule="evenodd"
      d="M30.52 6.63a.83.83 0 0 1 .828.001l12.017 6.938a.83.83 0 0 1 .067 1.395l-4.737 3.378a.83.83 0 0 1-.897.043l-7.315-4.223a.83.83 0 0 1-.415-.723l.035-6.094a.83.83 0 0 1 .417-.715Zm1.235 2.153-.024 4.183 6.435 3.715 3.252-2.32-9.663-5.578Z"
      clipRule="evenodd"
    />
    <path
      fill={color}
      fillRule="evenodd"
      d="M30.474 12.73a.83.83 0 0 1 .839-.005l7.315 4.223a.83.83 0 0 1 .415.729l-.122 9.864a.83.83 0 0 1-.342.661l-18.055 13.12a.83.83 0 0 1-1.313-.76l2.38-22.17a.83.83 0 0 1 .402-.625l8.481-5.037Zm.43 1.675-7.707 4.577-2.136 19.898 16.205-11.776.11-8.962-6.472-3.737Z"
      clipRule="evenodd"
    />
    <path
      fill={color}
      fillRule="evenodd"
      d="M29.677 24.007a.83.83 0 0 1 .302 1.134l-8.658 14.944a.83.83 0 0 1-1.436-.832l8.658-14.944a.83.83 0 0 1 1.134-.302Z"
      clipRule="evenodd"
    />
    <path
      fill={color}
      fillRule="evenodd"
      d="M31.492 20.858c-.696-.401-1.74-.15-2.28.783-.538.933-.233 1.963.463 2.365.696.402 1.741.151 2.28-.782.539-.933.233-1.964-.463-2.366Zm-3.717-.047c.923-1.6 2.935-2.321 4.547-1.39 1.613.93 1.994 3.034 1.07 4.633-.923 1.599-2.935 2.32-4.547 1.39-1.612-.931-1.993-3.034-1.07-4.633ZM4.21 40.608a.83.83 0 0 1 .83-.83h14.618a.83.83 0 1 1 0 1.66H5.04a.83.83 0 0 1-.83-.83Z"
      clipRule="evenodd"
    />
  </svg>
);

// eslint-disable-next-line local/no-default-props
ESign.defaultProps = {
  width: 48,
  height: 48,
  color: '#042238',
  fillColor: '#7FE3FF',
};

ESign.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  fillColor: PropTypes.string,
};

export default ESign;
