import React, { memo } from 'react';
import PropTypes from 'prop-types';

const FiveStars = ({ width = 116, height = 24, ...props }) => (
  <svg
    width={width}
    height={height}
    {...props}
    fill="none"
    viewBox="0 0 116 24"
  >
    <path
      d="M5.102 10.86c.734 0 1.38.132 1.937.398.563.26 1 .646 1.313 1.156.312.505.468 1.125.468 1.86 0 .802-.169 1.494-.508 2.078-.338.578-.835 1.023-1.492 1.335-.656.313-1.455.47-2.398.47A8.56 8.56 0 0 1 2.742 18a4.626 4.626 0 0 1-1.336-.469v-1.656c.39.219.86.398 1.407.54.546.134 1.07.202 1.57.202.531 0 .987-.078 1.367-.234.38-.156.672-.396.875-.719.208-.323.313-.732.313-1.226 0-.657-.211-1.162-.633-1.516-.417-.36-1.07-.54-1.961-.54-.313 0-.646.03-1 .087a8.12 8.12 0 0 0-.875.172l-.805-.477.43-5.586H8.03v1.617h-4.32l-.25 2.844c.187-.042.411-.08.672-.117.26-.042.583-.063.969-.063Zm5.601 6.171c0-.427.11-.726.328-.898a1.27 1.27 0 0 1 .813-.266c.318 0 .588.089.812.266.23.172.344.471.344.898 0 .417-.115.719-.344.907a1.245 1.245 0 0 1-.812.273c-.318 0-.589-.091-.813-.273-.219-.188-.328-.49-.328-.907Zm11.797-4.75c0 .922-.073 1.748-.219 2.477-.14.724-.364 1.338-.672 1.844-.307.505-.708.89-1.203 1.156-.494.265-1.093.398-1.797.398-.88 0-1.606-.232-2.18-.695-.572-.469-.997-1.14-1.273-2.016-.276-.88-.414-1.935-.414-3.164s.125-2.281.375-3.156c.255-.88.667-1.555 1.235-2.023.567-.47 1.32-.704 2.257-.704.886 0 1.615.235 2.188.704.578.463 1.005 1.135 1.281 2.015.281.875.422 1.93.422 3.164Zm-5.906 0c0 .964.062 1.768.187 2.414.13.646.344 1.13.64 1.453.298.318.694.477 1.188.477.495 0 .891-.159 1.188-.477.297-.317.51-.799.64-1.445.136-.646.204-1.453.204-2.422 0-.958-.065-1.76-.196-2.406-.13-.646-.343-1.13-.64-1.453-.297-.328-.696-.492-1.196-.492s-.898.164-1.195.492c-.292.323-.503.807-.633 1.453-.125.646-.187 1.448-.187 2.406Z"
      fill="#042238"
    />
    <path fill="#fff" fillOpacity=".01" d="M27.5 4h16v16h-16z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.565 5.338c.038.008.048.009.085.023a.41.41 0 0 1 .197.166c.02.033.023.042.038.078l1.537 4.354h4.337l.044.002.043.007a.41.41 0 0 1 .198.691l-.033.03-3.584 2.815 1.542 4.626.012.043c.002.015.006.03.007.044a.409.409 0 0 1-.61.396l-.037-.025-3.84-2.816-3.841 2.816-.038.025-.04.02a.409.409 0 0 1-.563-.46l.012-.043 1.542-4.626-3.584-2.816-.033-.029-.03-.032a.408.408 0 0 1 .271-.666l.044-.002h4.338l1.536-4.354.017-.04c.019-.034.022-.044.046-.074a.41.41 0 0 1 .387-.153Z"
      fill="#FFCD00"
    />
    <path fill="#fff" fillOpacity=".01" d="M45.5 4h16v16h-16z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.565 5.338c.038.008.048.009.085.023a.41.41 0 0 1 .197.166c.02.033.023.042.038.078l1.537 4.354h4.337l.044.002.043.007a.41.41 0 0 1 .198.691l-.033.03-3.584 2.815 1.542 4.626.012.043c.002.015.006.03.007.044a.409.409 0 0 1-.61.396l-.037-.025-3.84-2.816-3.841 2.816-.038.025-.04.02a.409.409 0 0 1-.563-.46l.012-.043 1.542-4.626-3.584-2.816-.033-.029-.03-.032a.408.408 0 0 1 .271-.666l.044-.002h4.338l1.536-4.354.017-.04c.019-.034.022-.044.046-.074a.41.41 0 0 1 .387-.153Z"
      fill="#FFCD00"
    />
    <path fill="#fff" fillOpacity=".01" d="M63.5 4h16v16h-16z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.565 5.338c.038.008.048.009.085.023a.41.41 0 0 1 .197.166c.02.033.023.042.038.078l1.537 4.354h4.337l.044.002.043.007a.41.41 0 0 1 .198.691l-.033.03-3.584 2.815 1.542 4.626.012.043c.003.015.006.03.007.044a.409.409 0 0 1-.61.396l-.037-.025-3.84-2.816-3.841 2.816-.038.025-.04.02a.409.409 0 0 1-.563-.46l.012-.043 1.542-4.626-3.584-2.816-.033-.029-.03-.032a.408.408 0 0 1 .271-.666l.044-.002h4.338l1.536-4.354.017-.04c.018-.034.022-.044.046-.074a.41.41 0 0 1 .387-.153Z"
      fill="#FFCD00"
    />
    <path fill="#fff" fillOpacity=".01" d="M81.5 4h16v16h-16z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M89.565 5.338c.038.008.048.009.085.023a.41.41 0 0 1 .197.166c.02.033.023.042.038.078l1.537 4.354h4.337l.044.002.043.007a.41.41 0 0 1 .198.691l-.033.03-3.584 2.815 1.542 4.626.012.043c.003.015.006.03.007.044a.409.409 0 0 1-.61.396l-.037-.025-3.84-2.816-3.841 2.816-.038.025-.04.02a.409.409 0 0 1-.563-.46l.012-.043 1.542-4.626-3.584-2.816-.033-.029-.03-.032a.408.408 0 0 1 .271-.666l.044-.002h4.338l1.536-4.354.017-.04c.018-.034.022-.044.046-.074a.41.41 0 0 1 .387-.153Z"
      fill="#FFCD00"
    />
    <path fill="#fff" fillOpacity=".01" d="M99.5 4h16v16h-16z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M107.565 5.338c.038.008.048.009.085.023a.41.41 0 0 1 .197.166c.021.033.023.042.038.078l1.537 4.354h4.337l.044.002.043.007a.408.408 0 0 1 .198.691l-.033.03-3.584 2.815 1.542 4.626.012.043c.002.015.006.03.007.044a.408.408 0 0 1-.61.396l-.037-.025-3.841-2.816-3.84 2.816-.038.025-.04.02a.41.41 0 0 1-.561-.282.41.41 0 0 1-.002-.178l.012-.043 1.542-4.626-3.584-2.816-.033-.029-.03-.032a.411.411 0 0 1 .106-.61c.05-.03.107-.05.165-.056l.044-.002h4.338l1.536-4.354.017-.04c.019-.034.022-.044.046-.074a.412.412 0 0 1 .344-.157l.043.004Z"
      fill="#FFCD00"
    />
  </svg>
);

FiveStars.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default memo(FiveStars);
