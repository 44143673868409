import React from 'react';
import PropTypes from 'prop-types';

import FinalForm from '../../../../components/FinalForm';
import {
  RadioField,
  TextAreaField,
} from '../../../../components/FinalFormFields';

import { questions } from './questions';

import styles from './SelfReportedBgEdit.module.scss';

const SelfReportedBgEdit = (props) => {
  const { onSubmit, application, id, formRef } = props;

  const {
    evicted_bkg_question_enabled,
    conviction_bkg_question_enabled,
    civil_action_bkg_question_enabled,
  } = application || {};

  return (
    <FinalForm
      id={id}
      ref={formRef}
      initialValues={{
        evicted: application?.evicted,
        evicted_description: application?.evicted_description,
        civil_action: application?.civil_action,
        civil_action_description: application?.civil_action_description,
        conviction: application?.conviction,
        conviction_description: application?.conviction_description,
      }}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <article>
          {evicted_bkg_question_enabled && (
            <div className={styles.section}>
              <RadioField
                inline={false}
                label={questions.evicted}
                name="evicted"
                options={[
                  {
                    value: true,
                    label: 'Yes',
                  },
                  {
                    value: false,
                    label: 'No',
                  },
                ]}
              />
              {values?.evicted && (
                <TextAreaField
                  style={styles.description}
                  label="Please explain"
                  name="evicted_description"
                  rows="4"
                />
              )}
            </div>
          )}
          {application?.applying_as_tenant &&
            conviction_bkg_question_enabled && (
              <div className={styles.section}>
                <RadioField
                  inline={false}
                  label={questions.conviction}
                  name="conviction"
                  options={[
                    {
                      value: true,
                      label: 'Yes',
                    },
                    {
                      value: false,
                      label: 'No',
                    },
                  ]}
                />
                {values?.conviction && (
                  <TextAreaField
                    style={styles.description}
                    label="Please explain"
                    name="conviction_description"
                    rows="4"
                  />
                )}
              </div>
            )}
          {civil_action_bkg_question_enabled && (
            <div className={styles.section}>
              <RadioField
                inline={false}
                label={questions.civil_action}
                name="civil_action"
                options={[
                  {
                    value: true,
                    label: 'Yes',
                  },
                  {
                    value: false,
                    label: 'No',
                  },
                ]}
              />
              {values?.civil_action && (
                <TextAreaField
                  style={styles.description}
                  label="Please explain"
                  name="civil_action_description"
                  rows="4"
                />
              )}
            </div>
          )}
        </article>
      )}
    </FinalForm>
  );
};

SelfReportedBgEdit.propTypes = {
  onSubmit: PropTypes.func,
  id: PropTypes.string,
  formRef: PropTypes.object,
  application: PropTypes.object,
};

export default SelfReportedBgEdit;
