import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Lock = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 24" {...rest} fill={color}>
    <path d="M12 2c2.8 0 5.068 2.284 5.068 5.102l-.001 2.244h1.825a.61.61 0 0 1 .608.613v11.429a.61.61 0 0 1-.608.612H5.108a.61.61 0 0 1-.608-.612V9.959a.61.61 0 0 1 .608-.612l1.824-.001V7.102c0-2.746 2.154-4.985 4.854-5.098L12 2Zm6.284 8.571H5.716v10.205h12.568V10.57ZM12 13.43a1.022 1.022 0 0 1 .609 1.836v2.041a.61.61 0 0 1-.609.612.61.61 0 0 1-.608-.612v-2.042A1.022 1.022 0 0 1 12 13.43Zm0-10.205c-2.127 0-3.851 1.736-3.851 3.878v2.245h7.702V7.102c0-2.082-1.63-3.78-3.675-3.874L12 3.224Z" />
  </svg>
);

Lock.defaultProps = {
  width: 24,
  height: 24,
  color: 'currentColor',
};

Lock.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Lock);
