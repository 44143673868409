import React, { useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import {
  DOCUMENTS_SORTING_CRITERIA,
  DOCUMENTS_SORTING_OPTIONS,
} from '../../../constants/documents/documents_sorting_options';
import CaretDown from '../../../icons/CaretDown';
import InfiniteList from '../../InfiniteList';
import AllDocumentsTableRow from '../AllDocumentsTableRow/AllDocumentsTableRow';

import DocumentsSkeleton from './Skeleton/DocumentsSkeleton';

import styles from './AllDocumentsTable.module.scss';

const AllDocumentsTable = ({
  documents,
  className,
  loading,
  onCopy,
  onDelete,
  onSortingChange,
  sortConfig,
}) => {
  const rowRenderer = useCallback(({ item }) => {
    return (
      <AllDocumentsTableRow
        key={item.id}
        document={item}
        onCopy={() => {
          onCopy(item);
        }}
        onDelete={() => onDelete(item)}
      />
    );
  }, []);

  if (loading) {
    return <DocumentsSkeleton />;
  }

  const headers = [
    {
      label: 'Date',
      value: 'created_at',
      sorting: true,
      onClick: () => {
        onSortingChange(DOCUMENTS_SORTING_CRITERIA.CREATED_AT);
      },
      isActive: sortConfig === DOCUMENTS_SORTING_OPTIONS.OLDEST,
    },
    {
      label: 'Title & Category',
      value: 'title',
    },
    {
      label: 'Rental',
      value: 'rental',
      sorting: true,
      onClick: () => {
        onSortingChange(DOCUMENTS_SORTING_CRITERIA.RENTAL);
      },
      isActive: sortConfig === DOCUMENTS_SORTING_OPTIONS.RENTAL_DESC,
    },
    {
      label: 'E-Sign',
      value: 'status',
      getter: null,
      className: styles.statusHeader,
    },
    { label: '', value: 'actions' },
  ];

  return (
    <table
      className={cx(styles.container, className, {
        [styles.expand]: documents?.length === 1,
      })}
    >
      <thead className={styles.header}>
        <tr>
          {headers.map((header) => (
            <th
              key={header.value}
              className={cx(styles[header.value], {
                [styles.sortable]: header.sorting,
                [styles.active]: header.isActive,
              })}
              onClick={header.onClick && header.onClick}
            >
              {header.label}
              {header.sorting && <CaretDown className={styles.sortingCaret} />}
            </th>
          ))}
        </tr>
      </thead>
      <InfiniteList
        key={`list-${documents?.lenght}-${sortConfig}`}
        data={documents}
        rowRenderer={rowRenderer}
        rowCount={documents.length}
        defaultRowHeight={80}
        overscanRowCount={5}
      />
    </table>
  );
};

AllDocumentsTable.propTypes = {
  className: PropTypes.string,
  documents: PropTypes.array,
  loading: PropTypes.bool,
  onCopy: PropTypes.func,
  onDelete: PropTypes.func,
  onSortingChange: PropTypes.func,
  sortConfig: PropTypes.object,
};

export default AllDocumentsTable;
