import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import LoadingScreen from '../../components/LoadingScreen';
import { isAdminLogin } from '../../core/auth/authService';
import { useUserProfile } from '../../core/TTgraphql';
import { linkTo, routes } from '../../helpers/routes';
import Intro from '../../layout/TenantOnboarding/steps/Intro';
import { getActiveLeaseForRenter } from '../../services/utilities/getActiveLeaseForRenter';
import { useConfig } from '../ConfigProvider';

import { RentersProfileContext } from './context';

import styles from './RentersProfileProvider.module.scss';

const RentersProfileProvider = ({ children }) => {
  const { user, loading } = useUserProfile();
  const history = useHistory();
  const { PRIVATE_BASE_PATH } = useConfig();
  const isAdmin = isAdminLogin();
  const currentLease = useMemo(() => {
    if (loading) return null;

    return getActiveLeaseForRenter(user);
  }, [user, loading]);

  const value = useMemo(() => ({ currentLease }), [currentLease]);

  const isInOnboardingFlow = useMemo(() => {
    if (loading || !history || isAdmin) return false;

    return history.location?.pathname?.startsWith(
      `${PRIVATE_BASE_PATH}${routes.tenantOnboardingBase.slice(1)}`,
    );
  }, [PRIVATE_BASE_PATH, history?.location]);

  const shouldShowRenterOnboardingIntro = useMemo(() => {
    const renterStartedOnboardingFlow =
      currentLease?.renter_started_onboarding_flow ?? true;

    return !isAdmin && !renterStartedOnboardingFlow;
  }, [currentLease, isAdmin]);

  useEffect(() => {
    if (!isAdmin && !loading) {
      const renterStartedOnboardingFlow =
        currentLease?.renter_started_onboarding_flow ?? true;

      const renterCompletedOnboardingFlow =
        currentLease?.renter_completed_onboarding_flow ?? true;

      if (
        renterStartedOnboardingFlow &&
        !renterCompletedOnboardingFlow &&
        history &&
        !isInOnboardingFlow
      ) {
        history.replace(linkTo(routes.tenantOnboardingStepPaymentMethod));
      }
    }
  }, [currentLease, isAdmin, loading, history]);

  if (loading) {
    return (
      <div className={styles.fullPageLoader}>
        <LoadingScreen loading />
      </div>
    );
  }

  return (
    <RentersProfileContext.Provider value={value}>
      {shouldShowRenterOnboardingIntro ? <Intro /> : children}
    </RentersProfileContext.Provider>
  );
};

RentersProfileProvider.propTypes = {
  children: PropTypes.node,
};

export default RentersProfileProvider;
