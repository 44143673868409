import selection from './selection.json';

export const allowedSizes = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];
export const allowedColors = [
  'default',
  'prominent',
  'success',
  'warning',
  'error',
];
export const iconNames = selection.icons.map((icon) => icon.properties.name);
