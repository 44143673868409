import { useCallback, useRef } from 'react';

import { useActiveExperiments } from '../../providers/ActiveExperimentsProvider/context';
import { useConfig } from '../../providers/ConfigProvider';

import tryAssignActiveCookieExperiment from './tryAssignActiveCookieExperiment';

const extractDomain = (url) => {
  try {
    const urlObject = new URL(url);
    // Get domain without subdomain
    const parts = urlObject.hostname.split('.');
    if (parts.length > 2) {
      return `.${parts.slice(-2).join('.')}`;
    }
    return `.${urlObject.hostname}`;
  } catch (e) {
    console.warn('Invalid URL provided for domain extraction', e);
    return null;
  }
};

/**
 * Hook that provides a simplified interface for assigning cookie-based experiments
 * @returns {Function} assignExperiment - Function to assign an experiment variant
 * @example
 * const assignExperiment = useCookieExperimentAssignment();
 * const variant = assignExperiment('my-experiment', { expirationDays: 30 });
 */
export const useCookieExperimentAssignment = () => {
  const { cookieExperiments } = useActiveExperiments();
  const { OWNERS_URL } = useConfig();
  const cookieExperimentsRef = useRef(cookieExperiments);
  const domain = extractDomain(OWNERS_URL);

  cookieExperimentsRef.current = cookieExperiments;

  return useCallback(
    async (experimentName, options = {}, callback = null) => {
      if (!cookieExperimentsRef.current) {
        await new Promise((resolve) => {
          const checkExperiments = () => {
            if (cookieExperimentsRef.current) {
              resolve();
            } else {
              setTimeout(checkExperiments, 50);
            }
          };
          checkExperiments();
        });
      }

      const variant = tryAssignActiveCookieExperiment(
        experimentName,
        cookieExperimentsRef.current,
        { ...options, domain },
      );

      if (typeof callback === 'function') {
        callback(variant);
      }

      return variant;
    },
    [OWNERS_URL],
  );
};
