import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useMutation } from '@apollo/client';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import trimEmail from '../../../../components/Input/trimEmail';
import LoadingScreen from '../../../../components/LoadingScreen';
import Modal from '../../../../components/Modal';
import PhoneField from '../../../../components/PhoneField';
import PropertyUnitRoomDropdowns from '../../../../components/PropertyUnitRoomDropdowns';
import { useErrorToast, useInfoToast } from '../../../../components/Toast';
import ListingModeEnum from '../../../../constants/enums/ListingModeEnum';
import { useListings } from '../../../../core/TTgraphql';
import basicUserProfile from '../../../../graphql/basicUserProfile.graphql';
import createLeadGQL from '../../../../graphql/mutations/createLead.graphql';
import leadsQuery from '../../../../graphql/queries/leadsQuery.graphql';
import removeUnusedMUPFields from '../../../../helpers/removeUnusedMUPFields';
import {
  getLeadsQueryInfo,
  segmentTracking,
} from '../../../../services/utilities';
import clearMask from '../../../../services/utilities/clearMask';
import composeValidators from '../../../../validators/composeValidators';
import fieldRequired from '../../../../validators/fieldRequired';
import fieldRequiredCustom from '../../../../validators/fieldRequiredCustom';
import validEmail from '../../../../validators/validEmail';
import validName from '../../../../validators/validName';
import validPhone from '../../../../validators/validPhone';

import styles from './AddNewLeadModal.module.scss';

const AddNewLeadModal = ({
  open,
  onClose,
  selectedValue,
  segmentLocation,
  segmentLoadLocation,
  loadEventFired,
  onLoadEventFired,
}) => {
  const showInfoToast = useInfoToast();
  const showErrorToast = useErrorToast();

  useEffect(() => {
    if (open && !loadEventFired) {
      segmentTracking('add_a_new_lead_modal loaded', {
        location: segmentLoadLocation,
      });

      onLoadEventFired();
    }
  }, [open, loadEventFired]);

  const { listings = [], loading: loadingListings } = useListings({
    variables: { mode: ListingModeEnum.ROOTS_ONLY },
  });

  const [createLeadRequest, { loading: loadingCreateLeadMutation }] =
    useMutation(createLeadGQL);

  const onSubmit = async (data) => {
    const toSubmit = {
      ...removeUnusedMUPFields(data),
      listingId: data.listing_id,
    };
    try {
      const submitData = {
        ...toSubmit,
        phone: clearMask(data.phone),
      };

      const result = await createLeadRequest({
        variables: submitData,
        refetchQueries: [
          { query: basicUserProfile },
          {
            query: leadsQuery,
            variables: getLeadsQueryInfo().variables,
          },
          {
            query: leadsQuery,
            variables: getLeadsQueryInfo(location.search, false),
          },
        ],
        awaitRefetchQueries: true,
      });

      onClose(result?.data?.addLead?.lead);
      showInfoToast('Lead added!');
    } catch (e) {
      const message =
        get(e, `graphQLErrors[0].message`) || 'Something went wrong';
      showErrorToast(message);
    }
  };

  const onCancelClicked = () => {
    if (segmentLocation) {
      segmentTracking('cancel clicked', {
        location: segmentLocation,
      });
    }
    onClose();
  };

  const onSubmitClicked = () => {
    if (segmentLocation) {
      segmentTracking('submit clicked', {
        location: segmentLocation,
      });
    }
  };

  return (
    <Modal open className={styles.container} autoFocus={false}>
      <LoadingScreen loading={loadingListings || loadingCreateLeadMutation}>
        <h3 id="add-lead-modal" className={styles.title}>
          Add a new lead!
        </h3>
        <p className={styles.text}>
          Easily keep track of all the renters interested in your property.
        </p>
        <Form
          onSubmit={onSubmit}
          subscription={{
            submitting: true,
            values: true,
          }}
          initialValues={{
            listing_id: selectedValue,
          }}
        >
          {({ handleSubmit, submitting, values }) => (
            <form onSubmit={handleSubmit}>
              <PropertyUnitRoomDropdowns
                label="Property Interested In"
                properties={listings}
                dataQaTags={{
                  property: 'properties-dropdown',
                }}
                required
                lockPreSelectedFields
                selectedValue={selectedValue}
              />
              <div className={styles.formGroup}>
                <Field
                  label="First Name"
                  component={Input}
                  name="first_name"
                  id="first_name"
                  className={styles.name}
                  validate={composeValidators(fieldRequired, validName)}
                />
                <Field
                  label="Last Name"
                  component={Input}
                  name="last_name"
                  id="last_name"
                  className={styles.name}
                  validate={composeValidators(fieldRequired, validName)}
                />
              </div>
              <Field
                label="Email"
                component={Input}
                type="email"
                name="email"
                id="email"
                validate={
                  values.phone
                    ? validEmail
                    : composeValidators(
                        fieldRequiredCustom('Add an email or phone number'),
                        validEmail,
                      )
                }
                parse={trimEmail}
              />
              <Field
                label="Phone"
                component={PhoneField}
                id="phone"
                name="phone"
                validate={
                  values.email
                    ? validPhone
                    : composeValidators(
                        fieldRequiredCustom('Add an email or phone number'),
                        validPhone,
                      )
                }
                className={styles.phone}
              />
              <div className={styles.btnWrap}>
                <Button
                  secondary
                  id="close-add-lead-modal"
                  className={styles.btn}
                  disabled={submitting}
                  onClick={onCancelClicked}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  id="add-lead-button"
                  className={styles.btn}
                  loading={submitting}
                  onClick={onSubmitClicked}
                >
                  Submit
                </Button>
              </div>
            </form>
          )}
        </Form>
      </LoadingScreen>
    </Modal>
  );
};

AddNewLeadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedValue: PropTypes.string,
  segmentLocation: PropTypes.string,
  segmentLoadLocation: PropTypes.string,
  loadEventFired: PropTypes.bool.isRequired,
  onLoadEventFired: PropTypes.func.isRequired,
};

export default AddNewLeadModal;
