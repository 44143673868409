import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import colors from '../../../../../constants/colors';
import CheckmarkFilled from '../../../../../icons/streamline/CheckmarkFilled';
import { H5 } from '../../../../Typography';
import BenefitItemExperimentH from '../Benefit/BenefitItemExperimentH';

import styles from './BenefitSectionExperimentH.module.scss';

const BenefitSectionExperimentH = ({
  benefits,
  selectedBenefit,
  extraParams,
}) => {
  const isSingleGroup =
    benefits.length === 1 ||
    (selectedBenefit !== 'all_features' &&
      benefits.filter((b) => b.key === selectedBenefit).length === 1);

  return benefits.map((benefit) => {
    const allItems = benefit.items;
    const premiumItems = allItems.filter((item) => item.plan !== 'pro');
    const proItems = allItems.filter((item) => item.plan !== 'premium');

    const maxItems = Math.max(premiumItems.length, proItems.length);
    const isLastGroup = benefits.indexOf(benefit) === benefits.length - 1;

    return (
      <div
        className={cx(
          styles.benefitsGroup,
          { [styles.all]: selectedBenefit === 'all_features' },
          {
            [styles.show]:
              selectedBenefit === benefit.key ||
              selectedBenefit === 'all_features',
          },
          { [styles.last]: isLastGroup },
          { [styles.singleGroup]: isSingleGroup },
          { [styles.first]: benefits.indexOf(benefit) === 0 },
        )}
        key={benefit.key}
      >
        <table className={styles.benefitsTable}>
          {selectedBenefit === 'all_features' && (
            <thead>
              <tr className={styles.headerRow}>
                <th className={styles.premiumHeader}>
                  <H5 className={styles.title}>
                    {benefit.titleShort || benefit.title}
                  </H5>
                </th>
                <th className={styles.proHeader}>
                  <div className={styles.emptyHeader} />
                </th>
                <th className={cx(styles.premiumHeader, styles.premiumColumn)}>
                  <div className={styles.emptyHeader} />
                </th>
              </tr>
            </thead>
          )}

          <tbody>
            {Array.from({ length: maxItems }).map((_, index) => {
              const premiumItem = premiumItems[index];
              const proItem = proItems[index];
              const isLastRow = index === maxItems - 1;

              return (
                <tr key={index} className={styles.benefitRow}>
                  <td className={styles.premiumCell}>
                    {premiumItem && (
                      <BenefitItemExperimentH
                        item={premiumItem}
                        extraParams={extraParams}
                      />
                    )}
                  </td>

                  <td className={styles.proCell}>
                    {proItem && (
                      <div className={styles.checkmarkContainer}>
                        <CheckmarkFilled
                          className={
                            selectedBenefit === 'leases' ||
                            (selectedBenefit === 'all_features' &&
                              benefit.key === 'leases')
                              ? styles.leases
                              : styles.checkmark
                          }
                          color={colors.slimProgressBarFill}
                        />
                      </div>
                    )}
                  </td>

                  <td
                    className={cx(
                      styles.premiumCheckCell,
                      styles.premiumColumn,
                      { [styles.lastRow]: isLastRow && isLastGroup },
                    )}
                  >
                    {premiumItem && (
                      <div className={styles.checkmarkContainer}>
                        <CheckmarkFilled
                          className={
                            selectedBenefit === 'leases' ||
                            (selectedBenefit === 'all_features' &&
                              benefit.key === 'leases')
                              ? styles.leases
                              : styles.checkmark
                          }
                          color={colors.slimProgressBarFill}
                        />
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <div className={styles.broderRadius} />
        </table>
      </div>
    );
  });
};

BenefitSectionExperimentH.propTypes = {
  benefits: PropTypes.array.isRequired,
  selectedBenefit: PropTypes.string.isRequired,
  extraParams: PropTypes.object.isRequired,
};

export default BenefitSectionExperimentH;
