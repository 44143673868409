import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import cx from 'classnames';
import get from 'lodash.get';

import Button from '../../../components/Button';
import { useUserProfile } from '../../../core/TTgraphql';
import checkIfLandlordPaysExperimentIsTest from '../../../helpers/experiments/checkIfLandlordPaysExperimentIsTest';
import ForwardArrow from '../../../icons/ArrowRight';
import { useConfig } from '../../../providers/ConfigProvider';
import { segmentTracking } from '../../../services/utilities';
import InviteApplyScreenOptionsModal from '../../owners/components/InviteApplyScreenOptionsModal';

import styles from './OwnerHeaderRenterSection.module.scss';

const OwnerHeaderRenterSection = () => {
  const location = useLocation();
  const history = useHistory();

  const { user } = useUserProfile();
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const { PRIVATE_BASE_PATH } = useConfig();

  const isLandlordPaysExperimentTest = checkIfLandlordPaysExperimentIsTest(
    user.active_experiments,
  );

  const leadsCount = get(user, 'leads_count.all', 0) || 0;

  const getSelectedIndex = () => {
    switch (location.pathname) {
      case '/owners/renters/leads':
        return 0;
      case '/owners/renters/applicants':
        return 1;
      case '/owners/renters/tenants':
        return 2;
      case '/owners/renters/tenants/archived':
        return 2;
      case '/owners/archived/leads':
        return 0;
      case '/owners/archived/applicants':
        return 1;
      case '/owners/archived/tenants':
        return 2;
    }
    return -1;
  };
  const selectedIndex = getSelectedIndex();

  const getSegmentLocation = () => {
    switch (selectedIndex) {
      case 0:
        return 'leads';
      case 1:
        return 'applicants_tab';
      case 2:
        return 'tenants';
    }
  };

  const handleScreenATenantClick = () => {
    if (user.listings_count === 0) {
      return history.push(`${PRIVATE_BASE_PATH}properties/create`);
    }

    segmentTracking(`combine_actions_screen_application_modal opened`, {
      location: `applicants`,
      experiment: 'combine_actions_screen_application',
    });

    setOpenInviteModal(true);
  };

  const handleInviteToApply = () => {
    if (user.listings_count === 0) {
      return history.push(`${PRIVATE_BASE_PATH}properties/create`);
    }

    segmentTracking(`invite_to_apply clicked`, {
      location: `index_header`,
      ...(isLandlordPaysExperimentTest !== null && {
        experiment: `LL Pays ${
          isLandlordPaysExperimentTest ? 'Test' : 'Control'
        }`,
      }),
    });

    const currentParams = new URLSearchParams(location.search);
    currentParams.set('status', 'INVITED');
    const newQueryString = currentParams.toString();

    history.push({
      pathname: `${PRIVATE_BASE_PATH}invite-renter`,
      search: `?${newQueryString}`,
      state: {
        redirectTo: location.pathname,
      },
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.mainRowContainer}>
        <div className={styles.itemsContainer}>
          <div className={styles.itemStyles}>
            <span
              id="leads_tab"
              className={cx(styles.itemLabel, {
                [styles.itemLabelSelected]: selectedIndex === 0,
              })}
              onClick={() => {
                segmentTracking(`leads clicked`, {
                  location: getSegmentLocation(),
                });
                history.push(`${PRIVATE_BASE_PATH}renters/leads`);
              }}
            >
              LEADS ({leadsCount})
            </span>
            <div
              style={{ position: 'relative', top: 16 }}
              className={cx(styles.bottomItemLabel, {
                [styles.bottomItemLabelSelected]: selectedIndex === 0,
              })}
            />
          </div>
          <div className={styles.separatorStyle}>
            <ForwardArrow
              color="#6D757F"
              style={{ width: 15, height: 15 }}
              strokeWidth={2.75}
            />
          </div>
          <div className={styles.itemStyles}>
            <span
              id="applicants_tab"
              className={cx(styles.itemLabel, {
                [styles.itemLabelSelected]: selectedIndex === 1,
              })}
              onClick={() => {
                segmentTracking(`applicants clicked`, {
                  location: getSegmentLocation(),
                });
                history.push(`${PRIVATE_BASE_PATH}renters/applicants`);
              }}
            >
              APPLICANTS ({user.applicants_count})
            </span>
            <div
              style={{ position: 'relative', top: 16 }}
              className={cx(styles.bottomItemLabel, {
                [styles.bottomItemLabelSelected]: selectedIndex === 1,
              })}
            />
          </div>
          <div className={styles.separatorStyle}>
            <ForwardArrow
              color="#6D757F"
              style={{ width: 15, height: 15 }}
              strokeWidth={2.75}
            />
          </div>
          <div className={styles.itemStyles}>
            <span
              id="tenants_tab"
              className={cx(styles.itemLabel, {
                [styles.itemLabelSelected]: selectedIndex === 2,
              })}
              onClick={() => {
                segmentTracking(`tenants clicked`, {
                  location: getSegmentLocation(),
                });
                history.push(`${PRIVATE_BASE_PATH}renters/tenants`);
              }}
            >
              TENANTS ({user.tenants_count})
            </span>
            <div
              style={{ position: 'relative', top: 16 }}
              className={cx(styles.bottomItemLabel, {
                [styles.bottomItemLabelSelected]: selectedIndex === 2,
              })}
            />
          </div>
        </div>
        <div className={styles.buttonsContainer}>
          <Button
            secondary
            onClick={handleScreenATenantClick}
            className={styles.screenBtn}
            id="screen_tenant"
          >
            SCREEN A TENANT
          </Button>
          <Button
            onClick={handleInviteToApply}
            className={styles.screenBtn}
            id="invite_to_apply"
          >
            INVITE TO APPLY
          </Button>
        </div>
      </div>
      <div className={styles.bottomContainer} />
      <InviteApplyScreenOptionsModal
        open={openInviteModal}
        onClose={() => setOpenInviteModal(false)}
      />
    </div>
  );
};

export default OwnerHeaderRenterSection;
