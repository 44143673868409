const parseListingFilters = (data) => {
  return data.map((item) => {
    return {
      ...item,
      id: item.id,
      nickname: item.name,
      children_listings: item.children_filters
        ? parseListingFilters(item.children_filters)
        : null,
    };
  });
};

export default parseListingFilters;
