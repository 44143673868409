import React, { memo } from 'react';
import PropTypes from 'prop-types';

import colors from '../constants/colors';

const Title = (props) => (
  <svg {...props} viewBox="0 0 29 24">
    <path
      d="M20.17 22.262a.88.88 0 0 1 .892.87c0 .44-.335.803-.77.86L20.17 24H1.725a.88.88 0 0 1-.892-.869c0-.44.336-.803.77-.86l.122-.009H20.17ZM25.315 9.55a.88.88 0 0 1 .892.869c0 .44-.336.803-.77.86l-.122.009H1.724a.88.88 0 0 1-.89-.869c0-.44.335-.803.77-.86l.12-.009h23.59Zm1.367 3.179a.88.88 0 0 1 .891.868c0 .44-.335.804-.77.861l-.12.008H1.724a.88.88 0 0 1-.892-.869c0-.44.336-.803.77-.86l.122-.008H26.68Zm-1.367 3.178a.88.88 0 0 1 .892.869c0 .44-.336.803-.77.86l-.122.008H1.724a.88.88 0 0 1-.89-.868c0-.44.335-.804.77-.861l.12-.008h23.59Zm1.367 3.178a.88.88 0 0 1 .891.869c0 .44-.335.803-.77.86l-.12.009H1.724a.88.88 0 0 1-.892-.87c0-.439.336-.802.77-.86l.122-.008H26.68ZM27.572 0H1.428a.587.587 0 0 0-.595.58v6.95c0 .32.266.578.595.578h26.144c.329 0 .595-.259.595-.579V.58c0-.32-.266-.579-.595-.579Zm-.594 1.158V6.95H2.022V1.158h24.956Z"
      fill={props.color}
      fillRule="evenodd"
    />
  </svg>
);

Title.defaultProps = {
  width: 29,
  height: 24,
  color: colors.text,
};

Title.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Title);
