import React from 'react';
import useWindowSize from '@rooks/use-window-size';
import cx from 'classnames';
import { FORM_ERROR } from 'final-form';
import get from 'lodash.get';
import PropTypes from 'prop-types';

import Button from '../../../components/Button';
import SidebarMarketing from '../../../components/SidebarMarketing';
import SignupForm from '../../../components/SignupForm';
import { TermsPrivacy } from '../../../components/SignupForm/TermsPrivacy';
import SocialSignup from '../../../components/SocialSignup';
import { smallScreenWidth } from '../../../constants/media-breakpoints';
import authPage from '../../../hoc/auth-page';
import MarketingLayout from '../../../layout/MarketingLayout';
import { segmentTracking } from '../../../services/utilities';
import forumBuildingImage from '../login/assets/forum-building-illustration.svg';
import forumLogo from '../login/assets/forum-logo.svg';

import useSignupMutation from './useSignupMutation';

import styles from './ForumSignupPage.module.scss';

const ForumSignupPage = ({ location, history }) => {
  const signup = useSignupMutation(location);

  const MOBILE_BREAKPOINT = smallScreenWidth;
  const { innerWidth } = useWindowSize();
  const isMobile = innerWidth <= MOBILE_BREAKPOINT;

  const onSubmit = async (data) => {
    try {
      await signup({
        ...data,
        sso_service: 'forum',
      });
      history.push(`/onboarding/process${history.location.search}`);
    } catch (error) {
      const parsedError = get(error, `graphQLErrors[0]`) || {};
      if (parsedError && parsedError.code === 471) {
        // return email error
        return {
          email: parsedError.message,
        };
      }
      if (parsedError && parsedError.code === 500) {
        return {
          [FORM_ERROR]: 'Something went wrong, please try again',
        };
      }
      const message = parsedError.message || 'Something went wrong';
      return {
        [FORM_ERROR]: message,
      };
    }
  };
  return (
    <MarketingLayout
      headerClassName={styles.header}
      LogoToReplace={forumLogo}
      onLogoClick={() => {
        segmentTracking('tt_logo clicked', { location: 'Landlord Signup' });
        window.open('https://www.turbotenant.com/community/');
      }}
      action={
        <Button
          style={{ width: isMobile ? 136 : 176 }}
          to={`/auth/forum/login${history.location.search}`}
          small={true}
          secondary={true}
        >
          Landlord Login
        </Button>
      }
      side={
        <SidebarMarketing
          className={styles.sidebar}
          subtitle={
            <span className={styles.sidebarText}>
              Join over 700k landlords who use TurboTenant to level up their
              property management business
            </span>
          }
          graphic={forumBuildingImage}
          showLogo
        />
      }
    >
      <div className={cx(styles.container)}>
        <div className={cx(styles.content)}>
          <h2 className={cx(styles.title)}>
            Sign Up for the <span>Better Landlords</span> forum
          </h2>
          <p className={styles.subtitle}>
            It's the same login information as your existing TurboTenant
            account.
          </p>
          <SocialSignup className={styles.social} ssoService="forum" />
          <SignupForm onSubmit={onSubmit} mainFields={true} />
          <TermsPrivacy />
        </div>
      </div>
    </MarketingLayout>
  );
};

ForumSignupPage.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default authPage(ForumSignupPage);
