import { segmentTracking } from '../../services/utilities';

// This is a map to store recent assignments to avoid rapid-fire/race conditions.
const recentAssignments = new Map();

function getCookie(name) {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  return match ? match[2] : null;
}

function setCookie(name, value, days, domain) {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const cookieString = domain
    ? `${name}=${value};expires=${date.toUTCString()};path=/;domain=${domain}`
    : `${name}=${value};expires=${date.toUTCString()};path=/`;
  document.cookie = cookieString;
  segmentTracking(`${name} cookie placed`);
}

/**
 * Assigns a variant for an experiment to a user via cookie based on DB configuration
 * @param {string} experimentName - The name of the experiment to assign
 * @param {Object[]} activeExperiments - Array of all active experiments
 * @param {Object} [options] - Configuration options
 * @param {number} [options.expirationDays=90] - Number of days until cookie expires
 * @param {string[]} [options.excludeDeviceTypes] - Array of device types to exclude
 * @param {string} [options.forceVariant] - Force a specific variant
 * @param {string} [options.domain] - The domain for the cookie
 * @returns {string|null} - Returns the assigned variant or null if invalid config or device type is excluded
 */
function tryAssignActiveCookieExperiment(
  experimentName,
  activeExperiments,
  options = {},
) {
  if (recentAssignments.has(experimentName)) {
    return recentAssignments.get(experimentName);
  }

  const cookieName = `experiment_${experimentName}`;
  const existingVariant = getCookie(cookieName);

  if (existingVariant) {
    recentAssignments.set(experimentName, existingVariant);
    setTimeout(() => {
      recentAssignments.delete(experimentName);
    }, 500);
    return existingVariant;
  }

  const experimentConfig = activeExperiments.find(
    (exp) => exp.name === experimentName,
  );

  if (!experimentConfig) {
    console.warn(`No experiment configuration found for "${experimentName}"`);
    return null;
  }

  const { experiment_variants, winner_variant_id } = experimentConfig;
  const {
    expirationDays = 90,
    excludeDeviceTypes = [],
    forceVariant,
    domain,
  } = options;

  if (!experiment_variants?.length) {
    console.warn('Invalid experiment configuration provided');
    return null;
  }

  if (forceVariant) {
    const validVariant = experiment_variants.some(
      (variant) => variant.name === forceVariant,
    );
    if (validVariant) {
      const cookieName = `experiment_${experimentName}`;
      setCookie(cookieName, forceVariant, expirationDays, domain);
      return forceVariant;
    }
    console.warn(
      `Forced variant "${forceVariant}" is not valid for experiment "${experimentName}"`,
    );
  }

  // If there's a winner variant, assign it directly
  if (winner_variant_id) {
    const winningVariant = experiment_variants.find(
      (variant) => variant.id === winner_variant_id,
    );
    if (winningVariant) {
      const cookieName = `experiment_${experimentName}`;
      setCookie(cookieName, winningVariant.name, expirationDays, domain);
      return winningVariant.name;
    }
  }

  if (excludeDeviceTypes.length > 0) {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const isTablet = /iPad|Android(?!.*Mobile)/i.test(navigator.userAgent);

    let currentDeviceType;
    if (isTablet) {
      currentDeviceType = 'tablet';
    } else if (isMobile) {
      currentDeviceType = 'mobile';
    } else {
      currentDeviceType = 'desktop';
    }

    if (excludeDeviceTypes.includes(currentDeviceType)) {
      return null;
    }
  }

  const totalAllocation = experiment_variants.reduce(
    (sum, variant) => sum + variant.allocation,
    0,
  );

  const random = Math.floor(Math.random() * totalAllocation) + 1;
  let cumulativePoints = 0;

  for (const variant of experiment_variants) {
    cumulativePoints += variant.allocation;
    if (random <= cumulativePoints) {
      setCookie(cookieName, variant.name, expirationDays, domain);
      return variant.name;
    }
  }

  // Fallback to first variant if something goes wrong
  setCookie(cookieName, experiment_variants[0].name, expirationDays, domain);
  return experiment_variants[0].name;
}

export default tryAssignActiveCookieExperiment;
