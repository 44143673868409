import React from 'react';
import { useField } from 'react-final-form';
import cx from 'classnames';
import PropTypes from 'prop-types';

import isLengthLessThanOrEqualTo from '../../validators/isLengthLessThanOrEqualTo';
import Input from '../Input';

import CoreField from './CoreField';

import styles from './TextAreaField.module.scss';

const TextAreaField = ({
  name,
  children,
  className,
  maxLength,
  withCounter = false,
  validate = [],
  ...props
}) => {
  const field = useField(name);
  const validations = [...(Array.isArray(validate) ? validate : [validate])];

  if (maxLength !== null && maxLength !== undefined) {
    validations.push(isLengthLessThanOrEqualTo(maxLength));
  }

  return (
    <>
      <CoreField
        name={name}
        component={Input}
        type="message"
        as="textarea"
        maxLength={maxLength}
        className={cx(styles.textareaField, className)}
        validate={validations}
        {...props}
      />
      {withCounter && (
        <span className={styles.counter}>
          {withCounter &&
            `${field?.input?.value.length} / ${maxLength} characters used`}
        </span>
      )}
    </>
  );
};

TextAreaField.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  withCounter: PropTypes.bool,
  validate: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.func),
  ]),
};

export default TextAreaField;
