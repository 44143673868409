import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import colors from '../../../constants/colors';
import Radio from '../icons/Radio';
import RadioChecked from '../icons/RadioChecked';

import styles from './VerticalProgressBarOption.module.scss';

const VerticalProgressBarOption = ({
  className,
  label,
  labelClassName,
  labelContent,
  completed,
  active,
  lastOption,
  children,
}) => {
  let icon;
  if (completed) {
    icon = <RadioChecked className={styles.icon} />;
  } else if (active) {
    icon = <Radio className={styles.icon} fill={colors.primary} />;
  } else {
    icon = <Radio className={styles.icon} fill={colors.greyLight} />;
  }

  return (
    <div
      className={cx(styles.optionWrap, {
        [styles.isDone]: completed,
        [styles.lastOption]: lastOption,
      })}
    >
      {icon}
      <div
        className={cx(styles.option, className, {
          [styles.lastOption]: lastOption,
        })}
      >
        <div className={styles.labelWrapper}>
          <span className={cx(styles.label, labelClassName)}>
            {labelContent || label}
          </span>
        </div>
        {children ? children : null}
      </div>
    </div>
  );
};

VerticalProgressBarOption.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  labelContent: PropTypes.node,
  completed: PropTypes.bool,
  active: PropTypes.bool,
  children: PropTypes.node,
};

export default VerticalProgressBarOption;
