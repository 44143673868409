import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import colors from '../../../../../constants/colors';
import CheckMarkFilled from '../../../../../icons/CheckMarkFilled';

import styles from './BenefitItemExperimentH.module.scss';

const BenefitItemExperimentHMobile = ({
  item,
  extraParams,
  isProPlan = false,
  showCheckmark,
}) => {
  return (
    <div key={item.title} className={styles.benefitItem}>
      <div className={cx(styles.plan, { [styles.proPlan]: isProPlan })}>
        <div className={styles.colLeft}>
          <h3 className={styles.itemTitle}>{item.title}</h3>
          {item?.description && (
            <p className={styles.itemDescription}>{item.description}</p>
          )}
          {item.cta && (
            <div className={styles.itemCta}>
              <a
                href={
                  typeof item.ctaLink === 'function'
                    ? item.ctaLink(extraParams)
                    : item.ctaLink
                }
                target="_blank"
                rel="noopener noreferrer"
                onClick={item.onClick || (() => {})}
              >
                {item.cta}
              </a>
            </div>
          )}
        </div>

        {showCheckmark && (
          <div className={styles.checkmarkContainer}>
            <CheckMarkFilled color={colors.slimProgressBarFill} />
          </div>
        )}
      </div>
    </div>
  );
};

BenefitItemExperimentHMobile.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    cta: PropTypes.string,
    ctaLink: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    onClick: PropTypes.func,
    icon: PropTypes.func,
    iconProps: PropTypes.object,
  }).isRequired,
  extraParams: PropTypes.object,
  isProPlan: PropTypes.bool,
  showCheckmark: PropTypes.bool,
};

export default BenefitItemExperimentHMobile;
