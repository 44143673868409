import { isAdminLogin } from '../../core/auth/authService';
import { getSignupTrackingCookie } from '../../helpers/signupTrackingData';
import { getConfig } from '../configService';
import { getItem } from '../localStorageService';

function retrieveUnAuthExperiments() {
  /**
   * NOTE: We try to prefix our cookies - ls variables with experiment_
   * so we can automatically pick them up here. But some that were created without
   * awareness of this are hardcoded in this 'toRetrieve' list.
   */
  const toRetrieve = ['signup_test_onboarding'];

  const localStorageExperiments = Object.keys(localStorage)
    .filter((key) => key.startsWith('experiment_') || toRetrieve.includes(key))
    .reduce((acc, key) => {
      acc[key] = getItem(key);
      return acc;
    }, {});

  const cookieExperiments = document.cookie
    .split(';')
    .map((cookie) => cookie.trim())
    .filter((cookie) => {
      const cookieName = cookie.split('=')[0];
      return (
        cookieName.startsWith('experiment_') || toRetrieve.includes(cookieName)
      );
    })
    .reduce((acc, cookie) => {
      const [key, value] = cookie.split('=');
      try {
        acc[key] = decodeURIComponent(value);
      } catch {
        acc[key] = value;
      }
      return acc;
    }, {});

  return { ...localStorageExperiments, ...cookieExperiments };
}

// NOTE: We will soon add logic here to trigger mixpanel session replays if it is configured inside of the experiment.
// For now we are hardcoding it to only work for gt_oa_multi_select experiment as this is a PoC

export function segmentTracking(event, properties) {
  const loggedInAsAdmin = isAdminLogin();
  if (!window.analytics) {
    return;
  }
  if (!loggedInAsAdmin) {
    //grab values from cookie and send them to segment
    const { source, medium, campaign, content, term } =
      getSignupTrackingCookie();

    const experiments = JSON.parse(getItem('active_experiments') || '[]');

    const ownerExperiments = JSON.parse(
      getItem('owner_active_experiments') || '[]',
    );

    const experimentsAttributes = {};

    experiments.forEach((experiment) => {
      experimentsAttributes[`experiment_${experiment.name}`] = experiment.value;
    });

    ownerExperiments.forEach((experiment) => {
      experimentsAttributes[`oae_experiment_${experiment.name}`] =
        experiment.value;
    });

    window.analytics.track(
      event,
      {
        ...properties,
        utm_source: source,
        utm_medium: medium,
        utm_campaign: campaign,
        utm_content: content,
        utm_term: term,
        // We destructure retrieveUnAuthExperiments() before experimentsAttributes to ensure experiments coming from the db take priority over the ones coming from the cookie.
        ...(retrieveUnAuthExperiments() || {}),
        ...experimentsAttributes,
      },
      {
        context: {
          campaign: {
            source,
            medium,
            campaign,
            content,
            term,
            name: campaign,
          },
        },
      },
    );
  }
}

export function segmentIdentify(globalId, intercomHash, traits = {}) {
  const { IS_OWNER } = getConfig();
  const loggedInAsAdmin = isAdminLogin();
  if (window.analytics && !loggedInAsAdmin) {
    const decodedId = atob(globalId).split(':').pop();
    window.analytics.ready(function () {
      window.analytics.identify(
        `${IS_OWNER ? 'Owner' : 'Renter'}:${decodedId}`,
        { ...traits },
        {
          ...(intercomHash && {
            integrations: {
              Intercom: {
                user_hash: intercomHash,
              },
            },
          }),
        },
        () => {
          //grab values from cookie and send them to segment
          const { source, medium, campaign, content, term } =
            getSignupTrackingCookie();
          window.analytics.page(
            null,
            null,
            {
              utm_source: source,
              utm_medium: medium,
              utm_campaign: campaign,
              utm_content: content,
              utm_term: term,
            },
            {
              context: {
                campaign: {
                  source,
                  medium,
                  campaign,
                  content,
                  term,
                  name: campaign,
                },
              },
            },
          );
        },
      );
    });
  }
}
