import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../components/Modal/index';

import REIHubSubscriptionSuccess from './REIHubSubscriptionSuccess';

import styles from './REIHubSubscriptionSuccessModal.module.scss';

const ReiHubSubscriptionSuccessModal = ({ open, onClose, ...rest }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      className={styles.container}
      autoFocus={false}
      id="reiHubSubscriptionSuccessModal"
    >
      <REIHubSubscriptionSuccess
        onClose={onClose}
        className={styles.card}
        {...rest}
      />
    </Modal>
  );
};

ReiHubSubscriptionSuccessModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ReiHubSubscriptionSuccessModal;
