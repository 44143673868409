import selection from './selection.json';

export const allowedSizes = [
  'sm',
  'md',
  'lg',
  'xl',
  'xxl',
  'xxxl',
  'huge',
  'max',
];

export const allowedColors = [
  'default',
  'navy',
  'primary',
  'secondary',
  'reverse-secondary',
  'reverse',
  'success',
  'warning',
  'error',
  'partial',
  'close-darkbg',
  'close-lightbg',
  'star',
];
export const iconNames = selection.icons.map((icon) => icon.properties.name);
