import React from 'react';

import { smallScreenWidth } from '../../../../constants/media-breakpoints';
import { useIsScreenSize } from '../../../../hooks/useIsScreenSize';

import DesktopSkeleton from './components/DesktopSkeleton';
import MobileSkeleton from './components/MobileSkeleton';

import styles from './DocumentsSkeleton.module.scss';

const DocumentsSkeleton = () => {
  const isSmallScreen = useIsScreenSize(smallScreenWidth);

  if (isSmallScreen) {
    return <MobileSkeleton className={styles.mobile} />;
  }

  return <DesktopSkeleton className={styles.desktop} />;
};

export default DocumentsSkeleton;
