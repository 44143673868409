import React from 'react';
import { useLottie } from 'lottie-react';

import isiOSDevice from '../../helpers/isIosDevice';
import IframeAnimation from '../Animations/iOSIframeAnimation';

import animationPathJson from './assets/accountingSuccess.animation.json';

const AccountingSuccessAnimation = () => {
  const style = {
    minHeight: '100vh',
    minWidth: '100vw',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 0,
  };

  const options = {
    path: animationPathJson,
    loop: true,
    autoplay: true,
  };

  const LoaderData = useLottie(options, style);

  const iOS = isiOSDevice();

  if (iOS) {
    return (
      <IframeAnimation
        style={style}
        src="https://lottie.host/embed/6d837f18-e2a3-42fe-a2c7-856024cf05a5/1K20KhJOdG.json"
      />
    );
  }

  return LoaderData.View;
};

export default AccountingSuccessAnimation;
