import React, { useEffect } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import CheckMarkFilled from '../../icons/CheckMarkFilled';
import FireworksAnimation from '../Animations/FireworksAnimation';
import Button from '../Button';
import Modal from '../Modal';

import styles from './SuccessModal.module.scss';

const LeaseChecklistSuccessModal = ({
  className,
  open,
  title,
  secondaryLabel,
  secondaryAction,
  primaryLabel,
  primaryAction,
  onLoad,
  classNameActions,
  classNameTitle,
  children,
  showAnimation,
  showCheckMark,
}) => {
  useEffect(() => {
    if (open) {
      onLoad();
    }
  }, [open]);

  return (
    <Modal
      autoFocus={false}
      open={open}
      containerClassName={styles.modalContainer}
      className={cx(styles.container, className)}
      mobileFullScreen
      onClose={secondaryAction}
    >
      {showAnimation && (
        <div className={styles.animation}>
          <FireworksAnimation />
        </div>
      )}

      {showCheckMark && (
        <div className={styles.checkmark}>
          <CheckMarkFilled width={96} height={96} color="#009E74" />
        </div>
      )}

      <h2 className={cx(styles.title, classNameTitle)}>{title}</h2>
      {children}
      <div className={cx(styles.actions, classNameActions)}>
        {secondaryLabel && (
          <Button
            className={styles.backBtn}
            variant="secondary"
            onClick={secondaryAction}
          >
            {secondaryLabel}
          </Button>
        )}
        <Button onClick={primaryAction} variant="primary">
          {primaryLabel}
        </Button>
      </div>
    </Modal>
  );
};

LeaseChecklistSuccessModal.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  title: PropTypes.node,
  secondaryLabel: PropTypes.string,
  secondaryAction: PropTypes.func,
  primaryLabel: PropTypes.string,
  primaryAction: PropTypes.func,
  onClose: PropTypes.func,
  onAction: PropTypes.func,
  onLoad: PropTypes.func,
  classNameActions: PropTypes.string,
  classNameTitle: PropTypes.string,
  children: PropTypes.node,
  showAnimation: PropTypes.bool,
  showCheckMark: PropTypes.bool,
};

LeaseChecklistSuccessModal.defaultProps = {
  secondaryAction: () => ({}),
  primaryAction: () => ({}),
  onLoad: () => {},
  showAnimation: true,
  showCheckMark: false,
};

export default LeaseChecklistSuccessModal;
