import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import LoadingScreen from '../../../../../components/LoadingScreen';
import { useErrorToast } from '../../../../../components/Toast';
import PLAID_VERIFICATION_STATUS from '../../../../../constants/plaid_verifications/plaid_verification_status';
import { useUserProfile } from '../../../../../core/TTgraphql';
import basicUserProfileGQL from '../../../../../graphql/basicUserProfile.graphql';
import createManualPlaidVerificationMutationGQL from '../../../../../graphql/mutations/plaid_verifications/createManualPlaidVerificationMutation.graphql';
import renterPlaidVerifications from '../../../../../graphql/queries/renterPlaidVerifications.graphql';
import renterSettingsBillingQueryGQL from '../../../../../graphql/queries/renterSettingsBillingQuery.graphql';
import { useConfig } from '../../../../../providers/ConfigProvider';
import { getLinkToken } from '../../../../../services/plaidService';
import StripeElementsWrapper from '../../../../common/stripe/StripeElementsWrapper';
import { useUpdatePaymentMethod } from '../../../../payments/usePayments';

import AddPaymentMethods from './AddPaymentMethods';

const AddPaymentMethodsWrapper = ({
  history,
  location,
  hideBackButton,
  redirectToBeforeSuccess,
  redirectToBeforeVerify,
  setupPaymentsFlowData,
  showSuccessToast,
  wrapPaymentMethodsClassName,
  wrapStripeElementClassName,
  loadingWrapperClassName,
  showCancelButton,
  skipIdentityVerification,
  linkForExistingRentPaymentsPaymentMethod,
  showExistingRentPaymentsPaymentMethod,
}) => {
  const { PRIVATE_BASE_PATH } = useConfig();
  const showErrorMessage = useErrorToast();
  const { user, loading: userLoading } = useUserProfile();

  const [updatePaymentMethod, { loading: loadingMut }] =
    useUpdatePaymentMethod();

  const [
    createManualPlaidVerificationMutation,
    { loading: loadingCreateManualPlaidVerificationMutation },
  ] = useMutation(createManualPlaidVerificationMutationGQL);

  const [config, setConfig] = useState(null);
  const [reloadingPlaid, setReloadingPlaid] = useState(false);
  const [manualMicrodepositsFlowSelected, setManualMicrodepositsFlowSelected] =
    useState(false);

  const selectManualMicrodepositsFlow = (
    useManualMicrodeposits = false,
    onLoad = null,
  ) => {
    setManualMicrodepositsFlowSelected({ useManualMicrodeposits, onLoad });
  };

  const configExists = config && Object.keys(config).length > 0;

  const queryParameters = queryString.parse(location.search) || {};
  const { redirectTo } = queryParameters;

  const verificationRequired = get(
    user,
    'stripe_id_verification_required',
    false,
  );
  const makePaymentFlow = location?.state?.makePaymentFlow || false;

  const postSubmitActions = (data) => {
    if (data?.updatePaymentMethod?.ok === false) {
      showErrorMessage('An error occurred');
      return;
    }

    if (!skipIdentityVerification && verificationRequired) {
      if (setupPaymentsFlowData) {
        return setupPaymentsFlowData.showStripeId();
      }

      return history.push(
        `${PRIVATE_BASE_PATH}payments/verify-identity${
          makePaymentFlow ? '?makePaymentFlow=1' : ''
        }`,
      );
    }

    if (typeof setupPaymentsFlowData?.onSuccess === 'function') {
      return setupPaymentsFlowData.onSuccess();
    }

    if (redirectToBeforeSuccess) {
      return history.push(redirectToBeforeSuccess);
    }

    return history.push({
      pathname: `${PRIVATE_BASE_PATH}payments/payment-method-success`,
      state: { redirectTo, ...location.state },
    });
  };

  const handleBankAddViaPlaid = async (plaidPublicToken, accounts) => {
    try {
      const { account_id: plaidAccountId } = accounts;
      const verificationStatus = accounts?.account?.verification_status;
      const accountName = accounts?.account?.name?.split('...')[0]?.trim();
      const last4 = accounts?.account?.mask;

      // If user enters plaid manual flow
      if (
        verificationStatus ===
        PLAID_VERIFICATION_STATUS.PENDING_MANUAL_VERIFICATION
      ) {
        const res = await createManualPlaidVerificationMutation({
          variables: {
            plaid_account_id: plaidAccountId,
            plaid_public_token: plaidPublicToken,
            account_name: accountName,
            last4,
          },
          refetchQueries: [
            {
              query: renterSettingsBillingQueryGQL,
            },
            {
              query: renterPlaidVerifications,
            },
            {
              query: basicUserProfileGQL,
            },
          ],
          ...(setupPaymentsFlowData ? { awaitRefetchQueries: true } : {}),
        });

        if (res?.data?.createManualPlaidVerificationMutation) {
          if (setupPaymentsFlowData) {
            return setupPaymentsFlowData?.manualPlaidFlowSelected();
          }

          if (redirectToBeforeVerify) {
            return history.push(redirectToBeforeVerify);
          }

          return history.push({
            pathname: `${PRIVATE_BASE_PATH}payments/plaid-manual-setup-started`,
            state: { ...location.state, redirectTo },
          });
        }
      }

      const res = await updatePaymentMethod({
        variables: {
          plaidPublicToken,
          plaidAccountId,
          isRentPaymentsPaymentMethod: true,
        },
        refetchQueries: [
          {
            query: renterSettingsBillingQueryGQL,
          },
          {
            query: basicUserProfileGQL,
          },
        ],
      });

      postSubmitActions(res?.data);
    } catch (err) {
      if (err?.graphQLErrors?.[0]?.message) {
        showErrorMessage(err?.graphQLErrors?.[0].message);
      } else if (typeof err === 'string') {
        showErrorMessage(err);
      } else {
        console.error(err);
        showErrorMessage(err.toString());
      }
    }
  };

  useEffect(() => {
    const addPlaidConfig = async () => {
      setReloadingPlaid(true);
      const token = await getLinkToken(
        null,
        manualMicrodepositsFlowSelected?.useManualMicrodeposits,
      );
      setConfig({
        // Required, fetch a link token from your server and pass it
        // back to your app to initialize Link.
        token,
        onSuccess: handleBankAddViaPlaid,
        onLoad: () => {
          setReloadingPlaid(false);
          typeof manualMicrodepositsFlowSelected?.onLoad === 'function' &&
            manualMicrodepositsFlowSelected?.onLoad();
        },
      });
    };
    addPlaidConfig();
  }, [manualMicrodepositsFlowSelected]);

  return (
    <StripeElementsWrapper>
      <LoadingScreen
        className={loadingWrapperClassName}
        loading={
          !configExists ||
          loadingMut ||
          loadingCreateManualPlaidVerificationMutation ||
          userLoading ||
          reloadingPlaid
        }
      >
        {config && (
          <AddPaymentMethods
            plaidConfig={config}
            hideBackButton={hideBackButton}
            showSuccessToast={showSuccessToast}
            selectManualMicrodepositsFlow={selectManualMicrodepositsFlow}
            setupPaymentsFlowData={setupPaymentsFlowData}
            postSubmitActions={postSubmitActions}
            wrapStripeElementClassName={wrapStripeElementClassName}
            wrapPaymentMethodsClassName={wrapPaymentMethodsClassName}
            redirectToBeforeSuccess={redirectToBeforeSuccess}
            showCancelButton={showCancelButton}
            showExistingRentPaymentsPaymentMethod={
              showExistingRentPaymentsPaymentMethod
            }
            linkForExistingRentPaymentsPaymentMethod={
              linkForExistingRentPaymentsPaymentMethod
            }
          />
        )}
      </LoadingScreen>
    </StripeElementsWrapper>
  );
};

AddPaymentMethodsWrapper.propTypes = {
  setupPaymentsFlowData: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  hideBackButton: PropTypes.bool,
  showSuccessToast: PropTypes.bool,
  redirectToBeforeSuccess: PropTypes.string,
  redirectToBeforeVerify: PropTypes.string,
  wrapPaymentMethodsClassName: PropTypes.string,
  wrapStripeElementClassName: PropTypes.string,
  loadingWrapperClassName: PropTypes.string,
  showCancelButton: PropTypes.bool,
  skipIdentityVerification: PropTypes.bool,
  showExistingRentPaymentsPaymentMethod: PropTypes.bool,
  linkForExistingRentPaymentsPaymentMethod: PropTypes.string,
};

export default withRouter(AddPaymentMethodsWrapper);
