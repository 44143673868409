import React, { useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';

import { useUserProfile } from '../../../core/TTgraphql';
import { useConfig } from '../../../providers/ConfigProvider';
import { getConfig } from '../../../services/configService';
import { getActiveLeaseForRenter } from '../../../services/utilities/getActiveLeaseForRenter';

const StripeElementsWrapper = ({ stripeKey, children }) => {
  const { user } = useUserProfile({
    polling: false,
  });
  const { IS_OWNER } = getConfig();

  const { STRIPE_PUBLISHED_KEY, STRIPE_AZIBO_PUBLISHED_KEY } = useConfig();

  const currentLease = getActiveLeaseForRenter(user);

  const defaultStripeKey = useMemo(() => {
    if (IS_OWNER) {
      if (user.is_azibo) return STRIPE_AZIBO_PUBLISHED_KEY;
      return STRIPE_PUBLISHED_KEY;
    }

    if (currentLease?.is_azibo) return STRIPE_AZIBO_PUBLISHED_KEY;
    return STRIPE_PUBLISHED_KEY;
  }, [
    IS_OWNER,
    currentLease?.is_azibo,
    STRIPE_AZIBO_PUBLISHED_KEY,
    STRIPE_PUBLISHED_KEY,
  ]);

  const stripePromise = useMemo(
    () => loadStripe(stripeKey || defaultStripeKey),
    [stripeKey, defaultStripeKey],
  );

  return (
    <Elements
      stripe={stripePromise}
      options={{
        fonts: [
          {
            cssSrc:
              'https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap',
          },
        ],
      }}
    >
      {children}
    </Elements>
  );
};

StripeElementsWrapper.propTypes = {
  stripeKey: PropTypes.string,
  children: PropTypes.node,
};

export default StripeElementsWrapper;
