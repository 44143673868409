import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Plus = ({
  color = 'currentColor',
  width = 24,
  height = 24,
  ...props
}) => (
  <svg
    viewBox="0 0 24 24"
    fill={color}
    width={width}
    height={height}
    {...props}
  >
    <path d="M12 2.21c5.523 0 10 4.478 10 10 0 5.523-4.478 10-10 10s-10-4.477-10-10c0-5.522 4.477-10 10-10Zm0 1.278a8.724 8.724 0 1 0 .001 17.445A8.724 8.724 0 0 0 12 3.488Zm0 3.978c.352 0 .638.286.638.639v8.212a.638.638 0 0 1-1.276 0V8.105c0-.353.286-.639.638-.639Zm4.106 4.107a.638.638 0 0 1 0 1.276H7.894a.638.638 0 1 1 0-1.276h8.212Z" />
  </svg>
);

Plus.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default memo(Plus);
