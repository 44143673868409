import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import cx from 'classnames';
import PropTypes from 'prop-types';

import DownArrow from '../../icons/DownArrow';
import Button from '../Button';

import styles from './ButtonPopover.module.scss';

const ButtonPopover = ({
  className,
  optionsClassName,
  label,
  buttonProps,
  small,
  children,
  customAction: CustomAction,
  closeOnClick,
  iconAfter = true,
  customContainerProps,
  offset,
  opened: controlledOpened,
  setOpened: setControlledOpened,
  ...restProps
}) => {
  const [internalOpened, setInternalOpened] = useState(false);

  const opened =
    controlledOpened !== undefined ? controlledOpened : internalOpened;
  const openPopover = setControlledOpened || setInternalOpened;

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const popper = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: offset || [10, 10],
        },
      },
    ],
  });

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        referenceElement &&
        !referenceElement.contains(event.target) &&
        !referenceElement.nextSibling?.contains(event.target)
      ) {
        setTimeout(() => openPopover(false), 0);
      }
    };
    document.addEventListener('click', handleOutsideClick, true);
    return () => {
      document.removeEventListener('click', handleOutsideClick, true);
    };
  }, [opened, referenceElement, openPopover]);

  return (
    <>
      <div
        className={cx(styles.container, className, {
          [styles.custom]: !!CustomAction,
        })}
        ref={setReferenceElement}
        {...customContainerProps}
      >
        {CustomAction ? (
          <CustomAction
            onClick={() => {
              openPopover(!opened);
            }}
            {...buttonProps}
            small={small}
            {...restProps}
          />
        ) : (
          <Button
            {...buttonProps}
            small={small}
            icon={DownArrow}
            iconAfter={iconAfter}
            onClick={() => {
              openPopover(!opened);
            }}
            {...restProps}
          >
            {label}
          </Button>
        )}
      </div>
      {opened && (
        <div
          className={cx(styles.options, optionsClassName)}
          onClick={() => {
            if (closeOnClick) {
              openPopover(false);
            }
          }}
          {...popper.attributes.popper}
          ref={setPopperElement}
          style={popper.styles.popper}
        >
          {children}
        </div>
      )}
    </>
  );
};

ButtonPopover.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  buttonProps: PropTypes.object,
  children: PropTypes.node,
  closeOnClick: PropTypes.bool,
  small: PropTypes.bool,
  optionsClassName: PropTypes.string,
  customAction: PropTypes.func,
  iconAfter: PropTypes.bool,
  customContainerProps: PropTypes.object,
  offset: PropTypes.array,
  opened: PropTypes.bool,
  setOpened: PropTypes.func,
};

export default ButtonPopover;
