import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { allowedSizes, iconNames } from './constants';

import fontStyles from './BrandFont.module.scss';
import styles from './BrandIcon.module.scss';

const BrandIcon = ({ name, className, size = 'lg', ...rest }) => (
  <span
    style={{
      fontSize: typeof size === 'number' && `${size}px`,
    }}
    className={cx(
      styles.brandIcon,
      fontStyles[`tt-icon-brand-${name}`],
      {
        [styles[`font-size-${size}`]]: allowedSizes.includes(size),
      },
      className,
    )}
    {...rest}
  >
    {Array(11)
      .fill(0)
      .map((_, i) => (
        <span
          key={i}
          className={cx(
            fontStyles[`tt-icon-brand-${name}`],
            fontStyles[`path${i + 1}`],
          )}
        />
      ))}
  </span>
);

BrandIcon.propTypes = {
  name: PropTypes.oneOf(iconNames).isRequired,
  className: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(allowedSizes)]),
};

export default BrandIcon;
