import PropTypes from 'prop-types';

const commonFields = {
  id: PropTypes.string.isRequired,
  email: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  active: PropTypes.bool,
  has_password: PropTypes.bool,
  created_at: PropTypes.string,
  telephone: PropTypes.string,
  is_azibo: PropTypes.bool,
};

export const renterPropTypes = PropTypes.shape({
  ...commonFields,
  __typename: PropTypes.oneOf(['RenterProfile']).isRequired,
});

export const ownerPropTypes = PropTypes.shape({
  ...commonFields,
  __typename: PropTypes.oneOf(['OwnerProfile']).isRequired,
});

const userPropType = PropTypes.oneOfType([renterPropTypes, ownerPropTypes]);

export default userPropType;
