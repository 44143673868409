import React, { memo } from 'react';
import PropTypes from 'prop-types';

import SkeletonSvgGradient from '../../../../../components/Skeleton/SkeletonSvgGradient';

const DesktopSkeleton = ({ borderClassName, ...props }) => (
  <svg width="100%" height="auto" viewBox="0 0 960 867" fill="none" {...props}>
    <SkeletonSvgGradient />
    <path
      d="M0.5 3.99999C0.5 2.067 2.067 0.5 4 0.5H956C957.933 0.5 959.5 2.067 959.5 4V442C959.5 443.933 957.933 445.5 956 445.5H4C2.067 445.5 0.5 443.933 0.5 442V3.99999Z"
      stroke="#E6E9F0"
      className={borderClassName}
    />
    <path
      d="M32 36C32 33.7909 33.7909 32 36 32H172C174.209 32 176 33.7909 176 36V44C176 46.2091 174.209 48 172 48H36C33.7909 48 32 46.2091 32 44V36Z"
      fill="#E6E9F0"
    />
    <path
      d="M32 56C32 53.7909 33.7909 52 36 52H364C366.209 52 368 53.7909 368 56V78C368 80.2091 366.209 82 364 82H36C33.7909 82 32 80.2091 32 78V56Z"
      fill="#E6E9F0"
    />
    <path
      d="M32 90C32 87.7909 33.7909 86 36 86H316C318.209 86 320 87.7909 320 90V106C320 108.209 318.209 110 316 110H36C33.7909 110 32 108.209 32 106V90Z"
      fill="#E6E9F0"
    />
    <circle cx="52" cy="146" r="20" fill="#E6E9F0" />
    <path
      d="M80 138C80 135.791 81.7909 134 84 134H532C534.209 134 536 135.791 536 138V154C536 156.209 534.209 158 532 158H84C81.7909 158 80 156.209 80 154V138Z"
      fill="#E6E9F0"
    />
    <circle cx="42" cy="192" r="10" fill="#E6E9F0" />
    <rect
      x="40"
      y="218"
      width="16"
      height="4"
      transform="rotate(-90 40 218)"
      fill="#E6E9F0"
    />
    <mask id="path-10-inside-1_13561_32782" fill="white">
      <path d="M56 182H924V218H56V182Z" />
    </mask>
    <path
      d="M924 217H56V219H924V217Z"
      fill="#E6E9F0"
      mask="url(#path-10-inside-1_13561_32782)"
    />
    <path
      d="M56 186C56 183.791 57.7909 182 60 182H140C142.209 182 144 183.791 144 186V194C144 196.209 142.209 198 140 198H60C57.7909 198 56 196.209 56 194V186Z"
      fill="#E6E9F0"
    />
    <path
      d="M764 186C764 183.791 765.791 182 768 182H920C922.209 182 924 183.791 924 186V202C924 204.209 922.209 206 920 206H768C765.791 206 764 204.209 764 202V186Z"
      fill="#E6E9F0"
    />
    <rect
      x="40"
      y="230"
      width="12"
      height="4"
      transform="rotate(-90 40 230)"
      fill="#E6E9F0"
    />
    <circle cx="42" cy="240" r="10" fill="#E6E9F0" />
    <rect
      x="40"
      y="346"
      width="96"
      height="4"
      transform="rotate(-90 40 346)"
      fill="#E6E9F0"
    />
    <mask id="path-17-inside-2_13561_32782" fill="white">
      <path d="M56 230H924V346H56V230Z" />
    </mask>
    <path
      d="M924 345H56V347H924V345Z"
      fill="#E6E9F0"
      mask="url(#path-17-inside-2_13561_32782)"
    />
    <path
      d="M56 234C56 231.791 57.7909 230 60 230H140C142.209 230 144 231.791 144 234V242C144 244.209 142.209 246 140 246H60C57.7909 246 56 244.209 56 242V234Z"
      fill="#E6E9F0"
    />
    <path
      d="M184 234C184 231.791 185.791 230 188 230H444C446.209 230 448 231.791 448 234V246C448 248.209 446.209 250 444 250H188C185.791 250 184 248.209 184 246V234Z"
      fill="#E6E9F0"
    />
    <path
      d="M184 258C184 255.791 185.791 254 188 254H364C366.209 254 368 255.791 368 258V270C368 272.209 366.209 274 364 274H188C185.791 274 184 272.209 184 270V258Z"
      fill="#E6E9F0"
    />
    <path
      d="M844 234C844 231.791 845.791 230 848 230H920C922.209 230 924 231.791 924 234V250C924 252.209 922.209 254 920 254H848C845.791 254 844 252.209 844 250V234Z"
      fill="#E6E9F0"
    />
    <path
      d="M184 294C184 291.791 185.791 290 188 290H444C446.209 290 448 291.791 448 294V306C448 308.209 446.209 310 444 310H188C185.791 310 184 308.209 184 306V294Z"
      fill="#E6E9F0"
    />
    <path
      d="M184 318C184 315.791 185.791 314 188 314H364C366.209 314 368 315.791 368 318V330C368 332.209 366.209 334 364 334H188C185.791 334 184 332.209 184 330V318Z"
      fill="#E6E9F0"
    />
    <path
      d="M844 294C844 291.791 845.791 290 848 290H920C922.209 290 924 291.791 924 294V310C924 312.209 922.209 314 920 314H848C845.791 314 844 312.209 844 310V294Z"
      fill="#E6E9F0"
    />
    <rect
      x="40"
      y="358"
      width="12"
      height="4"
      transform="rotate(-90 40 358)"
      fill="#E6E9F0"
    />
    <circle cx="42" cy="368" r="10" fill="#E6E9F0" />
    <path
      d="M56 362C56 359.791 57.7909 358 60 358H140C142.209 358 144 359.791 144 362V370C144 372.209 142.209 374 140 374H60C57.7909 374 56 372.209 56 370V362Z"
      fill="#E6E9F0"
    />
    <path
      d="M184 362C184 359.791 185.791 358 188 358H444C446.209 358 448 359.791 448 362V374C448 376.209 446.209 378 444 378H188C185.791 378 184 376.209 184 374V362Z"
      fill="#E6E9F0"
    />
    <path
      d="M184 386C184 383.791 185.791 382 188 382H364C366.209 382 368 383.791 368 386V398C368 400.209 366.209 402 364 402H188C185.791 402 184 400.209 184 398V386Z"
      fill="#E6E9F0"
    />
    <rect x="724" y="360" width="200" height="40" rx="20" fill="#E6E9F0" />
    <path
      d="M0.5 466C0.5 464.067 2.067 462.5 4 462.5H956C957.933 462.5 959.5 464.067 959.5 466V904C959.5 905.933 957.933 907.5 956 907.5H4C2.067 907.5 0.5 905.933 0.5 904V466Z"
      fill="white"
    />
    <path
      d="M0.5 466C0.5 464.067 2.067 462.5 4 462.5H956C957.933 462.5 959.5 464.067 959.5 466V904C959.5 905.933 957.933 907.5 956 907.5H4C2.067 907.5 0.5 905.933 0.5 904V466Z"
      stroke="#E6E9F0"
      className={borderClassName}
    />
    <path
      d="M32 498C32 495.791 33.7909 494 36 494H172C174.209 494 176 495.791 176 498V506C176 508.209 174.209 510 172 510H36C33.7909 510 32 508.209 32 506V498Z"
      fill="#E6E9F0"
    />
    <path
      d="M32 518C32 515.791 33.7909 514 36 514H364C366.209 514 368 515.791 368 518V540C368 542.209 366.209 544 364 544H36C33.7909 544 32 542.209 32 540V518Z"
      fill="#E6E9F0"
    />
    <path
      d="M32 552C32 549.791 33.7909 548 36 548H316C318.209 548 320 549.791 320 552V568C320 570.209 318.209 572 316 572H36C33.7909 572 32 570.209 32 568V552Z"
      fill="#E6E9F0"
    />
    <circle cx="52" cy="608" r="20" fill="#E6E9F0" />
    <path
      d="M80 600C80 597.791 81.7909 596 84 596H532C534.209 596 536 597.791 536 600V616C536 618.209 534.209 620 532 620H84C81.7909 620 80 618.209 80 616V600Z"
      fill="#E6E9F0"
    />
    <circle cx="42" cy="654" r="10" fill="#E6E9F0" />
    <rect
      x="40"
      y="680"
      width="16"
      height="4"
      transform="rotate(-90 40 680)"
      fill="#E6E9F0"
    />
    <mask id="path-41-inside-3_13561_32782" fill="white">
      <path d="M56 644H924V680H56V644Z" />
    </mask>
    <path
      d="M924 679H56V681H924V679Z"
      fill="#E6E9F0"
      mask="url(#path-41-inside-3_13561_32782)"
    />
    <path
      d="M56 648C56 645.791 57.7909 644 60 644H140C142.209 644 144 645.791 144 648V656C144 658.209 142.209 660 140 660H60C57.7909 660 56 658.209 56 656V648Z"
      fill="#E6E9F0"
    />
    <path
      d="M764 648C764 645.791 765.791 644 768 644H920C922.209 644 924 645.791 924 648V664C924 666.209 922.209 668 920 668H768C765.791 668 764 666.209 764 664V648Z"
      fill="#E6E9F0"
    />
    <rect
      x="40"
      y="692"
      width="12"
      height="4"
      transform="rotate(-90 40 692)"
      fill="#E6E9F0"
    />
    <circle cx="42" cy="702" r="10" fill="#E6E9F0" />
    <rect
      x="40"
      y="808"
      width="96"
      height="4"
      transform="rotate(-90 40 808)"
      fill="#E6E9F0"
    />
    <mask id="path-48-inside-4_13561_32782" fill="white">
      <path d="M56 692H924V808H56V692Z" />
    </mask>
    <path
      d="M924 807H56V809H924V807Z"
      fill="#E6E9F0"
      mask="url(#path-48-inside-4_13561_32782)"
    />
    <path
      d="M56 696C56 693.791 57.7909 692 60 692H140C142.209 692 144 693.791 144 696V704C144 706.209 142.209 708 140 708H60C57.7909 708 56 706.209 56 704V696Z"
      fill="#E6E9F0"
    />
    <path
      d="M184 696C184 693.791 185.791 692 188 692H444C446.209 692 448 693.791 448 696V708C448 710.209 446.209 712 444 712H188C185.791 712 184 710.209 184 708V696Z"
      fill="#E6E9F0"
    />
    <path
      d="M184 720C184 717.791 185.791 716 188 716H364C366.209 716 368 717.791 368 720V732C368 734.209 366.209 736 364 736H188C185.791 736 184 734.209 184 732V720Z"
      fill="#E6E9F0"
    />
    <path
      d="M844 696C844 693.791 845.791 692 848 692H920C922.209 692 924 693.791 924 696V712C924 714.209 922.209 716 920 716H848C845.791 716 844 714.209 844 712V696Z"
      fill="#E6E9F0"
    />
    <path
      d="M184 756C184 753.791 185.791 752 188 752H444C446.209 752 448 753.791 448 756V768C448 770.209 446.209 772 444 772H188C185.791 772 184 770.209 184 768V756Z"
      fill="#E6E9F0"
    />
    <path
      d="M184 780C184 777.791 185.791 776 188 776H364C366.209 776 368 777.791 368 780V792C368 794.209 366.209 796 364 796H188C185.791 796 184 794.209 184 792V780Z"
      fill="#E6E9F0"
    />
    <path
      d="M844 756C844 753.791 845.791 752 848 752H920C922.209 752 924 753.791 924 756V772C924 774.209 922.209 776 920 776H848C845.791 776 844 774.209 844 772V756Z"
      fill="#E6E9F0"
    />
    <rect
      x="40"
      y="820"
      width="12"
      height="4"
      transform="rotate(-90 40 820)"
      fill="#E6E9F0"
    />
    <circle cx="42" cy="830" r="10" fill="#E6E9F0" />
    <path
      d="M56 824C56 821.791 57.7909 820 60 820H140C142.209 820 144 821.791 144 824V832C144 834.209 142.209 836 140 836H60C57.7909 836 56 834.209 56 832V824Z"
      fill="#E6E9F0"
    />
    <path
      d="M184 824C184 821.791 185.791 820 188 820H444C446.209 820 448 821.791 448 824V836C448 838.209 446.209 840 444 840H188C185.791 840 184 838.209 184 836V824Z"
      fill="#E6E9F0"
    />
    <path
      d="M184 848C184 845.791 185.791 844 188 844H364C366.209 844 368 845.791 368 848V860C368 862.209 366.209 864 364 864H188C185.791 864 184 862.209 184 860V848Z"
      fill="#E6E9F0"
    />
    <rect x="724" y="822" width="200" height="40" rx="20" fill="#E6E9F0" />
  </svg>
);

DesktopSkeleton.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  borderClassName: PropTypes.string,
};

export default memo(DesktopSkeleton);
