import React from 'react';

import { H2, Paragraph } from '../../../../components/Typography';

const AlreadyHasInsurance = () => {
  return (
    <>
      <H2 align="center" className="mb-4">
        You've already added renters insurance
      </H2>
      <Paragraph align="center" size="large" className="mb-24">
        You can view your renters insurance in your TurboTenant account.
      </Paragraph>
    </>
  );
};

export default AlreadyHasInsurance;
