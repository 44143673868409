import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const RedirectRoute = ({ from = '', to, ...rest }) => (
  <Route
    {...rest}
    path={from}
    render={({ match, location }) => {
      const params = from.split('/').filter((param) => param.startsWith(':'));
      let destination = to;
      params.forEach((param) => {
        destination = destination.replace(param, match.params[param.slice(1)]);
      });
      destination += location.search;
      return <Redirect to={destination} />;
    }}
  />
);

RedirectRoute.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string.isRequired,
};

export default RedirectRoute;
