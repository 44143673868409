import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './Spinner.module.scss';

const Spinner = ({ secondary, className, ...props }) => (
  <div
    className={cx(
      styles.spinner,
      className,
      {
        [styles.secondary]: secondary,
        [styles.primary]: !secondary,
      },
      'tt-spinner', // used for testing
    )}
    data-qa={props['data-qa']}
    data-spinner
  >
    <div className={cx(styles.bounce1)} />
    <div className={cx(styles.bounce2)} />
    <div className={cx(styles.bounce3)} />
  </div>
);

Spinner.propTypes = {
  'secondary': PropTypes.bool,
  'className': PropTypes.string,
  'data-qa': PropTypes.string,
};

export default Spinner;
