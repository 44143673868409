// 60.0 Send Work Order
// Triggered: Triggered when a landlord submits the Send Work Order Form
// Audience: 3rd Party (Contractor, maintenance person, etc.)
// FROM: support@turbotenant.com
// REPLY-TO: <landlord's email>
// ----------
// Campaign_id: maintenance_request_notification_LL
// Template: Renter - Normal Transactional Email
// Subject Work Order from [Landlord Name]
// Graphic: Maintenance

import React from 'react';
import PropTypes from 'prop-types';

import config from '../../../config';
import formatAddress from '../../../utils/format-address';
import {
  BackgroundBox,
  H1New,
  H3,
  H4,
  HrNew,
  Link,
  List,
  ListItemNew,
  P,
} from '../components/EmailTypography';
import Logo from '../components/Logo';
import NormalTransactionalTemplate from '../components/NormalTransactionalTemplate';

const MaintenanceRequestWorkOrder = ({
  owner: { first_name: ownerFirstName, last_name: ownerLastName },
  messageFromOwner,
  maintenanceRequest: { title, description },
  listing,
  photos,
  renter,
  isPreview,
  ...otherProps
}) => {
  const {
    first_name: renterFirstName,
    last_name: renterLastName,
    telephone: renterTelephone,
    email: renterEmail,
  } = renter || {};

  return (
    <NormalTransactionalTemplate
      {...otherProps}
      banner={`${config.assetsBaseUrl}/headers/Maintenance-Banner.jpg`}
      hideLogo={true}
      love={false}
      isPreview={isPreview}
      previewText={description}
    >
      <H4>Maintenance Request</H4>

      <H1New style={{ marginTop: '4px', marginBottom: '4px' }}>
        You Received a Work Order
      </H1New>

      <P style={{ marginBottom: '24px' }}>
        {ownerFirstName} {ownerLastName} sent you a work order for their rental
        property: {formatAddress(listing)}.
      </P>

      {messageFromOwner && (
        <BackgroundBox style={{ padding: '16px', marginBottom: '24px' }}>
          <P
            style={{
              marginBottom: '8px',
              fontSize: '16px',
              fontWeight: 'bold',
            }}
          >
            Message from {ownerFirstName} {ownerLastName}:
          </P>
          <P style={{ marginBottom: '0' }}>
            <i
              style={{
                whiteSpace: 'pre-wrap',
              }}
            >
              {messageFromOwner}
            </i>
          </P>
        </BackgroundBox>
      )}

      <HrNew />

      <H3 style={{ marginBottom: '16px' }}>Maintenance Request</H3>

      <P style={{ marginBottom: '4px', fontWeight: 'bold' }}>{title}</P>

      <P style={{ marginBottom: '24px', whiteSpace: 'pre-wrap' }}>
        {description}
      </P>

      {photos && photos.length > 0 && (
        <P style={{ marginBottom: 0, fontWeight: 'bold' }}>
          Photos of the issue are listed below:
        </P>
      )}

      {photos && photos.length > 0 && (
        <List style={{ marginBottom: '24px' }}>
          {photos.map((photo, index) => (
            <ListItemNew key={index}>
              <Link
                href={photo.url}
                style={{ color: '#2370A3' }}
                target="_blank"
                disabled={isPreview}
              >
                Photo {index + 1}
              </Link>
            </ListItemNew>
          ))}
        </List>
      )}

      {(renterFirstName || renterLastName) && (
        <>
          <P style={{ marginBottom: '4px' }}>
            <b>Tenant's Contact Information</b>
          </P>
          <P style={{ marginBottom: '4px' }}>
            {renterFirstName} {renterLastName}
          </P>
          {renterTelephone && (
            <P style={{ marginTop: '4px', marginBottom: '4px' }}>
              <Link
                href={`tel:${renterTelephone.replace(/[^\d]/g, '')}`}
                disabled={isPreview}
              >
                {renterTelephone}
              </Link>
            </P>
          )}
          <P style={{ marginTop: '4px', marginBottom: '32px' }}>
            <Link href={`mailto:${renterEmail}`} disabled={isPreview}>
              {renterEmail}
            </Link>
          </P>
        </>
      )}

      <HrNew />

      <div
        style={{ textAlign: 'center', marginTop: '24px', marginBottom: '24px' }}
      >
        <Logo />
      </div>

      <P style={{ textAlign: 'center', fontStyle: 'italic' }}>
        This landlord uses TurboTenant to manage their rental property.
        Interested in creating great rental experiences yourself?
      </P>

      <div style={{ textAlign: 'center', marginBottom: '24px' }}>
        <Link
          href="https://www.turbotenant.com/how-it-works/?utm_campaign=Content%20Promotion%20in%20Product&utm_source=email&utm_content=Maintenance%20Work%20Order"
          style={{ color: '#2370A3' }}
          disabled={isPreview}
        >
          Streamline how you manage your property
        </Link>
      </div>
    </NormalTransactionalTemplate>
  );
};

MaintenanceRequestWorkOrder.propTypes = {
  owner: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
  }),
  messageFromOwner: PropTypes.string,
  maintenanceRequest: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
  listing: PropTypes.shape({
    address: PropTypes.string.isRequired,
    unit: PropTypes.string,
    room_name: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
  }),
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  ),
  renter: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    telephone: PropTypes.string,
    email: PropTypes.string.isRequired,
  }),
  isPreview: PropTypes.bool,
};

export default MaintenanceRequestWorkOrder;
