import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { H2, Paragraph } from '../../../../components/Typography';

import styles from './RentersInsurance.module.scss';

const RequireInsurance = ({ description, isUrgent }) => {
  return (
    <>
      <H2 align="center" className="mb-4">
        Renters insurance is required
        {description && (
          <>
            :{' '}
            <span className={cx(styles.span, { [styles.urgent]: isUrgent })}>
              {description}
            </span>
          </>
        )}
      </H2>
      <Paragraph align="center" size="large" className="mb-24">
        Upload proof or purchase affordable coverage through TurboTenant to
        avoid lease violations.
      </Paragraph>
    </>
  );
};

RequireInsurance.propTypes = {
  description: PropTypes.string,
  isUrgent: PropTypes.bool,
};

export default RequireInsurance;
