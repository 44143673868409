const loadChildren = (listing_children) => {
  const childrenArray = [];
  const mapChildren = (listing_children) => {
    listing_children.map((item) => {
      childrenArray.push(item);
      if (Array.isArray(item.children_listings)) {
        mapChildren(item.children_listings);
      }
    });
  };

  mapChildren(listing_children);

  return childrenArray;
};

export default loadChildren;
